<template>
  <div>
    <div
      v-for="l in logs"
      :key="l.createdAt"
      :class="{ errorLog: l.error, row: true }"
    >
      <div class="q-pa-sm col-2">
        {{ $d(new Date(l.createdAt), "long") }}
      </div>
      <div class="q-pa-sm col-1">
        {{ $t("flags." + extractLanguage(l.objectId)) }}
      </div>
      <div class="q-pa-sm col-8">{{ l.message }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";
import { articleService } from "@/services/ArticleService";
import { ElcanoNotificationType, EventTrackingObjectDto } from "@/types/api";
import { signalRService } from "@/services/SignalrService";

const logs = ref<EventTrackingObjectDto[]>([]);

const props = defineProps<{
  id: number;
}>();

/**
 * Détermine la langue à partir de l'objectId du log.
 */
function extractLanguage(objectId: string) {
  return objectId.split(":")[1];
}

/**
 * Recharge les logs pour l'article.
 */
async function refreshLogs() {
  logs.value.splice(0);
  logs.value.push(...(await articleService.getLogs(props.id)));
}

onMounted(async () => {
  console.log("onMounted LogsArticle");
  signalRService.subscribeToMessageType(
    `article:${props.id}`,
    ElcanoNotificationType.ArticleLogUpdate,
    callbackUpdateMessage
  );
  await refreshLogs();
});

onUnmounted(async () => {
  signalRService.unsubscribeToMessageType(
    `article:${props.id}`,
    ElcanoNotificationType.ArticleLogUpdate,
    callbackUpdateMessage
  );
});

/**
 * Retour signalr lors de la modification du message.
 */
function callbackUpdateMessage() {
  console.log("Article mis à jour.");
  refreshLogs();
}
</script>

<style lang="scss">
.errorLog {
  background-color: red;
  color: white;
}
</style>
