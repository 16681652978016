<template>
  <div class="row q-gutter-sm">
    <q-select
      v-model="publication"
      class="col"
      :options="publications"
      filled
      dense
      emit-value
      map-options
      :label="$t('export.fields.publication')"
    />
    <q-select
      v-model="selectedYear"
      class="col"
      :options="yearOptions"
      filled
      dense
      emit-value
      map-options
      use-input
    />
    <q-input
      v-for="b in budgets"
      :key="b.month"
      v-model.number="b.amount"
      class="col-12"
      type="number"
      filled
      :label="months[b.month - 1] + ' ' + b.year + ' (' + b.idPublication + ')'"
    />
    <q-btn
      class="col-auto"
      color="primary"
      dense
      unelevated
      :label="$t('contribution.freelances.buttons.save')"
      @click="saveData"
    />
  </div>
</template>

<script setup lang="ts">
import { date, useQuasar } from "quasar";
import { ref, onMounted, watch } from "vue";
import { useConfigStore } from "@/stores/config";
import { freelanceBudgetService } from "@/services/FreelanceBudgetService";
import { getPublicationOptions } from "@/helpers/publication";
import { FreelanceBudgetDto } from "@/types/api";
import { formatErrorMessage } from "@/utils";
import { i18n } from "@/i18n";

const $q = useQuasar();
const configStore = useConfigStore();
const selectedYear = ref<number>(new Date().getFullYear());
const publication = ref<string>(configStore.$state.config.publication);
const publications = getPublicationOptions({ allOption: false });
const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2022 },
  (_, i) => 2023 + i
).sort((a, b) => b - a);
const budgets = ref<FreelanceBudgetDto[]>([]);
const months = defineMonths();
watch([publication, selectedYear], () => {
  refreshData();
});

onMounted(async () => {
  refreshData();
});

/** Chargement des données */
async function refreshData() {
  budgets.value = await freelanceBudgetService.getBudgets(
    publication.value,
    selectedYear.value,
    undefined,
    false
  );
}

/** Chargement des données */
async function saveData() {
  try {
    await freelanceBudgetService.updateBudgets(
      publication.value,
      selectedYear.value,
      budgets.value
    );

    $q.notify({
      type: "succes",
      message: i18n.t("contribution.freelances.notifications.savedBudgets"),
    });
  } catch (e) {
    console.error(e);
    const message = formatErrorMessage(
      e,
      "contribution.freelances.notifications.errorOnsaveBudgets"
    );
    $q.notify({
      type: "warning",
      message,
    });
  }
}

/** Définition des mois */
function defineMonths() {
  const d = new Date(2020, 0, 1);
  const months = [] as string[];
  for (let i = 0; i < 12; i++) {
    months.push(date.formatDate(d, "MMMM"));
    d.setMonth(d.getMonth() + 1);
  }
  return months;
}
</script>
