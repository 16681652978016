<template>
  <q-list bordered class="rounded-borders">
    <q-item-label header>Assignments</q-item-label>
    <q-expansion-item
      v-for="(profileAssignment, idx) in profileAssignments"
      :key="'profileAssignment' + idx"
      :label="getAssignmentLabel(profileAssignment.idAssignment)"
      expand-separator
    >
      <q-card :class="!Dark.isActive ? 'bg-grey-2' : ''">
        <q-card-section>
          <div class="q-gutter-md">
            <q-select
              v-model="profileAssignment.idPublication"
              :options="
                getPublicationOptions({
                  idPublicationToAdd: profileAssignment.idPublication,
                })
              "
              map-options
              emit-value
              filled
              label="publication"
              @update:model-value="onPublicationChanged(profileAssignment)"
            />
            <q-select
              v-model="profileAssignment.sections"
              :options="
                getSectionOptions({
                  publication: profileAssignment.idPublication,
                })
              "
              map-options
              emit-value
              filled
              multiple
              use-chips
              stack-label
              label="rubriques"
              @update:model-value="
                $emit('update:model-value', profileAssignments)
              "
            />
          </div>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>
</template>
<script setup lang="ts">
import type { ProfileAssignmentDto } from "@/types/api";
import { Dark } from "quasar";
import { toRefs } from "vue";
import { useUserStore } from "@/stores/user";
import { useLocale } from "@/utils";
import { getPublicationOptions } from "@/helpers/publication";
import { getSectionOptions } from "@/helpers/section";

const props = defineProps<{
  modelValue: ProfileAssignmentDto[];
}>();
const emits = defineEmits<{
  (e: "update:model-value", value: ProfileAssignmentDto[]): void;
}>();
const { modelValue: profileAssignments } = toRefs(props);
const { assignments } = useUserStore();
const { isFr } = useLocale();

/**
 * Function pour récupérer le nom du assignment
 */
function getAssignmentLabel(idAssignment: string) {
  const assignment = assignments.find((r) => r.id === idAssignment);
  if (!assignment) return "";
  return isFr.value ? assignment.labelFr : assignment.labelEn;
}

/**
 * Fonction appelé lors de la selection d'une publication
 */
function onPublicationChanged(profileAssignment: ProfileAssignmentDto) {
  profileAssignment.sections = [];
  emits("update:model-value", profileAssignments.value);
}
</script>
