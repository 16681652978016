<template>
  <q-dialog
    ref="dialogRef"
    persistent
    :maximized="$q.screen.lt.sm"
    @hide="onDialogHide"
  >
    <q-card class="dialog-wrapper">
      <q-bar
        :style="
          'background-color:' +
          getThemeByPublication(config.publication)?.primary
        "
        class="dialog-header text-white"
      >
        <div>
          {{ $t("associatedArticles.dialogs.suggestedAssociationArticles") }}
        </div>
        <q-space />
        <q-btn v-close-popup dense flat icon="close" />
      </q-bar>
      <q-card-section v-if="suggestedArticles.length > 0">
        <q-list padding>
          <AssociatedArticleItem
            v-for="r in suggestedArticles"
            :key="r.id"
            :article="r"
          >
            <template #actions="{ article }">
              <q-btn
                :icon="iconForArticle(article.id)"
                flat
                @click.stop="handleClickOnArticle(article.id)"
              />
            </template>
          </AssociatedArticleItem>
        </q-list>
        <div class="row justify-center" style="margin-bottom: 20px">
          <q-btn
            :label="$t('associatedArticles.dialogs.btnCancel')"
            style="margin-left: 20px"
            color="black"
            @click="onDialogCancel()"
          />
          <q-btn
            :label="$t('associatedArticles.dialogs.btnAdd')"
            style="margin-left: 20px"
            color="primary"
            :disable="chosenArticles.length == 0"
            @click="onDialogOK(chosenArticles)"
          />
        </div>
      </q-card-section>
      <q-card-section v-else>
        <div class="row justify-center">
          <q-icon name="warning" size="3em" color="warning" />
          {{ $t("associatedArticles.dialogs.noSuggestedArticles") }}
        </div>
        <div class="row justify-center">
          <q-btn
            :label="$t('associatedArticles.dialogs.btnClose')"
            style="margin-left: 20px"
            color="black"
            @click="onDialogCancel()"
          />
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent } from "quasar";
import type { ArticleDto } from "@/types/api";
import { ref } from "vue";
import { useConfigStore } from "@/stores/config";
import { getThemeByPublication } from "@/theme";
import AssociatedArticleItem from "@/components/Article/AssociatedArticleItem.vue";

const props = defineProps<{
  articles: ArticleDto[];
}>();
defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogCancel, onDialogOK, onDialogHide } =
  useDialogPluginComponent();

const suggestedArticles = ref<ArticleDto[]>(props.articles);
const { config } = useConfigStore();

const chosenArticles = ref<ArticleDto[]>([]);

/**
 * Ajoute l'article dans les articles choisis.
 */
function handleClickOnArticle(id: number) {
  if (chosenArticles.value.some((p) => p.id == id)) {
    let indexArticleToRemove = chosenArticles.value.findIndex(
      (p) => p.id == id
    );
    chosenArticles.value.splice(indexArticleToRemove, 1);
  } else {
    let articleToAdd = suggestedArticles.value.find((p) => p.id == id);

    if (articleToAdd) {
      chosenArticles.value.push(articleToAdd);
    }
  }
}

/**
 *
 */
function iconForArticle(id: number) {
  if (chosenArticles.value.some((p) => p.id == id)) return "check_box";
  else return "add_circle";
}
</script>
<style scoped>
.dialog-header {
  height: auto;
  min-height: 32px;
}
@media screen and (min-width: 600px) {
  .dialog-wrapper {
    width: 1000px;
    max-width: 80vw;
  }
}
</style>
