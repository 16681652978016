<template>
  <q-card bordered>
    <q-card-section>
      <FormCountry :default-country="country" @submit="actions.onEditCountry" />
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import { useCountryActions } from "@/composables/useCountryActions";
import FormCountry from "@/components/Country/FormCountry.vue";

const route = useRoute();

const { country, actions } = useCountryActions();

actions.findCountry(parseInt(route.params.id as string, 10));
</script>
