import type { AxiosResponse } from "axios";
import type { SuggestionDto, SuggestionUpdateDto } from "@/types/api";
import type { SuggestionServiceInstance } from "@/types/suggestion";
import { AxiosRequest } from "@/services/ElcanoAPI";

/**
 * Service pour récupérer la liste des utilisateurs
 */
class SuggestionService
  extends AxiosRequest
  implements SuggestionServiceInstance
{
  rootPath: string;
  /**
   * Constructeur de la class Suggestion service
   */
  constructor(rootPath: string) {
    super();
    this.rootPath = rootPath;
  }

  /**
   *
   */
  getSuggestion(id: string): Promise<SuggestionDto> {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}`)
      .then((response: AxiosResponse<SuggestionDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  addSuggestion(suggestion: SuggestionDto): Promise<SuggestionDto> {
    return this.axiosInstance
      .post(`${this.rootPath}`, suggestion)
      .then((response: AxiosResponse<SuggestionDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  updateSuggestion(suggestion: SuggestionUpdateDto): Promise<SuggestionDto> {
    return this.axiosInstance
      .put(`${this.rootPath}/${suggestion.id}`, suggestion)
      .then((response: AxiosResponse<SuggestionDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const suggestionService = new SuggestionService("suggestions");
