import type { RouteRecordRaw } from "vue-router";
import { defineAsyncComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import { RoutesName } from "@/router/routesName";
import i18n from "@/i18n";
import { authService } from "@/services/AuthService";
import { versionInfoService } from "@/services/VersionInfoService";
import {
  showReloadFrontRequiredDialog,
  showAPIUnevailableDialog,
} from "@/composables/useMessageDialogPresets";
import { useConfigStore } from "@/stores/config";
import { RightReference } from "@/types/api";

import PageHome from "@/views/PageHome.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import PageAbout from "@/views/PageAbout.vue";
import PageSettings from "@/views/PageSettings.vue";

// Les vues des pages articles
import PageArticle from "@/views/Article/PageArticle.vue";
import PageListArticle from "@/views/Article/ListArticle.vue";
import PageEditArticle from "@/views/Article/EditArticle.vue";
import PagePlanning from "@/views/Article/PlanningArticle.vue";
import PageSearch from "@/views/Article/SearchArticle.vue";

// Les vues des pages drafts
import PageListDraft from "@/views/Draft/ListDraft.vue";

// Les vues des pages import
import PageImport from "@/views/Import/PageImport.vue";

// Les vues des pages publications
import PagePublication from "@/views/Publication/PagePublication.vue";
import PageListPublication from "@/views/Publication/ListPublication.vue";
import PageCreatePublication from "@/views/Publication/CreatePublication.vue";
import PageEditPublication from "@/views/Publication/EditPublication.vue";

// Les vues des pages medias
import PageMedia from "@/views/Media/PageMedia.vue";

// Les vues des pages Paramètres article
import PageArticleSettings from "@/views/ArticleSettings/PageArticleSettings.vue";
import PageListArticleSettings from "@/views/ArticleSettings/ListArticleSettings.vue";
import PageEditArticleSetting from "@/views/ArticleSettings/EditArticleSetting.vue";
import PageCreateArticleSetting from "@/views/ArticleSettings/CreateArticleSetting.vue";

// Les vues des pages formats
import PageFormat from "@/views/Format/PageFormat.vue";
import PageListFormat from "@/views/Format/ListFormat.vue";
import PageCreateFormat from "@/views/Format/CreateFormat.vue";
import PageEditFormat from "@/views/Format/EditFormat.vue";

// Les vues des pages rubriques
import PageSection from "@/views/Section/PageSection.vue";
import PageListSection from "@/views/Section/ListSection.vue";
import PageCreateSection from "@/views/Section/CreateSection.vue";
import PageEditSection from "@/views/Section/EditSection.vue";

// Les vues des pages pays
import PageCountry from "@/views/Country/PageCountry.vue";
import PageListCountry from "@/views/Country/ListCountry.vue";
import PageCreateCountry from "@/views/Country/CreateCountry.vue";
import PageEditCountry from "@/views/Country/EditCountry.vue";

// Les vues des pages utilisateurs
import PageUser from "@/views/User/PageUser.vue";
import PreviewArticle from "@/components/Article/PreviewArticle.vue";

// Les vues des pages freelances
import PageFreelances from "@/views/Freelances/PageFreelances.vue";

// Les vue des pages SearchAndReplace
import PageSearchAndReplace from "@/views/SearchAndReplace/PageSearchAndReplace.vue";
import PageReport from "@/views/SearchAndReplace/Report.vue";
import PageListReport from "@/views/SearchAndReplace/ListReport.vue";

//Les vues des pages d'aide
import PageHelp from "@/views/PageHelp.vue";

// Planning des articles format long
import PagePlanningLongFormat from "@/views/PlanningLongFormat/PagePlanningLongFormat.vue";

// Administation des mails
import PageMailTemplate from "@/views/MailTemplate/PageMailTemplate.vue";

// Les vues des pages export
import PageExport from "@/views/Export/PageExport.vue";

const ttt = defineAsyncComponent(() => import("@/components/Ee/ttt.vue"));

const BYPASS_CHECK_MATCH_API_FRONT_BACK_MODE_DEV = true; //Indique si on bypass la vérification de la version de l'api front et back si mode de developpement vite

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    name: RoutesName.Home,
    component: PageHome,
  },
  {
    path: "/publications",
    component: PagePublication,
    children: [
      {
        path: "",
        name: RoutesName.Publications,
        component: PageListPublication,
      },
      {
        path: "create",
        name: RoutesName.CreatePublication,
        component: PageCreatePublication,
        meta: {
          rights: RightReference.ADMIN_FULL,
        },
      },
      {
        path: ":id",
        name: RoutesName.EditPublication,
        component: PageEditPublication,
        meta: {
          rights: RightReference.ADMIN_FULL,
        },
      },
    ],
  },
  {
    path: "/articles",
    component: PageArticle,
    children: [
      {
        path: "",
        name: RoutesName.Articles,
        component: PageListArticle,
      },
      {
        path: ":id",
        name: RoutesName.EditArticle,
        component: PageEditArticle,
      },
      {
        path: "search",
        name: RoutesName.Search,
        component: PageSearch,
      },
    ],
  },
  {
    path: "/drafts",
    name: RoutesName.Drafts,
    component: PageListDraft,
  },
  {
    path: "/schedule",
    component: PageArticle,
    children: [
      {
        path: "",
        name: RoutesName.Planning,
        component: PagePlanning,
      },
    ],
  },
  {
    path: "/import",
    name: RoutesName.Import,
    component: PageImport,
  },
  {
    path: "/media",
    name: RoutesName.Media,
    component: PageMedia,
  },
  {
    path: "/articleSettings",
    component: PageArticleSettings,
    children: [
      {
        path: "",
        name: RoutesName.ArticleSettings,
        component: PageListArticleSettings,
      },
      {
        path: "create",
        name: RoutesName.CreateFormat,
        component: PageCreateArticleSetting,
      },
      {
        path: ":id",
        name: RoutesName.EditArticleSetting,
        component: PageEditArticleSetting,
      },
    ],
  },
  {
    path: "/sections",
    component: PageSection,
    children: [
      {
        path: "",
        name: RoutesName.Sections,
        component: PageListSection,
      },
      {
        path: "create",
        name: RoutesName.CreateSection,
        component: PageCreateSection,
      },
      {
        path: ":id",
        name: RoutesName.EditSection,
        component: PageEditSection,
      },
    ],
  },
  {
    path: "/formats",
    component: PageFormat,
    children: [
      {
        path: "",
        name: RoutesName.Formats,
        component: PageListFormat,
      },
      {
        path: "create",
        name: RoutesName.CreateFormat,
        component: PageCreateFormat,
      },
      {
        path: ":id",
        name: RoutesName.EditFormat,
        component: PageEditFormat,
      },
    ],
  },
  {
    path: "/freelances",
    children: [
      {
        path: "",
        name: RoutesName.FreelanceManagement,
        component: PageFreelances,
        meta: {
          rights: RightReference.FREELANCE_MANAGEMENT,
        },
      },
      {
        path: "budget",
        name: RoutesName.FreelanceBudget,
        component: PageFreelances,
        meta: {
          rights: RightReference.FREELANCE_MANAGEMENT,
        },
      },
      {
        path: "consumption",
        name: RoutesName.FreelanceConsumption,
        component: PageFreelances,
        meta: {
          rights: RightReference.FREELANCE_MANAGEMENT,
        },
      },
    ],
  },
  {
    path: "/searchAndReplace",
    name: RoutesName.SearchAndReplace,
    component: PageSearchAndReplace,
    children: [
      {
        path: "",
        name: RoutesName.ListReport,
        component: PageListReport,
      },
      {
        path: ":id",
        name: RoutesName.Report,
        component: PageReport,
      },
    ],
  },
  {
    path: "/longFormatPlanning",
    name: RoutesName.LongFormatPlanning,
    component: PagePlanningLongFormat,
  },
  {
    path: "/help",
    name: RoutesName.Help,
    component: PageHelp,
  },
  {
    path: "/countries",
    component: PageCountry,
    children: [
      {
        path: "",
        name: RoutesName.Countries,
        component: PageListCountry,
      },
      {
        path: "create",
        name: RoutesName.CreateCountry,
        component: PageCreateCountry,
      },
      {
        path: ":id",
        name: RoutesName.EditCountry,
        component: PageEditCountry,
      },
    ],
  },
  {
    path: "/users",
    children: [
      {
        path: "",
        name: RoutesName.Users,
        component: PageUser,
      },
      {
        path: "profiles",
        name: RoutesName.Profiles,
        component: PageUser,
      },
      {
        path: "assignments",
        name: RoutesName.Assignments,
        component: PageUser,
      },
      {
        path: "rightGroups",
        name: RoutesName.RightGroups,
        component: PageUser,
      },
      {
        path: "rights",
        name: RoutesName.Rights,
        component: PageUser,
      },
    ],
  },
  {
    path: "/about",
    name: RoutesName.About,
    component: PageAbout,
  },
  {
    path: "/settings",
    name: RoutesName.Settings,
    component: PageSettings,
  },
  {
    path: "/mails",
    name: RoutesName.MailTemplate,
    component: PageMailTemplate,
  },
  {
    path: "/ttt",
    name: "ttt",
    component: ttt,
  },
  {
    path: "/partial",
    children: [
      {
        path: "preview/:articleIds",
        name: RoutesName.Preview,
        component: PreviewArticle,
        props: true,
      },
    ],
  },
  {
    path: "/export",
    children: [
      {
        path: "freelance",
        name: RoutesName.ExportFreelance,
        component: PageExport,
      },
      {
        path: "translation",
        name: RoutesName.ExportTranslation,
        component: PageExport,
      },
    ],
  },
  {
    path: "/:catchAll(.*)*",
    name: RoutesName.NotFound,
    component: PageNotFound,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeResolve((to) => {
  if (!to.meta.rights || to.meta.rights.length == 0) return;
  if (typeof to.meta.rights === "string")
    return authService.hasRight(to.meta.rights as string);
  if (Array.isArray(to.meta.rights))
    return to.meta.rights.some((r) => authService.hasRight(r));
});

router.beforeEach(async (to, from, next) => {
  sanitizeRouteFromKeycloak(to);
  to.meta.title = i18n.global.t(`titles.${to.name?.toString()}`);
  const apiVersionCompatibility = await checkIfFrontMatchApi();

  if ((await checkIfApiIsUp()) === false || apiVersionCompatibility === null) {
    showAPIUnevailableDialog();
    next(false);
  } else if (apiVersionCompatibility === false) {
    showReloadFrontRequiredDialog();
    next(false);
  } else {
    next();
  }
});

/**
 * Permet de vérifier si l'api est en ligne
 * @returns Renvoi true si OK
 */
async function checkIfApiIsUp() {
  try {
    return await versionInfoService.checkOnline();
  } catch (error) {
    return false;
  }
}

/**
 * Permet de vérifier que la version de l'API est bien compatible avec la version du front
 * @returns Renvoi true si la version de l'api est compatible avec la version du front et null si l'api est down
 */
async function checkIfFrontMatchApi() {
  if (import.meta.env.DEV && BYPASS_CHECK_MATCH_API_FRONT_BACK_MODE_DEV) {
    console.log("Bypass du check API Front/API Back , serveur de dev vite");
    return true;
  }

  try {
    const response = await versionInfoService.getApiVersion();
    //convert response to number
    const apiVersion = Number(response);
    const configStore = useConfigStore();

    if (apiVersion === 0) return null;

    if (apiVersion > Number(configStore.config.apiVersion)) {
      console.log("API version Front : " + configStore.config.apiVersion);
      console.log("API version Back : " + apiVersion);
      console.log("API version missmatch");
      return false;
    } else {
      return true;
    }
  } catch (error) {
    return false;
  }
}

const sanitizeRouteFromKeycloak = function sanitizeRouteFromKeycloak(to) {
  if (
    to.hash !== null &&
    to.hash.indexOf("#state=") >= 0 &&
    to.hash.indexOf("&session_state=") >= 0
  ) {
    to.hash = "";
    to.fullPath = to.fullPath.split("#")[0];
  }
};

export default router;
