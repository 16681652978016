<template>
  <q-dialog ref="dialogRef" persistent>
    <q-card class="q-dialog-plugin" style="width: 500px; max-width: 80vw">
      <q-card-section>
        <div class="text-h6">
          {{
            i18n.t("longFormat.dialogs.messages.createSeriesTitle", {
              format: props.labelFormat,
            })
          }}
        </div>
      </q-card-section>

      <q-card-actions align="center" class="q-gutter-md">
        <q-radio
          v-for="conf in availableReleaseConfigs"
          :key="conf.idFormat"
          v-model="selectedConfigKey"
          :val="conf.key"
          :label="
            i18n.t(`longFormat.dialogs.messages.labelCycle${conf.cycle}`, {
              count: conf.weekOfMonth,
              day: i18n.t(`common.dayOfWeek.${conf.dayOfWeek}`, 2),
            })
          "
        />
      </q-card-actions>
      <q-card-section v-if="selectedConfigKey && availableDates.length > 0">
        <CustomInputDate
          v-model="seriesStartDate"
          type="date"
          dense
          :available-dates="availableDates"
          :label="$t('longFormat.dialogs.messages.seriesStartDate')"
        />
      </q-card-section>
      <q-list bordered>
        <q-item v-for="(author, index) in authors" :key="index" clickable>
          <q-item-section v-if="selectedConfig?.idPublication !== 'LLA'" side>
            <q-btn
              size="xs"
              class="q-mr-xs"
              dense
              flat
              @click="toggleLanguage(author)"
            >
              <q-tooltip> {{ $t("article.labels.btnChangeLang") }} </q-tooltip>
              <img
                :alt="author.language"
                class="img-flag"
                :src="getFlagPath(author.language)"
              />
            </q-btn>
          </q-item-section>
          <q-item-section>
            <q-select
              v-model="author.author"
              :options="contributorAuthorFiltered"
              emit-value
              map-options
              use-input
              filled
              dense
              input-debounce="50"
              autocomplete="mainLabel"
              clearable
              :label="formatDate(author.date)"
              @filter="filterInputAuthor"
            />
          </q-item-section>
          <q-item-section side>
            <q-btn
              v-if="index > 0 && index === authors.length - 1"
              icon="close"
              flat
              @click="authors.splice(index, 1)"
            />
          </q-item-section>
        </q-item>
      </q-list>
      <q-card-actions align="center" class="q-gutter-md">
        <q-btn
          v-if="availableDatesForSeries.length > authors.length"
          :label="$t('longFormat.dialogs.messages.addArticleOnSeries')"
          color="green-7"
          size="md"
          @click="onAddArticle"
        />
        <span
          v-if="selectedConfigKey && !availableDates.length"
          class="q-ml-md"
        >
          Pas de dates disponibles pour créer une série
        </span>
        <span
          v-if="
            selectedConfigKey &&
            availableDatesForSeries.length &&
            availableDatesForSeries.length === authors.length
          "
          class="q-ml-md"
        >
          Toutes les dates disponibles ont été utilisées
        </span>
      </q-card-actions>
      <q-card-actions align="center" class="q-gutter-md">
        <q-btn
          :disable="!authors.length || authors.some((a) => !a.author)"
          :label="$t('longFormat.dialogs.messages.createSeries')"
          color="primary"
          size="md"
          @click="onCreateSeries"
        />
        <q-btn
          :label="$t('article.dialogs.messages.cancel')"
          color="grey"
          size="md"
          @click="
            {
              selectedConfigKey = '';
              dialogRef?.hide();
            }
          "
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { getContributorOptions } from "@/helpers/contributor";
import { i18n } from "@/i18n";
import {
  ContributionType,
  LongFormatInformation,
  SeriesArticle,
} from "@/types/api";
import { removeDiacritics } from "@/utils";
import { QSelectOption, date, useDialogPluginComponent } from "quasar";
import { computed, ref, watch } from "vue";
import CustomInputDate from "@/components/Custom/CustomInputDate.vue";
import { getFlagPath, EnglishLanguage, FrenchLanguage } from "@/constants";
import { getAvailableDates } from "@/helpers/longFormatInformation";

const authors = ref<{ author: string; date: string; language: string }[]>([]);
const props = defineProps<{
  format: string | undefined;
  labelFormat: string | undefined;
  longFormatInfo: LongFormatInformation | undefined;
}>();
const emits = defineEmits<{
  (
    e: "update:authors",
    value: {
      publication: string;
      format: string;
      startDate: string;
      endDate: string;
      articles: SeriesArticle[];
    }
  ): void;
}>();
const currentFormatInfo = ref<LongFormatInformation | undefined>(
  props.longFormatInfo
);
const currentFormat = ref<string>(props.format ?? "");
const { dialogRef } = useDialogPluginComponent();
const seriesStartDate = ref<string>("");
const selectedConfigKey = ref<string>();
const availableReleaseConfigs = computed(() => {
  return (
    currentFormatInfo.value?.releaseConfigurations.filter(
      (rc) => rc.idFormat === currentFormat.value
    ) ?? []
  );
});
const selectedConfig = computed(() => {
  return currentFormatInfo.value?.releaseConfigurations.find(
    (rc) => selectedConfigKey.value === rc.key
  );
});

const availableDates = computed(() => {
  return getAvailableDates(currentFormatInfo.value, selectedConfigKey.value);
});
/** construit une liste de dates disponibles pour la série sans discontinuité */
const availableDatesForSeries = computed(() => {
  const dates = availableDates.value.map(
    (d) => d.replaceAll("/", "-") + "T00:00:00"
  );
  if (!dates.length) return [];

  const firstDate =
    seriesStartDate.value.replaceAll("/", "-").replace(".000", "") ?? "";
  const firstDateAvailableIndex = dates.indexOf(firstDate);
  const firstDateConfigIndex =
    selectedConfig.value?.availableDates.indexOf(firstDate);

  if (firstDateAvailableIndex == undefined || firstDateConfigIndex == undefined)
    return [];

  for (let i = 0; i < dates.length; i++) {
    if (
      dates[firstDateAvailableIndex + i] !==
      selectedConfig.value?.availableDates[firstDateConfigIndex + i]
    ) {
      return dates.slice(firstDateAvailableIndex, i + firstDateAvailableIndex);
    }
  }

  return dates.slice(firstDateAvailableIndex);
});
watch(availableDates, () => {
  if (!availableDates.value.length) seriesStartDate.value = "";
  else
    seriesStartDate.value =
      availableDates.value[0].replaceAll("/", "-") + "T00:00:00" ?? "";
});
watch(seriesStartDate, () => {
  if (!seriesStartDate.value) return;

  const contributors = contributorAuthorOptions.value;

  const indexAvailableDate = selectedConfig.value?.availableDates.findIndex(
    (d) => d.split("T")[0] === seriesStartDate.value.split("T")[0]
  );

  let contributorsToUse = contributors[0].value;

  if (indexAvailableDate && indexAvailableDate > 0) {
    const article = selectedConfig.value?.articles.find(
      (a) =>
        a.publishedOn?.split("T")[0] ===
        selectedConfig.value?.availableDates[indexAvailableDate - 1].split(
          "T"
        )[0]
    );
    const lastAuthor = article?.contributions.find(
      (c) => c.type == ContributionType.Author
    );
    const indexLastAuthor = contributors.findIndex(
      (c) => c.value === lastAuthor?.idContributor
    );
    contributorsToUse =
      contributors[indexLastAuthor + 1]?.value ?? contributors[0].value;
  }

  authors.value = [
    {
      author: contributors.length ? contributorsToUse : "",
      date: seriesStartDate.value,
      language: FrenchLanguage,
    },
  ];
});

const contributorAuthorOptions = computed<QSelectOption<string>[]>(() => {
  return getContributorOptions({
    publication: availableReleaseConfigs.value?.[0]?.idPublication ?? "",
    type: ContributionType.Author,
  });
});

watch(
  () => props.longFormatInfo,
  (value) => {
    currentFormatInfo.value = value;
    authors.value = [];
    selectedConfigKey.value = undefined;
  }
);
watch(
  () => props.format,
  (value) => {
    currentFormat.value = value ?? "";
    authors.value = [];
    selectedConfigKey.value = undefined;

    if (
      currentFormat.value &&
      currentFormatInfo.value &&
      currentFormatInfo.value.releaseConfigurations.some(
        (rc) => rc.idFormat === value
      )
    ) {
      selectedConfigKey.value =
        currentFormatInfo.value.releaseConfigurations.find(
          (rc) => rc.idFormat === value
        )?.key ?? "";
    }
  }
);
watch(selectedConfigKey, () => {
  authors.value = [];
});

/** Ajout d'un auteur */
function onAddArticle() {
  let indexOfLastAuthor =
    contributorAuthorOptions.value.findIndex(
      (v) => v.value === authors.value[authors.value.length - 1].author
    ) + 1;

  if (indexOfLastAuthor >= contributorAuthorOptions.value.length)
    indexOfLastAuthor = 0;

  authors.value.push({
    author: contributorAuthorOptions.value[indexOfLastAuthor].value,
    date: availableDatesForSeries.value[authors.value.length],
    language: FrenchLanguage,
  });
}

/** Formatage de la date */
function formatDate(value: string) {
  return date.formatDate(value, "dddd DD MMMM");
}

const authorFilterNeedle = ref<string>("");
const contributorAuthorFiltered = computed(() => {
  if (authorFilterNeedle.value === "") {
    return contributorAuthorOptions.value;
  }

  return contributorAuthorOptions.value.filter(
    (v) =>
      removeDiacritics(v.label)
        .toLowerCase()
        .indexOf(authorFilterNeedle.value) > -1
  );
});

/** filtre les auteurs proposés dans la liste */
function filterInputAuthor(val, update) {
  update(() => {
    authorFilterNeedle.value = removeDiacritics(val).toLowerCase();
  });
}

/** Validation de la série */
function onCreateSeries() {
  emits("update:authors", {
    publication:
      currentFormatInfo.value?.releaseConfigurations[0].idPublication ?? "",
    format: currentFormat.value,
    startDate: authors.value[0].date,
    endDate: authors.value[authors.value.length - 1].date,
    articles: authors.value.map((a) => ({
      author: a.author,
      language: a.language,
    })),
  });
  dialogRef.value?.hide();
}

/** Toggle la langue de l'article */
function toggleLanguage(params: { language: string }) {
  params.language =
    params.language === FrenchLanguage ? EnglishLanguage : FrenchLanguage;
}
</script>
<style lang="scss">
.img-flag {
  width: 32px;
  height: 32px;
  margin: auto 0px;
}
</style>
