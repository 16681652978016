<template>
  <q-table
    :rows="profiles"
    :columns="columns"
    :grid="$q.screen.xs"
    :filter="filter"
    bordered
    :rows-per-page-options="[10, 20, 0]"
    @row-click="onRowClick"
  >
    <template #top-left>
      <slot name="actions"></slot>
    </template>
    <template #top-right>
      <q-input
        v-model="filter"
        borderless
        dense
        debounce="300"
        :placeholder="$t('profile.fields.shortSearch')"
      >
        <template #append>
          <q-icon name="search" />
        </template>
      </q-input>
    </template>
  </q-table>
</template>
<script setup lang="ts">
import type { QTableColumn } from "quasar";
import type { ProfileDto } from "@/types/api";
import { ref } from "vue";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { i18n } from "@/i18n";

const emits = defineEmits<{
  (e: "rowClick", profile: ProfileDto): void;
}>();

const filter = ref("");
const userStore = useUserStore();
const { profiles } = storeToRefs(userStore);
const columns: QTableColumn<ProfileDto>[] = [
  {
    name: "id",
    label: i18n.t("profile.fields.shortId"),
    align: "left",
    field: (row) => row.id,
  },
  {
    name: "labelFr",
    label: i18n.t("profile.fields.shortLabelFr"),
    field: (row) => row.labelFr,
  },
  {
    name: "labelEn",
    label: i18n.t("profile.fields.shortLabelEn"),
    field: (row) => row.labelEn,
  },
  {
    name: "initialConfig",
    label: i18n.t("profile.fields.initialConfig"),
    field: (row) => row.initialConfig,
  },
];
/**
 * Fonction appelé lors d'un click sur une des lignes du tableau des profiles
 */
function onRowClick(evt: Event, row: ProfileDto) {
  emits("rowClick", row);
}
</script>
