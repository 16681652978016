<template>
  <div v-if="articleForm.id !== 0" class="q-gutter-y-sm">
    <div class="row q-col-gutter-x-xs">
      <q-toggle
        v-model="fridge"
        :true-value="false"
        :false-value="true"
        dense
        :disable="baseIsLocked"
        :style="'font-weight: bold;'"
        :label="publishedOnLabel"
        :tooltip="$t('article.fields.fridgeTooltip')"
        @update:model-value="handleFridgeChanged"
      />
    </div>
    <div class="row">
      <q-icon
        v-if="!fridge && isLongFormat"
        class="col-1 q-mb-md"
        name="warning"
        color="red-9"
        size="xs"
        style="cursor: pointer"
      >
        <q-tooltip>{{
          $t("article.messages.warnLongFormatPublisedOn")
        }}</q-tooltip>
      </q-icon>
      <CustomInputDate
        v-if="!fridge"
        ref="publishedOn"
        v-model="articleForm.publishedOn"
        class="col"
        dense
        :type="showTime ? 'datetime-local' : 'date'"
        :show-time="showTime"
        :disable="baseIsLocked"
        :label="$t('article.fields.publishedOn')"
      />
    </div>
    <div class="row q-col-gutter-x-xs">
      <q-toggle
        v-model="hasDeadline"
        dense
        :disable="baseIsLocked"
        :style="'font-weight: bold;'"
        :label="$t('article.fields.deadline')"
        @update:model-value="handleDeadlineChanged"
      />
    </div>
    <div class="row q-col-gutter-x-xs">
      <CustomInputDate
        v-if="hasDeadline"
        ref="deadline"
        v-model="articleForm.deadline"
        class="col"
        dense
        type="date"
        :disable="baseIsLocked"
        :label="$t('article.fields.deadline')"
      />
    </div>
    <div v-if="!fridge" class="row q-col-gutter-x-xs">
      <q-toggle
        v-if="!fridge"
        v-model="summaryOf"
        type="date"
        color="grey-9"
        dense
        :disable="baseIsLocked"
        :true-value="false"
        :false-value="true"
        :label="$t('article.fields.summaryOf')"
        :tooltip="$t('article.fields.summaryOfTooltip')"
        @update:model-value="handleSummaryOfChanged"
      />
    </div>
    <div v-if="!fridge" class="row q-col-gutter-x-xs">
      <CustomInputDate
        v-if="summaryOf"
        ref="summary"
        v-model="articleForm.summaryOf"
        class="col"
        type="date"
        dense
        :disable="baseIsLocked"
        :label="$t('article.fields.summaryOfLabel')"
        :show-time="false"
      />
    </div>
    <div v-if="!fridge && hasTranslation" class="row q-col-gutter-x-xs">
      <q-toggle
        v-model="publishedOnTranslation"
        color="grey-9"
        dense
        :disable="baseIsLocked"
        :true-value="false"
        :false-value="true"
        :label="publishedOnTranslationLabel"
        @update:model-value="handlePublishedOnTranslationChanged"
      />
    </div>
    <div v-if="!fridge && hasTranslation" class="row q-col-gutter-x-xs">
      <CustomInputDate
        v-if="publishedOnTranslation && hasTranslation"
        ref="publishedOn"
        v-model="articleForm.publishedOnTranslation"
        class="col"
        dense
        :type="showTime ? 'datetime-local' : 'date'"
        :show-time="showTime"
        :disable="baseIsLocked"
        :label="$t('article.fields.publishedOnTranslationLabel')"
      />
    </div>
    <div
      v-if="publishedOnTranslation && hasTranslation"
      class="row q-col-gutter-x-xs"
    >
      <q-toggle
        v-if="publishedOnTranslation"
        v-model="summaryOfTranslation"
        color="grey-9"
        :true-value="false"
        :false-value="true"
        dense
        :show-time="false"
        :disable="baseIsLocked"
        :type="'date'"
        :label="$t('article.fields.summaryOfTranslation')"
        @update:model-value="handleSummaryOfTranslationChanged"
      />
    </div>
    <div
      v-if="publishedOnTranslation && hasTranslation"
      class="row q-col-gutter-x-xs"
    >
      <CustomInputDate
        v-if="summaryOfTranslation"
        ref="summary"
        v-model="articleForm.summaryOfTranslation"
        dense
        type="date"
        class="col"
        :disable="baseIsLocked"
        :label="$t('article.fields.summaryOfTranslationLabel')"
      />
    </div>

    <q-separator class="q-mt-none" />
    <q-select
      ref="section"
      v-model="articleForm.sectionPrimary"
      for="articleSections"
      :disable="baseIsLocked"
      :label="$t('article.fields.mainSection')"
      filled
      :options="sectionOptionsMain"
      emit-value
      map-options
      :style="'font-weight: bold;'"
    >
      <template #option="scope">
        <q-item v-bind="scope.itemProps">
          <q-item-label>{{ scope.opt.label }}</q-item-label>
        </q-item>
      </template>
      <template #label>
        <span class="q-px-sm bg-primary text-white rounded-borders">{{
          $t("article.fields.mainSection")
        }}</span>
      </template>
    </q-select>
    <CustomMultiSelect
      v-model="articleForm.sectionSecondaries"
      :disable="baseIsLocked"
      :label="$t('article.fields.secondariesSections')"
      :options="sectionOptionsSecondary"
      :option-disable="
        (section) => articleForm.sectionPrimary === section.value
      "
    />
    <q-separator />
    <CustomMultiSelect
      v-model="articleForm.countries"
      :disable="baseIsLocked"
      :options="countryOptions"
      :label="$t('article.fields.countries')"
    />
    <q-separator />
    <q-tabs
      v-model="tabLang"
      align="left"
      active-color="primary"
      indicator-color="primary"
      dense
      narrow-indicator
      :breakpoint="0"
    >
      <q-tab
        v-for="(articleContent, indexArticleContent) in articleForm.contents"
        :key="'tab' + indexArticleContent"
        :label="$t(`languages.${articleContent.language}`)"
        :name="articleContent.language"
      />
    </q-tabs>
    <q-separator class="q-mt-none" />
    <q-tab-panels v-model="tabLang" animated>
      <q-tab-panel
        v-for="(articleContent, indexArticleContent) in articleForm.contents"
        :key="'tabPanel' + indexArticleContent"
        :name="articleContent.language"
        class="q-px-none q-px-md"
      >
        <div class="q-gutter-md">
          <q-select
            ref="format"
            v-model="articleContent.format"
            for="articleFormat"
            label="Format"
            filled
            :disable="!!languagesLocked[articleContent.language]"
            :options="formatOptions"
            :rules="[(val) => !!val || 'Le champ est requis']"
            emit-value
            map-options
          />
        </div>
      </q-tab-panel>
    </q-tab-panels>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { getSectionOptions, getSections } from "@/helpers/section";
import { getCountryOptions } from "@/helpers/country";
import { useFormatStore } from "@/stores/format";
import { getFormatOptions } from "@/helpers/format";
import CustomInputDate from "@/components/Custom/CustomInputDate.vue";
import CustomMultiSelect from "@/components/Custom/CustomMultiSelect.vue";
import { getNextEditionDay } from "@/helpers/article";
import { calculateWorkingDay } from "@/utils";
import { i18n } from "@/i18n";
const { formats } = useFormatStore();
const longFormats = formats.filter((f) => f.long).map((f) => f.id);

const {
  currentArticle: articleForm,
  lang: tabLang,
  baseIsLocked,
  languagesLocked,
} = storeToRefs(useCurrentArticleStore());

const hasTranslation = computed(() => {
  return articleForm.value.contents.some(
    (c) => c.language !== articleForm.value.idMainLanguage
  );
});
const fridge = computed(() => !Boolean(articleForm.value.publishedOn));
const isLongFormat = computed(() => {
  return (
    articleForm.value.publication != "GL" &&
    articleForm.value.contents.some((c) => longFormats.indexOf(c.format) >= 0)
  );
});
const hasDeadline = computed(() => Boolean(articleForm.value.deadline));
const summaryOf = computed(() => Boolean(articleForm.value.summaryOf));
const publishedOnTranslation = computed(() =>
  Boolean(articleForm.value.publishedOnTranslation)
);
const summaryOfTranslation = computed(() =>
  Boolean(articleForm.value.summaryOfTranslation)
);

const nextPublicationDate = getNextEditionDay();

const publishedOnDefault =
  articleForm.value.publishedOn ?? nextPublicationDate.toLocaleISOString();
const sectionOptionsMain = computed(() => {
  const idSections = [articleForm.value.sectionPrimary].filter(Boolean);

  return getSectionOptions({
    publication: articleForm.value.publication,
    allOption: false,
    idSectionsToAdd: idSections as number[],
    main: true,
  });
});
const sectionOptionsSecondary = computed(() => {
  const idSections = [...articleForm.value.sectionSecondaries].filter(Boolean);

  return getSectionOptions({
    publication: articleForm.value.publication,
    allOption: false,
    idSectionsToAdd: idSections as number[],
  });
});
const countryOptions = computed(() => {
  const idCountries = articleForm.value.countries.map((c) => c.id);
  return getCountryOptions(false, idCountries);
});
const formatOptions = computed(() => {
  const content = articleForm.value.contents.find(
    (c) => c.language == tabLang.value
  );
  return getFormatOptions({
    publication: articleForm.value.publication,
    allOption: false,
    idFormatToAdd: content?.format,
  });
});

const showTime = computed(() => {
  if (
    articleForm.value.contents.some((c) =>
      c.settings.some((s) => s.key1 === "JUSTRELEASED")
    )
  )
    return true;

  const sectionIds = [
    articleForm.value.sectionPrimary ?? 0,
    ...articleForm.value.sectionSecondaries,
  ];

  var sections = getSections(sectionIds);

  if (sections.some((s) => s.labelFr.toUpperCase().startsWith("ALERT")))
    return true;

  return false;
});

const publishedOnLabel = computed(() => {
  if (showTime.value) return i18n.t("article.fields.datePublication");

  return i18n.t("article.fields.datePublicationWithTime", {
    time: articleForm.value.publishedOn?.split("T")[1].substring(0, 5),
  });
});

const publishedOnTranslationLabel = computed(() => {
  if (articleForm.value.publishedOnTranslation === undefined)
    return i18n.t("article.fields.publishedOnTranslationAtSameDate");

  if (showTime.value) return i18n.t("article.fields.publishedOnTranslation");

  return i18n.t("article.fields.publishedOnTranslationWithTime", {
    time: articleForm.value.publishedOn?.split("T")[1].substring(0, 5),
  });
});

/** Modification du mode frigo */
function handleFridgeChanged(state) {
  if (state) {
    articleForm.value.publishedOnTranslation = undefined;
    articleForm.value.summaryOf = undefined;
    articleForm.value.summaryOfTranslation = undefined;
    articleForm.value.publishedOn = undefined;
    return;
  }

  articleForm.value.publishedOn = publishedOnDefault;
}
/** Modification de la date sommaire */
function handleSummaryOfChanged(state) {
  if (!state || articleForm.value.publishedOn === undefined) {
    articleForm.value.summaryOf = undefined;
    return;
  }

  const published = new Date(articleForm.value.publishedOn);
  const nextEdition = getNextEditionDay(published);
  articleForm.value.summaryOf = nextEdition.toLocaleISOString();
}
/** Modification de la date de publication de la traduction */
function handlePublishedOnTranslationChanged(state) {
  if (!state) {
    articleForm.value.summaryOfTranslation = undefined;
    articleForm.value.publishedOnTranslation = undefined;
    return;
  }

  articleForm.value.publishedOnTranslation = articleForm.value.publishedOn;
}
/** Modification de la date sommaire de la traduction */
function handleSummaryOfTranslationChanged(state) {
  if (!state || articleForm.value.publishedOnTranslation === undefined) {
    articleForm.value.summaryOfTranslation = undefined;
    return;
  }

  const published = new Date(articleForm.value.publishedOnTranslation);
  const nextEdition = getNextEditionDay(published);
  articleForm.value.summaryOfTranslation = nextEdition.toLocaleISOString();
}
/** Modification du mode frigo */
function handleDeadlineChanged(state) {
  if (state) {
    if (articleForm.value.publishedOn === undefined) {
      articleForm.value.deadline = undefined;
    } else {
      articleForm.value.deadline = calculateWorkingDay(
        new Date(articleForm.value.publishedOn),
        -1
      ).toLocaleISOString();
    }
  } else {
    articleForm.value.deadline = undefined;
  }
  console.log("handleDeadlineChanged", articleForm.value.deadline);
}
</script>
