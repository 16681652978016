/**
 * Liste des routes possible à appeler par un nom
 */
export enum RoutesName {
  /**
   * Group admin
   */
  Admin = "Admin",
  /**
   * Page d'accueil
   */
  Home = "Home",
  /**
   * Page qui liste les publications
   */
  Publications = "Publications",
  /**
   * Page pour créer une publication
   */
  CreatePublication = "CreatePublication",
  /**
   * Page pour éditer une publication
   */
  EditPublication = "EditPublication",
  /**
   * Page qui liste les articles
   */
  Articles = "Articles",
  /**
   * Page pour créer un article
   */
  CreateArticle = "CreateArticle",
  /**
   * Page pour éditer un article
   */
  EditArticle = "EditArticle",
  /**
   * Page pour l'historique article
   */
  HistoryArticle = "HistoryArticle",
  /**
   * Page pour les paramètres article
   */
  ParamsArticle = "ParamsArticles",
  /**
   * Page pour les contributions article
   */
  ContributionsArticle = "ContributionsArticle",
  /**
   * Page pour les notifications article
   */
  NotificationsArticle = "NotificationsArticle",
  /**
   * Page des logs article
   */
  LogsArticle = "LogsArticle",
  /**
   * Page des locks article
   */
  LocksArticle = "LocksArticle",
  /**
   * Page des enrichissements
   */
  AssociatedArticles = "AssociatedArticles",
  /**
   * Page qui liste les rubriques
   */
  Sections = "Sections",
  /**
   * Page pour créer une rubrique
   */
  CreateSection = "CreateSection",
  /**
   * Page pour éditer une rubrique
   */
  EditSection = "EditSection",
  /**
   * Page qui liste les formats
   */
  Formats = "Formats",
  /**
   * Page pour créer un format
   */
  CreateFormat = "CreateFormat",
  /**
   * Page pour éditer un format
   */
  EditFormat = "EditFormat",
  /**
   * Page qui liste les pays
   */
  Countries = "Countries",
  /**
   * Page pour créer un pays
   */
  CreateCountry = "CreateCountry",
  /**
   * Page pour éditer un pays
   */
  EditCountry = "EditCountry",
  /**
   * Page d'informations
   */
  About = "About",
  /**
   * Page non trouvée
   */
  NotFound = "NotFound",
  /**
   * Page de configuration
   */
  Settings = "Settings",
  /**
   * Page de planning article
   */
  Planning = "Planning",
  /**
   * Page de recherche d'article
   */
  Search = "Search",
  /**
   * Page des imports
   */
  Import = "Import",
  /**
   * Page des medias
   */
  Media = "Media",
  /**
   * Page des brouillons
   */
  Drafts = "Drafts",
  /**
   * Page des paramètres article
   */
  ArticleSettings = "ArticleSettings",
  /**
   * Page pour créer un paramètre d'artice
   */
  CreateArticleSetting = "CreateArticleSetting",
  /**
   * Page pour modifier un paramètre d'artice
   */
  EditArticleSetting = "EditArticleSetting",
  /**
   * Page qui liste les utilisateurs
   */
  Users = "Users",
  /**
   * Page pour modifier un utilisateur
   */
  EditUser = "EditUser",
  /**
   * Page qui liste les profiles
   */
  Profiles = "Profiles",
  /**
   * Page qui liste les groupes de droit
   */
  RightGroups = "RightGroups",
  /**
   * Page qui liste les droits
   */
  Rights = "Rights",
  /**
   * Page qui liste les assignments
   */
  Assignments = "Assignments",
  /**
   * Page d'impression d'articles
   */
  Preview = "Preview",

  /**
   * Page pour gérer les piges
   */
  FreelanceManagement = "FreelanceManagement",

  /**
   * Page pour gérer le budget des piges
   */
  FreelanceBudget = "FreelanceBudget",

  /**
   * Page pour consulter la consommation des piges
   */
  FreelanceConsumption = "FreelanceConsumption",

  /**
   * Page pour gérer l'aide de l'application
   */
  Help = "Help",

  /**
   * Page pour gérer l'harmonisation des mot clés
   */
  SearchAndReplace = "SearchAndReplace",

  /**
   * Page pour créer une rapport
   */
  CreateReport = "CreateReport",

  /**
   * Page pour voir un rapport
   */
  Report = "Report",

  /**
   * Page pour voir la liste des rapports
   */
  ListReport = "ListReport",

  /**
   * Page pour l'export des piges
   */
  ExportFreelance = "ExportFreelance",

  /**
   * Page pour l'export des traductions
   */
  ExportTranslation = "ExportTranslation",

  /**
   * Page pour le planning des formats longs
   */
  LongFormatPlanning = "LongFormatPlanning",

  /**
   * Page pour l'administration des mails
   */
  MailTemplate = "MailTemplate",
}
