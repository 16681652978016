<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', param)">
    <q-input
      v-model="param.searchText"
      filled
      :label="$t('searchAndReplace.labels.searchedText')"
      class="col"
      stack-label
    />
    <q-input
      v-model="param.replaceText"
      filled
      :label="$t('searchAndReplace.labels.replacedText')"
      class="col"
      stack-label
    />
    <CustomMultiSelect
      v-model="countries"
      :options="countryOptions"
      multiple
      :label="$t('searchAndReplace.labels.country')"
      @update:model-value="handleCountries($event)"
    >
    </CustomMultiSelect>
    <CustomMultiSelect
      v-model="param.mainSections"
      :options="sectionOptionsMain"
      multiple
      clearable
      :label="$t('searchAndReplace.labels.mainSection')"
      emit-value
    >
    </CustomMultiSelect>
    <CustomMultiSelect
      v-model="param.subSections"
      :options="sectionOptionsSecondary"
      multiple
      clearable
      :label="$t('searchAndReplace.labels.secondarySection')"
      emit-value
    >
    </CustomMultiSelect>
    <q-select
      v-model="param.publications"
      multiple
      filled
      clearable
      :options="
        getPublicationOptions({
          allOption: false,
          publicationsActive: true,
        })
      "
      :label="$t('searchAndReplace.labels.publications')"
      emit-value
      stack-label
    >
    </q-select>
    <q-select
      v-model="param.language"
      :options="languages"
      clearable
      filled
      :display-value="param.language ? $t(`languages.${param.language}`) : ''"
      :label="$t('searchAndReplace.labels.language')"
      stack-label
    >
      <template #option="scope">
        <q-item v-bind="scope.itemProps">
          <q-item-label>{{ $t(`languages.${scope.opt}`) }}</q-item-label>
        </q-item>
      </template>
    </q-select>
    <q-input
      v-model="fromDate"
      filled
      clearable
      stack-label
      :label="$t('searchAndReplace.labels.startingDate')"
    >
      <template #append>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date
              v-model="fromDate"
              @update:model-value="handleDateFrom($event)"
            >
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
    <q-input
      v-model="untilDate"
      filled
      clearable
      stack-label
      :label="$t('searchAndReplace.labels.endingDate')"
    >
      <template #append>
        <q-icon name="event" class="cursor-pointer">
          <q-popup-proxy cover transition-show="scale" transition-hide="scale">
            <q-date
              v-model="untilDate"
              @update:model-value="handleDateUntil($event)"
            >
              <div class="row items-center justify-end">
                <q-btn v-close-popup label="Close" color="primary" flat />
              </div>
            </q-date>
          </q-popup-proxy>
        </q-icon>
      </template>
    </q-input>
    <div class="q-mt-md q-gutter-md text-right">
      <q-btn
        :label="$t('searchAndReplace.labels.btnCreate')"
        type="submit"
        color="primary"
      />
    </div>
  </q-form>
</template>

<script lang="ts" setup>
import type { CountryDto, ParamSearchAndReplace } from "@/types/api";
import { computed, ref } from "vue";
import { getCountryOptions } from "@/helpers/country";
import CustomMultiSelect from "@/components/Custom/CustomMultiSelect.vue";
import { getSectionOptions } from "@/helpers/section";
import { useLanguageStore } from "@/stores/language";
import { getPublicationOptions } from "@/helpers/publication";
import { createParam } from "@/services/reportService";
import { QSelectOption } from "quasar";

defineEmits<{
  (e: "submit", value: ParamSearchAndReplace): void;
}>();

const countryOptions = getCountryOptions(false);
const countries = ref<CountryDto[]>([]);
const { languages } = useLanguageStore();
const fromDate = ref<string | undefined>();
const untilDate = ref<string | undefined>();

const param = ref<ParamSearchAndReplace>(createParam());

const sectionOptionsMain = computed(() => {
  if (param.value.publications.length > 0) {
    let options: QSelectOption<number>[] = [];

    param.value.publications.forEach((p) => {
      options = options.concat(
        getSectionOptions({
          sectionActive: true,
          allOption: false,
          main: true,
          publication: p,
        })
      );
    });

    return options;
  }

  return getSectionOptions({
    sectionActive: true,
    allOption: true,
    main: true,
  });
});

const sectionOptionsSecondary = computed(() => {
  if (param.value.publications.length > 0) {
    let options: QSelectOption<number>[] = [];

    param.value.publications.forEach((p) => {
      options = options.concat(
        getSectionOptions({
          sectionActive: true,
          allOption: false,
          main: false,
          publication: p,
        })
      );
    });

    return options;
  }

  return getSectionOptions({
    sectionActive: true,
    allOption: true,
    main: false,
  });
});

/**
 *
 */
function handleCountries(countries: CountryDto[]) {
  if (!countries) {
    param.value.idPays = [];
    return;
  }
  param.value.idPays = countries.map((c) => c.id);
}

/**
 *
 */
function handleDateFrom(date: string | null | number) {
  if (typeof date === "number") return;

  if (!date) {
    param.value.fromDate = undefined;
    return;
  }

  param.value.fromDate = new Date(date).toISOString();
}

/**
 *
 */
function handleDateUntil(date: string | null | number) {
  if (typeof date === "number") return;

  if (!date) {
    param.value.untilDate = undefined;
    return;
  }

  param.value.untilDate = new Date(date).toISOString();
}
</script>
