<template>
  <div>
    <q-form class="q-gutter-md" @submit.prevent.stop="onSubmit">
      <div class="row">
        <q-btn class="q-mr-xs" dense flat @click="toggleLanguage">
          <q-tooltip> {{ $t("article.labels.btnChangeLang") }} </q-tooltip>
          <img
            :alt="articleLanguage"
            class="img-flag"
            :src="getFlagPath(articleLanguage)"
          />
        </q-btn>
        <q-select
          v-model="articleForm.publication"
          class="col"
          dense
          filled
          :options="publicationOptions"
          emit-value
          map-options
        />
      </div>
      <q-select
        v-model="authors"
        :options="contributorAuthorFiltered"
        emit-value
        map-options
        use-input
        filled
        dense
        multiple
        input-debounce="50"
        autocomplete="mainLabel"
        :label="$t('contributionType.Author')"
        @filter="filterInputAuthor"
      />
      <q-input
        ref="articleTitleRef"
        v-model="articleTitle"
        :label="$t('article.fields.title')"
        :rules="[
          (val) => (val && val.length > 0) || $t('article.rules.title'),
          (val) => val.length <= 125 || $t('rules.maxlength', [125]),
        ]"
        lazy-rules
        filled
        dense
      />
      <q-select
        v-model="contentFormat"
        :label="$t('article.fields.format')"
        class="col"
        dense
        filled
        :options="formatOptions"
        emit-value
        map-options
      />
      <div class="row">
        <q-select
          v-model="articleForm.sectionPrimary"
          :label="$t('article.fields.mainSection')"
          class="col-12 col-sm-6"
          dense
          filled
          :options="sectionOptionsMain"
          emit-value
          map-options
        >
          <template #option="{ itemProps, opt }">
            <q-item v-bind="itemProps">
              <q-item-section>
                <q-item-label>{{ opt.label }}</q-item-label>
              </q-item-section>
            </q-item>
          </template>
        </q-select>
        <CustomMultiSelect
          v-model="articleForm.sectionSecondaries"
          :label="$t('article.fields.secondariesSections')"
          class="col-12 col-sm-6 q-mt-md q-mt-sm-none"
          dense
          :options="sectionOptionsSecondary"
          :option-disable="
            (section) => articleForm.sectionPrimary === section.value
          "
          @update:model-value="checkAlerteAndChangeIfPublicationDateMustChange"
        />
      </div>
      <CustomMultiSelect
        v-model="articleForm.countries"
        :label="$t('article.fields.countries')"
        :options="countryOptions"
        dense
      />
      <InputDate
        v-model="articleForm.publishedOn"
        type="date"
        dense
        :available-dates="availableDates"
        :label="$t('article.fields.publishedOn')"
      />
      <q-select
        v-model="serialSelected"
        :label="$t('article.fields.associatedSerial')"
        class="col"
        dense
        filled
        use-input
        :options="serialOptions"
        clearable
        emit-value
        map-options
        @filter="filterInput"
      />
      <div>
        <div class="q-mb-sm">
          <span class="text-subtitle2 text-weight-regular">{{
            $t("article.fields.pigisteSelect")
          }}</span>
        </div>
        <q-btn-toggle
          v-model="isFreelance"
          spread
          no-caps
          rounded
          unelevated
          toggle-color="primary"
          color="white"
          text-color="primary"
          style="border: 1px solid var(--q-primary)"
          :options="[
            { label: $t('article.labels.btnValid'), value: true },
            { label: $t('article.labels.btnNonValid'), value: false },
          ]"
        />
      </div>
      <q-select
        v-if="isFreelance"
        v-model="contributorFreelance.id"
        :options="contributorFreelanceFiltered"
        :label="$t('articleContribution.fields.contributor')"
        emit-value
        map-options
        filled
        dense
        use-input
        input-debounce="50"
        autocomplete="mainLabel"
        @filter="filterInputFreelance"
      />
      <q-input
        v-if="isFreelance"
        v-model="contributorFreelance.amount"
        class="amountInput"
        type="number"
        :label="$t('articleContribution.fields.amount')"
        filled
        dense
      >
        <template #append>€</template>
      </q-input>
      <div class="q-gutter-sm row">
        <q-btn
          :label="$t('article.labels.btnReset')"
          icon="restart_alt"
          size="sm"
          color="grey-5"
          @click="onReset"
        />
        <q-space />
        <q-btn
          :label="$t('article.labels.btnCreate')"
          icon="add_task"
          class="col-8"
          type="submit"
          color="green-6"
          :disable="isSubmitDisable"
        />
      </div>
    </q-form>
  </div>
</template>
<script lang="ts" setup>
import { type QSelectOption, type QInput, EventBus } from "quasar";
import type { ArticleDto, ContributionDto } from "@/types/api";
import type { ArticleForm } from "@/types/article";
import { ref, watch, computed, onMounted, inject } from "vue";
import { storeToRefs } from "pinia";
import { ContributionType, WorkflowState } from "@/types/api";
import {
  createArticleContent,
  createArticleForm,
} from "@/services/ArticleService";
import { authService } from "@/services/AuthService";
import { useLocale, removeDiacritics, getToday } from "@/utils";
import { getPublicationOptions } from "@/helpers/publication";
import { getCountryOptions } from "@/helpers/country";
import { getSectionOptions, getSections } from "@/helpers/section";
import { getFormatOptions } from "@/helpers/format";
import { useFormatStore } from "@/stores/format";
import { getContributorOptions } from "@/helpers/contributor";
import { useConfigStore } from "@/stores/config";
import { modificationType } from "@/components/Article/constant";
import InputDate from "@/components/Custom/CustomInputDate.vue";
import CustomMultiSelect from "@/components/Custom/CustomMultiSelect.vue";
import {
  getFlagPath,
  EnglishLanguage,
  FrenchLanguage,
  ArticleFormat,
} from "@/constants";
import { searchService } from "@/services/SearchService";
import { usePage } from "@/composables/usePage";

const bus = inject<EventBus>("bus");
const { showPanelRight } = usePage();
const { findFormat } = useFormatStore();

const emits = defineEmits<{
  (e: "submit", article: ArticleDto): void;
}>();

const { config } = storeToRefs(useConfigStore());
const { language } = useLocale();
const articleForm = ref<ArticleForm>(createArticleForm());
const articleLanguage = ref(language.value);
const articleTitle = ref("");
const articleTitleRef = ref<QInput>();
const contentFormat = ref("Article");
const availableDates = ref<string[] | undefined>(undefined);
const isFreelance = ref<boolean>();
const serialSelected = ref<number | undefined>();
const serials = ref<ArticleDto[]>([]);
const serialOptions = ref<QSelectOption<number>[]>([]);
const contributorFreelance = ref({
  id: "",
  amount: 0,
});
const publicationOptions = getPublicationOptions({
  publicationsActive: true,
  allOption: false,
});

const authors = ref<string[]>(
  authService.isRedactor(articleForm.value.publication)
    ? [authService.getUserId()]
    : []
);
const contributions = computed<ContributionDto[]>(() => {
  const list: ContributionDto[] = [];
  if (authors.value.length > 0) {
    authors.value.forEach((author) => {
      list.push({
        idContributor: author,
        type: ContributionType.Author,
      } as ContributionDto);
    });
    if (isFreelance.value) {
      list.push({
        idContributor: contributorFreelance.value.id,
        amount: contributorFreelance.value.amount,
        type: ContributionType.Freelancer,
      } as ContributionDto);
    }
  }
  return list;
});
const newArticleForm = computed(() => {
  return {
    ...articleForm.value,
    idMainLanguage: articleLanguage.value,
    contributions: contributions.value,
    contents: [
      createArticleContent({
        language: articleLanguage.value,
        title: articleTitle.value,
        format: contentFormat.value,
      }),
    ],
  } as ArticleForm;
});
const modifExist = computed(() => {
  return (
    modificationType.Base +
    (articleLanguage.value.startsWith("fr")
      ? modificationType.FrenchContent
      : modificationType.EnglishContent)
  );
});
const countryOptions = getCountryOptions(false);
const contributorFreelanceOptions = computed<QSelectOption<string>[]>(() => {
  return getContributorOptions({
    publication: articleForm.value.publication,
    type: ContributionType.Freelancer,
  });
});
const contributorFreelanceFiltered = ref<QSelectOption<string>[]>(
  contributorFreelanceOptions.value
);
const contributorAuthorOptions = computed<QSelectOption<string>[]>(() => {
  return getContributorOptions({
    publication: articleForm.value.publication,
    type: ContributionType.Author,
  });
});
const contributorAuthorFiltered = ref<QSelectOption<string>[]>(
  contributorAuthorOptions.value
);
const sectionOptionsMain = computed(() =>
  getSectionOptions({
    publication: articleForm.value.publication,
    allOption: false,
    main: true,
  })
);
const sectionOptionsSecondary = computed(() =>
  getSectionOptions({
    publication: articleForm.value.publication,
    allOption: false,
  })
);
const formatOptions = computed(() =>
  getFormatOptions({
    publication: articleForm.value.publication,
    allOption: false,
  })
);
const isSubmitDisable = computed<boolean>(() => {
  return (
    isFreelance.value === undefined ||
    articleTitle.value === "" ||
    contentFormat.value === "" ||
    articleForm.value.sectionPrimary === undefined ||
    authors.value.length === 0 ||
    (contributorFreelance.value.id === "" && isFreelance.value)
  );
});

bus?.on("formSimpleArticle:reset", () => onReset());
bus?.on(
  "formSimpleArticle:setBaseParam",
  (param: { format: string; availableDates: string[] }) => {
    contentFormat.value = param.format;
    availableDates.value = param.availableDates;
    if (param.availableDates.length > 0) {
      articleForm.value.publishedOn = param.availableDates[0];
    }
  }
);

onMounted(() => addFranceCountryForLLA());
watch(
  () => config.value.publication,
  () => {
    articleForm.value.publication = config.value.publication;
    addFranceCountryForLLA();
  }
);
watch(
  () => [articleForm.value.publication, showPanelRight.value],
  () => {
    if (showPanelRight.value) searchSerials();
  }
);
watch(
  () => articleForm.value.sectionPrimary,
  (newValue) => {
    articleForm.value.sectionSecondaries =
      articleForm.value.sectionSecondaries.filter((sec) => sec !== newValue);
  }
);
/**
 * Fonction appelé lorsque l'article est sauvegardé
 */
function onSubmit() {
  if (articleTitleRef.value?.validate() === false) return;

  const standfirstProperty =
    articleLanguage.value === FrenchLanguage ? "standfirstFr" : "standfirstEn";

  if (serialSelected.value) {
    newArticleForm.value.associatedArticles = [
      {
        idFromArticle: 0,
        idToArticle: serialSelected.value,
      },
    ];
  }

  try {
    const format = findFormat(contentFormat.value);
    const defaultStandfirst = format[standfirstProperty];

    if (defaultStandfirst)
      newArticleForm.value.contents[0].standfirst = defaultStandfirst;
  } catch {}

  articleForm.value = newArticleForm.value;
  emits("submit", exportToArticle(articleForm.value, modifExist.value));
}
/**
 *
 */
function onReset() {
  articleLanguage.value = language.value;
  articleTitle.value = "";
  contentFormat.value = "Article";
  isFreelance.value = undefined;
  articleForm.value = createArticleForm();
  availableDates.value = undefined;
}

/**
 * Appeler lors de la modification d'une rubrique secondaire
 * Check si la date de parution doit bouger dans le cas d'une alerte
 */
function checkAlerteAndChangeIfPublicationDateMustChange() {
  const sectionIds = [
    articleForm.value.sectionPrimary ?? 0,
    ...articleForm.value.sectionSecondaries,
  ];

  const sections = getSections(sectionIds);

  if (sections.some((s) => s.labelFr.toUpperCase().startsWith("ALERT"))) {
    articleForm.value.publishedOn = getToday(0).toLocaleISOString();
  }
}

/**
 * Toggle la langue de l'article
 */
function toggleLanguage() {
  articleLanguage.value =
    articleLanguage.value === FrenchLanguage ? EnglishLanguage : FrenchLanguage;
  updateSerialOptions();
}
/**
 *
 */
function exportToArticle(data: ArticleForm, modifType: number) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { sectionPrimary, sectionSecondaries, ...art } = data;
  const sectionActives = [
    ...sectionSecondaries.map((sec) => ({ main: false, section: sec })),
    sectionPrimary ? { main: true, section: sectionPrimary } : undefined,
  ].filter(Boolean);
  return {
    ...art,
    sections: sectionActives,
    modificationType: modifType,
  } as ArticleDto;
}
/** filtre les auteurs proposés dans la liste */
function filterInputAuthor(val, update) {
  if (val === "") {
    update(() => {
      contributorAuthorFiltered.value = contributorAuthorOptions.value;
    });
    return;
  }

  update(() => {
    const needle = removeDiacritics(val).toLowerCase();
    contributorAuthorFiltered.value = contributorAuthorOptions.value.filter(
      (v) => removeDiacritics(v.label).toLowerCase().indexOf(needle) > -1
    );
  });
}
/**
 *
 */
function filterInputFreelance(val, update) {
  if (val === "") {
    update(() => {
      contributorFreelanceFiltered.value = contributorFreelanceOptions.value;
    });
    return;
  }

  update(() => {
    const needle = removeDiacritics(val).toLowerCase();
    contributorFreelanceFiltered.value =
      contributorFreelanceOptions.value.filter(
        (v) => removeDiacritics(v.label).toLowerCase().indexOf(needle) > -1
      );
  });
}
/**
 * Ajout du pays France si la publication est LLA
 */
function addFranceCountryForLLA() {
  if (
    articleForm.value.countries.some(
      (c) => c.labelFr.toLowerCase() === "france"
    ) ||
    config.value.publication !== "LLA"
  )
    return;
  const country = countryOptions.find(
    (c) => c.value.labelFr.toLowerCase() === "france"
  );
  if (country) {
    articleForm.value.countries.push(country.value);
  }
}

/**
 * Fonction qui sert a filtrer dans les options
 */
async function filterInput(val, update) {
  update(() => {
    updateSerialOptions(val);
  });
}

/**
 * Met a jour les options des feuilletons
 */
function updateSerialOptions(search: string = "") {
  const needle = removeDiacritics(search).toLowerCase();
  serialOptions.value = serials.value
    .map(
      (a) =>
        a.contents.find((c) => c.language === articleLanguage.value) ??
        a.contents[0]
    )
    .filter(
      (c) =>
        c.state !== WorkflowState.Invalide &&
        (!search ||
          search.length < 2 ||
          removeDiacritics(c.title)
            .toLowerCase()
            .includes(needle.toLowerCase()))
    )
    .map((c) => ({
      label: c.title,
      value: c.idArticle,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

/**
 * Effectue la recherche
 * @param value recherche
 */
async function searchSerials() {
  const date = getToday(-730);
  const { data } = await searchService.simpleSearch({
    nbByPage: 50,
    deleted: false,
    publications: [articleForm.value.publication],
    formats: [ArticleFormat.Feuilleton],
    from: date.toUTCISOString(),
  });
  serials.value = data;
}
</script>
<style lang="scss" scoped>
.img-flag {
  width: 32px;
  height: 32px;
  margin: auto 0px;
}
.amountInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
