import { ArticleODataDto } from "@/types/api";
import DataSource from "devextreme/data/data_source";
import ODataStore from "devextreme/data/odata/store";
import { conf_elcanoapi_current } from "@/constants";
import { keycloakState } from "@/plugins/keycloak";
import type { FilterDescriptor, SortDescriptor } from "devextreme/data";

const articlesStore = new ODataStore<ArticleODataDto, "id">({
  url: conf_elcanoapi_current + "/odata/articles",
  key: "id",
  keyType: "Int32",
  version: 4,
  errorHandler(error) {
    console.error(error);
  },
  beforeSend(e) {
    e.headers = {
      Authorization: `Bearer ${keycloakState.token}`,
    };
  },
});

export const articlesSourceFactory = function (
  filter?: FilterDescriptor | Array<FilterDescriptor>,
  sort?: SortDescriptor<unknown> | Array<SortDescriptor<unknown>>
) {
  return new DataSource({
    store: articlesStore,
    expand: [
      "contents",
      "countries",
      "contributions",
      "associatedArticles",
      "contents.settings",
    ],
    filter,
    sort,
  });
};

export const articlesSource = new DataSource({
  store: articlesStore,
  expand: [
    "contents",
    "countries",
    "contributions",
    "associatedArticles",
    "contents.settings",
  ],
});
