<template>
  <Page>
    <q-card bordered>
      <q-card-section>
        <q-inner-loading :showing="loading">
          <q-spinner-cube color="orange" size="5.5em" />
        </q-inner-loading>

        <q-select
          v-model="idSelected"
          class="col-12 col-sm"
          :label="$t('mailTemplate.fields.label')"
          :options="options"
          emit-value
          map-options
          filled
        >
        </q-select>
        <q-form class="q-gutter-sm q-pt-sm column">
          <q-input
            ref="mainLabel"
            v-model="mailSelected.label"
            for="mailSelectedLabel"
            filled
            :label="$t('mailTemplate.fields.label')"
            class="col"
            :rules="[(val) => !!val || $t('rules.required')]"
          />
          <q-input
            ref="subjectFr"
            v-model="mailSelected.subjectFr"
            for="mailSelectedSubjectFr"
            filled
            :label="$t('mailTemplate.fields.subjectFr')"
            class="col"
            :rules="[(val) => !!val || $t('rules.required')]"
          />
          <q-input
            ref="subjectEn"
            v-model="mailSelected.subjectEn"
            for="mailSelectedSubjectEn"
            filled
            :label="$t('mailTemplate.fields.subjectEn')"
            class="col"
            :rules="[(val) => !!val || $t('rules.required')]"
          />
          <q-input
            ref="bodyFr"
            v-model="mailSelected.bodyFr"
            for="mailSelectedBodyFr"
            filled
            :label="$t('mailTemplate.fields.bodyFr')"
            type="textarea"
            class="col"
            :rules="[(val) => !!val || $t('rules.required')]"
          />
          <q-input
            ref="bodyEn"
            v-model="mailSelected.bodyEn"
            for="mailSelectedBodyEn"
            type="textarea"
            filled
            :label="$t('mailTemplate.fields.bodyEn')"
            class="col"
            :rules="[(val) => !!val || $t('rules.required')]"
          />
          <q-btn
            :label="$t('mailTemplate.labels.btnEdit')"
            color="primary"
            @click="editMailTemplate"
          />
        </q-form>
      </q-card-section>
    </q-card>
  </Page>
  <q-card> </q-card>
</template>

<script setup lang="ts">
import Page from "@/components/Layouts/Page.vue";
import { mailTemplateService } from "@/services/MailTemplateService";
import { MailTemplateDto } from "@/types/api";
import { QSelect, QSelectOption } from "quasar";
import { onMounted, ref, computed } from "vue";

const loading = ref(true);
const mails = ref([] as MailTemplateDto[]);
const idSelected = ref("");
const mailSelected = computed(
  () =>
    mails.value.find((mail) => mail.id === idSelected.value) ||
    ({} as MailTemplateDto)
);
const options = computed<QSelectOption<string>[]>(() => {
  return mails.value.map((mail) => ({
    label: `${mail.label} (${mail.id})`,
    value: mail.id,
  }));
});

onMounted(async () => {
  const getMails = await mailTemplateService.get();
  getMails.forEach((mail) => {
    mail.bodyEn = mail.bodyEn
      .replace(/<br>(?![\r\n])/g, "<br>\r\n")
      .replace(/<br\/>(?![\r\n])/g, "<br>\r\n");
    mail.bodyFr = mail.bodyFr
      .replace(/<br>(?![\r\n])/g, "<br>\r\n")
      .replace(/<br\/>(?![\r\n])/g, "<br>\r\n");
  });
  mails.value = getMails;
  idSelected.value = mails.value[0].id;
  loading.value = false;
});

/** Sauvegarde de l'article */
async function editMailTemplate() {
  try {
    loading.value = true;
    const updatedMail = await mailTemplateService.update(mailSelected.value);
    const index = mails.value.findIndex((mail) => mail.id === updatedMail.id);
    mails.value.splice(index, 1, updatedMail);
  } catch (error) {
    console.error(error);
  }
  loading.value = false;
}
</script>
