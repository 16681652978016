<template>
  <Page>
    <q-card bordered>
      <q-card-section>
        <div class="row q-gutter-sm">
          <InputDate
            v-model="startDate"
            class="col"
            dense
            type="date"
            :label="$t('export.fields.startDate')"
          />
          <InputDate
            v-model="endDate"
            class="col"
            dense
            type="date"
            :label="$t('export.fields.endDate')"
          />
          <q-select
            v-if="publications.length > 1"
            v-model="publication"
            class="col"
            :options="publications"
            filled
            dense
            emit-value
            map-options
            :label="$t('export.fields.publication')"
          />
          <q-select
            v-model="selectedUser"
            class="col"
            :options="users"
            filled
            dense
            emit-value
            map-options
            use-input
            :label="$t(labelUsers)"
            @filter="filterInput"
          />
          <q-btn
            class="col-auto btn-export"
            color="primary"
            dense
            unelevated
            :label="$t('export.buttons.export')"
            @click="exportData"
          />
        </div>
      </q-card-section>
    </q-card>
  </Page>
</template>
<script setup lang="ts">
import { ContributionType, ExportDataType, RightReference } from "@/types/api";
import { computed, ref, watch } from "vue";
import Page from "@/components/Layouts/Page.vue";
import { useRoute } from "vue-router";
import { authService } from "@/services/AuthService";
import InputDate from "@/components/Custom/CustomInputDate.vue";
import { getPublicationOptions } from "@/helpers/publication";
import { RoutesName } from "@/router/routesName";
import { exportDataService } from "@/services/ExportDataService";
import { getToday, removeDiacritics, upcastDatetime } from "@/utils";
import { getContributorOptions } from "@/helpers/contributor";
import { Notify } from "quasar";

const route = useRoute();
let now = getToday();
now.setDate(0);
const endDate = ref<string>(now.toLocaleISOString());
now.setDate(1);
const startDate = ref<string>(now.toLocaleISOString());
const exportFreelance = ref(false);
const exportTranslation = ref(false);

watch(() => route.name, checkExportMode);

const selectedUser = ref<string>("");
const labelUsers = computed(() => {
  return exportFreelance.value
    ? "export.fields.freelancer"
    : "export.fields.translator";
});

const search = ref<string>("");
const users = computed(() => {
  const options = {
    type: ContributionType.Freelancer,
    allOption: true,
    publication: publication.value
      ? [publication.value]
      : publications.value.map((p) => p.value),
  };

  if (!exportFreelance.value) {
    options.type = ContributionType.Translator;
  }

  let filteredUsers = getContributorOptions(options);

  const needle = removeDiacritics(search.value).toLowerCase();
  filteredUsers = filteredUsers.filter(
    (v) => removeDiacritics(v.label).toLowerCase().indexOf(needle) > -1
  );

  if (exportFreelance.value) return filteredUsers;

  if (authService.hasRight(RightReference.EXPORT_ALL_TRANSLATION))
    return filteredUsers;

  // il ne reste plus que l'export de ses propres traductions
  filteredUsers = filteredUsers.filter(
    (u) => u.value === authService.getUserId()
  );
  return filteredUsers;
});

const publications = computed(() => {
  let allPublications = getPublicationOptions({ allOption: true });

  if (exportFreelance.value) {
    if (!authService.hasRight(RightReference.EXPORT_ALL_FREELANCE)) {
      allPublications = allPublications.filter(
        (p) =>
          p.value == "" ||
          authService.hasRight(
            RightReference.PUBLICATION_EXPORT_FREELANCE,
            p.value
          )
      );
    }
  } else {
    if (!authService.hasRight(RightReference.EXPORT_ALL_TRANSLATION)) {
      allPublications = allPublications.filter(
        (p) =>
          p.value == "" ||
          authService.hasRight(
            RightReference.PUBLICATION_EXPORT_TRANSLATION,
            p.value
          )
      );
    }
  }

  return allPublications;
});
const publication = ref<string>(publications.value[0].value);

watch(publication, () => {
  if (users.value.find((u) => u.value == selectedUser.value) == undefined)
    selectedUser.value = users.value[0].value;
});

/**
 * Check si le type d'export est pigiste ou traduction
 */
function checkExportMode() {
  exportFreelance.value = route.name === RoutesName.ExportFreelance;
  exportTranslation.value = route.name === RoutesName.ExportTranslation;
  selectedUser.value = users.value[0].value;
}

/**
 * Lance l'export
 */
function exportData() {
  exportDataService
    .exportData({
      from: upcastDatetime(startDate.value) ?? startDate.value,
      to: upcastDatetime(endDate.value) ?? endDate.value,
      publicationId: publication.value,
      type: exportFreelance.value
        ? ExportDataType.Freelance
        : ExportDataType.Translator,
      userId: selectedUser.value,
    })
    .catch((e) => {
      Notify.create({
        message: e.message,
        color: "negative",
      });
    });
}
/**
 * Fonction qui sert a filtrer dans les options
 */
function filterInput(val, update) {
  update(() => {
    search.value = val;
  });
}
checkExportMode();
</script>
<style lang="scss" scoped>
.btn-export {
  height: 40px;
}
</style>
