<template>
  <q-splitter v-model="splitterModel">
    <template #before>
      <q-tabs v-model="tab" vertical class="text-teal">
        <q-tab
          v-for="(infos, idx) in eventIds"
          :key="idx"
          :name="idx"
          :label="idx"
          class="text-left"
        />
      </q-tabs>
    </template>
    <template #after>
      <q-tab-panels
        v-model="tab"
        animated
        swipeable
        vertical
        transition-prev="jump-up"
        transition-next="jump-up"
      >
        <q-tab-panel v-for="(infos, idx) in eventIds" :key="idx" :name="idx">
          <div class="q-gutter-sm q-mb-md">
            <q-btn
              :label="
                infos
                  ? $t('import.labels.btnRefresh')
                  : $t('import.labels.btnInfos')
              "
              color="primary"
              @click="getInfos(idx)"
            />
            <q-btn
              :label="$t('import.labels.btnRetry')"
              color="secondary"
              @click="retryImport(idx)"
            />
            <q-btn
              label="Remove"
              color="deep-orange"
              @click="removeImport(idx)"
            />
          </div>
          <q-field outlined :hint="$t('import.fields.informations')" readonly>
            <template #control>
              <div
                class="self-center full-width no-outline"
                style="white-space: pre-wrap"
              >
                {{ infos }}
              </div>
            </template>
          </q-field>
        </q-tab-panel>
      </q-tab-panels>
    </template>
  </q-splitter>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useImportActions } from "@/composables/useImportActions";

const { eventIds, actions } = useImportActions();
const { getInfos, retryImport, removeImport } = actions;
const tab = ref(
  eventIds.value && Object.keys(eventIds.value).length !== 0
    ? Object.keys(eventIds.value)[0]
    : ""
);
const splitterModel = ref(20);

watch(eventIds.value, (newVal) => {
  tab.value = Object.keys(newVal).slice(-1)[0];
});
</script>
