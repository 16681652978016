import { computed } from "vue";
import { i18n } from "@/i18n";
import { ElcanoApiError } from "@/types/crud";
import { EnglishLanguage, FrenchLanguage } from "@/constants";

/**
 * Nous devrions avoir que des traitements bas niveau
 * L'utilisation de store dans utils.ts peut provoquer des problèmes de chargement de l'application.
 * A cause de l'enchainement des imports et l'utilsation de ce fichier avant que l'utilisateur soit prêt.
 */

/**
 * Fonction générique pour savoir si la valeur est null ou undefined
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isNil(value: any): boolean {
  return value === undefined || value === null;
}

/**
 * Fonction générique pour savoir si la valeur est non vide ou égal à 0
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
export function isEmpty(value: any): boolean {
  return isNil(value) || value === 0 || value === "";
}

/**
 * Fonction servant à récupérer la date avec comme paramètre le nombre de jour à ajouter.
 */
export function getDateWithNbDays(date: Date, daysToAdd: number) {
  date = date.addDays(daysToAdd);
  return date.toLocaleISOString();
}

/**
 * Fonction servant à récupérer la date du jour avec comme paramètre le nombre de jour à ajouter.
 */
export function getDateNow(daysToAdd: number) {
  return getDateWithNbDays(new Date(), daysToAdd);
}

/** Calcul la date en ajoutant ou retranchant des jours ouvrés */
export function calculateWorkingDay(startDate = new Date(), numberOfDays = 1) {
  let currentDate = new Date(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate()
  );

  let i = 0;
  while (i < Math.abs(numberOfDays)) {
    currentDate = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() + (numberOfDays > 0 ? 1 : -1)
    );
    const dayOfWeek = currentDate.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) i++;
  }

  return currentDate;
}

/**
 * Retourne une date avec un nombre de jour ouvrable ajouté.
 */
export function getNextWorkingDay(currentDate = new Date(), nbDays = 1) {
  return calculateWorkingDay(currentDate, nbDays);
}

/**
 * Fonction servant à récupérer la date du jour à minuit avec comme paramètre le nombre de jour à ajouter.
 */
export function getToday(daysToAdd = 0) {
  const now = new Date();
  const today = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + daysToAdd
  );
  return today;
}

/**
 * Permet de formater la date sous la forme YYYY/MM/DD.
 */
export function formatDate(date: Date | string | undefined) {
  if (date === undefined) return "";

  let isoString = "";
  if (typeof date === "string") {
    const dateInput = new Date(date);
    isoString = dateInput.toLocaleISOString();
  } else {
    isoString = date.toLocaleISOString();
  }

  return isoString.split("T")[0].replaceAll("-", "/");
}

/**
 * Format l'heure et les minutes.
 * @param date date à afficher
 */
export function formatTime(date: Date | string | undefined) {
  if (date === undefined) return 0;
  if (typeof date === "string") date = new Date(date);
  return (
    date.getHours().toString().padStart(2, "0") +
    ":" +
    date.getMinutes().toString().padStart(2, "0")
  );
}

/**
 * Permet de récupérer le temps écoulé depuis une date
 * @param date date à comparer
 */
export function elapsedTime(date: Date | string | undefined) {
  if (date === undefined) return 0;
  if (typeof date === "string") date = new Date(date);

  const now = new Date();
  const diff = now.getTime() - date.getTime();
  return Math.floor(Math.floor(diff / 1000) / 60);
}

/**
 *
 * @param obj
 * @returns
 */
export function instanceOfElcanoApiError<T>(
  obj: ElcanoApiError<T> | T
): obj is ElcanoApiError<T> {
  return obj && typeof obj == "object" && "message" in obj && "data" in obj;
}

/**
 * Permet de formater le message d'erreur provenant de l'API.
 */
export function formatErrorMessage<T>(
  e: T | Error | ElcanoApiError<T>,
  defaultMessage: string
) {
  if (instanceOfElcanoApiError(e)) {
    const code = e.data["code"] ?? "default";
    return i18n.t(`exceptions.${e.data["type"]}.${code}`, e.data);
  }
  return i18n.t(defaultMessage);
}

/**
 * Helper pour déterminer dans quelle langue on est
 */
export function useLocale() {
  const languages = [FrenchLanguage, EnglishLanguage];
  const isFr = computed<boolean>(() => i18n.locale.value === "fr");
  const isEn = computed<boolean>(() => i18n.locale.value === "en");
  const language = computed<string>(
    () => languages.find((l) => l.startsWith(i18n.locale.value)) ?? languages[0]
  );
  const otherLanguage = computed<string | undefined>(() =>
    languages.find((l) => !l.startsWith(i18n.locale.value))
  );

  return {
    isFr,
    isEn,
    language,
    otherLanguage,
  };
}

/**
 * Helper pour retirer les diacritics dans un texte
 */
export function removeDiacritics(text: string) {
  return text.normalize("NFD").replace(/\p{Diacritic}/gu, "");
}

/**
 * Helper pour retirer les doublons dans un tableau d'objets
 */
export function removeDuplicatesObjectInArray<TObj>(arr: TObj[]) {
  const arrToString = arr.filter(Boolean).map((obj) => JSON.stringify(obj));
  return [...new Set(arrToString)].map((str) => JSON.parse(str));
}

/**
 * Fonction pour capturé les erreurs suite à des appels asynchrones ou sinon renvoie les valeurs si aucunes erreurs
 */
export function handlePromiseResults<T>(results: PromiseSettledResult<T>[]) {
  const errors = results
    .filter((result) => result.status === "rejected")
    .map((result) => (result as PromiseRejectedResult).reason);

  if (errors.length) {
    // Aggregate all errors into one
    throw new AggregateError(errors);
  }

  return results.map((result) => (result as PromiseFulfilledResult<T>).value);
}

/**
 * Fonction pour transformer la date provenant du server en date javascript en accord avec la local de l'utilisateur.
 * @param date Date
 */
export function downcastDatetime(date: string | undefined) {
  if (date === undefined) return undefined;
  return date.replace("Z", "");
}

/**
 * Fonction pour transformer la date provenant du client en date compatible pour le serveur.
 * @param date Date
 */
export function upcastDatetime(date: string | undefined) {
  if (date === undefined) return undefined;
  if (date.indexOf("/") >= 0) date = date.replaceAll("/", "-");
  if (date.indexOf("T") === -1) return date + "T00:00:00Z";
  if (date.endsWith("Z")) return date;
  return date + "Z";
}

const percentColors = [
  { pct: 0.0, color: { r: 0x75, g: 0xf5, b: 0x42 } }, // vert
  { pct: 0.5, color: { r: 0xf5, g: 0xb9, b: 0x42 } }, // orange
  { pct: 1.0, color: { r: 0xf5, g: 0x42, b: 0x42 } }, // rouge
];

/**
 * Fonction pour récupérer la couleur en fonction du pourcentage
 * @param pct pourcentage
 * @returns couleur
 * @example getColorForPercentage(0.5) => rgb(245, 184, 66)
 */
export function getColorForPercentage(pct) {
  let i = 1;
  for (i; i < percentColors.length - 1; i++) {
    if (pct < percentColors[i].pct) {
      break;
    }
  }
  const lower = percentColors[i - 1];
  const upper = percentColors[i];
  const range = upper.pct - lower.pct;
  const rangePct = (pct - lower.pct) / range;
  const pctLower = 1 - rangePct;
  const pctUpper = rangePct;
  const color = {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  };
  return "rgb(" + [color.r, color.g, color.b].join(",") + ")";
}
