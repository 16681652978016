<template>
  <div>
    <div class="q-mb-lg">
      <div class="text-subtitle1">
        {{ $t("import.titles.importTrackEvent") }}
      </div>
      <q-form ref="form" class="q-gutter-md" @submit="onSubmit">
        <q-input
          v-model="eventId"
          filled
          :label="$t('import.fields.eventId')"
          lazy-rules
          :rules="[(val) => (val && val.length > 0) || 'Please type something']"
        />
        <div>
          <div class="q-gutter-sm">
            <q-btn
              :label="$t('import.labels.btnPost')"
              type="submit"
              color="primary"
            />
            <q-btn
              :label="$t('import.labels.btnRetry')"
              color="secondary"
              @click="retryEvent"
            />
          </div>
        </div>
      </q-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { QForm } from "quasar";
import { ref } from "vue";
import { useImportActions } from "@/composables/useImportActions";

const { actions } = useImportActions();
const { retryImport, getInfos } = actions;

const eventId = ref("ImportArticlesRequest-GUID");
const form = ref<QForm>();

/**
 *
 */
function retryEvent() {
  if (!form.value) return;

  form.value.validate().then(() => {
    retryImport(eventId.value);
  });
}

/**
 *
 */
function onSubmit() {
  getInfos(eventId.value);
}
</script>
