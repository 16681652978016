<template>
  <div>
    <div class="row q-gutter-sm">
      <q-select
        v-model="publication"
        class="col"
        :options="publications"
        filled
        dense
        emit-value
        map-options
        :label="$t('export.fields.publication')"
      />
      <q-select
        v-model="selectedYear"
        class="col"
        :options="yearOptions"
        filled
        dense
        emit-value
        map-options
        use-input
      />
    </div>
    <div class="chart-full-height">
      <BarChart id="my-chart-id" :options="chartOptions" :data="chartData" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { date } from "quasar";
import { ref, onMounted, watch } from "vue";
import { useConfigStore } from "@/stores/config";
import { freelanceBudgetService } from "@/services/FreelanceBudgetService";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  ChartData,
} from "chart.js";
import { getPublicationOptions } from "@/helpers/publication";
ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement
);

const configStore = useConfigStore();
const selectedYear = ref<number>(new Date().getFullYear());
const publication = ref<string>(configStore.$state.config.publication);
const publications = getPublicationOptions({ allOption: false });
const yearOptions = Array.from(
  { length: new Date().getFullYear() - 2022 },
  (_, i) => 2023 + i
).sort((a, b) => b - a);
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore:next-line
const chartData = ref<ChartData<"bar">>(createData([], []));
watch([publication, selectedYear], () => {
  refreshData();
});

const chartOptions = {
  responsive: true,
};
onMounted(async () => {
  refreshData();
});

/** Chargement des données */
async function refreshData() {
  console.log("refreshData");
  const budgets = await freelanceBudgetService.getBudgets(
    publication.value,
    selectedYear.value,
    undefined,
    true
  );
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore:next-line
  chartData.value = createData(
    budgets.map((b) => b.consumption),
    budgets.map((b) => b.amount)
  );
}

/** Définition des mois */
function defineMonths() {
  const d = new Date(2020, 0, 1);
  const months = [] as string[];
  for (let i = 0; i < 12; i++) {
    months.push(date.formatDate(d, "MMMM"));
    d.setMonth(d.getMonth() + 1);
  }
  return months;
}

/** Creation du jeu de données */
function createData(consumption: number[], budget: number[]) {
  return {
    labels: defineMonths(),
    datasets: [
      {
        label: "Consommation",
        data: consumption,
        borderColor: "#000000a0",
        backgroundColor: "#000000a0",
      },
      {
        label: "Budget",
        type: "line",
        data: budget,
        borderColor: "#f00000a0",
        backgroundColor: "#f00000a0",
      },
    ],
  };
}
</script>
<style scoped lang="scss">
.chart-full-height {
  height: calc(100vh - 240px);
  canvas {
    max-height: calc(100vh - 240px);
  }
}
</style>
