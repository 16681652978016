<template>
  <div>
    <div v-for="l in locks" :key="l.modificationType">
      <div class="q-pa-sm col-2">
        {{ l.userName }} : {{ getTypeLock(l.modificationType) }}
      </div>
    </div>
    <q-btn
      v-if="locks.length > 0 && hasRight"
      icon="lock_open"
      @click="unlockArticle()"
      ><q-tooltip class="bg-primary">{{
        $t("article.locks.message")
      }}</q-tooltip>
    </q-btn>
  </div>
</template>

<script lang="ts" setup>
import { ArticleLockDto, RightReference } from "@/types/api";
import { authService } from "@/services/AuthService";
import { articleService } from "@/services/ArticleService";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { getTypeLock } from "@/services/LockService";

const props = defineProps<{
  locks: ArticleLockDto[];
  publication: string;
}>();

const { currentArticle, refreshLocks } = useCurrentArticleStore();
const hasRight = authService.hasRight(
  RightReference.PUBLICATION_ARTICLE_ACTION_FREE,
  props.publication
);
/**
 * Libère l'article.
 */
async function unlockArticle() {
  await articleService.freeLock(currentArticle.id);
  await refreshLocks();
}
</script>
