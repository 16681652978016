import type { LanguageState } from "@/types/language";
import { defineStore } from "pinia";
import { languageService } from "@/services/LanguageService";

export const useLanguageStore = defineStore({
  id: "language",
  state: (): LanguageState => ({
    languages: [],
  }),
  actions: {
    async fetchLanguages() {
      this.languages = await languageService.getList();
    },
  },
});
