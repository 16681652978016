import { Editor } from "@ckeditor/ckeditor5-core";
import {
  BaseComment,
  BaseCommentData,
  BaseCommentThread,
  CommentsAdapter as CkCommentsAdapter,
  CommentDataJSON,
  CommentThreadData,
  UpdateCommentThreadEventData,
} from "@ckeditor/ckeditor5-comments/src/comments/commentsrepository";

import { commentService } from "@/services/CommentService";

/**
 *
 */
type Callback = () => void;

/**
 * Convertit un symbol ou une string en string.
 */
function getStringFromSymbolOrString(value: string | symbol): string {
  if (typeof value === "symbol") return value.description ?? value.toString();

  return value;
}

/**
 *
 */
class CommentsAdapter implements CkCommentsAdapter {
  editor: Editor;
  subscribers: Callback[];

  /**
   *
   */
  constructor(editor: Editor) {
    this.editor = editor;
    this.subscribers = [];
  }

  /**
   * Pour rendre le plugin compatible avec le Context CkEditor.
   */
  static get isContextPlugin() {
    return true;
  }

  /**
   *
   */
  onChange(callback: Callback) {
    this.subscribers.push(callback);
  }

  /**
   *
   */
  init() {
    const commentsRepositoryPlugin =
      this.editor.plugins.get("CommentsRepository");
    // Set the adapter on the `CommentsRepository#adapter` property.
    commentsRepositoryPlugin.adapter = this;
  }

  /** @type {CommentsAdapter.addCommentThread} */
  addCommentThread(
    data: Omit<CommentThreadData, "isFromAdapter"> & {
      comments: Array<CommentDataJSON>;
    }
  ) {
    return commentService
      .addCommentThread({
        ...data,
        channelId: getStringFromSymbolOrString(data.channelId),
        comments: data.comments.map((comment) => ({
          ...comment,
          channelId: getStringFromSymbolOrString(data.channelId),
          threadId: data.threadId,
          createdAt: comment.createdAt
            ? comment.createdAt.toISOString()
            : new Date().toISOString(),
          commentId: comment.commentId ?? "",
          attributes: comment.attributes,
        })),
        attributes: data.attributes,
        resolvedAt: data.resolvedAt ? data.resolvedAt.toISOString() : undefined,
        resolvedBy: data.resolvedBy ?? undefined,
        unlinkedAt: data.unlinkedAt ? data.unlinkedAt.toISOString() : undefined,
        context: data.context
          ? {
              type: data.context.type,
              value: data.context.value,
            }
          : undefined,
      })
      .then((thread) => {
        return {
          threadId: thread.threadId,
          comments: thread.comments.map((comment) => ({
            commentId: comment.commentId,
            createdAt: new Date(comment.createdAt),
          })),
        };
      });
  }

  /** @type {CommentsAdapter.getCommentThread} */
  getCommentThread(data: Omit<BaseCommentThread, "isFromAdapter">) {
    return commentService
      .getCommentThread(
        data.threadId,
        getStringFromSymbolOrString(data.channelId)
      )
      .then((thread) => {
        return {
          ...thread,
          attributes: thread.attributes ?? {},
          resolvedAt: thread.resolvedAt ? new Date(thread.resolvedAt) : null,
          comments: thread.comments.map((comment) => ({
            ...comment,
            createdAt: new Date(comment.createdAt),
          })),
        };
      });
  }

  /** @type {CommentsAdapter.getCommentThread} */
  updateCommentThread(
    data: Omit<UpdateCommentThreadEventData, "isFromAdapter">
  ) {
    return commentService
      .updateCommentThread({
        ...data,
        channelId: getStringFromSymbolOrString(data.channelId),
        unlinkedAt: data.unlinkedAt ? data.unlinkedAt.toISOString() : undefined,
        context: data.context ?? undefined,
      })
      .then(() => {
        Promise.resolve();
      });
  }

  /** @type {CommentsAdapter.resolveCommentThread} */
  resolveCommentThread(data: Omit<BaseCommentThread, "isFromAdapter">) {
    return commentService
      .resolveCommentThread(
        data.threadId,
        getStringFromSymbolOrString(data.channelId)
      )
      .then((thread) => ({
        threadId: thread.threadId,
        resolvedBy: thread.resolvedBy ?? "",
        resolvedAt: thread.resolvedAt
          ? new Date(thread.resolvedAt)
          : new Date(),
      }));
  }

  /** @type {CommentsAdapter.reopenCommentThread} */
  reopenCommentThread(data: Omit<BaseCommentThread, "isFromAdapter">) {
    return commentService
      .reopenCommentThread(
        data.threadId,
        getStringFromSymbolOrString(data.channelId)
      )
      .then(() => {
        Promise.resolve();
      });
  }

  /** @type {CommentsAdapter.removeCommentThread} */
  removeCommentThread(data: Omit<BaseCommentThread, "isFromAdapter">) {
    return commentService
      .removeCommentThread(
        data.threadId,
        getStringFromSymbolOrString(data.channelId)
      )
      .then(() => {
        Promise.resolve();
      });
  }

  /** @type {CommentsAdapter.addComment} */
  addComment(data: Omit<BaseComment, "isFromAdapter"> & BaseCommentData) {
    return commentService
      .addComment({
        ...data,
        channelId: getStringFromSymbolOrString(data.channelId),
        authorId: "",
        createdAt: new Date().toISOString(),
      })
      .then((comment) => {
        return {
          commentId: comment.commentId,
          createdAt: new Date(comment.createdAt),
        };
      });
  }

  /** @type {CommentsAdapter.updateComment} */
  updateComment(
    data: Omit<BaseComment, "isFromAdapter"> & Partial<BaseCommentData>
  ) {
    return commentService
      .updateComment({
        ...data,
        channelId: getStringFromSymbolOrString(data.channelId),
      })
      .then(() => {
        Promise.resolve();
      });
  }

  /** @type {CommentsAdapter.removeComment} */
  removeComment(data: Omit<BaseComment, "isFromAdapter">) {
    return commentService.removeComment(data.commentId).then(() => {
      Promise.resolve();
    });
  }
}

export default CommentsAdapter;
