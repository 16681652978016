import type { CrudServiceInstance } from "@/types/crud";
import type { MediaDto } from "@/types/api";
import type { MediaServiceInstance } from "@/types/media";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";
import { AxiosResponse } from "axios";

/**
 * Service media pour récupérer les medias et les modifier.
 */
class MediaService extends AxiosRequest implements MediaServiceInstance {
  crudService: CrudServiceInstance<MediaDto>;

  /**
   * Constructeur de la class Format service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<MediaDto>(rootPath);
  }

  /**
   * Récupérer un media par son id
   * @param {number} id - id du media à récupérer
   */
  getSingle(id: number) {
    return this.axiosInstance
      .get(`${this.rootPath}/getMedia?id=${id}`)
      .then((response: AxiosResponse<MediaDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupérer un media par son chemin
   * @param {string} path - chemin du media à récupérer
   */
  getSingleByPath(path: string) {
    return this.axiosInstance
      .get(`${this.rootPath}/getMedia?filename=${path}`)
      .then((response: AxiosResponse<MediaDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Sauvegarde un media.
   * @param media - Le media.
   */
  update(media: MediaDto) {
    return this.axiosInstance
      .put(`${this.rootPath}/save`, media)
      .then((response: AxiosResponse<MediaDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const mediaService = new MediaService("medias");
