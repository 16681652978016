<template>
  <q-form class="q-gutter-md" @submit="$emit('submit', publication)">
    <q-input
      ref="id"
      v-model="publication.id"
      for="publicationId"
      :label="$t('publication.fields.id')"
      :rules="[(val) => !!val || $t('rules.required')]"
      filled
    />
    <q-input
      ref="name"
      v-model="publication.name"
      for="publicationName"
      :label="$t('publication.fields.name')"
      filled
    />
    <q-checkbox
      ref="active"
      v-model="publication.active"
      for="publicationActive"
      :label="$t('publication.fields.active')"
    />
    <div>
      <q-badge color="secondary">Ordre d'affichage :</q-badge>
      <q-slider
        v-model="publication.showOrder"
        :min="0"
        :max="10"
        marker-labels
        markers
        snap
      />
    </div>
    <div class="q-mt-md q-gutter-md text-right">
      <q-btn :label="labelBtnSubmit" type="submit" color="primary" />
    </div>
  </q-form>
</template>
<script lang="ts" setup>
import type { PropType } from "vue";
import type { PublicationDto } from "@/types/api";
import { toRefs } from "vue";
import { i18n } from "@/i18n";
import { createPublication } from "@/services/PublicationService";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("publication.labels.btnEdit");
    },
  },
  defaultPublication: {
    type: Object as PropType<PublicationDto>,
    default() {
      return createPublication();
    },
  },
});
defineEmits<{
  (e: "submit", value: PublicationDto): void;
}>();
const { defaultPublication: publication } = toRefs(props);
</script>
