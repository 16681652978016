<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', rightGroup)">
    <q-input
      v-model="rightGroup.labelFr"
      filled
      :label="$t('rightGroup.fields.labelFr')"
    />
    <q-input
      v-model="rightGroup.labelEn"
      filled
      :label="$t('rightGroup.fields.labelEn')"
    />
    <div class="q-mt-md text-right">
      <q-btn
        v-if="rightGroup.initialConfig === false"
        :label="labelBtnSubmit"
        type="submit"
        color="primary"
      />
    </div>
  </q-form>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import type { RightGroupDto } from "@/types/api";
import { toRefs } from "vue";
import { createRightGroup } from "@/services/RightGroupService";
import { i18n } from "@/i18n";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("rightGroup.labels.btnEdit");
    },
  },
  defaultRightGroup: {
    type: Object as PropType<RightGroupDto>,
    default() {
      return createRightGroup();
    },
  },
});
defineEmits<{
  (e: "submit", value: RightGroupDto): void;
}>();
const { defaultRightGroup: rightGroup } = toRefs(props);
</script>
