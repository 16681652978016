import type { QSelectOption } from "quasar";
import { WorkflowState } from "@/types/api";
import { i18n } from "@/i18n";

export const workFlowStateColor: Record<WorkflowState, string> = {
  Pitch: "#4B0082",
  Redaction: "#C351F9",
  Relecture: "#FC39B9",
  Correction: "#FC3434",
  RelectureTraducteurReferent: "#FFACAC",
  AttenteCorrectionSr: "#FAB022",
  CorrectionSr: "#FBE525",
  Importation: "#4CDEFE",
  ImportationEnErreur: "#000000",
  Importe: "#3BB54A",
  Invalidation: "#CC9428",
  Invalide: "#D9D9D9",
  InvalidationEnErreur: "#000000",
  AttenteTraduction: "#4B0082",
  Traduction: "#C351F9",
} as const;

/**
 * Check si la clé en paramètre est dans l'objet WorkflowState
 */
export function isWorkflowState(
  key: WorkflowState | string
): key is WorkflowState {
  return key in WorkflowState;
}

/**
 * Fonction pour récupérer la couleur du statut du workflow
 */
export function getWorkflowStateColor(key: WorkflowState | string) {
  if (isWorkflowState(key)) return workFlowStateColor[key];
  return undefined;
}

/**
 *
 */
export function getCSSColorState(state: WorkflowState | string | undefined) {
  if (!state) return undefined;
  return `background: ${getWorkflowStateColor(
    state
  )}; color: ${getWorkflowStateTextColor(state)};`;
}

/**
 * Fonction pour récupérer la couleur du texte du statut du workflow
 */
export function getWorkflowStateTextColor(key: WorkflowState | string) {
  if (isWorkflowState(key)) {
    const r = parseInt(workFlowStateColor[key].substring(1, 3), 16);
    const g = parseInt(workFlowStateColor[key].substring(3, 5), 16);
    const b = parseInt(workFlowStateColor[key].substring(5, 7), 16);
    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;
    return luma > 40 ? "#000000" : "#ffffff";
  } else return "#ffffff";
}
/**
 * Fonction pour récupérer la liste des statuts du workflow
 */
export function getWorflowState(allOption = true) {
  return [
    allOption ? { value: "", label: i18n.t(`article.states.All`) } : undefined,
    ...Object.keys(WorkflowState).map((key) => ({
      label: i18n.t(`article.states.${key}`),
      value: WorkflowState[key],
    })),
  ].filter(Boolean) as QSelectOption<WorkflowState>[];
}
