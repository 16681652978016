<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', format)">
    <q-input
      ref="id"
      v-model="format.id"
      for="formatId"
      filled
      :label="$t('format.fields.id')"
      :rules="[(val) => !!val || $t('rules.required')]"
    />
    <div class="q-ma-none q-gutter-md row items-start">
      <q-input
        ref="labelFr"
        v-model="format.labelFr"
        for="formatLabelFr"
        filled
        :label="$t('format.fields.labelFr')"
        class="col"
      />
      <q-input
        ref="labelEn"
        v-model="format.labelEn"
        for="formatLabelEn"
        filled
        :label="$t('format.fields.labelEn')"
        class="col"
      />
    </div>
    <div class="q-ma-none q-gutter-md row items-start">
      <q-checkbox
        v-model="format.active"
        for="formatActive"
        class="col"
        :label="$t('format.fields.active')"
      />
      <q-checkbox
        v-model="format.long"
        for="formatLong"
        class="col"
        :label="$t('format.fields.long')"
      />
    </div>
    <q-input
      ref="maxLength"
      v-model="format.maxLength"
      for="maxLength"
      type="number"
      filled
      :label="$t('format.fields.maxLength')"
      class="col"
    />
    <q-btn
      :label="$t('format.labels.btnAddPublication')"
      color="primary"
      @click="showDialogAddPublication = true"
    />
    <q-list v-if="format.formatPublications.length" separator>
      <q-item-label header>Publications</q-item-label>
      <q-item
        v-for="(pub, index) in format.formatPublications"
        :key="'publication' + index"
        tag="label"
      >
        <q-item-section>
          <q-item-label>{{
            getLabelPublication(pub.publication)
          }}</q-item-label>
        </q-item-section>
        <q-item-section side top>
          <q-toggle v-model="pub.active" />
        </q-item-section>
      </q-item>
    </q-list>
    <div class="q-mt-md q-gutter-md text-right">
      <q-btn :label="labelBtnSubmit" type="submit" color="primary" />
    </div>
    <DialogAddPublication
      v-model="showDialogAddPublication"
      :selected="format.formatPublications.map((p) => p.publication)"
      @add:publications="onNewPublications"
    />
  </q-form>
</template>
<script lang="ts" setup>
import type { PropType } from "vue";
import type { FormatDto } from "@/types/api";
import { ref, toRefs } from "vue";
import { i18n } from "@/i18n";
import { createFormat } from "@/services/FormatService";
import { usePublicationStore } from "@/stores/publication";
import DialogAddPublication from "@/components/Publication/DialogAddPublication.vue";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("format.labels.btnEdit");
    },
  },
  defaultFormat: {
    type: Object as PropType<FormatDto>,
    default() {
      return createFormat();
    },
  },
});
defineEmits<{
  (e: "submit", value: FormatDto): void;
}>();
const showDialogAddPublication = ref<boolean>(false);
const { defaultFormat: format } = toRefs(props);
const publicationStore = usePublicationStore();
const { publications } = publicationStore;

/**
 * Fonction appelé lors du choix des publications associés
 */
function onNewPublications(newPublications: string[]) {
  const getActiveStatus = (publicationId: string) => {
    const pub = format.value.formatPublications.find(
      (p) => p.publication === publicationId
    );
    return pub ? pub.active : true;
  };
  format.value.formatPublications = newPublications.map((pub) => ({
    publication: pub,
    active: getActiveStatus(pub),
  }));
}

/**
 * Fonction pour récupérer le label de la publication en fonction de l'id
 */
function getLabelPublication(publicationId: string) {
  const pub = publications.find((p) => p.id === publicationId);
  return pub?.name ?? publicationId;
}
</script>
