import { ElcanoNotificationType } from "@/types/api";
import {
  HubConnection,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { conf_elcanoapi_current, conf_front_only } from "@/constants";

import type { SignalRServiceInstance } from "@/types/signalR";
import { authService } from "@/services/AuthService";

/**
 *
 */
class SignalRService implements SignalRServiceInstance {
  connection: HubConnection | null;
  onCloseAlreadySetup: boolean;
  // callback par groupe puis type de message et enfin la liste des callbacks associées.
  callbacks: Record<string, Record<string, ((data: any) => void)[]>>;

  /**
   * Constructeur.
   */
  constructor() {
    this.connection = null;
    this.callbacks = {};
    this.onCloseAlreadySetup = false;
  }

  /**
   * Récupère l'id de la connection courante.
   */
  getConnectionId() {
    return this.connection?.connectionId || undefined;
  }

  /**
   * Initialize la connection.
   */
  setup() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${conf_elcanoapi_current}/hubs/notification`, {
        accessTokenFactory: () => {
          return authService.keycloakInstance?.token ?? "";
        },
      })
      .configureLogging(LogLevel.Information)
      .build();

    return this.start();
  }

  /**
   * Lance la connection.
   */
  start(): Promise<unknown> {
    if (!this.connection) return Promise.reject("Connection not established.");
    const userId = authService.getUserId();

    const startedPromise = this.connection
      .start()
      .then(() => {
        if (this.connection) {
          const currentUserGroup = `user:${userId}`;
          const ignoreGroupForReconnection = ["_GENERAL_", currentUserGroup];
          this.subscribeToMessageType(currentUserGroup, "_ALL_", (data) =>
            console.log("DEBUG SIGNALR CURRENT USER", data)
          );

          if (authService.hasRight("ADMIN_FULL")) {
            this.subscribeToMessageType(
              "ADMIN",
              ElcanoNotificationType.ImportationEnd,
              (data) => console.log("ADMIN MESSAGE", data)
            );
            ignoreGroupForReconnection.push("ADMIN");
          }

          for (const [key] of Object.entries(this.callbacks)) {
            if (ignoreGroupForReconnection.indexOf(key) < 0) {
              this.connection.send("SubscribeGroup", key);
            }
          }
        }

        return this.listenMessage();
      })
      .catch((err) => {
        console.error("Failed to connect with hub", err);
        if (conf_front_only) {
          // on ne boucle pas jusqu'a avoir la connection
          // car on est en mode sans API
          return Promise.resolve();
        }

        return new Promise((resolve, reject) =>
          setTimeout(() => this.start().then(resolve).catch(reject), 5000)
        );
      });

    if (!this.onCloseAlreadySetup) {
      this.onCloseAlreadySetup = true;
      this.connection.onclose(() => setTimeout(() => this.start(), 5000));
    }

    return startedPromise;
  }

  /**
   * Souscrit a la réception des messsages.
   */
  listenMessage() {
    if (!this.connection) return;

    this.connection.on(
      "ReceiveMessage",
      (data: { group: string | null; type: string; data: any }) => {
        console.log("ReceiveMessage", data);

        data.group = data.group ?? "_GENERAL_";
        const groupCallback = this.callbacks[data.group];

        if (groupCallback) {
          const registeredCallback = groupCallback[data.type] ?? [];
          if (groupCallback["_ALL_"])
            registeredCallback.push(...groupCallback["_ALL_"]);

          if (registeredCallback) {
            registeredCallback.forEach((c) => c(data));
          }
        }
      }
    );
  }

  /**
   * Permet de souscrire à un type de message.
   */
  async subscribeToMessageType(
    group: string | null,
    type: string,
    callback: (data: any) => void
  ) {
    if (!this.connection) return;

    const needSubscribeToGroup = group !== null;
    group = group ?? "_GENERAL_";

    if (!this.callbacks[group]) {
      if (needSubscribeToGroup) {
        try {
          await this.connection.send("SubscribeGroup", group);
          this.callbacks[group] = {};
        } catch (error) {
          console.log("subscribeToMessageType error", error);
          return;
        }
      }
    }

    if (!this.callbacks[group][type]) this.callbacks[group][type] = [];

    if (this.callbacks[group][type].indexOf(callback) >= 0) return;

    this.callbacks[group][type].push(callback);
  }

  /**
   * Permet de se désinscrire à un type de message.
   */
  async unsubscribeToMessageType(
    group: string | null,
    type: string,
    callback: (data: any) => void
  ) {
    console.log("unsubscribe", group, type);

    const needUnsubscribeToGroup = group !== null;
    group = group ?? "_GENERAL_";

    if (!this.callbacks[group]) return;
    if (!this.callbacks[group][type]) return;

    this.callbacks[group][type] = this.callbacks[group][type].filter(
      (c) => c != callback
    );

    if (this.callbacks[group][type].length === 0)
      delete this.callbacks[group][type];

    if (
      Object.keys(this.callbacks[group]).length === 0 &&
      needUnsubscribeToGroup &&
      this.connection
    ) {
      delete this.callbacks[group];
      try {
        await this.connection.send("UnsubscribeGroup", group);
        console.log("unsubscribe", group);
      } catch (error) {
        console.log("unsubscribeToMessageType error", error);
      }
    }
  }

  /**
   * Se désinscrit d'un groupe.
   */
  async unsubscribeGroup(group: string) {
    if (!this.callbacks[group]) return;

    delete this.callbacks[group];

    if (this.connection) {
      try {
        await this.connection.send("UnsubscribeGroup", group);
      } catch (error) {
        console.log("unsubscribeGroup error", error);
      }
    }
  }

  /**
   * Envoie un message au travers du hub.
   */
  async sendMessage(data: {
    group: string | null;
    type: string;
    data: any;
  }): Promise<void> {
    if (!this.connection) return;

    console.log("Send Message");
    await this.connection.send("SendMessage", data);
  }
}

export const signalRService: SignalRServiceInstance = new SignalRService();
