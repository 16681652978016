<template>
  <PageWithPanel>
    <template #panelRight>
      <div class="q-pa-md">
        <div v-if="user && showForm == 'users'">
          <FormUser
            :user="user"
            @add-profile="userActions.onAddUserProfile"
            @remove-profile="userActions.onRemoveUserProfile"
            @save-user="userActions.onSaveUser"
          />
        </div>
        <div v-if="showForm == 'profils'">
          <div v-if="profile != null" class="q-py-md q-gutter-sm">
            <q-btn
              v-if="profile.initialConfig === false"
              rounded
              color="primary"
              icon="delete"
              :label="$t('profile.labels.btnDelete')"
              @click="profileActions.onDeleteProfile(profile ? profile.id : '')"
            />
          </div>
          <FormProfile
            :default-profile="profile ?? undefined"
            @submit="(profileData) => onFormProfileSubmit(profileData)"
          />
        </div>
        <div v-if="showForm == 'rightGroups'">
          <div v-if="rightGroup != null" class="q-py-md q-gutter-sm">
            <q-btn
              v-if="rightGroup.initialConfig === false"
              rounded
              color="primary"
              icon="delete"
              :label="$t('rightGroup.labels.btnDelete')"
              @click="
                rightGroupActions.onDeleteRightGroup(
                  rightGroup ? rightGroup.id : 0
                )
              "
            />
          </div>
          <FormRightGroup
            :default-right-group="rightGroup ?? undefined"
            @submit="(rightGroupData) => onFormRightGroupSubmit(rightGroupData)"
          />
        </div>
        <div v-if="showForm == 'rights'">
          <div v-if="right != null" class="q-py-md q-gutter-sm">
            <q-btn
              rounded
              color="primary"
              icon="delete"
              :label="$t('right.labels.btnDelete')"
              @click="rightActions.onDeleteRight(right ? right.id : '')"
            />
          </div>
          <FormRight
            :default-right="right ?? undefined"
            @submit="(rightData) => onFormRightSubmit(rightData)"
          />
        </div>
        <div v-if="showForm == 'assignments'">
          <div v-if="assignment != null" class="q-py-md q-gutter-sm">
            <q-btn
              v-if="assignment.initialConfig === false"
              rounded
              color="primary"
              icon="delete"
              :label="$t('assignment.labels.btnDelete')"
              @click="
                assignmentActions.onDeleteAssignment(
                  assignment ? assignment.id : ''
                )
              "
            />
          </div>
          <FormAssignment
            :default-assignment="assignment ?? undefined"
            @submit="(assignmentData) => onFormAssignmentSubmit(assignmentData)"
          />
        </div>
      </div>
    </template>
    <template #default>
      <div class="q-gutter-sm q-px-xs-sm q-px-sm-none">
        <q-card class="q-mb-md">
          <q-tabs
            v-model="tab"
            class="bg-primary text-white"
            align="left"
            narrow-indicator
            @update:model-value="onTabChanged"
          >
            <q-tab :name="RoutesName.Users" :label="$t('user.tabs.users')" />
            <q-tab
              v-if="isAdminUser"
              :name="RoutesName.Profiles"
              :label="$t('user.tabs.profiles')"
            />
            <q-tab
              v-if="isAdminUser"
              :name="RoutesName.Assignments"
              :label="$t('user.tabs.assignments')"
            />
            <q-tab
              v-if="isAdminUser"
              :name="RoutesName.RightGroups"
              :label="$t('user.tabs.rightGroups')"
            />
            <q-tab
              v-if="isAdminUser"
              :name="RoutesName.Rights"
              :label="$t('user.tabs.rights')"
            />
          </q-tabs>
          <q-separator />
          <q-tab-panels v-model="tab" animated>
            <q-tab-panel :name="RoutesName.Users">
              <ListUser
                @row-click="
                  (user) =>
                    userActions.editUser(user.id, () => handleShowForm('users'))
                "
                @on-add="
                  userActions.createUser();
                  handleShowForm('users');
                "
              />
            </q-tab-panel>
            <q-tab-panel :name="RoutesName.Profiles">
              <ListProfile
                @row-click="
                  (profile) =>
                    profileActions.editProfile(profile.id, () =>
                      handleShowForm('profils')
                    )
                "
              >
                <template #actions>
                  <q-btn
                    color="primary"
                    :label="$t('profile.labels.btnNew')"
                    @click="
                      profileActions.createProfile(() =>
                        handleShowForm('profils')
                      )
                    "
                  />
                </template>
              </ListProfile>
            </q-tab-panel>
            <q-tab-panel :name="RoutesName.Assignments">
              <ListAssignment
                @row-click="
                  (assignment) =>
                    assignmentActions.editAssignment(assignment.id, () =>
                      handleShowForm('assignments')
                    )
                "
              >
                <template #actions>
                  <q-btn
                    color="primary"
                    :label="$t('assignment.labels.btnNew')"
                    @click="
                      assignmentActions.createAssignment(() =>
                        handleShowForm('assignments')
                      )
                    "
                  />
                </template>
              </ListAssignment>
            </q-tab-panel>
            <q-tab-panel :name="RoutesName.RightGroups">
              <ListRightGroup
                @row-click="
                  (rightGroup) =>
                    rightGroupActions.editRightGroup(rightGroup.id, () =>
                      handleShowForm('rightGroups')
                    )
                "
              >
                <template #actions>
                  <q-btn
                    color="primary"
                    :label="$t('rightGroup.labels.btnNew')"
                    @click="
                      rightGroupActions.createRightGroup(() =>
                        handleShowForm('rightGroups')
                      )
                    "
                  />
                </template>
              </ListRightGroup>
            </q-tab-panel>
            <q-tab-panel :name="RoutesName.Rights">
              <ListRight
                @row-click="
                  (right) =>
                    rightActions.editRight(right.id, () =>
                      handleShowForm('rights')
                    )
                "
              >
                <template #actions>
                  <q-btn
                    color="primary"
                    :label="$t('right.labels.btnNew')"
                    @click="
                      rightActions.createRight(() => handleShowForm('rights'))
                    "
                  />
                </template>
              </ListRight>
            </q-tab-panel>
          </q-tab-panels>
        </q-card>
        <q-inner-loading
          :showing="loading"
          :label="$t('messages.waiting')"
          label-class="text-teal"
          label-style="font-size: 1.1em"
          style="z-index: 10"
        />
      </div>
    </template>
  </PageWithPanel>
</template>
<script setup lang="ts">
import {
  ProfileDto,
  AssignmentDto,
  RightGroupDto,
  RightDto,
  RightReference,
} from "@/types/api";
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RoutesName } from "@/router/routesName";
// Components
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import ListUser from "@/components/User/ListUser.vue";
import ListProfile from "@/components/Profile/ListProfile.vue";
import ListRightGroup from "@/components/Right/ListRightGroup.vue";
import ListRight from "@/components/Right/ListRight.vue";
import ListAssignment from "@/components/Assignment/ListAssignment.vue";
import FormUser from "@/components/User/FormUser.vue";
import FormProfile from "@/components/Profile/FormProfile.vue";
import FormRightGroup from "@/components/Right/FormRightGroup.vue";
import FormRight from "@/components/Right/FormRight.vue";
import FormAssignment from "@/components/Assignment/FormAssignment.vue";
// Composables
import { useUserActions } from "@/composables/useUserActions";
import { useProfileActions } from "@/composables/useProfileActions";
import { useAssignmentActions } from "@/composables/useAssignmentActions";
import { useRightGroupActions } from "@/composables/useRightGroupActions";
import { useRightActions } from "@/composables/useRightActions";
import { usePage } from "@/composables/usePage";
import { authService } from "@/services/AuthService";
import { useUserStore } from "@/stores/user";
import { handlePromiseResults } from "@/utils";

const { user, actions: userActions } = useUserActions();
const { profile, actions: profileActions } = useProfileActions();
const { assignment, actions: assignmentActions } = useAssignmentActions();
const { rightGroup, actions: rightGroupActions } = useRightGroupActions();
const { right, actions: rightActions } = useRightActions();
const { name } = useRoute();
const router = useRouter();
const { showPanelRight } = usePage();
const isAdminUser = authService.hasRight(RightReference.ADMIN_USER);
const userStore = useUserStore();
/**
 * Type FormType qui sert à afficher le bon formulaire
 */
type FormType =
  | "users"
  | "profils"
  | "assignments"
  | "rights"
  | "rightGroups"
  | null;

const tab = ref<string>(name as string);
const showForm = ref<FormType>(null);
const loading = ref<boolean>(true);

onMounted(async () => {
  const promises = [
    userStore.fetchUserWithParam(
      authService.hasRight(RightReference.ADMIN_FULL),
      authService.hasRight(RightReference.ADMIN_USER) ||
        authService.hasRight(RightReference.FREELANCE_MANAGEMENT)
    ),
    userStore.fetchProfiles(),
    userStore.fetchAssignments(),
    userStore.fetchRightGroups(),
    userStore.fetchRights(),
  ];

  const results = await Promise.allSettled(promises);
  try {
    handlePromiseResults(results);
  } catch (err) {
    for (const error of (err as AggregateError).errors) {
      console.error(error);
    }
  }
  loading.value = false;
});

router.afterEach((to) => {
  tab.value = to.name as string;
});
/**
 * Fonction servant à afficher le panneau de droite avec le bon formulaire
 */
function handleShowForm(formType: FormType) {
  showPanelRight.value = true;
  showForm.value = formType;
}
/**
 * Fonction appelé au moment du changement d'onglet.
 */
function onTabChanged(value) {
  router.push({ name: value });
}
/**
 * Fonction appelé au moment de soumettre le formulaire profile
 */
function onFormProfileSubmit(profileData: ProfileDto) {
  profile.value
    ? profileActions.onEditProfile(profileData)
    : profileActions.onCreateProfile(profileData);
}
/**
 * Fonction appelé au moment de soumettre le formulaire assignment
 */
function onFormAssignmentSubmit(assignmentData: AssignmentDto) {
  assignment.value
    ? assignmentActions.onEditAssignment(assignmentData)
    : assignmentActions.onCreateAssignment(assignmentData);
}
/**
 * Fonction appelé au moment de soumettre le formulaire groupe de droit
 */
function onFormRightGroupSubmit(rightGroupData: RightGroupDto) {
  rightGroup.value
    ? rightGroupActions.onEditRightGroup(rightGroupData)
    : rightGroupActions.onCreateRightGroup(rightGroupData);
}
/**
 * Fonction appelé au moment de soumettre le formulaire de droit
 */
function onFormRightSubmit(rightData: RightDto) {
  right.value
    ? rightActions.onEditRight(rightData)
    : rightActions.onCreateRight(rightData);
}
</script>
