import type { CountryViewActions } from "@/types/country";
import type { CountryDto } from "@/types/api";
import { toRefs, reactive } from "vue";
import { useRouter } from "vue-router";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { createCountry } from "@/services/CountryService";
import { useCountryStore } from "@/stores/country";
import { RoutesName } from "@/router/routesName";
import { usePage } from "@/composables/usePage";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions pays
 */
interface CountryActionState {
  country: CountryDto;
  callbackOnSubmit: ((countryData: CountryDto) => void) | undefined;
}

/**
 * Fonction servant à interagir sur les différentes vues pays
 */
export const useCountryActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { findCountry, updateCountry, addCountry } = useCountryStore();
  const { showPanelRight } = usePage();

  const state = reactive<CountryActionState>({
    country: createCountry(),
    callbackOnSubmit: undefined,
  });

  const countryActions: CountryViewActions = {
    createCountry() {
      showPanelRight.value = true;
      state.country = createCountry();
      state.callbackOnSubmit = countryActions.onCreateCountry;
    },
    findCountry(countryId: number) {
      findCountry(`${countryId}`)
        .then((c) => {
          state.country = c;
          state.callbackOnSubmit = countryActions.onEditCountry;
        })
        .catch((err: Error) => {
          $q.notify(err.message);
          router.push({ name: RoutesName.Countries });
        });
    },
    editCountry(countryId: number) {
      showPanelRight.value = true;
      countryActions.findCountry(countryId);
    },
    onCreateCountry(countryData: CountryDto) {
      addCountry(countryData)
        .then(() => {
          showPanelRight.value = false;
          $q.notify(i18n.t("country.messages.created"));
          router.push({ name: RoutesName.Countries });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditCountry(countryData: CountryDto) {
      updateCountry(countryData)
        .then(() => {
          $q.notify(i18n.t("country.messages.updated"));
          router.push({ name: RoutesName.Countries });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
  };
  return { ...toRefs(state), actions: countryActions };
});
