import type { AxiosResponse } from "axios";
import type { LongFormatServiceInstance } from "@/types/longFormat";
import type { CrudServiceInstance } from "@/types/crud";
import {
  AddSerieLongFormatArticleParam,
  LongFormatInformation,
  MoveLongFormatArticleParam,
  MoveSerieLongFormatArticleParam,
  RemoveLongFormatArticleParam,
  SeriesArticle,
} from "@/types/api";
import { downcastArticleDto } from "@/helpers/article";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";
import { downcastDatetime, upcastDatetime } from "@/utils";

/**
 * Service LongFormat pour manipuler les articles long format dans leur serie.
 */
class LongFormatService
  extends AxiosRequest
  implements LongFormatServiceInstance
{
  crudService: CrudServiceInstance<LongFormatInformation>;
  /**
   * Constructeur de la class Article service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<LongFormatInformation>(rootPath);
  }

  /**
   * Récupère les configurations, articles et dates des formats longs pour une publication.
   * @param publication Identifiant de la publication.
   * @returns LongFormatInformation.
   */
  get(publication: string) {
    return this.axiosInstance
      .get(`${this.rootPath}/${publication}`)
      .then((response: AxiosResponse<LongFormatInformation>) => {
        for (const article of response.data.articles) {
          downcastArticleDto(article);
        }
        for (const conf of response.data.releaseConfigurations) {
          for (const article of conf.articles) {
            downcastArticleDto(article);
          }
          conf.availableDates = conf.availableDates.map(
            (date) => downcastDatetime(date) as string
          );
        }
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Déplace un article sur une nouvelle date de parution.
   * @param idPublication Identifiant de la publication.
   * @param idArticle Identifiant de l'article
   * @param currentDate Date de parution de l'article.
   * @param newDate Nouvelle date de parution.
   * @returns Promise
   */
  moveArticle(
    idPublication: string,
    idArticle: number,
    currentDate: string,
    newDate: string
  ) {
    const param = {
      idPublication,
      idArticle,
      currentDate: upcastDatetime(currentDate),
      newDate: upcastDatetime(newDate),
    } as MoveLongFormatArticleParam;

    return this.axiosInstance
      .post(`${this.rootPath}/moveArticle`, param)
      .then((response: AxiosResponse) => {
        if (response.status !== 200) {
          throw new Error("Erreur lors du déplacement de l'article");
        }
      })
      .catch(this.handleError);
  }

  /**
   * Supprime un article d'une serie long format.
   * @param idPublication Identifiant de la publication.
   * @param idArticle Identifiant de l'article
   * @param currentDate Date de parution de l'article.
   * @param deleteArticle Vrai si l'on souhaite marqué l'article comme supprimé, sinon l'article passe en frigo.
   * @returns Promise
   */
  removeArticle(
    idPublication: string,
    idArticle: number,
    currentDate: string,
    deleteArticle: boolean
  ) {
    const param = {
      idPublication,
      idArticle,
      currentDate: upcastDatetime(currentDate),
      delete: deleteArticle,
    } as RemoveLongFormatArticleParam;

    return this.axiosInstance
      .post(`${this.rootPath}/removeArticle`, param)
      .then((response: AxiosResponse) => {
        if (response.status !== 200) {
          throw new Error("Erreur lors de la suppresion d'un article");
        }
      })
      .catch(this.handleError);
  }

  /**
   * Déplace une série d'article long format.
   * @param idPublication Identifiant de la publication.
   * @param idArticle Identifiant de l'article
   * @param currentDate Date de parution de l'article.
   * @param push Vrai si l'on souhaite poussé la série, sinon on l'avance.
   * @returns Promise
   */
  moveSeries(
    idPublication: string,
    idArticle: number,
    currentDate: string,
    push: boolean
  ) {
    const param = {
      idPublication,
      idArticle,
      currentDate: upcastDatetime(currentDate),
      push,
    } as MoveSerieLongFormatArticleParam;

    return this.axiosInstance
      .post(`${this.rootPath}/moveSeries`, param)
      .then((response: AxiosResponse) => {
        if (response.status !== 200) {
          throw new Error("Erreur lors du déplacement d'une série");
        }
      })
      .catch(this.handleError);
  }

  /**
   * Ajoute une serie d'articles long format.
   * @param idPublication Identifiant de la publication.
   * @param idFormat Identifiant du format.
   * @param startDate Date du premier article.
   * @param endDate Date du dernier article.
   * @param authors Liste des auteurs des articles.
   * @returns Promise
   */
  addSeries(
    idPublication: string,
    idFormat: string,
    startDate: string,
    endDate: string,
    articles: SeriesArticle[]
  ) {
    const param = {
      idPublication,
      idFormat,
      currentDate: upcastDatetime(startDate),
      endDate: upcastDatetime(endDate),
      articles,
    } as AddSerieLongFormatArticleParam;

    return this.axiosInstance
      .post(`${this.rootPath}/AddSeries`, param)
      .then((response: AxiosResponse) => {
        if (response.status !== 200) {
          throw new Error("Erreur lors de la création d'une série");
        }
      })
      .catch(this.handleError);
  }
}

export const longFormatService = new LongFormatService("longFormats");
