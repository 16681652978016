import type { FormatDto } from "@/types/api";
import type { FormatViewActions } from "@/types/format";
import { toRefs, reactive } from "vue";
import { useRouter } from "vue-router";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { createFormat } from "@/services/FormatService";
import { useFormatStore } from "@/stores/format";
import { RoutesName } from "@/router/routesName";
import { usePage } from "@/composables/usePage";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions format
 */
interface FormatActionState {
  format: FormatDto;
  callbackOnSubmit: ((formatData: FormatDto) => void) | undefined;
}

/**
 * Fonction servant à interagir sur les différentes vues format
 */
export const useFormatActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { findFormat, addFormat, updateFormat } = useFormatStore();
  const { showPanelRight } = usePage();
  const state = reactive<FormatActionState>({
    format: createFormat(),
    callbackOnSubmit: undefined,
  });

  const formatActions: FormatViewActions = {
    createFormat() {
      showPanelRight.value = true;
      state.format = createFormat();
      state.callbackOnSubmit = formatActions.onCreateFormat;
    },
    findFormat(formatId: string) {
      try {
        const form = findFormat(`${formatId}`);
        state.format = form;
        state.callbackOnSubmit = formatActions.onEditFormat;
      } catch (err) {
        if (err instanceof Error) $q.notify(err.message);

        router.push({ name: RoutesName.Formats });
      }
    },
    editFormat(formatId: string) {
      showPanelRight.value = true;
      formatActions.findFormat(formatId);
    },
    onCreateFormat(formatData: FormatDto) {
      addFormat(formatData)
        .then(() => {
          showPanelRight.value = false;
          $q.notify(i18n.t("format.messages.created"));
          router.push({ name: RoutesName.Formats });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditFormat(formatData: FormatDto) {
      updateFormat(formatData)
        .then(() => {
          $q.notify(i18n.t("format.messages.updated"));
          router.push({ name: RoutesName.Formats });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
  };
  return { ...toRefs(state), actions: formatActions };
});
