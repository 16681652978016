<template>
  <PageWithPanel :actions="pageActions">
    <template v-if="!loading">
      <Welcome v-if="isBlocsEmpty" />
      <q-list v-else>
        <AutoRefresh
          :auto-refresh="true"
          :auto-refresh-time-in-seconds="600"
          :refresh-function="loadAllArticles"
          :refresh-condition-checker="() => true"
          :auto-refresh-toggle="false"
        />
        <div
          class="q-gutter-md q-mb-md fit row wrap justify-start items-start items-stretch content-start"
        >
          <HomeCounterBloc
            v-for="b in adminBlocs"
            :key="b.title"
            :title="b.title"
            :counter="b.counter"
            :action-label="b.actionLabel"
            :action-callback="b.actionCallback"
          />
          <HomeCircularCounterBloc
            v-for="b in circularBlocs"
            :key="b.title"
            :="b"
          />
        </div>
        <HomeArticleBloc v-for="b in blocs" :key="b.titleKey" v-bind="b" />
      </q-list>
    </template>
    <template #panelRight>
      <div class="q-pa-md">
        <FormSimpleArticle :key="formSimpleArticle" @submit="onCreate" />
      </div>
    </template>
    <q-inner-loading
      :showing="loading"
      :label="$t('messages.waiting')"
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </PageWithPanel>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import type { PageAction } from "@/types/page";
import { searchService } from "@/services/SearchService";
import { authService } from "@/services/AuthService";
import {
  ArticleDto,
  ContributionType,
  RightReference,
  WorkflowState,
} from "@/types/api";
import { useHomeBlock } from "@/composables/useHomeBlock";
import { calculateWorkingDay, handlePromiseResults } from "@/utils";
import Welcome from "@/components/Welcome.vue";
import HomeArticleBloc from "@/components/Home/HomeArticleBloc.vue";
import HomeCounterBloc from "@/components/Home/HomeCounterBloc.vue";
import HomeCircularCounterBloc from "@/components/Home/HomeCircularCounterBloc.vue";
import FormSimpleArticle from "@/components/Article/FormSimpleArticle.vue";
import router from "@/router";
import { RoutesName } from "@/router/routesName";
import { useUserStore } from "@/stores/user";
import { i18n } from "@/i18n";
import { useArticleActions } from "@/composables/useArticleActions";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import AutoRefresh from "@/components/Custom/AutoRefresh.vue";
import { ReviewTranslationInErrorAfter } from "@/constants";
import { HomeRowStyle } from "@/types/home";
import { usePublicationStore } from "@/stores/publication";
import { freelanceBudgetService } from "@/services/FreelanceBudgetService";

const { publications } = usePublicationStore();

const loading = ref(true);
const { users } = useUserStore();
const canAccessToAllFreelanceToSet = () =>
  authService.hasRight(RightReference.FREELANCE_MANAGEMENT);
const isRedactor = authService.isRedactor.bind(authService);
const isEditor = authService.isEditor.bind(authService);
const isTranslator = authService.isTranslator.bind(authService);
const isRedactorOrTranslator = (publication) =>
  isRedactor(publication) || isTranslator(publication);

const canDisplayReviewBlock = (publication) => {
  return (
    isRedactor(publication) ||
    authService.hasRight(RightReference.HOME_BLOCK_DISPLAY_REVIEW, publication)
  );
};
const canDisplayLongFormatBlock = (publication) => {
  return authService.hasRight(
    RightReference.HOME_BLOCK_DISPLAY_LONG_FORMAT,
    publication
  );
};

const { actions } = useArticleActions();
let formSimpleArticle = ref<number>(0);

const { articles: myArticles, loadArticles: loadMyArticles } = useHomeBlock(
  isRedactor,
  searchService.myArticles.bind(searchService)
);
const { articles: myNextLongFormat, loadArticles: loadMyNextLongFormat } =
  useHomeBlock(isRedactor, searchService.myNextLongFormat.bind(searchService));
const {
  articles: articlesAwaitingEditorCorrection,
  loadArticles: loadArticlesAwaitingEditorCorrection,
} = useHomeBlock(
  isEditor,
  searchService.articlesAwaitingEditorCorrection.bind(searchService)
);
const {
  articles: articlesInEditorCorrection,
  loadArticles: loadArticlesInEditorCorrection,
} = useHomeBlock(
  isEditor,
  searchService.articlesInEditorCorrection.bind(searchService)
);
const {
  articles: myCorrectionsAsEditor,
  loadArticles: loadMyCorrectionsAsEditor,
} = useHomeBlock(
  isEditor,
  searchService.myCorrectionsAsEditor.bind(searchService)
);
const {
  articles: myCorrectionsAsAuthor,
  loadArticles: loadMyCorrectionsAsAuthor,
} = useHomeBlock(
  isRedactor,
  searchService.myCorrectionsAsAuthor.bind(searchService)
);
const { articles: myReviews, loadArticles: loadMyReviews } = useHomeBlock(
  canDisplayReviewBlock,
  searchService.myReviews.bind(searchService)
);
const { articles: myTranslations, loadArticles: loadMyTranslations } =
  useHomeBlock(isTranslator, searchService.myTranslations.bind(searchService));
const {
  articles: myTranslationsToReview,
  loadArticles: loadMyTranslationsToReview,
} = useHomeBlock(
  isRedactorOrTranslator,
  searchService.myTranslationsToReview.bind(searchService)
);

const {
  articles: myLastestCompletedTranslations,
  loadArticles: loadMyLastestCompletedTranslations,
} = useHomeBlock(
  isTranslator,
  searchService.myLastestCompletedTranslations.bind(searchService)
);

const {
  articles: myFreelancersWithoutAmount,
  loadArticles: loadMyFreelancersWithoutAmount,
} = useHomeBlock(
  isRedactor,
  searchService.myFreelancersWithoutAmount.bind(searchService)
);
const {
  articles: freelancersWithoutAmount,
  loadArticles: loadFreelancersWithoutAmount,
} = useHomeBlock(
  canAccessToAllFreelanceToSet,
  searchService.freelancersWithoutAmount.bind(searchService)
);
const { articles: myOnlineArticles, loadArticles: loadMyOnlineArticles } =
  useHomeBlock(isRedactor, searchService.myOnlineArticles.bind(searchService));

const {
  articles: myArticlesInErrorEditor,
  loadArticles: loadArticlesInErrorEditor,
} = useHomeBlock(
  isEditor,
  searchService.myArticlesInErrorEditor.bind(searchService)
);

const {
  articles: myOnlineArticlesNotInImportedStatus,
  loadArticles: loadMyOnlineArticlesNotInImportedStatus,
} = useHomeBlock(
  isEditor,
  searchService.myOnlineArticlesNotInImportedStatus.bind(searchService)
);

const { articles: longFormatArticles, loadArticles: loadLongFormatArticles } =
  useHomeBlock(
    canDisplayLongFormatBlock,
    searchService.longFormatArticles.bind(searchService)
  );

const {
  articles: translationsForNextEditionToReview,
  loadArticles: loadTranslationsForNextEditionToReview,
} = useHomeBlock(
  canDisplayLongFormatBlock,
  searchService.translationsForNextEditionToReview.bind(searchService)
);

/** Bloc */
interface Bloc {
  title: string;
  counter: number;
  actionLabel: string;
  actionCallback: () => void;
}

/** Bloc */
interface CircularBloc {
  title: string;
  subtitle: string;
  idPublication: string;
  publication: string;
  value: number;
  min: number;
  max: number;
}

const adminBlocs = computed(() => {
  const aBlocs: Bloc[] = [];

  if (authService.hasRight(RightReference.FREELANCE_MANAGEMENT)) {
    aBlocs.push({
      title: "home.freelancersWithoutId",
      counter: users.filter(
        (u) => !u.deleted && u.freelance.length && u.idBackOffice === undefined
      ).length,
      actionLabel: "home.actions.editFreelancers",
      actionCallback: () => {
        router.push({ name: RoutesName.Users, query: { freelance: "true" } });
      },
    });
  }

  return aBlocs;
});

const circularBlocs = ref<CircularBloc[]>([]);

const now = new Date();
publications.forEach((p) => {
  if (
    authService.hasRight(
      RightReference.HOME_BLOCK_DISPLAY_FREELANCE_CONSUMPTION,
      p.id
    )
  ) {
    freelanceBudgetService
      .getBudgets(p.id, now.getFullYear(), now.getMonth() + 1, true)
      .then((budgets) => {
        if (budgets.length != 1) return;
        const budget = budgets[0];
        circularBlocs.value.push({
          publication: p.name,
          idPublication: p.id,
          title: i18n.t("home.freelanceConsumptionBudget", {
            budget: budget.amount,
          }),
          subtitle: i18n.t("home.freelanceConsumption", {
            id: p.id.substring(0, 2),
          }),
          min: 0,
          max: budget.amount,
          value: budget.consumption,
        });
      });
  }
});

const blocs = computed(() => {
  return [
    {
      titleKey: "home.freelancersWithoutAmount",
      contributionType: null,
      showTranslation: false,
      setFreelanceAmount: true,
      showSigns: true,
      articles: freelancersWithoutAmount.value,
      showPublication: true,
      showContributor: [ContributionType.Author, ContributionType.Freelancer],
    },
    {
      titleKey: "home.myArticles",
      contributionType: null,
      showTranslation: false,
      articles: myArticles.value,
    },
    {
      titleKey: "home.myNextLongFormat",
      contributionType: null,
      showTranslation: false,
      articles: myNextLongFormat.value,
      getClasses: function (article: ArticleDto) {
        if (!article.deadline) return HomeRowStyle.Default;
        const deadline = new Date(article.deadline);
        const now = new Date();

        if (deadline <= now) return HomeRowStyle.Error;

        const deadlineWarning = calculateWorkingDay(deadline, -2);
        if (now >= deadlineWarning) return HomeRowStyle.Warning;

        return HomeRowStyle.Default;
      },
    },
    {
      titleKey: "home.myCorrections",
      contributionType: null,
      showTranslation: false,
      articles: myCorrectionsAsEditor.value,
    },
    {
      titleKey: "home.articlesAwaitingEditorCorrection",
      contributionType: null,
      showTranslation: null,
      state: WorkflowState.AttenteCorrectionSr,
      articles: articlesAwaitingEditorCorrection.value,
      showPublication: true,
      showSigns: true,
      showContributor: [ContributionType.Author, ContributionType.Editor],
    },
    {
      titleKey: "home.articlesInEditorCorrection",
      contributionType: null,
      showTranslation: null,
      state: WorkflowState.CorrectionSr,
      articles: articlesInEditorCorrection.value,
      showPublication: true,
      showSigns: true,
      showContributor: [ContributionType.Author, ContributionType.Editor],
    },
    {
      titleKey: "home.myCorrections",
      contributionType: null,
      showTranslation: false,
      articles: myCorrectionsAsAuthor.value,
    },
    {
      titleKey: "home.myReviews",
      contributionType: ContributionType.Reviewer,
      showTranslation: false,
      articles: myReviews.value,
    },
    {
      titleKey: "home.myTranslations",
      contributionType: null,
      showTranslation: true,
      articles: myTranslations.value,
    },
    {
      titleKey: "home.myTranslationsToReview",
      contributionType: ContributionType.Reviewer,
      showTranslation: true,
      articles: myTranslationsToReview.value,
      getClasses: function (article: ArticleDto) {
        if (!article.publishedOn) return HomeRowStyle.Default;
        const publishedOn = new Date(article.publishedOn);
        const nextWorkingDay = calculateWorkingDay(new Date(), 1);
        nextWorkingDay.setHours(23, 59, 59, 999);
        const now = new Date();

        if (publishedOn <= nextWorkingDay) {
          return now.getHours() >= ReviewTranslationInErrorAfter
            ? HomeRowStyle.Error
            : HomeRowStyle.Warning;
        }

        return HomeRowStyle.Default;
      },
    },
    {
      titleKey: "home.myFreelancersWithoutAmount",
      contributionType: null,
      showTranslation: false,
      setFreelanceAmount: true,
      showSigns: true,
      articles: myFreelancersWithoutAmount.value,
    },
    {
      titleKey: "home.myTranslationsCompleted",
      contributionType: null,
      showTranslation: true,
      articles: myLastestCompletedTranslations.value,
    },
    {
      titleKey: "home.articlesInErrorEditor",
      contributionType: null,
      showTranslation: false,
      articles: myArticlesInErrorEditor.value,
    },
    {
      titleKey: "home.onlineArticlesNotInImportedStatus",
      contributionType: null,
      showTranslation: false,
      articles: myOnlineArticlesNotInImportedStatus.value,
    },
    {
      titleKey: "home.articlesLongFormat",
      contributionType: null,
      showTranslation: false,
      articles: longFormatArticles.value,
    },
    {
      titleKey: "home.translationsForNextEditionToReview",
      contributionType: null,
      showTranslation: false,
      articles: translationsForNextEditionToReview.value,
    },
    {
      titleKey: "home.myOnlineArticles",
      contributionType: null,
      showTranslation: null,
      articles: myOnlineArticles.value,
    },
  ].filter((b) => b.articles.length > 0);
});

const pageActions = computed<PageAction[]>(() => [
  {
    label: i18n.t("article.labels.btnNew"),
    shortLabel: i18n.t("article.labels.shortBtnNew"),
    icon: "add",
    disable: false,
    handler: actions.createArticle,
    visible: true,
  },
]);

const isBlocsEmpty = computed(
  () => blocs.value.length + adminBlocs.value.length === 0
);

onMounted(() => {
  loadAllArticles();
});

/** Load All Articles */
async function loadAllArticles() {
  const promises = [
    loadMyArticles(),
    loadMyNextLongFormat(),
    loadMyCorrectionsAsEditor(),
    loadArticlesAwaitingEditorCorrection(),
    loadArticlesInEditorCorrection(),
    loadMyCorrectionsAsAuthor(),
    loadMyReviews(),
    loadMyTranslations(),
    loadMyTranslationsToReview(),
    loadMyLastestCompletedTranslations(),
    loadMyFreelancersWithoutAmount(),
    loadMyOnlineArticles(),
    loadFreelancersWithoutAmount(),
    loadArticlesInErrorEditor(),
    loadMyOnlineArticlesNotInImportedStatus(),
    loadLongFormatArticles(),
    loadTranslationsForNextEditionToReview(),
  ];
  const results = await Promise.allSettled(promises);
  try {
    handlePromiseResults(results);
    loading.value = false;
  } catch (err) {
    for (const error of (err as AggregateError).errors) {
      console.error(error);
    }
    loading.value = false;
  }
}

/** On Create Article */
function onCreate(article: ArticleDto) {
  actions.onCreateArticle(article, () => {
    formSimpleArticle.value += 1;
  });
}
</script>
