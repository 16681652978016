<template>
  <Page>
    <div v-if="$keycloak && $keycloak.ready">
      <div v-if="$keycloak.authenticated">
        <h2>Vous êtes authentifié avec Keycloak</h2>
        <h3>Token Bearer récupéré via Keycloak:</h3>
        <code>{{ token }}</code>
        <br />

        <h3>Contenu du token Bearer:</h3>
        <code>{{ $keycloak.tokenParsed }}</code>
        <br />
        <button @click="$keycloak.logoutFn">Déconnexion</button>
        <button @click="$keycloak.keycloak?.updateToken(300)">
          Rafraishir le token
        </button>
      </div>
    </div>
  </Page>
</template>

<script setup lang="ts">
import { useKeycloak } from "@/plugins/keycloak/useKeycloak";
import Page from "@/components/Layouts/Page.vue";
const { token } = useKeycloak();
</script>
