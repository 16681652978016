import { LongFormatInformation } from "@/types/api";

/** Permet de connaitre toutes les dates disponibles d'une configuration */
export function getAvailableDates(
  info?: LongFormatInformation,
  releaseKey?: string
) {
  if (!info || !releaseKey) return [];

  const releaseConfig = info?.releaseConfigurations.find(
    (rc) => rc.key === releaseKey
  );

  if (!releaseConfig) return [];

  const usedDates = releaseConfig.articles.map(
    (a) => a.publishedOn?.split("T")[0]
  );

  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  let dates =
    releaseConfig.availableDates
      .filter((d) => new Date(d) > tomorrow)
      .map((d) => d.split("T")[0]) ?? [];

  dates = dates.filter((d) => !usedDates.some((ud) => ud == d));

  return dates.map((d) => d.replaceAll("-", "/"));
}
