<template>
  <q-card bordered>
    <q-card-section>
      <FormCountry
        :label-btn-submit="$t('country.labels.btnCreate')"
        @submit="onCreateCountry"
      />
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { useCountryActions } from "@/composables/useCountryActions";
import FormCountry from "@/components/Country/FormCountry.vue";

const { actions } = useCountryActions();
const { onCreateCountry } = actions;
</script>
