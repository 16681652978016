import type { CrudServiceInstance } from "@/types/crud";
import type { FreelanceBudgetDto } from "@/types/api";
import { FreelanceBudgetServiceInstance } from "@/types/freelanceBudget";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";
import { AxiosResponse } from "axios";

/**
 * Service assignment pour récupérer les budgets et les modifier.
 */
class FreelanceBudgetService
  extends AxiosRequest
  implements FreelanceBudgetServiceInstance
{
  crudService: CrudServiceInstance<FreelanceBudgetDto>;

  /**
   * Constructeur de la class Assignment service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<FreelanceBudgetDto>(rootPath);
  }

  /**
   * Liste les assignments
   * @param {string} publication - publication.
   * @param {number} year - année.
   * @param {number} month - mois.
   * @param {boolean} withConsumption - avec la consommation.
   */
  getBudgets(
    publication: string,
    year: number,
    month: number | undefined = undefined,
    withConsumption: boolean = false
  ) {
    return this.axiosInstance
      .get(
        `${this.rootPath}/${publication}/${year}/${
          month === undefined ? "" : month
        }?withConsumption=${withConsumption}`
      )
      .then((response: AxiosResponse<FreelanceBudgetDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Met à jour les budgets
   * @param {string} publication - publication.
   * @param {number} year - année.
   * @param {FreelanceBudgetDto[]} budgets - budgets.
   */
  updateBudgets(
    publication: string,
    year: number,
    budgets: FreelanceBudgetDto[]
  ) {
    return this.axiosInstance
      .post(`${this.rootPath}/${publication}/${year}`, budgets)
      .then((response: AxiosResponse) => {
        if (response.status !== 200) {
          throw new Error("Erreur lors de la sauvegarde du budget");
        }
      })
      .catch(this.handleError);
  }
}

export const freelanceBudgetService = new FreelanceBudgetService(
  "freelanceBudgets"
);
