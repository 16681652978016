import type ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import type { EditorConfig } from "@ckeditor/ckeditor5-core/src/editor/editorconfig";
import type Plugin from "@ckeditor/ckeditor5-core/src/plugin";
import type { CommentThreadDto } from "@/types/api";
import CommentsAdapter from "@/ckadapter/CommentsAdapter";
import TrackChangesAdapter from "@/ckadapter/TrackChangesAdapter";
import UsersIntegration from "@/ckadapter/UsersIntegration";
import { useUserStore } from "@/stores/user";
import "@/ckeditor";
import "@/ckeditor/build/translations/fr";
import {
  conf_elcanoapi_current,
  prolexis_core_path,
  prolexis_url_proxy,
} from "@/constants";
import { authService } from "@/services/AuthService";
import { useConfigStore } from "@/stores/config";
import MentionCustomization from "@/components/ckeditor/mentionCustomization";
import { commentService } from "@/services/CommentService";
import { signalRService } from "@/services/SignalrService";
import { ElcanoNotificationType } from "@/types/api";

import i18n from "@/i18n";
import { i18n as i18nInstance } from "@/i18n";
import { removeDiacritics, useLocale } from "@/utils";
import {
  CommentsConfig,
  SidebarConfig,
} from "@ckeditor/ckeditor5-comments/src/config";
const { isFr } = useLocale();

const licenseKey =
  "R1R2ejNkaE03Sm8rY1dOL0FiNEZDLzROSldHNS9ZcllOMjNjV3VibXVVRGlpMithS0NOZmU3VkUwakowWnMxVi1NakF5TkRBMk1UZz0=";

/**
 *
 */
export function disableCollaborationPlugins(instance: ClassicEditor) {
  instance.commands.get("trackChanges")?.forceDisabled("Elcano");
  instance.commands.get("addCommentThread")?.forceDisabled("Elcano");
}

/**
 *
 */
export function configureCommentsOnly(
  instance: ClassicEditor,
  onlyCommentMode: boolean
) {
  const commentsOnlyPlugin = instance.plugins.get("CommentsOnly") as Plugin;
  commentsOnlyPlugin.isEnabled = onlyCommentMode;
  if (onlyCommentMode) {
    instance.commands.get("addCommentThread")?.forceDisabled("Elcano");
  } else {
    instance.commands.get("addCommentThread")?.clearForceDisabled("Elcano");
  }
}

/** Configure les plugins de l'instance CkEditor */
export function configurePlugins(
  instance: ClassicEditor,
  onlyCommentMode: boolean,
  prolexisCallback?: () => void,
  helpCallback?: () => void,
  linkTranslationCallback?: (url, callback) => Promise<void>
) {
  configureCommentsOnly(instance, onlyCommentMode);
  if (onlyCommentMode === false) {
    const wordCountPlugin = instance.plugins.get("WordCount");
    wordCountPlugin.isEnabled = true;
  }

  if (prolexisCallback) {
    const diagplwsPlugin = instance.plugins.get("DiagPlWs") as Plugin & {
      callback: () => void;
    };
    diagplwsPlugin.callback = prolexisCallback;
  }

  if (helpCallback) {
    const helpPlugin = instance.plugins.get("Aide") as Plugin & {
      callback: () => void;
    };
    helpPlugin.callback = helpCallback;
  }

  if (linkTranslationCallback) {
    const linkTranslationPlugin = instance.plugins.get(
      "LinkTranslation"
    ) as Plugin & {
      callback: (url, callback) => Promise<void>;
    };

    linkTranslationPlugin.callback = linkTranslationCallback;
  }

  const { destroy } = syncComments(instance, null);
  instance.on("destroy", () => {
    destroy();
  });
}

/**
 *
 */
export function switchAnnotationToInline(instance: ClassicEditor) {
  try {
    instance.plugins.get("AnnotationsUIs").switchTo("inline");
  } catch (error) {
    console.error(error);
  }
}
/**
 *
 */
export function switchInlineAnnotationToSidebar(instance: ClassicEditor) {
  try {
    instance.plugins.get("AnnotationsUIs").switchTo("wideSidebar");
  } catch (error) {
    console.error(error);
  }
}

/**
 *
 */
export function getCkInstance(container: HTMLElement | null) {
  if (container == null) return;

  const domEditableElement:
    | (Element & { ckeditorInstance: ClassicEditor })
    | null = container.querySelector(".ck-editor__editable");

  if (domEditableElement) return domEditableElement.ckeditorInstance;
}

/**
 *
 */
interface EditorReadyOptions {
  needToSaveCallback: () => void;
}

/**
 * Permet de désactiver le bouton gras lorsque l'on est dans un interTitre
 * @param editorInstance instance CkEditor
 * @returns void
 */
function disableBoldCommand(editorInstance: ClassicEditor) {
  const boldCommand = editorInstance.commands.get("bold");

  if (!boldCommand) return;

  const selection = editorInstance.model.document.selection;
  const positions = [selection.getFirstPosition(), selection.getLastPosition()];

  for (let index = 0; index < positions.length; index++) {
    const element = positions[index];

    if (element && element.parent && element.parent["name"] === "interTitre") {
      boldCommand.forceDisabled("Elcano");
      return;
    }
  }

  boldCommand.clearForceDisabled("Elcano");
}

/**
 * Permet de désactiver le bouton heading lorsque la selection comprend un gras
 * Pour eviter de passer du texte en intertitre ou chapitre insiders
 * @param editorInstance instance CkEditor
 * @returns void
 */
function disableHeadingCommand(editorInstance: ClassicEditor) {
  const headingCommand = editorInstance.commands.get("heading");

  if (!headingCommand) return;

  const selection = editorInstance.model.document.selection;
  const positions = [selection.getFirstPosition(), selection.getLastPosition()];

  for (let index = 0; index < positions.length; index++) {
    const element = positions[index];

    if (element && element.parent && element.parent["name"] === "paragraph") {
      // parcourir l'ensemble des enfants du parent pour voir si il y a un bold
      const children = element.parent.getChildren();
      for (const child of children) {
        for (const attr of child.getAttributes()) {
          if (attr[0] === "bold" && attr[1] === true) {
            headingCommand.forceDisabled("Elcano");
            return;
          }
        }
      }
    }
  }

  headingCommand.clearForceDisabled("Elcano");
}
/**
 *
 */
export function onEditorReady(
  editorInstance: ClassicEditor,
  options?: EditorReadyOptions
) {
  editorInstance.model.document.selection.on("change:range", function () {
    disableBoldCommand(editorInstance);
    disableHeadingCommand(editorInstance);
  });
  editorInstance.commands.get("insertImage")?.on("execute", () => {
    editorInstance.execute("imageStyle", { value: "centerbig" });
  });

  if (!options) return;

  if (options.needToSaveCallback) {
    editorInstance.model.markers.on(
      "update:comment",
      (evt, marker, oldRange, newRange) => {
        const threadId = marker.name.split(":")[1];
        if (!newRange) {
          console.log(
            `The comment thread with ID ${threadId} has been removed.`
          );
          options.needToSaveCallback();
        }
      }
    );
  }
}

/**
 * Interface de la config custom de ckeditor pour les commentaires
 */
interface CommentsConfigCustom extends CommentsConfig {
  editorConfig: EditorConfigCustom;
}

/**
 * Config Prolexis
 */
interface ProlexisConfig {
  urlWs: string;
  corePath: string;
}

/**
 * Interface de la config custom de ckeditor
 */
interface EditorConfigCustom extends EditorConfig {
  DiagPlWs?: ProlexisConfig;
  comments?: CommentsConfigCustom;
  needTranslationForAnnotation?: boolean;
}

/**
 *
 */
export function configureCkFinder() {
  const loc = window.location;
  window.CKFinder.basePath = `${loc.protocol}//${loc.host}/ckfinder/`;
  window.CKFinder.config({
    plugins: ["customPanel", "pluginSearchMetadata", "keyCloakTokenManager"],
    connectorPath: `${conf_elcanoapi_current}/medias`,
    i18n: i18n,
    authService: authService,
    removeModules: "EditImage,Maximize",
    displayFoldersPanel: false,
    resizeImages: false,
    startupPath: "Images:/",
    thumbnailDefaultSize: 150,
    thumbnailMinSize: 150,
    thumbnailMaxSize: 150,
    dialogOverlaySwatch: "a",
    baseUrl: window.location.origin,
  });
}

/**
 * Récupère les options par défaut pour les commentaires.
 */
function getCommentsOption() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const builtinPlugins = window.ClassicEditor.ClassicEditor.builtinPlugins;
  const MentionPlugin = builtinPlugins.find(
    (plugin) => plugin.pluginName == "Mention"
  );

  const userStore = useUserStore();

  const feedItems = userStore.usersLight
    .filter((u) => u.freelance.length == 0)
    .map((u) => ({
      userId: u.id,
      id: "@" + u.name,
      text: "@" + u.name,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));

  const feedItemsFiltered = function (query) {
    const q = removeDiacritics(query.toLowerCase());

    return feedItems.filter((item) =>
      removeDiacritics(item.text.toLowerCase()).includes(q)
    );
  };

  return {
    editorConfig: {
      extraPlugins: [MentionPlugin, MentionCustomization],
      mention: {
        feeds: [
          {
            marker: "@",
            feed: feedItemsFiltered,
            minimumCharacters: 1,
          },
        ],
      },
    },
  };
}

/**
 * Synchronise automatiquement les commentaires à l'aide de SignalR.
 */
function syncComments(instance: any, threadId: string | null) {
  const commentsRepository = instance.plugins.get("CommentsRepository");
  const annotations = instance.plugins.get("Annotations");
  const channelId = instance.config.get("collaboration").channelId;

  /**
   * Rafraichit les commentaires en recréant le context.
   */
  async function refreshComments(data: any) {
    console.log("refreshComment", threadId, data.data);

    if (threadId !== null && threadId !== data.data) return;

    const currentThreadId = threadId ?? data.data;
    if (!currentThreadId) return;

    const threadFromServer = await commentService.getCommentThread(
      currentThreadId,
      channelId
    );

    const thread = commentsRepository.getCommentThread(currentThreadId);

    if (!thread) {
      try {
        await commentsRepository.fetchCommentThread({
          channelId,
          currentThreadId,
        });
      } catch (error) {
        console.error(error);
      }
      return;
    }

    syncCommentsBetweenLocalAndServer(thread, threadFromServer);
  }

  signalRService.subscribeToMessageType(
    channelId,
    threadId && threadId.endsWith(":notifications")
      ? ElcanoNotificationType.ArticleGeneralComment
      : ElcanoNotificationType.ArticleContentComment,
    refreshComments
  );

  /**
   * A appeler lors de la destruction de CkEditor.
   */
  function destroy() {
    signalRService.unsubscribeToMessageType(
      channelId,
      ElcanoNotificationType.ArticleGeneralComment,
      refreshComments
    );
  }

  return { commentsRepository, annotations, destroy };
}

/**
 * Permet de mettre a jour le thread local avec les données du serveur.
 */
function syncCommentsBetweenLocalAndServer(
  thread: any,
  threadFromServer: CommentThreadDto
) {
  const comments = threadFromServer.comments;

  for (let index = 0; index < thread.comments.length; index++) {
    const element = thread.comments.get(index);
    if (!comments.some((cc) => element.id === cc.commentId))
      element.remove({ isFromAdapter: true });
  }

  comments.forEach((c) => {
    const existingComment = thread.getComment(c.commentId);

    if (existingComment) {
      existingComment.update({
        ...c,
        isFromAdapter: true,
      });
    } else {
      thread.addComment({
        ...c,
        isFromAdapter: true,
      });
    }
  });
}

/**
 * Chargement des articles avec les suggestions acceptées
 * @param plugin Plugin
 */
export function getTextWithAcceptedSuggestionsOnEditor(
  editorInstance: ClassicEditor
) {
  const plugin = editorInstance.plugins.get("TrackChangesData");

  return getTextWithAcceptedSuggestions(plugin);
}

/**
 * Chargement des articles avec les suggestions acceptées
 * @param plugin Plugin
 */
function getTextWithAcceptedSuggestions(plugin: any) {
  if (plugin === undefined) return Promise.resolve("");

  return plugin
    .getDataWithAcceptedSuggestions()
    .then((text: string) => {
      return text;
    })
    .catch(() => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(getTextWithAcceptedSuggestions(plugin));
        }, 200);
      });
    });
}

/**
 * Permet d'exporter les variables necessaires a l'init du composant CkEditor
 */
export function useCkEditor(publication: string = "LLA") {
  configureCkFinder();
  const configStore = useConfigStore();
  const editor = window.ClassicEditor.ClassicEditor;
  const sidebar: SidebarConfig = { container: undefined };
  const editorConfig: EditorConfigCustom = {
    language: isFr.value ? "fr" : "en",
    aiAssistant: {
      authKey: "sk-authKey",
      commands: [
        {
          groupId: "editOrReview",
          groupLabel: i18nInstance.t("ckeditor.ai.groups.editOrReview"),
          commands: [
            {
              id: "improveWriting",
              label: i18nInstance.t("ckeditor.ai.commands.improveWriting"),
              prompt:
                "Tu es un journaliste spécialisé d'une publication destinée aux professionnels. Ta mission est d'améliorer la qualité des articles. Il faut en particulier veiller au respect des points suivants : \r\n" +
                " - les phrases et les paragraphes sont courts \r\n" +
                " - il n'y a pas d'anacoluthes \r\n" +
                " - il n'y a pas de faute d'orthographe \r\n" +
                " - il n'y a pas d'erreur de typographie \r\n" +
                " - les noms propres de personnes ou d'organismes sont écrits en gras à leur première occurrence et uniquement dans ce cas. Les noms de lieux ne sont pas mis en gras \r\n" +
                " - tu dois conserver la langue du texte qui t'a été fourni \r\n",
            },
            {
              id: "makeShorter",
              label: i18nInstance.t("ckeditor.ai.commands.makeShorter"),
              prompt:
                "Remove any repetitive, redundant, or non-essential writing in this content " +
                "without changing the meaning or losing any key information.",
            },
          ],
        },
        {
          groupId: "changeStyle",
          groupLabel: i18nInstance.t("ckeditor.ai.groups.changeStyle"),
          commands: [
            {
              id: "journalism",
              label: i18nInstance.t("ckeditor.ai.commands.Journalism"),
              prompt:
                "Rewrite this content as a journalist using engaging language to convey the importance of the information.",
            },
          ],
        },
        {
          groupId: "translate",
          groupLabel: i18nInstance.t("ckeditor.ai.groups.translate"),
          commands: [
            {
              id: "translateEnglish",
              label: i18nInstance.t("ckeditor.ai.commands.translateToEnglish"),
              prompt:
                "Tu es un traducteur dans une société de presse, ton travail consiste à traduire des articles de presse écrit en français vers de l'anglais pour un public professionnel anglophone. " +
                "Ne traduis pas mot à mot, et utilise des tournures de phrase anglo-saxonnes. " +
                "Si tu as des doutes, indique-les entre parenthèses. Les liens sous la forme (AI, XX/XX/XX) sont traduits par (AI du XX/XX/XX)." +
                "Traduis-moi en anglais le texte suivant. ",
            },
            {
              id: "translateFrench",
              label: i18nInstance.t("ckeditor.ai.commands.translateToFrench"),
              prompt:
                "Tu es un traducteur dans une société de presse, ton travail consiste à traduire des articles de presse écrit en anglais vers du français pour un public professionnel francophone. " +
                "Ne traduis pas mot à mot, et utilise des tournures de phrase francophones. " +
                "Si tu as des doutes, indique-les entre parenthèses. Les liens sous la forme (AI du XX/XX/XX) sont traduits par (AI, XX/XX/XX)." +
                "Traduis-moi en français le texte suivant. ",
            },
          ],
        },
      ],
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Texte courant",
          class: "ck-heading_paragraph",
        },
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          model: "interTitre",
          view: {
            name: "h3",
            classes: "interTitre",
          },
          title: "Inter Titre",
          class: "ck-heading_heading3_intertitre",
          // It needs to be converted before the standard 'heading2'.
          // converterPriority: "high",
        },
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          model: "titre1",
          view: {
            name: "h3",
            classes: "titre1",
          },
          title: "Chapitre Insiders",
          class: "ck-heading_heading3_titre1",
          // It needs to be converted before the standard 'heading2'.
          // converterPriority: "high",
        },
      ],
    },
    licenseKey: licenseKey,
    extraPlugins: [
      UsersIntegration as unknown as typeof Plugin,
      CommentsAdapter as typeof Plugin & typeof CommentsAdapter,
      TrackChangesAdapter as typeof Plugin & typeof TrackChangesAdapter,
    ],
    sidebar: sidebar,
    DiagPlWs: {
      // Url d'accès à ProLexis Webservice ou au proxy
      urlWs: `${prolexis_url_proxy}/${publication}/${configStore.config.proLexisApiKey}`,
      // Url ou path d'accès à l'interface de correction Web
      corePath: prolexis_core_path,
    },
    comments: getCommentsOption(),
    image: {
      insert: {
        integrations: ["openCKFinder"],
        type: "inline",
      },
      styles: {
        options: [
          {
            name: "leftsmall",
            title: "Petite image à gauche",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>    <rect stroke="#000" stroke-width="3" id="svg_1" height="36.69219" width="28.26757" y="14.19673" x="11.42218" fill="#fff"/>  <rect id="svg_2" height="0.8" width="0" y="77.2" x="154.4" stroke="#000" fill="#fff"/>  <line stroke="#000" id="svg_11" y2="15.39741" x2="93.82093" y1="15.39741" x1="49.36509" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_19" y2="22.38142" x2="93.82093" y1="22.38142" x1="50" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_20" y2="29.36543" x2="93.82093" y1="29.36543" x1="49.68254" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_21" y2="36.34944" x2="93.82093" y1="36.34944" x1="50.63491" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_34" y2="43.96836" x2="95.40821" y1="43.96836" x1="50.95237" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_35" y2="50.95237" x2="95.40821" y1="50.95237" x1="51.58727" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_36" y2="57.93638" x2="95.40821" y1="57.93638" x1="12.22286" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_38" y2="64.92039" x2="94.45584" y1="64.92039" x1="11.27049" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_39" y2="70.95203" x2="95.09075" y1="70.95203" x1="11.9054" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_40" y2="77.93604" x2="94.13839" y1="77.93604" x1="10.95304" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_41" y2="84.92005" x2="95.09075" y1="84.92005" x1="11.9054" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_42" y2="91.90406" x2="94.13839" y1="91.90406" x1="10.95304" stroke-width="2" fill="none"/> </g></svg>',
            className: "image-small-left",
            modelElements: ["imageInline"],
            isDefault: true,
          },
          {
            name: "leftmedium",
            title: "Moyenne image à gauche",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>    <rect stroke="#000" stroke-width="3" id="svg_1" height="60.17997" width="46.36251" y="14.19673" x="11.42218" fill="#fff"/>  <rect id="svg_2" height="0.8" width="0" y="77.2" x="154.4" stroke="#000" fill="#fff"/>  <line stroke="#000" id="svg_11" y2="15.39741" x2="93.82094" y1="15.39741" x1="65.23784" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_19" y2="22.38142" x2="93.82094" y1="22.38142" x1="65.23784" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_20" y2="29.36543" x2="93.82094" y1="29.36543" x1="65.23784" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_21" y2="36.34944" x2="93.82094" y1="36.34944" x1="65.23784" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_22" y2="42.69854" x2="93.82094" y1="42.69854" x1="65.23784" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_23" y2="49.68255" x2="93.82094" y1="49.68255" x1="65.23784" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_24" y2="56.98401" x2="94.13839" y1="56.98401" x1="65.55529" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_25" y2="64.60293" x2="94.13839" y1="64.60293" x1="65.55529" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_26" y2="72.85676" x2="94.45585" y1="72.85676" x1="65.87275" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_28" y2="83.33278" x2="94.45584" y1="83.33278" x1="11.9054" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_29" y2="90.31678" x2="94.45584" y1="90.31678" x1="11.9054" stroke-width="2" fill="none"/> </g></svg>',
            className: "image-medium-left",
            modelElements: ["imageInline"],
          },
          {
            name: "leftbig",
            title: "Grande image à gauche",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>    <rect stroke="#000" stroke-width="3" id="svg_1" height="71.30576" width="54.93379" y="14.19673" x="11.42218" fill="#fff"/>  <rect id="svg_2" height="0.8" width="0" y="77.2" x="154.4" stroke="#000" fill="#fff"/>  <line stroke="#000" id="svg_11" y2="15.39741" x2="93.82094" y1="15.39741" x1="72.85676" stroke-width="3" fill="none"/>  <line stroke="#000" id="svg_12" y2="22.55952" x2="93.82094" y1="22.55952" x1="72.85676" stroke-width="3" fill="none"/>  <line stroke="#000" id="svg_13" y2="29.36543" x2="93.82094" y1="29.36543" x1="72.85676" stroke-width="3" fill="none"/>  <line stroke="#000" id="svg_14" y2="36.52754" x2="93.82094" y1="36.52754" x1="72.85676" stroke-width="3" fill="none"/>  <line stroke="#000" id="svg_15" y2="43.33345" x2="94.45585" y1="43.33345" x1="73.49167" stroke-width="3" fill="none"/>  <line stroke="#000" id="svg_16" y2="50.49556" x2="94.45585" y1="50.49556" x1="73.49167" stroke-width="3" fill="none"/>  <line stroke="#000" id="svg_17" y2="57.30147" x2="94.45585" y1="57.30147" x1="73.49167" stroke-width="3" fill="none"/>  <line stroke="#000" id="svg_18" y2="64.46358" x2="94.45585" y1="64.46358" x1="73.49167" stroke-width="3" fill="none"/> </g></svg>',
            className: "image-big-left",
            modelElements: ["imageInline"],
          },
          {
            name: "centersmall",
            title: "Petite image au centre",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>  <title>Layer 1</title>  <rect stroke="#000" stroke-width="3" id="svg_1" height="27.53432" width="42.55304" y="21.81565" x="29.83457" fill="#fff"/>  <rect id="svg_2" height="0.8" width="0" y="77.2" x="154.4" stroke="#000" fill="#fff"/>  <g id="svg_8">   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_3" y2="72.8" x2="88.5041" y1="72.8" x1="11.6" stroke="#000" fill="none"/>   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_4" y2="82.8" x2="86.58053" y1="82.8" x1="11.6" stroke="#000" fill="none"/>   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_5" y2="91.6" x2="88.42577" y1="91.6" x1="10.8" stroke="#000" fill="none"/>  </g> </g></svg>',
            className: "image-small-center",
            modelElements: ["imageBlock"],
          },
          {
            name: "centermedium",
            title: "Moyenne image au centre",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>  <title>Layer 1</title>  <rect stroke="#000" stroke-width="3" id="svg_1" height="36.16163" width="55.88615" y="18.95856" x="22.53311" fill="#fff"/>  <rect id="svg_2" height="0.8" width="0" y="77.2" x="154.4" stroke="#000" fill="#fff"/>  <g id="svg_8">   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_3" y2="72.8" x2="88.5041" y1="72.8" x1="11.6" stroke="#000" fill="none"/>   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_4" y2="82.8" x2="86.58053" y1="82.8" x1="11.6" stroke="#000" fill="none"/>   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_5" y2="91.6" x2="88.42577" y1="91.6" x1="10.8" stroke="#000" fill="none"/>  </g> </g></svg>',
            className: "image-medium-center",
            modelElements: ["imageBlock"],
          },
          {
            name: "centerbig",
            title: "Grande image au centre",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>  <title>Layer 1</title>  <rect stroke="#000" stroke-width="3" id="svg_1" height="49.71882" width="76.83818" y="14.19673" x="11.42218" fill="#fff"/>  <rect id="svg_2" height="0.8" width="0" y="77.2" x="154.4" stroke="#000" fill="#fff"/>  <g id="svg_8">   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_3" y2="72.8" x2="88.5041" y1="72.8" x1="11.6" stroke="#000" fill="none"/>   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_4" y2="82.8" x2="86.58053" y1="82.8" x1="11.6" stroke="#000" fill="none"/>   <line stroke-linecap="undefined" stroke-linejoin="undefined" id="svg_5" y2="91.6" x2="88.42577" y1="91.6" x1="10.8" stroke="#000" fill="none"/>  </g> </g></svg>',
            className: "image-big-center",
            modelElements: ["imageBlock"],
          },
          {
            name: "rightsmall",
            title: "Petite image à droite",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>    <rect stroke="#000" stroke-width="3" id="svg_1" height="36.69219" width="28.26757" y="10.70473" x="64.75462" fill="#fff"/>  <rect id="svg_2" height="0.8" width="0" y="77.2" x="154.4" stroke="#000" fill="#fff"/>  <line stroke="#000" id="svg_11" y2="11.58795" x2="54.13906" y1="11.58795" x1="9.68322" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_19" y2="18.57196" x2="54.13906" y1="18.57196" x1="10.31813" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_20" y2="25.55597" x2="54.13906" y1="25.55597" x1="10.00067" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_21" y2="32.53998" x2="54.13906" y1="32.53998" x1="10.95304" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_34" y2="39.52399" x2="54.77397" y1="39.52399" x1="10.31813" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_35" y2="47.14291" x2="55.09143" y1="47.14291" x1="11.27049" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_36" y2="56.66656" x2="95.40821" y1="56.66656" x1="12.22286" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_38" y2="63.96802" x2="94.45584" y1="63.96802" x1="11.27049" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_39" y2="70.95203" x2="95.09075" y1="70.95203" x1="11.9054" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_40" y2="77.93604" x2="94.13839" y1="77.93604" x1="10.95304" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_41" y2="84.92005" x2="95.09075" y1="84.92005" x1="11.9054" stroke-width="2" fill="none"/>  <line stroke="#000" id="svg_42" y2="91.90406" x2="94.13839" y1="91.90406" x1="10.95304" stroke-width="2" fill="none"/> </g></svg>',
            className: "image-small-right",
            modelElements: ["imageInline"],
          },
          {
            name: "rightmedium",
            title: "Moyenne image à droite",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>    <rect fill="#fff" x="47.22468" y="14.44364" width="46.36251" height="60.17997" id="svg_1" stroke-width="3" stroke="#000"/>  <rect fill="#fff" stroke="#000" x="154.4" y="77.2" width="0" height="0.8" id="svg_2"/>  <line fill="none" stroke-width="2" x1="12.89212" y1="15.1505" x2="41.47522" y2="15.1505" id="svg_11" stroke="#000"/>  <line fill="none" stroke-width="2" x1="12.89212" y1="22.13451" x2="41.47522" y2="22.13451" id="svg_19" stroke="#000"/>  <line fill="none" stroke-width="2" x1="12.89212" y1="29.11852" x2="41.47522" y2="29.11852" id="svg_20" stroke="#000"/>  <line fill="none" stroke-width="2" x1="12.89212" y1="36.10253" x2="41.47522" y2="36.10253" id="svg_21" stroke="#000"/>  <line fill="none" stroke-width="2" x1="12.89212" y1="42.45163" x2="41.47522" y2="42.45163" id="svg_22" stroke="#000"/>  <line fill="none" stroke-width="2" x1="12.89212" y1="49.43564" x2="41.47522" y2="49.43564" id="svg_23" stroke="#000"/>  <line fill="none" stroke-width="2" x1="13.20957" y1="56.7371" x2="41.79267" y2="56.7371" id="svg_24" stroke="#000"/>  <line fill="none" stroke-width="2" x1="13.20957" y1="64.35602" x2="41.79267" y2="64.35602" id="svg_25" stroke="#000"/>  <line fill="none" stroke-width="2" x1="13.52703" y1="72.60985" x2="42.11013" y2="72.60985" id="svg_26" stroke="#000"/>  <line fill="none" stroke-width="2" x1="11.9054" y1="83.33278" x2="94.45584" y2="83.33278" id="svg_28" stroke="#000"/>  <line fill="none" stroke-width="2" x1="11.9054" y1="90.31678" x2="94.45584" y2="90.31678" id="svg_29" stroke="#000"/> </g></svg>',
            className: "image-medium-right",
            modelElements: ["imageInline"],
          },
          {
            name: "rightbig",
            title: "Grande image à droite",
            icon: '<svg width="100" height="100" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"> <g>  <rect fill="#fff" x="38.08829" y="14.19673" width="54.93379" height="71.30576" id="svg_1" stroke-width="3" stroke="#000"/>  <rect fill="#fff" stroke="#000" x="154.4" y="77.2" height="0.8" id="svg_2"/>  <line fill="none" stroke-width="3" x1="11.90565" y1="15.39741" x2="32.86983" y2="15.39741" id="svg_11" stroke="#000"/>  <line fill="none" stroke-width="3" x1="11.90565" y1="22.55952" x2="32.86983" y2="22.55952" id="svg_12" stroke="#000"/>  <line fill="none" stroke-width="3" x1="11.90565" y1="29.36543" x2="32.86983" y2="29.36543" id="svg_13" stroke="#000"/>  <line fill="none" stroke-width="3" x1="11.90565" y1="36.52754" x2="32.86983" y2="36.52754" id="svg_14" stroke="#000"/>  <line fill="none" stroke-width="3" x1="12.54056" y1="43.33345" x2="33.50474" y2="43.33345" id="svg_15" stroke="#000"/>  <line fill="none" stroke-width="3" x1="12.54056" y1="50.49556" x2="33.50474" y2="50.49556" id="svg_16" stroke="#000"/>  <line fill="none" stroke-width="3" x1="12.54056" y1="57.30147" x2="33.50474" y2="57.30147" id="svg_17" stroke="#000"/>  <line fill="none" stroke-width="3" x1="12.54056" y1="64.46358" x2="33.50474" y2="64.46358" id="svg_18" stroke="#000"/> </g></svg>',
            className: "image-big-right",
            modelElements: ["imageInline"],
          },
        ],
      },
      toolbar: [
        "imageStyle:leftsmall",
        "imageStyle:leftmedium",
        "imageStyle:leftbig",
        "|",
        "imageStyle:centersmall",
        "imageStyle:centermedium",
        "imageStyle:centerbig",
        "|",
        "imageStyle:rightsmall",
        "imageStyle:rightmedium",
        "imageStyle:rightbig",
      ],
    },
  };

  return {
    editor,
    editorConfig,
  };
}

/**
 * Permet d'exporter les variables necessaires a l'init du composant CkEditor Context.
 */
export async function useCkEditorContext(
  channelId: string,
  threadId: string,
  sideBar: HTMLElement | null,
  target: HTMLElement | null,
  narrowSidebar: boolean,
  hasCommentClass = "has-comment"
) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const contextBuiltinPlugins = window.ClassicEditor.Context.builtinPlugins;

  const editorConfig: EditorConfig = {
    language: isFr.value ? "fr" : "en",
    licenseKey: licenseKey,
    plugins: [
      ...contextBuiltinPlugins,
      UsersIntegration as unknown as typeof Plugin,
      CommentsAdapter as typeof Plugin & typeof CommentsAdapter,
    ],
    collaboration: {
      channelId,
    },
    comments: getCommentsOption(),
  };

  if (sideBar) editorConfig.sidebar = { container: sideBar };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const context = await window.ClassicEditor.Context.create(editorConfig);

  if (sideBar && narrowSidebar)
    context.plugins.get("AnnotationsUIs").switchTo("narrowSidebar");

  const { commentsRepository, annotations, destroy } = syncComments(
    context,
    threadId
  );
  let threads: unknown | null = null;

  commentsRepository.on(
    `addCommentThread:${channelId}`,
    (evt, data) => {
      threads = data;
      handleCommentThread(commentsRepository.getCommentThread(data.threadId));
    },
    { priority: "low" }
  );

  commentsRepository.on(
    "removeCommentThread:" + channelId,
    () => {
      if (target) target.classList.remove(hasCommentClass);
      threads = null;
    },
    { priority: "low" }
  );

  const checkThread = await commentService.getCommentThread(
    threadId,
    channelId
  );
  if (checkThread.comments.length > 0) {
    threads = await commentsRepository.fetchCommentThread({
      channelId,
      threadId,
    });
  }

  /**
   * Method a appeler par la UI lors de l'ajout d'un commentaire.
   */
  function addCommentThread() {
    console.log("addCommentThread", threads);
    if (threads) {
      return;
    }

    commentsRepository.openNewCommentThread({
      channelId,
      threadId,
      target,
    });
  }

  /**
   *
   */
  function handleCommentThread(thread) {
    // If the thread is not attached yet, attach it.
    // This is the difference between local and remote comments.
    // Locally created comments are attached in the `openNewCommentThread` call.
    // Remotely created comments need to be attached when they are received.
    if (!thread.isAttached) {
      thread.attachTo(target);
    }

    if (target) target.classList.add(hasCommentClass);

    // Activate this comment's annotation whenever the form field is focused.
    const threadView = commentsRepository._threadToController.get(thread).view;
    const annotation = annotations.getByInnerView(threadView);

    annotation.focusableElements.add(target);
  }

  return {
    context,
    addCommentThread,
    destroy: () => {
      destroy();
      context.destroy();
    },
  };
}
