<template>
  <div>
    <q-header elevated :class="{ 'bg-white': !Dark.isActive }">
      <q-toolbar>
        <q-btn
          flat
          round
          dense
          icon="menu"
          :color="Dark.isActive ? 'white' : 'black'"
          @click="handleMenu"
        />
        <slot name="titlePrepend"></slot>
        <q-toolbar-title :class="{ 'text-black': !Dark.isActive }">
          {{ getPageTitle() }}
        </q-toolbar-title>
        <slot name="menuTop"></slot>
        <div v-if="$q.screen.gt.sm" class="q-ml-md">
          <div class="q-gutter-sm">
            <q-btn
              v-for="(btn, idx) in actions"
              :key="'action' + idx"
              :color="btn.color ?? 'primary'"
              :label="btn.label"
              :icon="btn.icon"
              :disable="btn.disable"
              :loading="btn.loading"
              @click="btn.handler"
            >
              <q-tooltip v-if="btn.tooltip">{{ btn.tooltip }}</q-tooltip>
            </q-btn>
          </div>
        </div>
        <q-btn
          v-if="showCart"
          class="shadow-1 gt-sm q-mr-sm q-ml-sm"
          round
          flat
          outline
          color="black"
          icon="shopping_cart"
          size="sm"
        >
          <q-badge color="red" floating>{{ articleCartStore.count }}</q-badge>
          <CartMenu />
        </q-btn>
      </q-toolbar>
    </q-header>
    <q-page-container :class="{ 'bg-grey-3': !Dark.isActive }">
      <q-page ref="pageRef" v-scroll="onScroll" :padding="!noPadding ?? true">
        <router-view></router-view>
        <slot></slot>
        <q-page-scroller
          position="bottom-right"
          :scroll-offset="150"
          :offset="$q.screen.lt.md ? [90, 22] : [18, 18]"
          :z-index="100"
        >
          <q-btn fab icon="keyboard_arrow_up" color="secondary" padding="sm" />
        </q-page-scroller>
        <q-page-sticky
          v-if="actions && actions.length"
          position="bottom-right"
          :offset="[10, 6]"
          class="lt-md"
        >
          <q-fab
            :icon="stickyIcon"
            vertical-actions-align="right"
            direction="up"
            color="primary"
            style="margin: 10px"
          >
            <q-fab-action
              v-for="(action, idx) in actions"
              :key="idx"
              padding="xs"
              :color="action.color ?? 'primary'"
              :icon="action.icon"
              :disable="action.disable"
              :label="action.label"
              label-style="font-size: 11px"
              @click="action.handler"
            />
          </q-fab>
        </q-page-sticky>
      </q-page>
    </q-page-container>
  </div>
</template>

<script setup lang="ts">
import type { PageAction } from "@/types/page";
import type { QPage } from "quasar";
import { Dark, useQuasar, useMeta, scroll } from "quasar";
import { onUpdated, ref, computed } from "vue";
import { useRoute } from "vue-router";
import { useArticleCartStore } from "@/stores/articleCart";
import { usePage } from "@/composables/usePage";
import CartMenu from "@/components/Article/CartMenu.vue";
import { RoutesName } from "@/router/routesName";

const props = defineProps<{
  title?: string;
  actions?: PageAction[];
  noPadding?: boolean;
}>();
const pageRef = ref<QPage | null>(null);
const $q = useQuasar();
const { panelLeftMini, showPanelLeft } = usePage();
const route = useRoute();
const articleCartStore = useArticleCartStore();
const atBottomPage = ref<boolean>(false);

const showCart = computed<boolean>(() => {
  return (
    articleCartStore.count > 0 &&
    (route.name == RoutesName.EditArticle || route.name == RoutesName.Search)
  );
});

onUpdated(() => {
  useMeta({
    title: getPageTitle(),
    titleTemplate: (title) => `${title} - Elcano`,
  });
});

const stickyIcon = computed<string>(() => {
  if (!props.actions) return "add";
  if (props.actions.length > 1) return "add";

  return props.actions[0].icon;
});

/**
 *
 */
function handleMenu() {
  if ($q.screen.lt.md) {
    panelLeftMini.value = false;
    showPanelLeft.value = !showPanelLeft.value;
  } else {
    showPanelLeft.value = true;
    panelLeftMini.value = !panelLeftMini.value;
  }
}
/**
 *
 */
function getPageTitle() {
  return props.title ? props.title : route.meta.title;
}
/**
 * Fonction qui permet de savoir si on est en bas de la page
 */
function onScroll(position) {
  if (pageRef.value == null || position < 100) {
    atBottomPage.value = false;
    return;
  }
  const scrollTarget = scroll.getScrollTarget(pageRef.value.$el);
  const maxScrollHeight =
    scroll.getScrollHeight(scrollTarget) - window.innerHeight;
  atBottomPage.value = position === maxScrollHeight;
}
</script>
<style scoped>
:deep(.q-page-scroller) > * {
  z-index: 100;
}
</style>
