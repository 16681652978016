import { ArticleMailSendModel, PlanifierAmEmailModel } from "./../types/api";
import { MailServiceInstance } from "@/types/mail";
import type { AxiosResponse } from "axios";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { TypeDeMail, UpdateSubjectModel } from "@/types/api";
import { fileDownload } from "@/fileDownload";

/**
 *
 */
class MailService extends AxiosRequest implements MailServiceInstance {
  /**
   * Constructeur de la class Mail service
   */
  constructor(public rootPath: string) {
    super();
  }

  /**
   *
   */
  generateEmail(idArticle: number, typeMail: TypeDeMail) {
    return this.axiosInstance
      .post(
        `${this.rootPath}/GenerateEmail?idArticle=${idArticle}&typeDeMail=${typeMail}`,
        { responseType: "blob" }
      )
      .then((response) => {
        fileDownload(response.data, `${idArticle}_${typeMail}.html`);
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  getSubject(idArticle: number, typeMail: TypeDeMail): Promise<string> {
    return this.axiosInstance
      .get(
        `${this.rootPath}/GetSubject?idArticle=${idArticle}&typeDeMail=${typeMail}`
      )
      .then((response: AxiosResponse<string>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  updateSubject(idArticle: number, typeMail: TypeDeMail, subject: string) {
    const updateModel: UpdateSubjectModel = {
      idArticle: idArticle,
      typeMail: typeMail,
      newSubject: subject,
    };

    return this.axiosInstance
      .post(`${this.rootPath}/updateSubject`, updateModel)
      .then((response: AxiosResponse<string>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  planifyMail(idArticle: number, typeMail: TypeDeMail, date: string) {
    const planifModel: PlanifierAmEmailModel = {
      idArticle: idArticle,
      typeEmail: typeMail,
      datePlanification: date,
    };

    return this.axiosInstance
      .post(`${this.rootPath}/PlanifyAmEmail`, planifModel)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  getInfosSendingMail(idArticleContent: number): Promise<ArticleMailSendModel> {
    return this.axiosInstance
      .get(
        `${this.rootPath}/GetInfoSendingMail?idArticleContent=${idArticleContent}`
      )
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const mailService = new MailService("mails");
