import {
  ParamProcessReplacement,
  ReportSearchAndReplaceDto,
} from "./../types/api";
import { reportService } from "@/services/reportService";
import { ParamSearchAndReplace } from "@/types/api";
import { defineStore } from "pinia";

export const useReportStore = defineStore({
  id: "report",
  actions: {
    createReport() {
      return true;
    },
    async insertReport(param: ParamSearchAndReplace): Promise<string> {
      try {
        return await reportService.createReport(param);
      } catch (error) {
        console.error(error);
        throw new Error("Erreur lors de la création du rapport");
      }
    },
    async getListReports(): Promise<ReportSearchAndReplaceDto[]> {
      try {
        return await reportService.getReports();
      } catch (error) {
        console.error(error);
        throw new Error("Erreur lors de la création du rapport");
      }
    },
    async processReport(param: ParamProcessReplacement): Promise<any> {
      try {
        return await reportService.processReport(param);
      } catch (error) {
        console.error(error);
        throw new Error("Erreur lors du traitement du rapport");
      }
    },
  },
});
