<template>
  <q-dialog
    v-model="showDialog"
    @update:model-value="$emit('update:model-value', showDialog)"
  >
    <q-card style="width: 700px; max-width: 80vw">
      <q-card-section>
        <q-select
          v-model="newLanguage"
          :label="$t('languages.fields.add')"
          emit-value
          map-options
          :options="languages"
          :option-label="(lang) => (lang !== '' ? $t(`languages.${lang}`) : '')"
          :option-disable="
            (lang) =>
              article.contents.map((content) => content.language).includes(lang)
          "
        />
        <q-select
          v-model="translatorId"
          :options="contributors"
          :label="$t('contributionType.Translator')"
          emit-value
          map-options
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          :label="$t('languages.labels.btnCancel')"
          color="primary"
          @click="$emit('update:model-value', false)"
        />
        <q-btn
          flat
          :label="$t('languages.labels.btnOk')"
          color="primary"
          @click="onSubmitNewLanguage"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import type { ArticleDto } from "@/types/api";
import { useQuasar, QSelectOption } from "quasar";
import { ContributionType } from "@/types/api";
import { computed, ref, toRefs } from "vue";
import { useUserStore } from "@/stores/user";
import { useLanguageStore } from "@/stores/language";
import { articleService } from "@/services/ArticleService";
import { formatErrorMessage } from "@/utils";
import { getContributorOptions } from "@/helpers/contributor";
import { EnglishLanguage, FrenchLanguage } from "@/constants";

const $q = useQuasar();
const props = defineProps<{
  modelValue: boolean;
  article: ArticleDto;
}>();
const emits = defineEmits<{
  (e: "update:model-value", value: boolean): void;
  (e: "add:language"): void;
}>();

const userStore = useUserStore();
const languageStore = useLanguageStore();

const contributors = computed<QSelectOption<string>[]>(() => {
  return getContributorOptions({
    publication: props.article.publication,
    type: ContributionType.Translator,
  });
});

const defaultTranslator = computed<string>(() => {
  const contrib = props.article.contributions.find(
    (c) => c.type === ContributionType.Translator
  );

  if (contrib) return contrib.idContributor;

  return (
    userStore
      .filterUsersLightBycontributionType(
        ContributionType.Translator,
        props.article.publication,
        ""
      )
      .filter((usr) => usr.lastName === "TradInterne")[0]?.id ?? ""
  );
});

const { modelValue: showDialog } = toRefs(props);
const selectedNewLanguage = ref<string>("");

const newLanguage = computed({
  get() {
    if (selectedNewLanguage.value) return selectedNewLanguage.value;
    return props.article.idMainLanguage === FrenchLanguage
      ? EnglishLanguage
      : FrenchLanguage;
  },
  set(value: string) {
    selectedNewLanguage.value = value;
  },
});
const translatorId = ref<string>(defaultTranslator.value ?? "");
const { languages } = languageStore;

/**
 * Fonction appelé au moment de rajouter une nouvelle langue à l'article
 */
async function onSubmitNewLanguage() {
  try {
    await articleService.translate(
      props.article.id,
      newLanguage.value,
      translatorId.value
    );
    emits("add:language");
    selectedNewLanguage.value = "";
    translatorId.value = "";
    emits("update:model-value", false);
  } catch (e: unknown) {
    const message = formatErrorMessage(e, "article.errors.onSave");
    $q.notify({
      type: "warning",
      message,
    });
  }
}
</script>
