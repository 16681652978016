<template>
  <q-card>
    <q-card-section>
      <q-table
        :rows="countries"
        :columns="columns"
        :grid="$q.screen.xs"
        :title="$t('country.labels.lblTitle')"
        table-header-class="header_table"
        separator="cell"
        :filter="filter"
        bordered
        flat
        :rows-per-page-options="[50, 100, 0]"
        @row-click="onRowClick"
      >
        <template #top-right>
          <q-input
            v-model="filter"
            borderless
            dense
            debounce="300"
            :placeholder="$t('country.fields.shortSearch')"
          >
            <template #append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-active="props">
          <q-td :props="props">
            <q-icon v-if="props.value" name="done" />
            <q-icon v-else name="close" />
          </q-td>
        </template>
        <template #item="props">
          <QTableGridItem
            v-slot="slotProps"
            :columns="props.cols"
            @click="editCountry(props.row.id)"
          >
            <span v-if="slotProps.columnName != 'active'">{{
              slotProps.columnValue
            }}</span>
            <div v-else>
              <q-icon v-if="slotProps.columnValue" name="done" />
              <q-icon v-else name="close" />
            </div>
          </QTableGridItem>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import type { QTableColumn } from "quasar";
import type { CountryDto } from "@/types/api";
import { ref } from "vue";
import { useCountryStore } from "@/stores/country";
import { useCountryActions } from "@/composables/useCountryActions";
import { i18n } from "@/i18n";
import QTableGridItem from "@/components/QTableGridItem.vue";

const filter = ref("");
const countryStore = useCountryStore();
const { countries } = countryStore;
const columns: QTableColumn<CountryDto>[] = [
  {
    name: "id",
    label: i18n.t("country.fields.shortId"),
    align: "left",
    field: (row) => row.id,
  },
  {
    name: "labelFr",
    label: i18n.t("country.fields.shortLabelFr"),
    sortable: true,
    align: "left",
    field: (row) => row.labelFr,
  },
  {
    name: "labelEn",
    label: i18n.t("country.fields.shortLabelEn"),
    sortable: true,
    align: "left",
    field: (row) => row.labelEn,
  },
  {
    name: "active",
    label: i18n.t("country.fields.shortActive"),
    align: "center",
    sortable: true,
    field: (row) => row.active,
  },
];
const { actions } = useCountryActions();
const { editCountry } = actions;
/**
 * Fonction appelé lors d'un click sur une des lignes du tableau des pays
 */
function onRowClick(evt: Event, row: CountryDto) {
  editCountry(row.id);
}
</script>
