<template>
  <CellSectionsTemplate :data="props.data" />
  <CellCountriesTemplate :data="props.data" />
</template>
<script setup lang="ts">
import { ArticleODataDto } from "@/types/api";
import CellSectionsTemplate from "@/components/Article/ListArticles/CellSectionsTemplate.vue";
import CellCountriesTemplate from "@/components/Article/ListArticles/CellCountriesTemplate.vue";
const props = defineProps<{
  data: ArticleODataDto;
}>();
</script>
