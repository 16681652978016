import type { RightGroupDto } from "@/types/api";
import type { RightGroupViewActions } from "@/types/user";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { useUserStore } from "@/stores/user";
import { RoutesName } from "@/router/routesName";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions groupe de droit
 */
interface RightGroupActionState {
  rightGroup: RightGroupDto | null;
}
/**
 * Fonction servant à interagir sur les différentes vues groupe de droit
 */
export const useRightGroupActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { findRightGroup, addRightGroup, updateRightGroup, deleteRightGroup } =
    useUserStore();

  const state = reactive<RightGroupActionState>({
    rightGroup: null,
  });
  const actions: RightGroupViewActions = {
    findRightGroup(rightGroupId: number | string) {
      return findRightGroup(rightGroupId)
        .then((rightGroup) => {
          state.rightGroup = rightGroup;
        })
        .catch((err: Error) => {
          $q.notify(err.message);
          router.push({ name: RoutesName.RightGroups });
        });
    },
    createRightGroup(callback?: () => void) {
      state.rightGroup = null;
      if (callback) callback();
    },
    editRightGroup(rightGroupId: number | string, callback?: () => void) {
      actions.findRightGroup(rightGroupId).then(() => {
        if (callback) callback();
      });
    },
    onCreateRightGroup(rightGroup: RightGroupDto) {
      addRightGroup(rightGroup)
        .then(() => {
          $q.notify(i18n.t("rightGroup.messages.created"));
          router.push({ name: RoutesName.RightGroups });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditRightGroup(rightGroup: RightGroupDto) {
      updateRightGroup(rightGroup)
        .then(() => {
          $q.notify(i18n.t("rightGroup.messages.updated"));
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onDeleteRightGroup(rightGroupId: string) {
      $q.dialog({
        title: i18n.t("rightGroup.dialogs.titles.confirm"),
        message: i18n.t("rightGroup.dialogs.messages.delete"),
        cancel: true,
        persistent: true,
      }).onOk(() => {
        deleteRightGroup(rightGroupId).then(() => {
          $q.notify(i18n.t("rightGroup.messages.deleted"));
          router.push({ name: RoutesName.RightGroups });
        });
      });
    },
  };
  return { ...toRefs(state), actions };
});
