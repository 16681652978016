<template>
  <q-card bordered>
    <q-card-section>
      <FormArticleSetting
        :label-btn-submit="$t('articleSetting.labels.btnCreate')"
        @submit="actions.onCreateArticleSetting"
      />
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { useArticleSettingActions } from "@/composables/useArticleSettingsActions";
import FormArticleSetting from "@/components/ArticleSetting/FormArticleSetting.vue";

const { actions } = useArticleSettingActions();
</script>
