<template>
  <ListArticles
    :formats="formats"
    :list-view="listView"
    :language="language"
    :start-date="startDate"
    :end-date="endDate"
    v-on="eventsParamsChanged"
  />
</template>

<script setup lang="ts">
import type { LocationQuery } from "vue-router";
import { computed } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RoutesName } from "@/router/routesName";
import ListArticles from "@/components/Article/ListArticles.vue";

const route = useRoute();
const router = useRouter();
const formats = computed(() => {
  const { formats: f } = route.query;
  if (Array.isArray(f)) return f as string[];
  return (f ? [f] : []) as string[];
});
const listView = computed(() => {
  const { listView: l } = route.query;
  return (l as string) ?? "";
});
const language = computed(() => route.query.language as string);
const startDate = computed(() => route.query.startDate as string);
const endDate = computed(() => route.query.endDate as string);
const eventsParamsChanged = {
  "update:formats": (formatPayload) =>
    handleParamsChanged({ ...route.query, formats: formatPayload }),
  "update:language": (languagePayload) =>
    handleParamsChanged({ ...route.query, language: languagePayload }),
  "update:listView": (listViewPayload) => handleParamsChanged(listViewPayload),
};
/**
 *
 */
function handleParamsChanged(params: LocationQuery) {
  router.push({
    name: RoutesName.Articles,
    query: params,
  });
}
</script>
