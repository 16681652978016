import type { AxiosResponse } from "axios";
import { AxiosRequest } from "@/services/ElcanoAPI";
import type { ContributionServiceInstance } from "@/types/contribution";
import {
  ContributionParamDto,
  ContributionProcessingResultDto,
  CancelProcessingParamDto,
  ContributionState,
} from "@/types/api";

/**
 * Service contribution pour gérer les piges.
 */
class ContributionService
  extends AxiosRequest
  implements ContributionServiceInstance
{
  /**
   * Constructeur de la class Contribution service
   */
  constructor(public rootPath: string) {
    super();
  }

  /**
   * Verouilles les piges à traiter.
   * @param param Paramètres.
   * @returns Le nombre de piges et le montant global.
   */
  lockContributionsToBeProcessed(param: ContributionParamDto) {
    return this.axiosInstance
      .post(`${this.rootPath}/lockContributionsToBeProcessed`, param)
      .then((response: AxiosResponse<ContributionProcessingResultDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Déverouille les piges en traitement.
   * @returns Vrai si déverouillées.
   */
  unlockContributionsBeingProcessed() {
    return this.axiosInstance
      .post(`${this.rootPath}/unlockContributionsBeingProcessed`)
      .then((response: AxiosResponse<boolean>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Marque les piges comme traitées.
   * @returns Vrai si marquées.
   */
  setContributionsAsProcessed(dateProcessed: string) {
    return this.axiosInstance
      .post(`${this.rootPath}/setContributionsAsProcessed`, dateProcessed)
      .then((response: AxiosResponse<boolean>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Rafraichit le verrouillage pour les piges manquantes.
   * @returns Le nombre de piges, le montant global et le delta.
   */
  refreshContributionsToLock(param: ContributionParamDto) {
    return this.axiosInstance
      .post(`${this.rootPath}/refreshContributionsToLock`, param)
      .then((response: AxiosResponse<ContributionProcessingResultDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Rafraichit le verrouillage pour les piges manquantes.
   * @returns Le nombre de piges, le montant global et le delta.
   */
  getLockedContributions() {
    return this.axiosInstance
      .get(`${this.rootPath}/getLockedContributions`)
      .then((response: AxiosResponse<ContributionProcessingResultDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Rafraichit le verrouillage pour les piges manquantes.
   * @returns Le nombre de piges, le montant global et le delta.
   */
  removeProcessedDateOnContributions(param: CancelProcessingParamDto) {
    return this.axiosInstance
      .post(`${this.rootPath}/removeProcessedDateOnContributions`, param)
      .then((response: AxiosResponse<ContributionProcessingResultDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Notifie l'utilisateur d'une contribution.
   * @returns Vrai si la notification a été queue.
   */
  notify(id: number, message: string | undefined) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/notify`, message)
      .then(() => {
        return true;
      })
      .catch(this.handleError);
  }

  /**
   * Met à jour le statut d'une contribution.
   * @returns Vrai si la sauvegarde s'est bien passée.
   */
  updateState(id: number, state: ContributionState) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/state`, state)
      .then(() => {
        return true;
      })
      .catch(this.handleError);
  }

  /**
   * Vérifie si l'année est verrouillée.
   * @returns Vrai si l'année est verrouillée.
   */
  isYearLocked(year: number) {
    return this.axiosInstance
      .get(`${this.rootPath}/isYearLocked/${year}`)
      .then((response: AxiosResponse<boolean>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   * Verrouille l'année.
   * @returns Vrai si l'année est verrouillée.
   */
  lockYear(year: number) {
    return this.axiosInstance
      .post(`${this.rootPath}/lockYear/${year}`)
      .then(() => {
        return true;
      })
      .catch(this.handleError);
  }
  /**
   * Déverrouille l'année.
   * @returns Vrai si l'année est déverrouillée.
   */
  unlockYear(year: number) {
    return this.axiosInstance
      .delete(`${this.rootPath}/lockYear/${year}`)
      .then(() => {
        return true;
      })
      .catch(this.handleError);
  }
}

export const contributionService = new ContributionService("contributions");
