import type { QSelectOption } from "quasar";
import type { CountryDto } from "@/types/api";
import { useCountryStore } from "@/stores/country";
import { useLocale } from "@/utils";

/**
 * Méthode qui sert à récupérer la liste des pays pour être utiliser dans un dropdown
 * @param allOption - argument pour avoir dans le sélecteur une option tous les pays
 * @param idCountriesToAdd - id des pays a ajouter même s'ils sont inactifs
 */
export function getCountryOptions(
  allOption = true,
  idCountriesToAdd: number[] = []
) {
  const { countries } = useCountryStore();
  const { isFr } = useLocale();

  return [
    allOption ? { value: "", label: "Tous" } : undefined,
    ...countries
      .filter((c) => {
        if (idCountriesToAdd?.includes(c.id)) return true;
        return c.active;
      })
      .map((country) => ({
        value: country,
        label: isFr.value ? country.labelFr : country.labelEn,
      })),
  ].filter(Boolean) as QSelectOption<CountryDto>[];
}
