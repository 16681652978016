<template>
  <PageWithPanel :actions="pageActions">
    <template #panelRight>
      <div class="q-pa-md">
        <FormPublication
          v-if="callbackOnSubmit"
          :label-btn-submit="
            $t(
              publication.id !== ''
                ? 'publication.labels.btnEdit'
                : 'publication.labels.btnCreate'
            )
          "
          :default-publication="publication"
          @submit="callbackOnSubmit"
        />
      </div>
    </template>
  </PageWithPanel>
</template>

<script setup lang="ts">
import type { PageAction } from "@/types/page";
import { computed } from "vue";
import { usePublicationActions } from "@/composables/usePublicationActions";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import FormPublication from "@/components/Publication/FormPublication.vue";
import { i18n } from "@/i18n";

const { actions, callbackOnSubmit, publication } = usePublicationActions();

const pageActions = computed<PageAction[]>(() =>
  [
    {
      label: i18n.t("publication.labels.btnNew"),
      shortLabel: i18n.t("publication.labels.shortBtnNew"),
      icon: "add",
      disable: false,
      handler: actions.createPublication,
      visible: true,
    },
  ].filter((act) => act.visible)
);
</script>
