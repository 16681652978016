<template>
  <div v-if="language === 'all'">
    <div v-if="contentFr">
      {{ contentFr.shortTitle }}
    </div>
    <div v-if="contentEn">
      {{ contentEn.shortTitle }}
    </div>
  </div>
  <div v-else>
    <div v-if="shortTitles[language]">
      {{ shortTitles[language] }}
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs, computed } from "vue";
import { FrenchLanguage, EnglishLanguage } from "@/constants";

const props = defineProps<{
  data: ArticleODataDto;
  language: string;
  otherLanguage: string;
}>();
const { data } = toRefs(props);

const contentFr = computed(() => {
  return data.value.contents.find((c) => c.language === FrenchLanguage);
});
const contentEn = computed(() => {
  return data.value.contents.find((c) => c.language === EnglishLanguage);
});
const shortTitles = computed(() => {
  return {
    [FrenchLanguage]: contentFr.value?.shortTitle,
    [EnglishLanguage]: contentEn.value?.shortTitle,
  };
});
</script>
