import { createI18n } from "vue-i18n";
import fr from "./locales/fr.json";
import en from "./locales/en.json";

/**
 * i18n messages type
 */
export type MessageSchema = typeof fr;

/** Implem par défaut pour récupérer l'index de la pluralization */
function getChoiceIndexDefaultImpl(_choice: number, _choicesLength: number) {
  _choice = Math.abs(_choice);

  if (_choicesLength === 2) {
    return _choice ? (_choice > 1 ? 1 : 0) : 1;
  }
  return _choice ? Math.min(_choice, 2) : 0;
}

/** Implem custom */
function getChoiceIndex(choice: number, choicesLength: number) {
  if (choicesLength > 3) return Math.min(choice, choicesLength - 1);
  return getChoiceIndexDefaultImpl(choice, choicesLength);
}

const instance = createI18n({
  locale: "fr",
  messages: {
    fr,
    en,
  },
  datetimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
    },
    fr: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
    },
  },
  globalInjection: true,
  legacy: false,
  pluralRules: {
    fr: getChoiceIndex,
    en: getChoiceIndex,
  },
});

export default instance;

export const i18n = instance.global;
