<template>
  <q-table
    :rows="filteredUsers"
    :columns="columns"
    :grid="$q.screen.xs"
    bordered
    :rows-per-page-options="[10, 20, 0]"
    @row-click="onRowClick"
  >
    <template #top-left>
      <q-btn
        v-if="isAdminUser"
        color="primary"
        :label="$t('user.actions.create')"
        @click="emits('onAdd')"
      />
    </template>
    <template #top-right>
      <div class="row q-gutter-md">
        <q-select
          v-model="selectedProfiles"
          :label="$t('user.fields.shortProfiles')"
          :options="profileOptions"
          filled
          multiple
          map-options
          emit-value
          dense
          class="filter-profiles"
        >
          <template #no-option>
            <q-item>
              <q-item-section class="text-italic text-grey">
                All
              </q-item-section>
            </q-item>
          </template>
          <template #append>
            <q-icon name="close" @click="selectedProfiles = []" />
          </template>
        </q-select>
        <q-checkbox
          v-if="isAdminFull"
          v-model="deleted"
          :label="$t('user.fields.deleted')"
          :disable="!isAdminFull"
        />
        <q-checkbox v-model="disabled" :label="$t('user.fields.disabled')" />
        <q-checkbox
          v-model="freelancerWithoutId"
          :label="$t('user.fields.freelancerWithoutId')"
        />
        <q-input
          v-model="filter"
          borderless
          dense
          debounce="300"
          :placeholder="$t('user.fields.shortSearch')"
        >
          <template #append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
    </template>
    <template #body-cell-profiles="props">
      <q-td :props="props">
        <q-chip
          v-for="(profile, idx) in props.value"
          :key="'profile' + idx"
          color="primary"
          text-color="white"
          size="sm"
        >
          {{ isFr ? profile.labelFr : profile.labelEn }}
        </q-chip>
      </q-td>
    </template>
    <template #item="props">
      <QTableGridItem v-slot="slotProps" :columns="props.cols">
        <span v-if="slotProps.columnName != 'profiles'">{{
          slotProps.columnValue
        }}</span>
        <div v-else>
          <q-chip
            v-for="(profile, idx) in slotProps.columnValue as ProfileDto[]"
            :key="'profile' + idx"
            color="primary"
            text-color="white"
            size="sm"
          >
            {{ isFr ? profile.labelFr : profile.labelEn }}
          </q-chip>
        </div>
      </QTableGridItem>
    </template>
  </q-table>
</template>
<script setup lang="ts">
import type { QSelectOption, QTableColumn } from "quasar";
import { ProfileDto, RightReference, UserDto } from "@/types/api";
import { ComputedRef, computed, ref } from "vue";
import { useUserStore } from "@/stores/user";
import { useLocale, removeDiacritics } from "@/utils";
import { i18n } from "@/i18n";
import QTableGridItem from "@/components/QTableGridItem.vue";
import { useRoute } from "vue-router";
import { authService } from "@/services/AuthService";
import { storeToRefs } from "pinia";

const emits = defineEmits<{
  (e: "rowClick", user: UserDto): void;
  (e: "onAdd"): void;
}>();
const filter = ref("");
const { query } = useRoute();
const deleted = ref(false);
const disabled = ref(false);

const freelancerWithoutId = ref(query.freelance === "true");
const { isFr } = useLocale();
const { users, profiles } = storeToRefs(useUserStore());
const selectedProfiles = ref<ProfileDto[]>([]);
const profileOptions: ComputedRef<QSelectOption<ProfileDto>[]> = computed(
  () => {
    return profiles.value.map((profile) => ({
      label: isFr.value ? profile.labelFr : profile.labelEn,
      value: profile,
    }));
  }
);
const isAdminUser = authService.hasRight(RightReference.ADMIN_USER);
const isAdminFull = authService.hasRight(RightReference.ADMIN_FULL);
const filteredUsers = computed<UserDto[]>(() => {
  let results = users.value.filter(
    (u) => u.deleted === deleted.value && u.enabled === !disabled.value
  );

  if (freelancerWithoutId.value) {
    results = results.filter(
      (u) =>
        u.freelance.length > 0 &&
        (u.idBackOffice === undefined || u.idBackOffice === 0)
    );
  }

  if (
    selectedProfiles.value !== undefined &&
    selectedProfiles.value.length > 0
  ) {
    results = results.filter((u) =>
      u.profiles.some((p) =>
        selectedProfiles.value.some((sp) => sp.id === p.id)
      )
    );
  }

  if (filter.value) {
    const needle = removeDiacritics(filter.value).toLowerCase();

    results = results.filter(
      (u) =>
        removeDiacritics(u.firstName).toLowerCase().includes(needle) ||
        removeDiacritics(u.lastName).toLowerCase().includes(needle) ||
        removeDiacritics(u.login).toLowerCase().includes(needle)
    );
  }

  return results.sort((a, b) => {
    if (a.lastName < b.lastName) return -1;
    if (a.lastName > b.lastName) return 1;
    return 0;
  });
});
const columns: QTableColumn<UserDto>[] = [
  {
    name: "lastName",
    label: i18n.t("user.fields.lastName"),
    field: (row) => row.lastName,
    sortable: true,
  },
  {
    name: "firstName",
    label: i18n.t("user.fields.firstName"),
    field: (row) => row.firstName,
    sortable: true,
  },
  {
    name: "login",
    label: i18n.t("user.fields.login"),
    field: (row) => row.login,
    sortable: true,
  },
  {
    name: "idBackOffice",
    label: i18n.t("user.fields.idBackOffice"),
    field: (row) => row.idBackOffice,
    sortable: true,
  },
  {
    name: "profiles",
    label: i18n.t("user.fields.shortProfiles"),
    field: (row) => row.profiles,
  },
  {
    name: "addDate",
    label: i18n.t("user.fields.addDate"),
    field: (row) => row.addDate,
  },
];
/**
 * Fonction appelé lors d'un click sur une des lignes du tableau des utilisateurs
 */
function onRowClick(evt: Event, row: UserDto) {
  emits("rowClick", row);
}
</script>
<style>
.filter-profiles {
  min-width: 200px;
}
</style>
