// ..\..\Elcano.Common\Models\ArticleDto.cs
export interface ArticleDto {
    id: number;
    publication: string;
    publishedOn?: string;
    deadline?: string;
    publishedOnTranslation?: string;
    summaryOf?: string;
    summaryOfTranslation?: string;
    lastUpdate: string;
    xmin: number;
    modificationType: number;
    idMainLanguage: string;
    score: number;
    deleted: boolean;
    contents: ArticleContentDto[];
    sections: ArticleSectionDto[];
    countries: CountryDto[];
    settings: ArticleSettingDto[];
    contributions: ContributionDto[];
    associatedArticles: AssociationArticleDto[];
}

// ..\..\Elcano.Common\Models\ArticleDto.cs
export interface ArticleContentDto {
    id: number;
    idArticle: number;
    title: string;
    shortTitle?: string;
    summary?: string;
    seoTitle?: string;
    seoKeyword?: string;
    seoDescription?: string;
    tweet?: string;
    serialUrl?: string;
    articleUrl?: string;
    text: string;
    textWithAcceptedSuggestions?: string;
    standfirst: string;
    state: WorkflowState;
    invalidated: boolean;
    online: boolean;
    idBackOffice?: number;
    xmin: number;
    language: string;
    isTranslation: boolean;
    lastUpdate: string;
    format: string;
    idMainMedia?: number;
    pathMainMedia?: string;
    idSocialMedia?: number;
    pathSocialMedia?: string;
    charCount: number;
    wordCount: number;
    settings: ArticleSettingDto[];
    medias: MediaDto[];
}

// ..\..\Elcano.Common\Models\ArticleDto.cs
export interface ArticleSectionDto {
    section: number;
    main: boolean;
}

// ..\..\Elcano.Common\Models\ArticleDto.cs
export interface ArticleArticleSettingDto {
    idArticle: number;
    idSetting: number;
}

// ..\..\Elcano.Common\Models\ArticleDto.cs
export interface ArticleContentArticleSettingDto {
    idArticleContent: number;
    idSetting: number;
}

// ..\..\Elcano.Common\Models\ArticleDto.cs
export interface AssociationArticleDto {
    idFromArticle: number;
    idToArticle: number;
}

// ..\..\Elcano.Common\Models\ArticleDto.cs
export interface ContributionDto {
    id: number;
    type: ContributionType;
    state: ContributionState;
    amount?: number;
    createdAt: string;
    updatedAt: string;
    lastNotification?: string;
    processedAt?: string;
    idLanguage?: string;
    idContributor: string;
}

// ..\..\Elcano.Common\Models\ArticleHistoryDto.cs
export interface ArticleHistoryDto {
    id: number;
    revisionId: number;
    frenchRevisionId: number;
    englishRevisionId: number;
    idPublication: string;
    publishedOn: string;
    lastUpdate: string;
    idFormat: string;
    modificationType: number;
    sections: string[];
    countries: string[];
    jsonData: string;
    idUser: string;
    user: UserDto;
}

// ..\..\Elcano.Common\Models\ArticleLockDto.cs
export interface ArticleLockDto {
    articleId: number;
    userId: string;
    userName: string;
    modificationType: number;
    createdAt: string;
}

// ..\..\Elcano.Common\Models\ArticleSearch.cs
export interface SearchResult<T> {
    currentPage: number;
    totalResult: number;
    totalPage: number;
    data: T[];
}

// ..\..\Elcano.Common\Models\ArticleSearch.cs
export interface SearchContribution {
    user: string;
    state?: ContributionState;
    type?: ContributionType;
    translation?: boolean;
}

// ..\..\Elcano.Common\Models\ArticleSearch.cs
export interface SearchWorkflowState {
    state: WorkflowState;
    translation?: boolean;
    language?: string;
}

// ..\..\Elcano.Common\Models\ArticleSearch.cs
export interface SearchResultArticleElastic {
    id: number;
    titleFr?: string;
    texteFr?: string;
    shortTitleFr?: string;
    summaryFr?: string;
    standfirstFr?: string;
    titleEn?: string;
    texteEn?: string;
    shortTitleEn?: string;
    summaryEn?: string;
    standfirstEn?: string;
}

// ..\..\Elcano.Common\Models\ArticleSearch.cs
export interface ArticleSearch {
    idWorkflow: number[];
    idContentWorkflow: number[];
    idBackOffice: number[];
    page: number;
    nbByPage: number;
    publishedAt?: string;
    from?: string;
    to?: string;
    summaryFrom?: string;
    summaryTo?: string;
    language?: string;
    publications: string[];
    countries: number[];
    interests: number[];
    cms: number[];
    sections: number[];
    formats: string[];
    formatsExcluded: string[];
    contributions: SearchContribution[];
    states: WorkflowState[];
    statesAdvanced: SearchWorkflowState[];
    statesExcluded: WorkflowState[];
    texts: string[];
    onlyOnTitleAndStandfirst: boolean;
    fridge: boolean;
    online?: boolean;
    deleted?: boolean;
    orderAscending?: boolean;
}

// ..\..\Elcano.Common\Models\ArticleSearch.cs
export interface ResultSearchAndReplaceElastic {
    idArticle: number;
    title: string;
    snippets: string[];
    hasFrenchContent: boolean;
    hasEnglishContent: boolean;
}

// ..\..\Elcano.Common\Models\ArticleSettingDto.cs
export interface ArticleSettingDto {
    id: number;
    idBackOffice?: number;
    idType: SettingsType;
    subType: string;
    mainLabel: string;
    labelFr: string;
    labelEn: string;
    key1: string;
    active: boolean;
}

// ..\..\Elcano.Common\Models\ArticleVersionDto.cs
export interface ArticleVersionDto {
    xmin: number;
    contentsVersion: ArticleContentVersionDto[];
    lastModificationAuthor: string;
    lastModificationIdAuthor: string;
}

// ..\..\Elcano.Common\Models\ArticleVersionDto.cs
export interface ArticleContentVersionDto {
    xmin: number;
    language: string;
}

// ..\..\Elcano.Common\Models\AssignmentDto.cs
export interface AssignmentDto {
    id: string;
    labelFr: string;
    labelEn: string;
    redactor: boolean;
    editor: boolean;
    translator: boolean;
    freelance: boolean;
    rights: RightDto[];
    initialConfig: boolean;
}

// ..\..\Elcano.Common\Models\CommentDto.cs
export interface CommentThreadDto {
    channelId: string;
    threadId: string;
    comments: CommentDto[];
    resolvedAt?: string;
    resolvedBy?: string;
    unlinkedAt?: string;
    context?: Record<string, any>;
    attributes?: Record<string, any>;
}

// ..\..\Elcano.Common\Models\CommentDto.cs
export interface CommentDto {
    channelId: string;
    threadId: string;
    commentId: string;
    authorId: string;
    content: string;
    createdAt: string;
    attributes: Record<string, any>;
}

// ..\..\Elcano.Common\Models\CommentDto.cs
export interface UpdateCommentDto {
    channelId: string;
    threadId: string;
    commentId: string;
    content?: string;
    attributes?: Record<string, any>;
}

// ..\..\Elcano.Common\Models\ContributionParamDto.cs
export interface ContributionParamDto {
    untilDate: string;
    idFreelance?: string;
}

// ..\..\Elcano.Common\Models\ContributionParamDto.cs
export interface ContributionProcessingResultDto {
    nbContributions: number;
    amount: number;
    nbNewContributionsLocked: number;
    contributions: ContributionItemDto[];
}

// ..\..\Elcano.Common\Models\ContributionParamDto.cs
export interface ContributionItemDto {
    idArticle: number;
    publication: string;
    title: string;
    amount: number;
    freelanceName: string;
}

// ..\..\Elcano.Common\Models\ContributionParamDto.cs
export interface CancelProcessingParamDto {
    idBackOffice?: number;
    idArticle?: number;
}

// ..\..\Elcano.Common\Models\CountryDto.cs
export interface CountryDto {
    id: number;
    labelFr: string;
    labelEn: string;
    active: boolean;
    realCountry: boolean;
    idBackOffice: number;
    code?: string;
    isoCode?: string;
    hashtagFr?: string;
    hashtagEn?: string;
}

// ..\..\Elcano.Common\Models\EventTrackingObjectDto.cs
export interface EventTrackingObjectDto {
    objectId: string;
    createdAt: string;
    endedAt?: string;
    error: boolean;
    message?: string;
}

// ..\..\Elcano.Common\Models\FormatDto.cs
export interface FormatDto {
    id: string;
    labelFr: string;
    labelEn: string;
    standfirstFr?: string;
    standfirstEn?: string;
    active: boolean;
    long: boolean;
    maxLength: number;
    formatPublications: FormatPublicationDto[];
}

// ..\..\Elcano.Common\Models\FormatDto.cs
export interface FormatPublicationDto {
    publication: string;
    active: boolean;
}

// ..\..\Elcano.Common\Models\FreelanceBudgetDto.cs
export interface FreelanceBudgetDto {
    idPublication: string;
    year: number;
    month: number;
    amount: number;
    consumption: number;
}

// ..\..\Elcano.Common\Models\HistoryId.cs
export interface HistoryId {
    articleId: number;
    revisionId: number;
    frenchRevisionId: number;
    englishRevisionId: number;
}

// ..\..\Elcano.Common\Models\InterestTypeDto.cs
export interface InterestTypeDto {
    id: number;
    label: string;
}

// ..\..\Elcano.Common\Models\LanguageDto.cs
export interface LanguageDto {
    id: string;
    label: string;
}

// ..\..\Elcano.Common\Models\MailTemplateDto.cs
export interface MailTemplateDto {
    id: string;
    label: string;
    subjectFr: string;
    bodyFr: string;
    subjectEn: string;
    bodyEn: string;
}

// ..\..\Elcano.Common\Models\MediaDto.cs
export interface LinkedArticleToMedia {
    id: number;
    title: string;
}

// ..\..\Elcano.Common\Models\MediaDto.cs
export interface MediaDto {
    id: number;
    idMediaType: number;
    mediaType?: MediaTypeDto;
    legendFr?: string;
    legendEn?: string;
    associatedKeywords?: string;
    copyrightFr?: string;
    copyrightEn?: string;
    filePath: string;
    idBucket: number;
    lastPublishedArticle?: string;
    linkedArticles: LinkedArticleToMedia[];
}

// ..\..\Elcano.Common\Models\MediaTypeDto.cs
export interface MediaTypeDto {
    id: number;
    label: string;
}

// ..\..\Elcano.Common\Models\ParamSearchAndReplace.cs
export interface ParamSearchAndReplace {
    searchText: string;
    replaceText: string;
    idAuthor: string;
    publications: string[];
    idPays: number[];
    language?: string;
    fromDate?: string;
    untilDate?: string;
    mainSections: number[];
    subSections: number[];
}

// ..\..\Elcano.Common\Models\PreviewResult.cs
export interface PreviewResult {
    idArticle: number;
    idBackOffice: number;
    url: string;
    title: string;
    success: boolean;
}

// ..\..\Elcano.Common\Models\ProfileAssignmentDto.cs
export interface ProfileAssignmentDto {
    id: number;
    idPublication?: string;
    idAssignment: string;
    sections: number[];
    initialConfig: boolean;
}

// ..\..\Elcano.Common\Models\ProfileDto.cs
export interface ProfileDto {
    id: string;
    labelFr: string;
    labelEn: string;
    initialConfig: boolean;
    profileAssignments: ProfileAssignmentDto[];
}

// ..\..\Elcano.Common\Models\PublicationDto.cs
export interface PublicationDto {
    id: string;
    logo?: string;
    multiLanguage: boolean;
    active: boolean;
    name: string;
    showOrder: number;
    sections: SectionDto[];
}

// ..\..\Elcano.Common\Models\ReportStatus.cs
export enum ReportStatus {
    ToReplace = 'ToReplace',
    NotToReplace = 'NotToReplace',
    Replaced = 'Replaced',
    WaitingForExport = 'WaitingForExport',
    Exported = 'Exported',
    Error = 'Error',
    ProcessedWithoutModifications = 'ProcessedWithoutModifications',
}

// ..\..\Elcano.Common\Models\RightDto.cs
export interface RightDto {
    id: string;
    idGroup: number;
    order: number;
    labelFr: string;
    labelEn: string;
}

// ..\..\Elcano.Common\Models\RightGroupDto.cs
export interface RightGroupDto {
    id: number;
    labelFr: string;
    labelEn: string;
    order: number;
    initialConfig: boolean;
}

// ..\..\Elcano.Common\Models\SectionDto.cs
export interface SectionDto {
    id: number;
    labelFr: string;
    labelEn?: string;
    sectionPublications: SectionPublicationDto[];
    idBackOffice: number;
    idParent?: number;
    active: boolean;
    main: boolean;
    backgroundColor?: string;
    fontColor?: string;
    weight?: number;
}

// ..\..\Elcano.Common\Models\SectionPublicationDto.cs
export interface SectionPublicationDto {
    publication: string;
    active: boolean;
}

// ..\..\Elcano.Common\Models\SuggestionDto.cs
export interface SuggestionDto {
    id: string;
    authorId: string;
    type: string;
    createdAt?: string;
    data?: Record<string, any>;
    attributes?: Record<string, any>;
    state?: string;
    hasComments: boolean;
    originalSuggestionId?: string;
}

// ..\..\Elcano.Common\Models\SuggestionDto.cs
export interface SuggestionUpdateDto {
    id: string;
    attributes?: Record<string, any>;
    state?: string;
    hasComments?: boolean;
}

// ..\..\Elcano.Common\Models\TypeDeMail.cs
export enum TypeDeMail {
    Alert_complete = 'Alert_complete',
    Alert_abo = 'Alert_abo',
    Alert_prosp = 'Alert_prosp',
    Dossier_complet = 'Dossier_complet',
    Dossier_abo = 'Dossier_abo',
    Dossier_prosp = 'Dossier_prosp',
    Feuilleton_complet = 'Feuilleton_complet',
    Feuilleton_abo = 'Feuilleton_abo',
    Feuilleton_prosp = 'Feuilleton_prosp',
    Insiders_complet = 'Insiders_complet',
    Insiders_abo = 'Insiders_abo',
    Insiders_prosp = 'Insiders_prosp',
    Entourage_abo = 'Entourage_abo',
    Entourage_prosp = 'Entourage_prosp',
    Entourage_complet = 'Entourage_complet',
    GrandeEnquete_abo = 'GrandeEnquete_abo',
    GrandeEnquete_prosp = 'GrandeEnquete_prosp',
    GrandeEnquete_complet = 'GrandeEnquete_complet',
    Article_abo = 'Article_abo',
    JustReleased_abo = 'JustReleased_abo',
    JustReleased_prosp = 'JustReleased_prosp',
}

// ..\..\Elcano.Common\Models\UserDto.cs
export interface UserDto {
    id: string;
    idKeycloak?: string;
    idBackOffice?: number;
    deleted: boolean;
    enabled: boolean;
    email: string;
    login: string;
    name: string;
    lastName: string;
    firstName: string;
    language: string;
    signature?: string;
    allRights: Record<string, string[]>;
    profiles: ProfileDto[];
    lastAuthentification: number;
    redactor: string[];
    editor: string[];
    translator: string[];
    freelance: string[];
    addDate: string;
}

// ..\..\Elcano.Common\Models\WorkflowFireResult.cs
export interface WorkflowFireResult {
    state: WorkflowState;
    xmin: number;
    triggers: WorkflowTrigger[];
    errorCode: WorkflowErrorCode;
}

// ..\..\Elcano.Common\Models\WorkflowFireResult.cs
export enum WorkflowErrorCode {
    None = 'None',
    AlreadyImporterWithAnotherId = 'AlreadyImporterWithAnotherId',
}

// ..\..\Elcano.WorkflowApi\Models\ArticleMailSendModel.cs
export interface ArticleMailSendModel {
    nbSendings: number;
    lastSendDate?: string;
}

// ..\..\Elcano.WorkflowApi\Models\ArticleODataDto.cs
export interface ArticleODataDto {
    id: number;
    associatedArticles: AssociationArticleODataDto[];
    idPublication: string;
    publicationName?: string;
    publishedOn?: string;
    publishedOnTranslation?: string;
    summaryOf?: string;
    summaryOfTranslation?: string;
    lastUpdate: string;
    xmin: number;
    idMainLanguage: string;
    score: number;
    contents: ArticleContentODataDto[];
    contributions: ContributionDto[];
    sections: ArticleSectionODataDto[];
    countries: CountryDto[];
    settings: ArticleSettingDto[];
}

// ..\..\Elcano.WorkflowApi\Models\ArticleODataDto.cs
export interface AssociationArticleODataDto {
    id: number;
    idPublication: string;
    publishedOn?: string;
}

// ..\..\Elcano.WorkflowApi\Models\ArticleODataDto.cs
export interface ArticleContentODataDto {
    id: number;
    idArticle: number;
    title: string;
    shortTitle?: string;
    text: string;
    standfirst: string;
    state: WorkflowState;
    invalidated: boolean;
    online: boolean;
    idBackOffice?: number;
    xmin: number;
    language: string;
    isTranslation: boolean;
    lastUpdate: string;
    format: string;
    pathMainMedia?: string;
    pathSocialMedia?: string;
    articleUrl?: string;
    charCount: number;
    wordCount: number;
    settings: ArticleSettingDto[];
    medias: boolean;
}

// ..\..\Elcano.WorkflowApi\Models\ArticleODataDto.cs
export interface ArticleSectionODataDto {
    section: number;
    labelFr: string;
    labelEn?: string;
    main: boolean;
}

// ..\..\Elcano.WorkflowApi\Models\AssociationArticlesAutoParamDto.cs
export interface AssociationArticlesAutoParamDto {
    title: string;
    text: string;
    publicationId: string;
}

// ..\..\Elcano.WorkflowApi\Models\CommentatorDto.cs
export interface CommentatorDto {
    id: string;
    email: string;
    name: string;
    lastName: string;
    firstName: string;
}

// ..\..\Elcano.WorkflowApi\Models\EnrichissementModelSimple.cs
export interface ReponseAPI<T> {
    valid: boolean;
    error: string;
    data: T;
}

// ..\..\Elcano.WorkflowApi\Models\EnrichissementModelSimple.cs
export interface EnrichissementModelSimple {
    dociid: number;
    nombreCorrespondances: number;
    titreArticle: string;
    idWorkflow: number;
}

// ..\..\Elcano.WorkflowApi\Models\ExportDataType.cs
export enum ExportDataType {
    Freelance = 'Freelance',
    Translator = 'Translator',
}

// ..\..\Elcano.WorkflowApi\Models\ExportParam.cs
export interface ExportParam {
    type: ExportDataType;
    from: string;
    to: string;
    publicationId?: string;
    userId?: string;
}

// ..\..\Elcano.WorkflowApi\Models\InterestModelSimple.cs
export interface InterestModelSimple {
    id: number;
    libelleInteret: string;
    libelleInteretAn: string;
    poids: number;
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatArticleBaseParam.cs
export interface LongFormatArticleBaseParam {
    idPublication: string;
    idArticle: number;
    currentDate: string;
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatArticleBaseParam.cs
export interface MoveLongFormatArticleParam extends LongFormatArticleBaseParam {
    newDate: string;
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatArticleBaseParam.cs
export interface RemoveLongFormatArticleParam extends LongFormatArticleBaseParam {
    delete: boolean;
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatArticleBaseParam.cs
export interface MoveSerieLongFormatArticleParam extends LongFormatArticleBaseParam {
    push: boolean;
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatArticleBaseParam.cs
export interface AddSerieLongFormatArticleParam extends LongFormatArticleBaseParam {
    endDate: string;
    idFormat: string;
    articles: SeriesArticle[];
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatArticleBaseParam.cs
export interface SeriesArticle {
    author: string;
    language: string;
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatCycle.cs
export enum LongFormatCycle {
    Weekly = 'Weekly',
    Monthly = 'Monthly',
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatInformation.cs
export interface LongFormatInformation {
    releaseConfigurations: LongFormatReleaseConfiguration[];
    articles: ArticleDto[];
    idPublication: string;
}

// ..\..\Elcano.WorkflowApi\Models\LongFormatReleaseConfiguration.cs
export interface LongFormatReleaseConfiguration {
    key: string;
    idPublication: string;
    idFormat: string;
    cycle: LongFormatCycle;
    dayOfWeek: number;
    weekOfMonth: number;
    idSectionByDefault: number;
    nbDayBeforeRendered: number;
    availableDates: string[];
    articles: ArticleDto[];
}

// ..\..\Elcano.WorkflowApi\Models\PaginatedResultReportSearchAndReplace.cs
export interface PaginatedResultReportSearchAndReplace {
    pageMax: number;
    total: number;
    results: ResultReportSearchAndReplaceDto[];
}

// ..\..\Elcano.WorkflowApi\Models\ParamProcessReplacement.cs
export interface ParamProcessReplacement {
    idReport: string;
    statusToProcess: ReportStatus;
}

// ..\..\Elcano.WorkflowApi\Models\ParamUpdateResultModel.cs
export interface ParamUpdateResultModel {
    idReport: string;
    idArticle: number;
    status: ReportStatus;
}

// ..\..\Elcano.WorkflowApi\Models\PlanifierAmEmailModel.cs
export interface PlanifierAmEmailModel {
    idArticle: number;
    datePlanification: string;
    typeEmail: TypeDeMail;
}

// ..\..\Elcano.WorkflowApi\Models\ReplaceImageParam.cs
export interface ReplaceImageParam {
    resourceType: string;
    filePath: string;
    contents64: string;
    selectedFileName: string;
}

// ..\..\Elcano.WorkflowApi\Models\ReportsDto.cs
export interface ReportSearchAndReplaceDto {
    id: string;
    title: string;
    searchedText: string;
    replacingText: string;
    idAuthor: string;
    createdAt: string;
    totalOccurences: number;
    totalArticles: number;
}

// ..\..\Elcano.WorkflowApi\Models\ReportsDto.cs
export interface ResultReportSearchAndReplaceDto {
    id: string;
    reportId: string;
    title: string;
    snippets: string[];
    status: ReportStatus;
    idArticle: number;
    language: string;
}

// ..\..\Elcano.WorkflowApi\Models\ResultGenerationPlanifMail.cs
export interface ResultGenerationPlanifMail<T> {
    codeResult: CodeResultGenerationPlanifMail;
    data?: T;
}

// ..\..\Elcano.WorkflowApi\Models\ResultGenerationPlanifMail.cs
export enum CodeResultGenerationPlanifMail {
    Ok = 'Ok',
    Error = 'Error',
    NoMessage = 'NoMessage',
    InexistentArticle = 'InexistentArticle',
    UpdateFailed = 'UpdateFailed',
    SaveFailed = 'SaveFailed',
    NotAvailableAM = 'NotAvailableAM',
    PlanifDateError = 'PlanifDateError',
    HistoAlreadyExists = 'HistoAlreadyExists',
    TagAlreadyExists = 'TagAlreadyExists',
    NoContacts = 'NoContacts',
    ArticleNotOnline = 'ArticleNotOnline',
}

// ..\..\Elcano.WorkflowApi\Models\TriggerResult.cs
export interface TriggerResult {
    valid: WorkflowTrigger[];
    invalid: WorkflowTrigger[];
}

// ..\..\Elcano.WorkflowApi\Models\UpdatePublishedOn.cs
export interface UpdatePublishedOn {
    publishedOn: string;
    mainLanguage: boolean;
}

// ..\..\Elcano.WorkflowApi\Models\UpdateSubjectModel.cs
export interface UpdateSubjectModel {
    idArticle: number;
    typeMail: TypeDeMail;
    newSubject: string;
}

// ..\..\Elcano.WorkflowApi\Models\UserLightDto.cs
export interface UserLightDto extends UserModificationDto {
    id: string;
    idKeycloak?: string;
    redactor: string[];
    editor: string[];
    translator: string[];
    freelance: string[];
}

// ..\..\Elcano.WorkflowApi\Models\UserLightDto.cs
export interface UserModificationDto extends UserFromKeycloakModificationDto {
    email: string;
    login: string;
    name: string;
    lastName: string;
    firstName: string;
}

// ..\..\Elcano.WorkflowApi\Models\UserLightDto.cs
export interface UserFromKeycloakModificationDto extends UserModificationBaseDto {
    deleted: boolean;
    enabled: boolean;
    signature?: string;
    language: string;
}

// ..\..\Elcano.WorkflowApi\Models\UserLightDto.cs
export interface UserModificationBaseDto {
    idBackOffice?: number;
}

// ..\..\Elcano.Common\References\ArticleModificationTypes.cs
export enum ArticleModificationTypes {
    None = 'None',
    Base = 'Base',
    FrenchContent = 'FrenchContent',
    EnglishContent = 'EnglishContent',
}

// ..\..\Elcano.Common\References\ArticleSaveExceptionCode.cs
export enum ArticleSaveExceptionCode {
    ContributionLocked = 'ContributionLocked',
    ContributorMissing = 'ContributorMissing',
    ContributionLangMissing = 'ContributionLangMissing',
    UserDisconnected = 'UserDisconnected',
    NotReviewer = 'NotReviewer',
    NotLeadTranslator = 'NotLeadTranslator',
    ArticleLocked = 'ArticleLocked',
    UpdateByAnotherUser = 'UpdateByAnotherUser',
    SaveError = 'SaveError',
    MainContentDoesntExist = 'MainContentDoesntExist',
    TranslationExists = 'TranslationExists',
    TranslatorExists = 'TranslatorExists',
    NotAuthor = 'NotAuthor',
    EditionLocked = 'EditionLocked',
    MainContentNotEditableByTranslator = 'MainContentNotEditableByTranslator',
    AlreadyImporterWithAnotherId = 'AlreadyImporterWithAnotherId',
    Importing = 'Importing',
    SwitchMainLanguageAlreadyHaveTwoContent = 'SwitchMainLanguageAlreadyHaveTwoContent',
}

// ..\..\Elcano.Common\References\ArticleSaveExceptionCode.cs
export enum NotAllowedExceptionCode {
    WithPublication = 'WithPublication',
    WithoutPublication = 'WithoutPublication',
}

// ..\..\Elcano.Common\References\ContributionSaveExceptionCode.cs
export enum ContributionSaveExceptionCode {
    CantChangeStateOnOtherUserContribution = 'CantChangeStateOnOtherUserContribution',
    DoesntExist = 'DoesntExist',
    SaveError = 'SaveError',
}

// ..\..\Elcano.Common\References\ContributionState.cs
export enum ContributionState {
    Unknown = 'Unknown',
    ReviewRejected = 'ReviewRejected',
    ReviewAccepted = 'ReviewAccepted',
}

// ..\..\Elcano.Common\References\ContributionType.cs
export enum ContributionType {
    Unknown = 'Unknown',
    Author = 'Author',
    Editor = 'Editor',
    Translator = 'Translator',
    Freelancer = 'Freelancer',
    Reviewer = 'Reviewer',
    LeadTranslator = 'LeadTranslator',
}

// ..\..\Elcano.Common\References\ElcanoCmsKey.cs
export interface ElcanoCmsKey {
}

// ..\..\Elcano.Common\References\ElcanoFormat.cs
export interface ElcanoFormat {
}

// ..\..\Elcano.Common\References\ElcanoLanguage.cs
export interface ElcanoLanguage {
}

// ..\..\Elcano.Common\References\ElcanoMailTemplate.cs
export enum ElcanoMailTemplate {
    MentionedUserInContent = 'MentionedUserInContent',
    MentionedUserInArticle = 'MentionedUserInArticle',
    StatusUpdateCorrection = 'StatusUpdateCorrection',
    StatusUpdateLeadTranslatorReviewer = 'StatusUpdateLeadTranslatorReviewer',
    StatusUpdateReopen = 'StatusUpdateReopen',
    StatusUpdateReviewer = 'StatusUpdateReviewer',
    StatusUpdateReviewerForTranslation = 'StatusUpdateReviewerForTranslation',
    RemindReviewerForTranslation = 'RemindReviewerForTranslation',
    TranslationCreated = 'TranslationCreated',
    PublishedOnChanged = 'PublishedOnChanged',
}

// ..\..\Elcano.Common\References\ElcanoNotificationType.cs
export enum ElcanoNotificationType {
    Broadcast = 'Broadcast',
    ArticleLock = 'ArticleLock',
    ArticleUnlock = 'ArticleUnlock',
    ArticleGeneralComment = 'ArticleGeneralComment',
    ArticleContentComment = 'ArticleContentComment',
    ArticleStatusUpdate = 'ArticleStatusUpdate',
    ArticleLogUpdate = 'ArticleLogUpdate',
    SubscribeToGroup = 'SubscribeToGroup',
    UnsubscribeToGroup = 'UnsubscribeToGroup',
    UserMention = 'UserMention',
    ImportationEnd = 'ImportationEnd',
    PreviewEnd = 'PreviewEnd',
}

// ..\..\Elcano.Common\References\ElcanoPublication.cs
export interface ElcanoPublication {
}

// ..\..\Elcano.Common\References\ElcanoRight.cs
export interface ElcanoCloakRole {
}

// ..\..\Elcano.Common\References\ElcanoRight.cs
export interface ElcanoRight {
}

// ..\..\Elcano.Common\References\ElcanoRight.cs
export enum RightReference {
    ADMIN_FULL = 'ADMIN_FULL',
    ADMIN_USER = 'ADMIN_USER',
    ADMIN_BUSINESS = 'ADMIN_BUSINESS',
    ACCESS_ALL_PUBLICATIONS = 'ACCESS_ALL_PUBLICATIONS',
    PUBLICATION_ACCESS = 'PUBLICATION_ACCESS',
    LIST_COMMENTATORS = 'LIST_COMMENTATORS',
    PUBLICATION_ARTICLE_STATE_SAVE_ = 'PUBLICATION_ARTICLE_STATE_SAVE_',
    PUBLICATION_ARTICLE_WORKFLOW_ACTION_ = 'PUBLICATION_ARTICLE_WORKFLOW_ACTION_',
    PUBLICATION_ARTICLE_ACTION_DELETE = 'PUBLICATION_ARTICLE_ACTION_DELETE',
    PUBLICATION_ARTICLE_ACTION_FREE = 'PUBLICATION_ARTICLE_ACTION_FREE',
    PUBLICATION_ARTICLE_CREATE_TRANSLATION = 'PUBLICATION_ARTICLE_CREATE_TRANSLATION',
    EDITION_LOCK_MANAGEMENT = 'EDITION_LOCK_MANAGEMENT',
    EDITION_LOCK_BYPASS = 'EDITION_LOCK_BYPASS',
    REPORTING_ACCESS = 'REPORTING_ACCESS',
    FREELANCE_AMOUNT = 'FREELANCE_AMOUNT',
    FREELANCE_MANAGEMENT = 'FREELANCE_MANAGEMENT',
    MEDIA_LIST = 'MEDIA_LIST',
    MEDIA_ADMIN = 'MEDIA_ADMIN',
    PUBLICATION_ARTICLE_CONTRIBUTION_UpdateAmount = 'PUBLICATION_ARTICLE_CONTRIBUTION_UpdateAmount',
    PUBLICATION_ARTICLE_UpdateTweet = 'PUBLICATION_ARTICLE_UpdateTweet',
    PUBLICATION_EXPORT_FREELANCE = 'PUBLICATION_EXPORT_FREELANCE',
    PUBLICATION_EXPORT_TRANSLATION = 'PUBLICATION_EXPORT_TRANSLATION',
    EXPORT_ALL_FREELANCE = 'EXPORT_ALL_FREELANCE',
    EXPORT_ALL_TRANSLATION = 'EXPORT_ALL_TRANSLATION',
    REPLACEMENT_PROCESS = 'REPLACEMENT_PROCESS',
    CHANGE_PUBLISHED_ON = 'CHANGE_PUBLISHED_ON',
    MANAGE_LONG_FORMAT_SERIES = 'MANAGE_LONG_FORMAT_SERIES',
    HOME_BLOCK_DISPLAY_REVIEW = 'HOME_BLOCK_DISPLAY_REVIEW',
    HOME_BLOCK_DISPLAY_LONG_FORMAT = 'HOME_BLOCK_DISPLAY_LONG_FORMAT',
    HOME_BLOCK_DISPLAY_REVIEW_NEXT_EDITION = 'HOME_BLOCK_DISPLAY_REVIEW_NEXT_EDITION',
    HOME_BLOCK_DISPLAY_FREELANCE_CONSUMPTION = 'HOME_BLOCK_DISPLAY_FREELANCE_CONSUMPTION',
}

// ..\..\Elcano.Common\References\ParamSiteWebEtPublicationIndigo.cs
export interface ParamSiteWebEtPublicationIndigo {
}

// ..\..\Elcano.Common\References\ParamSiteWebEtPublicationIndigo.cs
export enum SiteWeb {
    AfricaIntelligenceFR = 'AfricaIntelligenceFR',
    AfricaIntelligenceCOM = 'AfricaIntelligenceCOM',
    IntelligenceOnlineFR = 'IntelligenceOnlineFR',
    IntelligenceOnlineCOM = 'IntelligenceOnlineCOM',
    LaLettre = 'LaLettre',
    Indigo = 'Indigo',
    GlitzFR = 'GlitzFR',
    GlitzCOM = 'GlitzCOM',
    LaLettreEN = 'LaLettreEN',
}

// ..\..\Elcano.Common\References\SettingsType.cs
export enum SettingsType {
    Unknown = 'Unknown',
    Interest = 'Interest',
    CMS = 'CMS',
}

// ..\..\Elcano.Common\References\WorkflowState.cs
export enum WorkflowState {
    Pitch = 'Pitch',
    Redaction = 'Redaction',
    AttenteTraduction = 'AttenteTraduction',
    Traduction = 'Traduction',
    Relecture = 'Relecture',
    Correction = 'Correction',
    AttenteCorrectionSr = 'AttenteCorrectionSr',
    CorrectionSr = 'CorrectionSr',
    RelectureTraducteurReferent = 'RelectureTraducteurReferent',
    Importation = 'Importation',
    Importe = 'Importe',
    ImportationEnErreur = 'ImportationEnErreur',
    Invalidation = 'Invalidation',
    Invalide = 'Invalide',
    InvalidationEnErreur = 'InvalidationEnErreur',
}

// ..\..\Elcano.Common\References\WorkflowTrigger.cs
export interface WorkflowTriggerParam {
    connectionId?: string;
    contributorIds: string[];
    message?: string;
}

// ..\..\Elcano.Common\References\WorkflowTrigger.cs
export enum WorkflowTrigger {
    Rediger = 'Rediger',
    Pitcher = 'Pitcher',
    DemanderTraduction = 'DemanderTraduction',
    Traduire = 'Traduire',
    Relire = 'Relire',
    Corriger = 'Corriger',
    DemanderCorrectionSr = 'DemanderCorrectionSr',
    CorrigerSr = 'CorrigerSr',
    RelireTraducteurReferent = 'RelireTraducteurReferent',
    Importer = 'Importer',
    ValiderImportation = 'ValiderImportation',
    RejeterImportation = 'RejeterImportation',
    Rouvrir = 'Rouvrir',
    Invalider = 'Invalider',
    ValiderInvalidation = 'ValiderInvalidation',
    RejeterInvalidation = 'RejeterInvalidation',
    Republier = 'Republier',
    RenvoyerAuTraducteur = 'RenvoyerAuTraducteur',
    RenvoyerAuTraducteurReferent = 'RenvoyerAuTraducteurReferent',
    RenvoyerAuRelecteur = 'RenvoyerAuRelecteur',
}
