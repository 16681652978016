<template>
  <div
    v-if="
      $route.name == RoutesName.Articles ||
      $route.name == RoutesName.Planning ||
      $route.name == RoutesName.LongFormatPlanning
    "
    class="q-gutter-sm q-pa-md text-right"
  >
    <q-btn
      color="secondary"
      :label="$t('article.labels.btnShow')"
      :to="{
        name: RoutesName.EditArticle,
        params: { id: currentArticle.id },
        query: { lang: lang },
      }"
      target="_blank"
    />
    <q-btn
      :label="$t('article.labels.btnSave')"
      color="primary"
      :disable="modifExist == modificationType.None"
      @click="onSubmit"
    />
  </div>
  <div>
    <q-list>
      <q-expansion-item
        group="settings"
        :label="$t('article.edit.titles.parameters')"
        default-opened
        header-class="bg-primary text-white"
        expand-icon-class="text-white"
      >
        <FormArticleMetaData class="q-pa-sm" />
      </q-expansion-item>
      <q-expansion-item
        group="settings"
        :label="$t('article.edit.titles.contributions')"
        header-class="bg-primary text-white"
        expand-icon-class="text-white"
      >
        <ContributionsArticle class="q-pa-sm" />
      </q-expansion-item>
      <q-expansion-item
        group="settings"
        :label="$t('article.edit.titles.cms')"
        header-class="bg-primary text-white"
        expand-icon-class="text-white"
      >
        <ParamsArticleContent class="q-pa-sm" />
      </q-expansion-item>
    </q-list>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import FormArticleMetaData from "@/components/Article/FormArticleMetaData.vue";
import ContributionsArticle from "@/components/Article/Contribution/ContributionsArticle.vue";
import ParamsArticleContent from "@/components/Article/ParamsArticleContent.vue";
import { modificationType } from "@/components/Article/constant";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useFormArticle } from "@/composables/useFormArticle";
import { useArticleActions } from "@/composables/useArticleActions";
import { RoutesName } from "@/router/routesName";

const currentArticleStore = useCurrentArticleStore();
const { modifExist, currentArticle, lang } = storeToRefs(currentArticleStore);
const { actions: formActions } = useFormArticle();
const { actions } = useArticleActions();

/**
 * Fonction appelé au moment de l'enregistrement de l'article
 */
function onSubmit() {
  formActions.onSubmit(() =>
    actions.onEditArticle(currentArticleStore.exportArticle(), false, () => {
      // On utilise un timer car est présent un débounce (300ms) sur le formulaire article content qui est mis à jour suite à la sauvegarde.
      setTimeout(() => {
        modifExist.value = modificationType.None;
      }, 400);
    })
  );
}
</script>
