<template>
  <q-list dense>
    <q-item
      v-for="(contribution, idx) in contributions"
      :key="'contribution' + idx"
      class="contribution-item"
    >
      <q-item-section>
        <div class="row text-grey-6 no-wrap" style="font-size: 0.9em">
          <div
            style="
              width: 25%;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            "
          >
            {{ $t(`contributionType.${contribution.type}`) }}
          </div>

          <div class="col-auto" :class="getClass(contribution)">
            {{ contribution.user }}
            <q-icon
              v-if="getIcon(contribution)"
              :name="getIcon(contribution)"
            />
          </div>
          <div>
            <q-icon
              v-if="showSignatureIcon(contribution.type)"
              name="edit"
              :style="`color: ${Dark.isActive ? 'white' : 'black'}`"
              class="q-ml-xs"
            />
          </div>
        </div>
      </q-item-section>
    </q-item>
  </q-list>
</template>
<script setup lang="ts">
import {
  ArticleLockDto,
  ArticleODataDto,
  ContributionDto,
  ContributionState,
  ContributionType,
  WorkflowState,
} from "@/types/api";
import { toRefs, computed } from "vue";
import { useUserStore } from "@/stores/user";
import { getContributionTypeOrder } from "@/helpers/contributionState";
import { authService } from "@/services/AuthService";
import { getModificationTypeFromLanguage } from "@/components/Article/constant";
import { Dark } from "quasar";

/** Interface ContributionItem */
interface ContributionItem {
  type: ContributionType;
  user: string | undefined;
  state: ContributionState;
  lastNotification: string | undefined;
  idLanguage: string | undefined;
  idContributor: string;
}
const props = defineProps<{
  data: ArticleODataDto;
  locks: ArticleLockDto[];
}>();
const { data, locks } = toRefs(props);
const { usersLight } = useUserStore();
const contributions = computed<ContributionItem[]>(() => {
  const contributionSorted = sortContributions(data.value.contributions);
  return contributionSorted.map((c) => ({
    type: c.type,
    user: usersLight.find((u) => u.id === c.idContributor.toString())?.name,
    state: c.state,
    lastNotification: c.lastNotification,
    idLanguage: c.idLanguage,
    idContributor: c.idContributor.toString(),
  }));
});

/** Affiche un icon de stylo si la signature est visible */
function showSignatureIcon(contributionType: ContributionType) {
  return (
    (data.value.contents.some((c) =>
      c.settings.some((s) => s.key1 == "SIGNATUREVISIBLE")
    ) &&
      contributionType == ContributionType.Author) ||
    (data.value.contents.some((c) =>
      c.settings.some((s) => s.key1 == "SIGNATUREPIGISTEVISIBLE")
    ) &&
      contributionType == ContributionType.Freelancer)
  );
}

/** Trie les contributions par type */
function sortContributions(contributions: ContributionDto[]) {
  return contributions.sort(
    (a: ContributionDto, b: ContributionDto) =>
      getContributionTypeOrder(a.type) - getContributionTypeOrder(b.type)
  );
}

/** Retourne si la contribution concerne la relecture en cours */
function isCurrentReview(contribution: ContributionItem) {
  if (!contribution.idLanguage) return false;

  const content = data.value.contents.find(
    (c) => c.language == contribution.idLanguage
  );

  if (!content) return false;

  if (
    contribution.type === ContributionType.Reviewer &&
    content.state === WorkflowState.Relecture
  ) {
    return true;
  }

  if (
    contribution.type === ContributionType.LeadTranslator &&
    content.state === WorkflowState.RelectureTraducteurReferent
  ) {
    return true;
  }

  return false;
}

/** Retourne si la contribution concerne une relecture SR / bouclage */
function isCurrentEditorReview(contribution: ContributionItem) {
  if (!contribution.idLanguage) return false;

  const content = data.value.contents.find(
    (c) => c.language == contribution.idLanguage
  );

  if (!content) return false;

  return (
    contribution.type === ContributionType.Editor &&
    content.state === WorkflowState.Importe
  );
}

/** Retourne la classe css en fonction du type de contribution */
function getClass(contribution: ContributionItem) {
  if (!isCurrentReview(contribution) && !isCurrentEditorReview(contribution))
    return "contributor-classic";

  let className =
    contribution.idContributor === authService.getUserId() ? "text-bold " : "";

  if (contribution.state === ContributionState.Unknown)
    return className + " contributor-classic";

  className += "text-white q-px-xs ";

  if (contribution.state === ContributionState.ReviewAccepted)
    className += "bg-teal";
  else className += "bg-red";

  return className;
}

/** Retourne la classe css en fonction du type de contribution */
function getIcon(contribution: ContributionItem) {
  if (!isCurrentReview(contribution)) return "";

  const lock = locks.value.find(
    (l) =>
      l.userId === contribution.idContributor &&
      (l.modificationType &
        getModificationTypeFromLanguage(contribution.idLanguage ?? "")) >
        0
  );
  if (lock) return "remove_red_eye";

  if (contribution.lastNotification) return "notifications";

  return "";
}
</script>
<style lang="scss" scoped>
.q-list--dense > .contribution-item {
  padding-top: 0;
  padding-bottom: 0;
  min-height: 25px;
  padding-left: 0px;
  padding-right: 0px;
}
.body--dark {
  .contributor-classic {
    color: white;
  }
}
.contributor-classic {
  color: black;
}
</style>
