<template>
  <PageWithPanel :actions="pageActions">
    <template #panelRight>
      <div class="q-pa-md">
        <FormArticleSetting
          v-if="callbackOnSubmit"
          :label-btn-submit="
            $t(
              articleSetting.id > 0
                ? 'articleSetting.labels.btnEdit'
                : 'articleSetting.labels.btnCreate'
            )
          "
          :default-article-setting="articleSetting"
          @submit="callbackOnSubmit"
          @delete="actions.onDeleteArticleSetting"
        />
      </div>
    </template>
  </PageWithPanel>
</template>

<script setup lang="ts">
import type { PageAction } from "@/types/page";
import { computed } from "vue";
import { useRoute } from "vue-router";
import { RoutesName } from "@/router/routesName";
import { useArticleSettingActions } from "@/composables/useArticleSettingsActions";
import FormArticleSetting from "@/components/ArticleSetting/FormArticleSetting.vue";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import { i18n } from "@/i18n";

const route = useRoute();
const { actions, articleSetting, callbackOnSubmit } =
  useArticleSettingActions();

const pageActions = computed<PageAction[]>(() =>
  [
    {
      label: i18n.t("articleSetting.labels.btnNew"),
      shortLabel: i18n.t("articleSetting.labels.shortBtnNew"),
      icon: "add",
      disable: false,
      handler: actions.createArticleSetting,
      visible: true,
    },
    {
      label: i18n.t("articleSetting.labels.btnDelete"),
      shortLabel: i18n.t("articleSetting.labels.shortBtnDelete"),
      icon: "delete",
      disable: false,
      handler: onDelete,
      visible: route.name === RoutesName.EditArticleSetting,
    },
  ].filter((act) => act.visible)
);

/**
 * Fonction appelé pour supprimer un articleSetting
 */
function onDelete() {
  actions.onDeleteArticleSetting(Number(route.params.id));
}
</script>
