import type { CrudServiceInstance } from "@/types/crud";
import type { PublicationDto } from "@/types/api";
import type { PublicationServiceInstance } from "@/types/publication";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un object type publication
 */
export function createPublication(
  params: Partial<PublicationDto> = {}
): PublicationDto {
  return {
    id: params?.id ?? "",
    active: params?.active ?? false,
    multiLanguage: params?.multiLanguage ?? true,
    name: params?.name ?? "",
    sections: params?.sections ?? [],
    showOrder: params?.showOrder ?? 0,
    logo: params?.logo ?? "",
  };
}

/**
 * Service publication pour récupérer les publications et les modifier.
 */
class PublicationService
  extends AxiosRequest
  implements PublicationServiceInstance
{
  crudService: CrudServiceInstance<PublicationDto>;

  /**
   * Constructeur de la class Publication service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<PublicationDto>(rootPath);
  }

  /**
   * Liste les publications
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre de publications affichées par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer une publication par son id
   * @param {number | string} id - id de la publication à récupérer
   */
  getSingle(id: string | number) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer une nouvelle publication
   * @param publication - données de la nouvelle publication à créer
   */
  insert(publication: PublicationDto) {
    return this.crudService.insert(publication);
  }

  /**
   * Met à jour une publication
   * @param publication - nouvelles données de la publication à mettre à jour
   */
  update(publication: PublicationDto) {
    return this.crudService.update(publication.id, publication);
  }
}

export const publicationService = new PublicationService("publications");
