<template>
  <div>
    <div class="q-mb-lg">
      <div class="text-subtitle1">
        {{ $t("import.titles.importArticleSettings") }}
      </div>
      <q-form class="q-gutter-md" @submit="onSubmit">
        <div>
          <q-btn
            :label="$t('import.labels.btnImport')"
            type="submit"
            color="primary"
          />
        </div>
      </q-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useImportActions } from "@/composables/useImportActions";

const { actions } = useImportActions();
const { importArticleSettings } = actions;

/**
 *
 */
function onSubmit() {
  importArticleSettings();
}
</script>
