<template>
  <q-card flat bordered>
    <q-card-section>
      <FormFormat :default-format="format" @submit="actions.onEditFormat" />
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import { useRoute } from "vue-router";
import { useFormatActions } from "@/composables/useFormatActions";
import FormFormat from "@/components/Format/FormFormat.vue";

const route = useRoute();
const { format, actions } = useFormatActions();

actions.findFormat(route.params.id as string);
</script>
