<template>
  <PageWithPanel :actions="pageActions">
    <template #panelRight>
      <div class="q-pa-md">
        <FormReport label-btn-submit="Créer" @submit="callbackOnSubmit" />
      </div>
    </template>
  </PageWithPanel>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { PageAction } from "@/types/page";
import { useReportActions } from "@/composables/useReportActions";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import FormReport from "@/components/Report/FormReport.vue";
import { ParamSearchAndReplace } from "@/types/api";
import { usePage } from "@/composables/usePage";

const { actions } = useReportActions();
const { showPanelRight } = usePage();

const pageActions = computed<PageAction[]>(() => [
  {
    label: "Créer un nouveau rapport",
    shortLabel: "Créer",
    icon: "add",
    disable: false,
    handler: () => {
      showPanelRight.value = true;
    },
    visible: true,
  },
]);

/**
 *
 */
function callbackOnSubmit(param: ParamSearchAndReplace) {
  actions.onCreateReport(param);
}
</script>
