<template>
  <q-card>
    <q-card-section>
      <q-table
        :columns="columns"
        :rows="reports"
        :rows-per-page-options="[10, 20, 0]"
      >
        <template #body-cell-actions="props">
          <q-td :props="props">
            <router-link
              :to="{
                name: RoutesName.Report,
                params: { id: props.row.id },
              }"
              target="_blank"
            >
              <q-btn
                color="primary"
                :label="$t('searchAndReplace.labels.btnViewReport')"
              >
              </q-btn>
            </router-link>
          </q-td>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { useReportActions } from "@/composables/useReportActions";
import { ReportSearchAndReplaceDto } from "@/types/api";
import { onMounted, ref } from "vue";
import { RoutesName } from "@/router/routesName";
import { QTableColumn, date } from "quasar";
import { i18n } from "@/i18n";
import { useUserStore } from "@/stores/user";

const { actions } = useReportActions();
const reports = ref<ReportSearchAndReplaceDto[]>();

const columns: QTableColumn<ReportSearchAndReplaceDto>[] = [
  {
    name: "title",
    label: i18n.t("searchAndReplace.columns.description"),
    field: (row) => row.title,
    align: "left",
  },
  {
    name: "impactedArticles",
    label: i18n.t("searchAndReplace.columns.totalImpactedArticles"),
    field: (row) => row.totalArticles,
    align: "center",
  },
  {
    name: "occurences",
    label: i18n.t("searchAndReplace.columns.totalOccurences"),
    field: (row) => row.totalOccurences,
    align: "center",
  },
  {
    name: "createdAt",
    label: i18n.t("searchAndReplace.columns.createdAt"),
    field: (row) => date.formatDate(row.createdAt, "DD/MM/YYYY"),
    align: "center",
  },
  {
    name: "auteur",
    label: i18n.t("searchAndReplace.columns.author"),
    field: (row) => getUserName(row.idAuthor),
    align: "center",
  },
  {
    name: "actions",
    label: i18n.t("searchAndReplace.columns.actions"),
    field: (row) => row,
  },
];

onMounted(async () => {
  reports.value = await actions.getReports();
});

/**
 *
 */
function getUserName(userId: string) {
  const { usersLight } = useUserStore();
  const user = usersLight.find((el) => el.id == userId);
  if (user != null) return user.name;
}
</script>
