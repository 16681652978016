<template>
  <div>
    <div class="q-gutter-md">
      <q-field
        :label="$t('user.fields.shortId')"
        dense
        stack-label
        filled
        readonly
      >
        <template #control>
          <div class="self-center full-width no-outline" tabindex="0">
            {{ user.id }}
          </div>
        </template>
      </q-field>
      <div class="q-ma-none q-gutter-md row items-start">
        <q-input
          v-model="currentUser.idBackOffice"
          type="number"
          class="col"
          :label="$t('user.fields.shortIdBackOffice')"
          dense
          stack-label
          filled
          :readonly="!canEditIdBackOfficeProperties"
        />
        <q-checkbox
          v-model="currentUser.deleted"
          class="col"
          :label="$t('user.fields.deleted')"
          :disable="!isAdminUser"
          :visible="isAdminFull"
        />
        <q-checkbox
          v-model="currentUser.enabled"
          class="col"
          :label="$t('user.fields.enabled')"
          :disable="!isAdminUser"
        />
      </div>
      <div class="q-ma-none q-gutter-md row items-start">
        <q-input
          v-model="currentUser.firstName"
          class="col"
          :label="$t('user.fields.shortFirstname')"
          dense
          stack-label
          filled
          :readonly="!isAdminUser"
        />
        <q-input
          v-model="currentUser.lastName"
          class="col"
          :label="$t('user.fields.shortLastname')"
          dense
          stack-label
          filled
          :readonly="!isAdminUser"
        />
      </div>
      <q-input
        v-model="currentUser.login"
        :label="$t('user.fields.shortLogin')"
        dense
        stack-label
        filled
        :readonly="userFromKeycloak || !isAdminUser"
      />
      <q-input
        v-model="currentUser.email"
        type="email"
        :label="$t('user.fields.shortEmail')"
        dense
        stack-label
        filled
        :readonly="userFromKeycloak || !isAdminUser"
      />
      <q-input
        v-model="currentUser.signature"
        :label="$t('user.fields.shortSignature')"
        dense
        stack-label
        filled
        :readonly="!isAdminUser"
      />
      <q-btn
        v-if="isFreelanceManager || isAdminUser"
        :label="$t('user.actions.save')"
        color="primary"
        @click="onSaveUser"
      />
      <q-select
        v-if="isAdminUser && currentUser.id"
        v-model="profileSelected"
        :label="$t('user.fields.shortProfiles')"
        :options="profileOptions"
        :option-disable="handleDisableOptions"
        dense
        filled
        @update:model-value="onSelectNewProfile"
      />
      <q-list v-if="user.profiles.length" separator>
        <q-item-label header>{{ $t("user.fields.profilesUsed") }}</q-item-label>
        <q-item v-for="(profile, idx) in user.profiles" :key="'profile' + idx">
          <q-item-section>
            <q-item-label>
              {{ isFr ? profile.labelFr : profile.labelEn }}
            </q-item-label>
          </q-item-section>
          <q-item-section side top>
            <q-btn
              v-if="isAdminUser"
              flat
              icon="delete"
              @click="onRemoveProfile(profile)"
            />
          </q-item-section>
        </q-item>
      </q-list>
    </div>
  </div>
</template>
<script setup lang="ts">
import { UserDto, ProfileDto, RightReference } from "@/types/api";
import type { QSelectOption } from "quasar";
import { computed, onUpdated, ref, toRefs } from "vue";
import { useUserStore } from "@/stores/user";
import { useLocale } from "@/utils";
import { authService } from "@/services/AuthService";
const props = defineProps<{
  user: UserDto;
}>();
const { user: currentUser } = toRefs(props);

const emits = defineEmits<{
  (e: "saveUser", user: UserDto): void;
  (e: "addProfile", userId: string, profile: ProfileDto): void;
  (e: "removeProfile", userId: string, profile: ProfileDto): void;
}>();
const { profiles } = useUserStore();
const { isFr } = useLocale();
const profileSelected = ref<string>();
const profileOptions: QSelectOption<ProfileDto>[] = profiles.map((profile) => ({
  label: isFr.value ? profile.labelFr : profile.labelEn,
  value: profile,
}));
const userFromKeycloak = computed(() => {
  return currentUser.value.idKeycloak !== undefined;
});
const isAdminUser = authService.hasRight(RightReference.ADMIN_USER);
const isAdminFull = authService.hasRight(RightReference.ADMIN_FULL);
const isFreelanceManager = authService.hasRight(
  RightReference.FREELANCE_MANAGEMENT
);
const canEditIdBackOfficeProperties = isAdminUser || isFreelanceManager;

onUpdated(() => {
  profileSelected.value = undefined;
});

/**
 * Fonction servant à mettre à jour les options possibles dans le sélecteur de profile
 */
function handleDisableOptions(profileOption: QSelectOption<ProfileDto>) {
  return props.user.profiles.map((p) => p.id).includes(profileOption.value.id);
}
/**
 * Fonction appelé lors de la sauvegarde
 */
function onSaveUser() {
  emits("saveUser", currentUser.value);
}
/**
 * Fonction appelé au moment d'un select d'un profile
 */
async function onSelectNewProfile(profileOption: QSelectOption<ProfileDto>) {
  emits("addProfile", props.user.id, profileOption.value);
}
/**
 * Fonction appelé au moment du click sur le bouton pour supprimer un profile
 */
async function onRemoveProfile(profile: ProfileDto) {
  emits("removeProfile", props.user.id, profile);
}
</script>
