/* this implementation is original ported from https://github.com/logaretm/vue-use-web by Abdelrahman Awad */

import type { MaybeRefOrGetter } from "@vueuse/shared";
import { toValue, useTimeoutFn } from "@vueuse/shared";
import {
  useEventListener,
  WindowEventName,
  useSupported,
  ConfigurableNavigator,
  defaultNavigator,
} from "@vueuse/core";
import { computed, ComputedRef, Ref, ref } from "vue";

/**
 * UseClipboardOptions
 */
export interface UseClipboardOptions<Source> extends ConfigurableNavigator {
  /**
   * Enabled reading for clipboard
   *
   * @default false
   */
  read?: boolean;

  /**
   * Copy source
   */
  source?: Source;

  /**
   * Milliseconds to reset state of `copied` ref
   *
   * @default 1500
   */
  copiedDuring?: number;

  /**
   * Whether fallback to document.execCommand('copy') if clipboard is undefined.
   *
   * @default false
   */
  legacy?: boolean;
}

/**
 * UseClipboardReturn
 */
export interface UseClipboardReturn<Optional> {
  isSupported: Ref<boolean>;
  text: ComputedRef<string>;
  copied: ComputedRef<boolean>;
  copy: Optional extends true
    ? (text?: string) => Promise<void>
    : (text: string) => Promise<void>;
  copyHtml: Optional extends true
    ? (text?: string) => Promise<void>
    : (text: string) => Promise<void>;
}

/**
 * Reactive Clipboard API.
 *
 * @see https://vueuse.org/useClipboard
 * @param options
 */
export function useClipboard(
  options?: UseClipboardOptions<undefined>
): UseClipboardReturn<false>;
export function useClipboard(
  options: UseClipboardOptions<MaybeRefOrGetter<string>>
): UseClipboardReturn<true>;
/**
 *
 */
export function useClipboard(
  options: UseClipboardOptions<MaybeRefOrGetter<string> | undefined> = {}
): UseClipboardReturn<boolean> {
  const {
    navigator = defaultNavigator,
    read = false,
    source,
    copiedDuring = 1500,
    legacy = false,
  } = options;

  const events = ["copy", "cut"];
  const isClipboardApiSupported = useSupported(
    () => navigator && "clipboard" in navigator
  );
  const isSupported = computed(() => isClipboardApiSupported.value || legacy);
  const text = ref("");
  const copied = ref(false);
  const timeout = useTimeoutFn(() => (copied.value = false), copiedDuring);

  /**
   * UpdateText
   */
  function updateText() {
    if (isClipboardApiSupported.value) {
      navigator?.clipboard.readText().then((value) => {
        text.value = value;
      });
    } else {
      text.value = legacyRead();
    }
  }

  if (isSupported.value && read) {
    for (const event of events)
      useEventListener(event as WindowEventName, updateText);
  }

  /**
   * Copy
   */
  async function copy(value = toValue(source)) {
    if (isSupported.value && value != null) {
      if (isClipboardApiSupported.value)
        await navigator?.clipboard.writeText(value);
      else legacyCopy(value);

      text.value = value;
      copied.value = true;
      timeout.start();
    }
  }

  /**
   * Copy Html content
   */
  async function copyHtml(value = toValue(source)) {
    if (isSupported.value && value != null) {
      try {
        if (isClipboardApiSupported.value) {
          await navigator?.clipboard.write([
            new ClipboardItem({
              "text/html": new Blob([value], {
                type: "text/html",
              }),
              "text/plain": new Blob([value], {
                type: "text/plain",
              }),
            }),
          ]);
        } else legacyCopyHtml(value);
      } catch (error) {
        legacyCopyHtml(value);
      }

      text.value = value;
      copied.value = true;
      timeout.start();
    }
  }

  /**
   * legacyCopy
   * @param value texte a copier
   */
  function legacyCopy(value: string) {
    const ta = document.createElement("textarea");
    ta.value = value ?? "";
    ta.style.position = "absolute";
    ta.style.opacity = "0";
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
  }
  /**
   * legacyCopy
   * @param value texte a copier
   */
  function legacyCopyHtml(value: string) {
    /** Copy Event Listener */
    function listener(e) {
      e.clipboardData.setData("text/html", value);
      e.clipboardData.setData("text/plain", value);
      e.preventDefault();
    }
    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
  }

  /**
   * legacyRead
   * @returns text
   */
  function legacyRead() {
    return document?.getSelection?.()?.toString() ?? "";
  }

  return {
    isSupported,
    text: text as ComputedRef<string>,
    copied: copied as ComputedRef<boolean>,
    copy,
    copyHtml,
  };
}
