<template>
  <q-dialog v-model="showDialogSaveState">
    <q-card>
      <q-card-section>
        <div>
          <div class="row">DEBUG</div>
          <div class="row">
            <q-checkbox
              :label="$t('modificationTypes.Base')"
              :model-value="(modifExist & 1) === 1"
              @update:model-value="
                (modifExist & 1) === 1 ? (modifExist -= 1) : (modifExist += 1)
              "
            />
          </div>
          <div class="row">
            <q-checkbox
              :label="$t('modificationTypes.FrenchContent')"
              :model-value="(modifExist & 2) === 2"
              @update:model-value="
                (modifExist & 2) === 2 ? (modifExist -= 2) : (modifExist += 2)
              "
            />
          </div>
          <div class="row">
            <q-checkbox
              :label="$t('modificationTypes.EnglishContent')"
              :model-value="(modifExist & 4) === 4"
              @update:model-value="
                (modifExist & 4) === 4 ? (modifExist -= 4) : (modifExist += 4)
              "
            />
          </div>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useKeyboardListener } from "@/composables/useKeyboardListener";

const showDialogSaveState = ref(false);
const { modifExist } = storeToRefs(useCurrentArticleStore());

const { startListening, stopListening } = useKeyboardListener(
  ["Control+Shift+SControl+Shift+S", "Control+Shift+sControl+Shift+s"],
  () => {
    showDialogSaveState.value = true;
  }
);

onMounted(() => {
  startListening();
});

onUnmounted(() => {
  stopListening();
});
</script>
