import type { ProfileDto, UserDto, UserLightDto } from "@/types/api";
import type { UserViewActions } from "@/types/user";
import { reactive, toRefs } from "vue";
import { useRouter } from "vue-router";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { useUserStore } from "@/stores/user";
import { RoutesName } from "@/router/routesName";
import { createUser as createUserService } from "@/services/UserService";

/**
 * Interface des états pour les actions user
 */
interface UserActionState {
  user: UserDto | null;
}
/**
 * Fonction servant à interagir sur les différentes vues user
 */
export const useUserActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const {
    findUser: storeFindUser,
    addUserProfile,
    insertUser,
    updateUser,
    removeUserProfile,
  } = useUserStore();

  const state = reactive<UserActionState>({
    user: null,
  });
  const userActions: UserViewActions = {
    findUser(userId: string) {
      try {
        state.user = { ...storeFindUser(userId) };
      } catch (err: any) {
        $q.notify(err.message);
        router.push({ name: RoutesName.Users });
      }
    },
    createUser() {
      state.user = createUserService();
    },
    editUser(userId: string, callback?: () => void) {
      userActions.findUser(userId);
      if (callback) callback();
    },
    onSaveUser(user: UserLightDto) {
      const handleSuccess = (userSuccess: UserDto) => {
        state.user = { ...userSuccess };
      };
      const handleError = (err: Error) =>
        $q.notify({
          message: err.message,
          color: "error",
        });

      if (!user.id) insertUser(user).then(handleSuccess).catch(handleError);
      else updateUser(user).then(handleSuccess).catch(handleError);
    },
    onAddUserProfile(userId: string, profile: ProfileDto) {
      addUserProfile(userId, profile).catch((err: Error) =>
        $q.notify(err.message)
      );
    },
    onRemoveUserProfile(userId: string, profile: ProfileDto) {
      removeUserProfile(userId, profile).catch((err: Error) =>
        $q.notify(err.message)
      );
    },
  };
  return { ...toRefs(state), actions: userActions };
});
