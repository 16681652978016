import type { RightDto } from "@/types/api";
import type { RightViewActions } from "@/types/user";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { useUserStore } from "@/stores/user";
import { RoutesName } from "@/router/routesName";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions groupe de droit
 */
interface RightActionState {
  right: RightDto | null;
}
/**
 * Fonction servant à interagir sur les différentes vues droit
 */
export const useRightActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { findRight, addRight, updateRight, deleteRight } = useUserStore();

  const state = reactive<RightActionState>({
    right: null,
  });
  const actions: RightViewActions = {
    findRight(rightId: string) {
      return findRight(rightId)
        .then((right) => {
          state.right = right;
        })
        .catch((err: Error) => {
          $q.notify(err.message);
          router.push({ name: RoutesName.Rights });
        });
    },
    createRight(callback?: () => void) {
      state.right = null;
      if (callback) callback();
    },
    editRight(rightId: string, callback?: () => void) {
      actions.findRight(rightId).then(() => {
        if (callback) callback();
      });
    },
    onCreateRight(right: RightDto) {
      addRight(right)
        .then(() => {
          $q.notify(i18n.t("right.messages.created"));
          router.push({ name: RoutesName.Rights });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditRight(right: RightDto) {
      updateRight(right)
        .then(() => {
          $q.notify(i18n.t("right.messages.updated"));
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onDeleteRight(rightId: string) {
      $q.dialog({
        title: i18n.t("right.dialogs.titles.confirm"),
        message: i18n.t("right.dialogs.messages.delete"),
        cancel: true,
        persistent: true,
      }).onOk(() => {
        deleteRight(rightId).then(() => {
          $q.notify(i18n.t("right.messages.deleted"));
          router.push({ name: RoutesName.Rights });
        });
      });
    },
  };
  return { ...toRefs(state), actions };
});
