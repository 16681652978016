import type { CrudServiceInstance } from "@/types/crud";
import type { UserDto, UserLightDto } from "@/types/api";
import type { UserServiceInstance } from "@/types/user";
import { CrudService } from "@/services/CrudService";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { AxiosResponse } from "axios";
import { FrenchLanguage } from "@/constants";

/**
 * Service pour récupérer la liste des utilisateurs
 */
class UserService extends AxiosRequest implements UserServiceInstance {
  crudService: CrudServiceInstance<UserDto>;
  /**
   * Constructeur de la class User service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<UserDto>(rootPath);
  }
  /**
   * Récupère la liste des utilisateurs
   */
  getList(withDeleted: boolean, withDisabled: boolean) {
    if (withDeleted && withDisabled) {
      return this.axiosInstance
        .get(`${this.rootPath}/full`)
        .then((response: AxiosResponse<UserDto[]>) => {
          if (response.status !== 200) return [];
          return response.data;
        })
        .catch(this.handleError);
    } else if (withDisabled) {
      return this.axiosInstance
        .get(`${this.rootPath}/withDisabled`)
        .then((response: AxiosResponse<UserDto[]>) => {
          if (response.status !== 200) return [];
          return response.data;
        })
        .catch(this.handleError);
    } else return this.crudService.getList();
  }

  /**
   * Récupère la liste des utilisateurs
   */
  getUsersLight() {
    return this.axiosInstance
      .get(`${this.rootPath}/light`, {
        cache: {
          methods: ["get"],
        },
      })
      .then((response: AxiosResponse<UserLightDto[]>) => {
        if (response.status !== 200) return [];
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   * Ajoute un profile à un utilisateur
   * @param id - id de l'utilisateur
   * @param profileId - id du profile a ajouter
   */
  addProfile(id: string, profileId: string) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/profile/${profileId}`)
      .then((response) => {
        if (response.status === 200) return Promise.resolve(true);
        return Promise.reject(false);
      })
      .catch(this.handleError);
  }
  /**
   * Insert un utilisateur
   * @param user - l'utilisateur
   */
  insertUser(user: UserLightDto) {
    return this.axiosInstance
      .post(`${this.rootPath}`, user)
      .then((response) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   * Met a jour un utilisateur
   * @param user - l'utilisateur
   */
  updateUser(user: UserLightDto) {
    return this.axiosInstance
      .put(`${this.rootPath}/${user.id}`, user)
      .then((response) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   * Retire un profile à un utilisateur
   * @param id - id de l'utilisateur
   * @param profileId - id du profile a retirer
   */
  removeProfile(id: string, profileId: string) {
    return this.axiosInstance
      .delete(`${this.rootPath}/${id}/profile/${profileId}`)
      .then((response) => {
        if (response.status === 200) return Promise.resolve(true);
        return Promise.reject(false);
      })
      .catch(this.handleError);
  }
}

/**
 * Fonction pour créer un object type UserDto
 */
export function createUser(): UserDto {
  return {
    id: "",
    idKeycloak: undefined,
    idBackOffice: undefined,
    signature: undefined,
    firstName: "",
    lastName: "",
    email: "",
    login: "",
    name: "",
    editor: [],
    redactor: [],
    translator: [],
    freelance: [],
    language: FrenchLanguage,
    allRights: {},
    lastAuthentification: 0,
    profiles: [],
    deleted: false,
    addDate: new Date().toISOString(),
    enabled: true,
  };
}

export const userService = new UserService("users");
