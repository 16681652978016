import { i18n } from "@/i18n";
// Importation du plugin Dialog de Quasar
import { Dialog } from "quasar";

/**
 * Affiche un message indiquant que nouvelle version est dispo et recharge la page
 */
export function showReloadFrontRequiredDialog() {
  Dialog.create({
    title: i18n.t(`messageDialog.title.updateElcano`),
    message: i18n.t(`messageDialog.content.updateElcano`),
    ok: i18n.t(`messageDialog.actions.updateElcanoOkButton`),
    html: true,
  }).onOk(() => {
    window.location.reload();
  });
}

/**
 * Affiche un message indiquant que l'API est indisponible et recharge la page
 */
export function showAPIUnevailableDialog() {
  Dialog.create({
    title: i18n.t(`messageDialog.title.ApiUnavailable`), // Titre de la dialog
    message: i18n.t(`messageDialog.content.ApiUnavailable`),
    ok: i18n.t(`messageDialog.actions.ApiUnavailableOkButton`),
    html: true,
  }).onOk(() => {
    window.location.reload();
  });
}
