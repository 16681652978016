import type { QSelectOption } from "quasar";
import type {
  ArticleDto,
  ArticleSectionDto,
  ArticleContentDto,
  ArticleSettingDto,
  ArticleContentODataDto,
  CountryDto,
} from "@/types/api";
import { date } from "quasar";
import { storeToRefs } from "pinia";
import { useSectionStore } from "@/stores/section";
import { useFormatStore } from "@/stores/format";
import { useArticleSettingStore } from "@/stores/articleSetting";
import { usePublicationStore } from "@/stores/publication";
import { useConfigStore } from "@/stores/config";
import { useUserStore } from "@/stores/user";
import { formatsPrimaries } from "@/helpers/format";
import { SettingsType, ContributionType, WorkflowState } from "@/types/api";
import {
  useLocale,
  downcastDatetime,
  upcastDatetime,
  formatDate,
} from "@/utils";
import {
  EnglishLanguage,
  FrenchLanguage,
  conf_facility_current,
} from "@/constants";

/**
 * Fonction pour récupérer la rubrique principale d'un article
 */
export function getMainSection(
  sections: ArticleSectionDto[],
  withParent = false
) {
  const { sections: listSection } = useSectionStore();
  const articleMainSectionFound = sections.find((sec) => sec.main === true);

  if (!articleMainSectionFound) return;

  let mainSectionFound = listSection.find(
    (s) => s.id === articleMainSectionFound.section
  );
  if (!mainSectionFound) return;
  if (withParent && mainSectionFound.idParent) {
    mainSectionFound = listSection.find(
      (s) => s.id === mainSectionFound?.idParent
    );
  }
  return mainSectionFound;
}

/**
 * Fonction pour récupérer les rubriques secondaires d'un article
 */
export function getSecondarySections(sections: ArticleSectionDto[]) {
  const { sections: listSection } = useSectionStore();
  const secondarySections = sections.filter((sec) => sec.main === false);

  if (secondarySections.length === 0) return [];

  return secondarySections
    .map((s) => listSection.find((section) => section.id === s.section))
    .filter((sec) => sec !== undefined);
}

/**
 * Fonction pour récupérer le contenu principale de l'article
 */
export function getMainContent(article: ArticleDto) {
  return (
    getArticleContentByLanguage(article.contents, article.idMainLanguage) ??
    article.contents[0]
  );
}

/**
 * Fonction pour récupérer le contenu de l'article en fonction de la langue
 */
export function getArticleContentByLanguage<
  C extends ArticleContentDto | ArticleContentODataDto,
>(contents: C[], language: string): C | undefined {
  return contents.find((content) => content.language === language);
}

/**
 * Fonction pour récupérer le format dans la langue spécifiée en param.
 */
export function getLabelFormatByLanguage(
  content: ArticleContentDto | ArticleContentODataDto,
  language: string
) {
  const { formats } = useFormatStore();

  const formatContent = formats.find((f) => f.id == content.format);

  return language.startsWith("fr")
    ? formatContent?.labelFr
    : formatContent?.labelEn;
}
/**
 * Méthode qui sert à récupérer la liste des paramètres article pour être utiliser dans un dropdown
 * @param byType - argument pour filtrer les paramètres article par type
 * @param allOption - argument pour avoir dans le sélecteur une option tous les paramètres article
 */
export function getArticleSettingsOptions(
  byType?: SettingsType,
  allOption = true,
  onlyActive = true
) {
  const { articleSettings, filterArtSettingsByType } = useArticleSettingStore();
  const { isFr } = useLocale();
  let list: ArticleSettingDto[];
  if (byType) list = filterArtSettingsByType(byType);
  else list = articleSettings;

  list = list.filter((s) => !onlyActive || s.active === onlyActive);

  return [
    allOption ? { value: "", label: "Tous" } : undefined,
    ...list.map((settings) => ({
      value: settings,
      label: isFr.value ? settings.labelFr : settings.labelEn,
    })),
  ].filter(Boolean) as QSelectOption<ArticleSettingDto>[];
}
/**
 * Méthode qui sert à récupérer le lien de l'article sur nos sites finaux
 * @param articleContents - le contenu de l'article
 * @param language - la langue du site final
 * @param addIgnoreValideParam - argument pour ajouter le paramètre ignorevalide dans le lien de l'article
 */
export function getLinkArticleWebsite(
  articleContents: (ArticleContentDto | ArticleContentODataDto)[],
  language: string,
  addIgnoreValideParam: boolean
) {
  let lienArticle = getArticleContentByLanguage(articleContents, language)
    ?.articleUrl;
  if (
    addIgnoreValideParam &&
    getArticleContentByLanguage(articleContents, language)?.online === false
  ) {
    lienArticle += "?ignorevalide=1&force=1";
  }

  if (conf_facility_current == "PROD") return lienArticle;
  else return lienArticle?.replace("//www.", "//3.test.");
}

/**
 * Fonction qui retourne les informations de l'article
 */
export function getInfoArticle(
  article: ArticleDto,
  localeSelected: string[] | null = null
) {
  // Déclaration des constantes
  const { publications } = usePublicationStore();
  const { language } = useLocale();
  const maskDateDisplay = "DD/MM/YYYY";
  const maskDateDisplayMini = "DD/MM/YY";
  const mainSection = getMainSection(article.sections);
  const secondarySections = getSecondarySections(article.sections);
  const publicationName = publications.find((p) => p.id == article.publication)
    ?.name;
  const publicationDate = date.formatDate(article.publishedOn, maskDateDisplay);
  //Creer une const localDisplay que test si localeSelected est null ou vide et si oui retourne la valeur de language sinon retourne la valeur de localeSelected
  const localDisplay =
    localeSelected == null || localeSelected.length > 1
      ? language.value
      : localeSelected[0];

  const publicationDateMini = date.formatDate(
    article.publishedOn,
    maskDateDisplayMini
  );
  const linkArticleWebsite = getLinkArticleWebsite(
    article.contents,
    localDisplay,
    true
  );
  const linkArticleWebsiteWithoutIgnoreValide = getLinkArticleWebsite(
    article.contents,
    localDisplay,
    false
  );

  let publication = article.publication;
  switch (article.publication) {
    case "GL":
      publication = "Glitz";
      break;
    case "LLA":
      publication = "LL";
      break;
  }
  const linkToArticleLabelWithLink =
    localDisplay === FrenchLanguage
      ? `(${publication} du <a href="${linkArticleWebsite}">${publicationDateMini}</a>)`
      : `(${publication} <a href="${linkArticleWebsite}">${publicationDateMini}</a>)`;
  const linkToArticleLabel = `(${publication} ${publicationDateMini})`;
  const linkToArticleLabelWithLinkWithoutIgnoreValide =
    localDisplay === FrenchLanguage
      ? `(${publication} du <a href="${linkArticleWebsiteWithoutIgnoreValide}">${publicationDateMini}</a>)`
      : `(${publication} <a href="${linkArticleWebsiteWithoutIgnoreValide}">${publicationDateMini}</a>)`;

  return {
    mainSection,
    secondarySections,
    publicationName,
    publicationDate,
    localDisplay,
    linkToArticleLabel,
    linkToArticleLabelWithLink,
    linkArticleWebsite,
    linkToArticleLabelWithLinkWithoutIgnoreValide,
  };
}
/**
 *
 */
export function getNextWeek(currentDate = new Date()) {
  const distanceInWeek = getDistanceInWeek(currentDate, 1);
  const startDate = currentDate.addDays(distanceInWeek + 7);
  const endDate = startDate.addDays(5);
  return [startDate.toLocaleISOString(), endDate.toLocaleISOString()];
}
/**
 *
 */
export function getPreviousWeek(currentDate = new Date()) {
  const distanceInWeek = getDistanceInWeek(currentDate, 1);
  const startDate = currentDate.addDays(distanceInWeek - 7);
  const endDate = startDate.addDays(5);
  return [startDate.toLocaleISOString(), endDate.toLocaleISOString()];
}

/**
 *
 */
export function getDistanceInWeek(currentDate, dayOfWeek: number) {
  const currentDayOfWeek = date.getDayOfWeek(currentDate);
  return dayOfWeek - currentDayOfWeek;
}

/**
 * Retourne la date de la prochaine édition.
 */
export function getNextEditionDay(
  currentDate = new Date(),
  publication: string | null = null
) {
  const { config } = storeToRefs(useConfigStore());
  const dayOfWeek = date.getDayOfWeek(currentDate);

  publication = publication ?? config.value.publication;

  let distanceInWeek = 0;

  if (publication !== "GL") {
    // jour suivant ou lundi suivant
    distanceInWeek = 1;
    if (dayOfWeek >= 5) distanceInWeek = 8 - dayOfWeek;
  } else {
    // Le jour de la semaine pour la publication Glitz est 4 / jeudi
    distanceInWeek = 4 - dayOfWeek;
    if (distanceInWeek <= 0) distanceInWeek += 7;
  }

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + distanceInWeek
  );
}
/**
 *
 */
export function getPreviousEditionDay(currentDate = new Date()) {
  const { config } = storeToRefs(useConfigStore());
  const dayOfWeek = date.getDayOfWeek(currentDate);

  let distanceInWeek = 0;

  if (config.value.publication !== "GL") {
    distanceInWeek = -1;
    if (dayOfWeek === 1) distanceInWeek = -3;
  } else {
    // Le jour de la semaine pour la publication Glitz est 4 / jeudi
    distanceInWeek = 4 - dayOfWeek;
    if (distanceInWeek >= 0) distanceInWeek -= 7;
  }

  return new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() + distanceInWeek
  );
}
/**
 *
 */
export function getContributorsName(
  article: ArticleDto,
  contributorType: ContributionType
) {
  const { usersLight } = useUserStore();
  const contributors = article.contributions.filter(
    (c) => c.type == contributorType
  );
  return contributors
    .map((c) => {
      return usersLight.find((u) => u.id === c.idContributor.toString())?.name;
    })
    .filter(Boolean);
}
/**
 * Récupère les auteurs de l'article
 */
export function getAuthorsName(article: ArticleDto) {
  return getContributorsName(article, ContributionType.Author);
}
/**
 * Récupère les traducteurs de l'article
 */
export function getTranslatorsName(article: ArticleDto) {
  return getContributorsName(article, ContributionType.Translator);
}
/**
 * Récupère les freelances de l'article
 */
export function getFreelancesName(article: ArticleDto) {
  return getContributorsName(article, ContributionType.Freelancer);
}
/**
 * Permet de savoir si l'article doit être traduit
 */
export function needToBeTranslated(article: ArticleDto, language: string) {
  if (article.publication === "LLA") return false;
  const translationLanguage = getTranslationLanguage(article);
  if (translationLanguage) return false;

  const content = getArticleContentByLanguage(article.contents, language);
  if (!content) return false;
  if (content.idBackOffice) return true;

  const stateDoesntNeedToBeTranslated = [
    WorkflowState.Pitch,
    WorkflowState.Redaction,
    WorkflowState.Relecture,
    WorkflowState.Correction,
  ];
  return !stateDoesntNeedToBeTranslated.includes(content.state);
}
/**
 *
 */
export function getTranslationLanguage(article: ArticleDto) {
  const language =
    article.idMainLanguage == FrenchLanguage ? EnglishLanguage : FrenchLanguage;
  const translation = getArticleContentByLanguage(article.contents, language);
  if (!translation) return undefined;
  return language;
}
/**
 *
 */
export function getCountryLabel(country: CountryDto, lang: string) {
  return lang.startsWith("fr") ? country.labelFr : country.labelEn;
}
/**
 *
 */
export function getSectionLabel(article: ArticleDto) {
  const section = getMainSection(article.sections, true);
  if (!section) return "";
  const { isFr } = useLocale();
  return isFr.value ? section.labelFr : section.labelEn ?? section.labelFr;
}
/**
 * Fonction qui retourne le premier article avec le format principal
 * @param {ArticleDto} articles
 * @param {string} date - Attention la date doit être au format YYYY/MM/DD
 * @param {string} language
 */
export function getArticlePrimary(
  articles: ArticleDto[],
  dateDay: string,
  language: string
) {
  const getDatePublishedOnFormat = (article: ArticleDto) => {
    let date = article.summaryOf
      ? formatDate(article.summaryOf)
      : formatDate(article.publishedOn);
    if (language != article.idMainLanguage) {
      if (article.publishedOnTranslation)
        date = formatDate(article.publishedOnTranslation);
      if (article.summaryOfTranslation)
        date = formatDate(article.summaryOfTranslation);
    }
    return date;
  };

  const filteredArticles = articles.filter(
    (article) => getDatePublishedOnFormat(article) === dateDay
  );
  const mappedArticles = filteredArticles.map(({ contents, ...article }) => ({
    ...article,
    contents,
    content: contents.find((content) => content.language == language),
  }));
  const primaries = mappedArticles.filter(
    ({ content }) => content && content?.format in formatsPrimaries
  );
  const sortedPrimaries = primaries.sort(
    (a, b) =>
      formatsPrimaries[a.content!.format] - formatsPrimaries[b.content!.format]
  );
  return sortedPrimaries[0];
}

/**
 * Fonction pour transformer les dates provenant du server.
 * @param date Date
 */
export function downcastArticleDto(article: ArticleDto) {
  article.deadline = downcastDatetime(article.deadline);
  article.publishedOn = downcastDatetime(article.publishedOn);
  article.publishedOnTranslation = downcastDatetime(
    article.publishedOnTranslation
  );
  article.summaryOf = downcastDatetime(article.summaryOf);
  article.summaryOfTranslation = downcastDatetime(article.summaryOfTranslation);
  return article;
}

/**
 * Fonction pour transformer les dates a destination du server.
 * @param date Date
 */
export function upcastArticleDto(article: ArticleDto) {
  article.deadline = upcastDatetime(article.deadline);
  article.publishedOn = upcastDatetime(article.publishedOn);
  article.publishedOnTranslation = upcastDatetime(
    article.publishedOnTranslation
  );
  article.summaryOf = upcastDatetime(article.summaryOf);
  article.summaryOfTranslation = upcastDatetime(article.summaryOfTranslation);
  return article;
}
