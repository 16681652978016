import type dxDataGrid from "devextreme/ui/data_grid";
import type { Column } from "devextreme/ui/data_grid";
import type { ArticleODataDto } from "@/types/api";
import { date, useQuasar } from "quasar";
import { computed, reactive, toRefs } from "vue";
import { getNextEditionDay } from "@/helpers/article";
import { useLocale, getDateWithNbDays } from "@/utils";
import { i18n } from "@/i18n";

/**
 * Interface du datagrid devextreme instancié
 */
type DataGrid = {
  instance?: dxDataGrid;
};

const isFeuilletonView = (formats: string[]) =>
  formats.length === 1 && formats[0] === "Feuilleton";

const sortType = (period: boolean) => {
  return period ? "asc" : "desc";
};

/**
 *
 */
export function useListArticles({
  fridge,
  period,
  startDate,
  endDate,
  language,
  formats,
}: {
  fridge: boolean;
  period: boolean;
  startDate: string;
  endDate: string;
  language: string;
  formats: string[];
}) {
  const $q = useQuasar();
  // Permet de simuler un autre jour dans la semaine pour les tests
  // 0 aujourd'hui, 1 demain, -1 hier
  const offsetDay = 0;
  const startDateDefault =
    startDate ||
    getNextEditionDay(new Date().addDays(offsetDay)).toLocaleISOString();
  const state = reactive<{
    datagrid: DataGrid;
    startDate: string;
    endDate: string;
    language: string;
    formats: string[];
    period: boolean;
    fridge: boolean;
  }>({
    datagrid: {},
    language,
    formats,
    fridge,
    period,
    startDate: startDateDefault,
    endDate: endDate || getDateWithNbDays(new Date(startDateDefault), 1),
  });
  const { isFr } = useLocale();

  const columns = computed<Column<ArticleODataDto, "id">[]>(() => {
    return [
      {
        dataField: "id",
        hidingPriority: 3,
        name: "id",
        caption: i18n.t("article.fields.id"),
        dataType: "number",
        filterOperations: ["="],
        allowHeaderFiltering: false,
        allowGrouping: false,
        visible: false,
      },
      {
        dataField: "contents.format",
        hidingPriority: 5,
        name: `idFormat`,
        dataType: "string",
        caption: i18n.t("article.fields.format"),
        visible: !isFeuilletonView(state.formats),
        allowHeaderFiltering: false,
        allowFiltering: false,
        allowSearch: false,
        allowSorting: false,
        minWidth: 50,
        cellTemplate: "cellFormatTemplate",
        calculateCellValue(rowData) {
          return rowData;
        },
      },
      {
        dataField: "contents.title",
        name: "title",
        caption: i18n.t("article.fields.title"),
        minWidth: 150,
        filterOperations: ["contains"],
        cellTemplate: "cellTitleTemplate",
        allowHeaderFiltering: false,
        allowGrouping: false,
        allowSorting: false,
        allowHiding: false,
        calculateCellValue(rowData) {
          return rowData;
        },
        calculateFilterExpression(filterValue: string) {
          return [
            `contents/any(c: contains(tolower(c/title), '${filterValue.toLowerCase()}'))`,
          ];
        },
      },
      {
        dataField: "contents.standfirst",
        name: "standfirst",
        caption: i18n.t("article.fields.standfirst"),
        visible: false,
        allowSearch: false,
        allowFiltering: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        cellTemplate: "cellStandfirstTemplate",
        calculateCellValue(rowData) {
          return rowData;
        },
      },
      {
        dataField: "contents.shortTitle",
        hidingPriority: 4,
        name: "shortTitle",
        caption: i18n.t("article.fields.shortTitle"),
        visible: isFeuilletonView(state.formats),
        allowSearch: false,
        allowFiltering: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        cellTemplate: "cellShortTitleTemplate",
        calculateCellValue(rowData) {
          return rowData;
        },
      },
      {
        dataField: "contributions",
        hidingPriority: 6,
        visible: !isFeuilletonView(state.formats),
        name: "contributions",
        caption: i18n.t("articleContribution.fields.contributors"),
        cellTemplate: "cellContributionsTemplate",
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false,
        filterOperations: ["contains"],
        calculateCellValue(rowData) {
          return rowData;
        },
      },
      {
        dataField: "publicationName",
        hidingPriority: 0,
        name: "publicationName",
        dataType: "string",
        caption: i18n.t("article.fields.publication"),
        visible: false,
        allowHeaderFiltering: false,
        allowFiltering: true,
        allowSearch: false,
        filterOperations: ["=", "contains", "startswith", "endswith"],
        calculateCellValue(rowData) {
          return rowData.publicationName ?? "";
        },
        calculateFilterExpression(filterValue: string, operation: string) {
          return [
            ["tolower(publicationName)", operation, filterValue.toLowerCase()],
            "or",
            ["tolower(idPublication)", operation, filterValue.toLowerCase()],
          ];
        },
      },
      {
        dataField: "sectionsAndCountries",
        hidingPriority: 5,
        name: "sectionsAndCountries",
        caption: i18n.t("article.fields.sectionsAndCountries"),
        allowSorting: false,
        allowSearch: true,
        allowFiltering: false,
        cellTemplate: "cellSectionsCountriesTemplate",
        filterOperations: ["contains"],
        calculateCellValue(rowData) {
          return rowData;
        },
        calculateFilterExpression(filterValue: string) {
          return [
            [
              `sections/any(s: (contains(tolower(s/labelFr), '${filterValue.toLowerCase()}') or contains(tolower(s/labelEn), '${filterValue.toLowerCase()}')))`,
            ],
            "or",
            [
              `countries/any(s: (contains(tolower(s/labelFr), '${filterValue.toLowerCase()}') or contains(tolower(s/labelEn), '${filterValue.toLowerCase()}')))`,
            ],
          ];
        },
      },
      {
        dataField: "sections",
        hidingPriority: 5,
        name: "sections",
        caption: i18n.t("article.fields.sections"),
        visible: false,
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false,
        cellTemplate: "cellSectionsTemplate",
        filterOperations: ["contains"],
        calculateCellValue(rowData) {
          return rowData;
        },
        calculateFilterExpression(filterValue: string) {
          return [
            `sections/any(s: (contains(tolower(s/labelFr), '${filterValue.toLowerCase()}') or contains(tolower(s/labelEn), '${filterValue.toLowerCase()}')))`,
          ];
        },
      },
      {
        dataField: "mainSection",
        hidingPriority: 5,
        name: "mainSection",
        caption: i18n.t("article.fields.mainSection"),
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false,
        filterOperations: ["contains"],
        visible: false,
        calculateCellValue(rowData) {
          if (!rowData.sections) return;
          if (rowData.sections.length === 0) return "";
          const section = rowData.sections.find((s) => s.main);
          const label = isFr.value ? section?.labelFr : section?.labelEn;
          return section ? label : "";
        },
        calculateFilterExpression(filterValue: string) {
          return [
            `sections/any(s: s/main eq true and (contains(tolower(s/labelFr), '${filterValue.toLowerCase()}') or contains(tolower(s/labelEn), '${filterValue.toLowerCase()}')))`,
          ];
        },
      },
      {
        dataField: "secondariesSection",
        hidingPriority: 5,
        name: "secondariesSection",
        caption: i18n.t("article.fields.secondariesSections"),
        cellTemplate: "cellSectionsSecondariesTemplate",
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false,
        filterOperations: ["contains"],
        visible: false,
        calculateCellValue(rowData) {
          return rowData;
        },
        calculateFilterExpression(filterValue: string) {
          return [
            `sections/any(s: s/main eq false and (contains(tolower(s/labelFr), '${filterValue.toLowerCase()}') or contains(tolower(s/labelEn), '${filterValue.toLowerCase()}')))`,
          ];
        },
      },
      {
        dataField: "countries",
        hidingPriority: 5,
        name: "countries",
        caption: i18n.t("article.fields.countries"),
        cellTemplate: "cellCountriesTemplate",
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false,
        visible: false,
        filterOperations: ["contains"],
        calculateSortValue(rowData) {
          return rowData.countries.map((country) =>
            isFr.value ? country.labelFr : country.labelEn
          );
        },
        calculateCellValue(rowData) {
          return rowData;
        },
        calculateFilterExpression(filterValue: string) {
          return [
            `countries/any(c: contains(tolower(c/labelFr), '${filterValue.toLowerCase()}') or contains(tolower(c/labelEn), '${filterValue.toLowerCase()}') or contains(tolower(c/isoCode), '${filterValue.toLowerCase()}'))`,
          ];
        },
      },

      {
        dataField: "contents.state",
        name: "state",
        caption: i18n.t("article.fields.state"),
        cellTemplate: "cellStateTemplate",
        allowSearch: false,
        allowFiltering: false,
        allowHiding: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        width: 130,
        calculateCellValue(rowData) {
          return rowData;
        },
      },
      {
        dataField: "contents.online",
        hidingPriority: 10,
        name: "online",
        caption: i18n.t("article.fields.online"),
        cellTemplate: "cellOnlineTemplate",
        allowSearch: false,
        allowFiltering: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        alignment: "center",
        calculateCellValue(rowData) {
          return rowData;
        },
      },
      {
        dataField: "wordsignscount",
        hidingPriority: 5,
        name: "wordsignscount",
        caption: i18n.t("article.fields.signAndWords"),
        cellTemplate: "cellWordSignCountTemplate",
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false,
        visible: !isFeuilletonView(state.formats),
        filterOperations: ["contains"],
        calculateCellValue(rowData) {
          return rowData;
        },
        calculateFilterExpression(filterValue: string) {
          return [
            `contents/any(c: contains(tolower(c/wordsignscount), '${filterValue.toLowerCase()}'))`,
          ];
        },
      },
      {
        dataField: "publishedOn",
        hidingPriority: 4,
        allowFiltering: false,
        name: "publishedOn",
        caption: i18n.t("article.fields.publishedOn"),
        dataType: "date",
        format: "shortDate",
        sortOrder: sortType(state.period),
        sortIndex: 0,
        visible: isFeuilletonView(state.formats),
        groupIndex: !isFeuilletonView(state.formats) ? 0 : undefined,
      },
      {
        dataField: "lastUpdate",
        hidingPriority: 4,
        allowFiltering: false,
        visible: false,
        name: "lastUpdate",
        caption: i18n.t("article.fields.lastModification"),
        dataType: "date",
        format: "shortDate",
      },
      {
        dataField: "nbAssociatedArticles",
        hidingPriority: 4,
        allowFiltering: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        visible: isFeuilletonView(state.formats),
        name: "nbAssociatedArticles",
        caption: i18n.t("article.fields.nbAssociatedArticles"),
        alignment: "left",
        calculateCellValue(rowData) {
          if (!rowData.associatedArticles) return;
          return rowData.associatedArticles.length;
        },
      },
      {
        dataField: "score",
        hidingPriority: 4,
        allowFiltering: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        visible: false,
        name: "score",
        caption: i18n.t("article.fields.score"),
        alignment: "left",
        sortOrder: "desc",
        sortIndex: 1,
        dataType: "number",
      },
      {
        dataField: "dateLastAssociatedArticle",
        hidingPriority: 4,
        allowFiltering: false,
        allowHeaderFiltering: false,
        allowSorting: false,
        visible: isFeuilletonView(state.formats),
        name: "dateLastAssociatedArticle",
        caption: i18n.t("article.fields.lastAssociatedArticle"),
        alignment: "left",
        calculateCellValue(rowData) {
          if (!rowData.associatedArticles) return;
          if (rowData.associatedArticles.length === 0) return "";
          const lastDate = rowData.associatedArticles
            .filter((art) => art.publishedOn)
            .sort((a, b) => {
              return (
                new Date(b.publishedOn as string).getTime() -
                new Date(a.publishedOn as string).getTime()
              );
            })[0].publishedOn;
          const defaultMask = "DD/MM/YYYY";
          return date.formatDate(lastDate, defaultMask);
        },
      },
      {
        dataField: "actions",
        name: "actions",
        caption: i18n.t("article.fields.actions"),
        visible: true,
        fixed: $q.screen.gt.sm,
        width: 180,
        fixedPosition: "right",
        allowHiding: false,
        allowEditing: false,
        allowReordering: false,
        allowGrouping: false,
        allowResizing: false,
        allowSorting: false,
        allowSearch: false,
        allowFiltering: false,
        cellTemplate: "cellActionsTemplate",
        calculateCellValue(rowData) {
          return rowData;
        },
      },
    ] as Column<ArticleODataDto, "id">[];
  });

  return {
    ...toRefs(state),
    columns,
  };
}
