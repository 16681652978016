<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', profile)">
    <q-input v-model="profile.id" filled :label="$t('profile.fields.id')" />
    <q-input
      v-model="profile.labelFr"
      filled
      :label="$t('profile.fields.labelFr')"
    />
    <q-input
      v-model="profile.labelEn"
      filled
      :label="$t('profile.fields.labelEn')"
    />
    <q-btn
      v-if="profile.initialConfig === false"
      :label="$t('profile.labels.btnAddAssignment')"
      color="primary"
      @click="showDialogAddAssignment = true"
    />
    <ListProfileAssignment v-model="profile.profileAssignments" />
    <div v-if="profile.initialConfig === false" class="q-mt-md text-right">
      <q-btn :label="labelBtnSubmit" type="submit" color="primary" />
    </div>
    <DialogAddAssignment
      v-model="showDialogAddAssignment"
      :selected="assignments"
      @add:assignments="onNewAssignments"
    />
  </q-form>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import type { ProfileDto } from "@/types/api";
import { ref, computed, toRefs } from "vue";
import {
  createProfile,
  createProfileAssignment,
} from "@/services/ProfileService";
import { i18n } from "@/i18n";
import DialogAddAssignment from "@/components/Assignment/DialogAddAssignment.vue";
import ListProfileAssignment from "./ListProfileAssignment.vue";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("profile.labels.btnEdit");
    },
  },
  defaultProfile: {
    type: Object as PropType<ProfileDto>,
    default() {
      return createProfile();
    },
  },
});
defineEmits<{
  (e: "submit", value: ProfileDto): void;
}>();
const { defaultProfile: profile } = toRefs(props);
const assignments = computed<string[]>(() =>
  profile.value.profileAssignments
    .map((p) => p.idAssignment ?? undefined)
    .filter(Boolean)
);
const showDialogAddAssignment = ref(false);

/**
 * Fonction appelé lors du choix des assignments associés
 */
function onNewAssignments(newAssignments: string[]) {
  // On retire les profileAssignments qui ont été déselectionnés
  profile.value.profileAssignments = profile.value.profileAssignments.filter(
    (pr) => newAssignments.indexOf(pr.idAssignment) >= 0
  );
  // On rajoute les profileAssignments qui ont été sélectionnés
  newAssignments
    .filter(
      (assignment) =>
        profile.value.profileAssignments.findIndex(
          (pr) => pr.idAssignment === assignment
        ) < 0
    )
    .forEach((assignment) =>
      profile.value.profileAssignments.push(
        createProfileAssignment({ idAssignment: assignment })
      )
    );
}
</script>
