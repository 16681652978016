<template>
  <q-dialog v-model="value">
    <q-card style="width: 700px; max-width: 80vw">
      <q-card-section>
        <q-select
          v-model="reviewerId"
          :options="contributors"
          :label="$t('contributionType.Reviewer')"
          emit-value
          multiple
          map-options
        />
        <q-input
          v-if="reviewerId.length === 1"
          v-model="message"
          :label="$t('reviewerMessage.message')"
          type="textarea"
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          flat
          :disable="saving"
          :label="$t('languages.labels.btnCancel')"
          color="primary"
          @click="value = false"
        />
        <q-btn
          flat
          :disable="saving || reviewerId.length === 0"
          :label="$t('languages.labels.btnOk')"
          :loading="saving"
          color="primary"
          @click="triggerReview"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useQuasar, QSelectOption, EventBus } from "quasar";
import {
  ArticleContentDto,
  ArticleDto,
  ContributionType,
  WorkflowTrigger,
} from "@/types/api";
import { computed, inject, ref } from "vue";
import { formatErrorMessage } from "@/utils";
import { useTriggers } from "@/composables/useTriggers";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { getContributorOptions } from "@/helpers/contributor";
const currentArticleStore = useCurrentArticleStore();
const bus = inject<EventBus>("bus");
const { actions } = useTriggers(bus);

const $q = useQuasar();
const props = defineProps<{
  modelValue: boolean;
  article: ArticleDto;
  articleContent: ArticleContentDto;
}>();

const emits = defineEmits<{
  (e: "update:modelValue", value: boolean): void;
}>();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emits("update:modelValue", value);
  },
});

const contributors = computed<QSelectOption<string>[]>(() => {
  return getContributorOptions({
    publication: props.article.publication,
    type: ContributionType.Reviewer,
  });
});

const saving = ref<boolean>(false);
const reviewerId = ref<string[]>([]);
const message = ref<string>("");

/**
 * Fonction appelé au moment d'execution l'action relire
 */
async function triggerReview() {
  try {
    saving.value = true;

    actions.sendTrigger(
      {
        id: props.article.id,
        lang: props.articleContent.language,
        trigger: WorkflowTrigger.Relire,
        xmin: props.articleContent.xmin,
        userIds: reviewerId.value,
        message: reviewerId.value.length === 1 ? message.value : undefined,
      },
      async (newState) => {
        if (newState === undefined) {
          saving.value = false;
          return;
        }

        await currentArticleStore.refreshArticle();
        saving.value = false;
        value.value = false;
      },
      reviewerId.value.length === 1 ? "" : "WithoutNotification"
    );
  } catch (e: unknown) {
    saving.value = false;

    const message = formatErrorMessage(e, "article.errors.onSave");
    $q.notify({
      type: "warning",
      message,
    });
  }
}
</script>
