import type { AccountState } from "@/types/account";
import { defineStore } from "pinia";
import { useUserStore } from "@/stores/user";
import { authService } from "@/services/AuthService";

export const useAccountStore = defineStore({
  id: "account",
  state: (): AccountState => ({
    userProfile: null,
    assignments: [],
  }),
  getters: {
    userId: (state) => {
      const userStore = useUserStore();
      const user = userStore.users.find(
        (u) => u.idKeycloak === state.userProfile?.id
      );
      if (user) return user.id;
      return null;
    },
  },
  actions: {
    async fetchUserProfile() {
      this.userProfile = await authService.getUserProfile();
    },
    fetchAssignments() {
      this.assignments = authService.getAssignments();
    },
  },
});
