import type { QSelectOption } from "quasar";
import type { FormatDto } from "@/types/api";
import { useFormatStore } from "@/stores/format";
import { useLocale } from "@/utils";

/**
 * Interface FormatParams
 */
interface FormatParams {
  publication?: string;
  formatActive?: boolean;
  allOption?: boolean;
  idFormatToAdd?: string;
}

export const formatsPrimaries = {
  Evenement: 1,
  Enquete: 2,
  Entourage: 3,
  "Maitre Espion": 4,
};

/**
 * Méthode qui sert à récupérer la liste des formats pour être utiliser dans un dropdown
 * @param params.publication - argument optionnel pour filtrer par publication
 * @param params.formatActive - argument pour récupérer tous les formats ou seul ceux actifs
 * @param params.allOption - argument pour avoir dans le sélecteur une option tous les formats
 * @param params.idFormatToAdd - argument pour ajouter un format dans la liste via son id
 */
export function getFormatOptions(params: FormatParams = {}) {
  const {
    publication,
    formatActive = true,
    allOption = true,
    idFormatToAdd,
  } = params;
  const { filterFormatsByPublication, formats } = useFormatStore();
  const { isFr } = useLocale();
  let list: FormatDto[];
  if (publication) list = filterFormatsByPublication(publication, formatActive);
  else list = formats;

  if (formatActive) list = list.filter((format) => format.active);

  if (idFormatToAdd && !list.some((s) => s.id === idFormatToAdd)) {
    const formatFound = formats.find((f) => f.id === idFormatToAdd);
    if (formatFound) list.push(formatFound);
  }

  return [
    allOption ? { value: "", label: "Tous" } : undefined,
    ...list.map((format) => ({
      value: format.id,
      label: isFr.value ? format.labelFr : format.labelEn,
    })),
  ].filter(Boolean) as QSelectOption<string>[];
}
