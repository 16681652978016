import { Notify, Dialog, Loading, Meta } from "quasar";
import quasarLang from "quasar/lang/fr";

export const quasarOptions = {
  plugins: {
    Notify,
    Dialog,
    Loading,
    Meta,
  },
  config: {
    dark: "auto" as const,
  },
  lang: quasarLang,
};
