import type { SectionDto } from "@/types/api";
import type { SectionViewActions } from "@/types/section";
import { toRefs, reactive } from "vue";
import { useRouter } from "vue-router";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { createSection } from "@/services/SectionService";
import { useSectionStore } from "@/stores/section";
import { RoutesName } from "@/router/routesName";
import { usePage } from "@/composables/usePage";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions rubrique
 */
interface SectionActionState {
  section: SectionDto;
  callbackOnSubmit: ((sectionData: SectionDto) => void) | undefined;
}

/**
 * Fonction servant à interagir sur les différentes vues rubrique
 */
export const useSectionActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { showPanelRight } = usePage();
  const { addSection, findSection, updateSection } = useSectionStore();

  const state = reactive<SectionActionState>({
    section: createSection(),
    callbackOnSubmit: undefined,
  });
  const sectionActions: SectionViewActions = {
    createSection() {
      showPanelRight.value = true;
      state.section = createSection();
      state.callbackOnSubmit = sectionActions.onCreateSection;
    },
    findSection(sectionId: number) {
      try {
        const sec = findSection(sectionId);
        state.section = sec;
        state.callbackOnSubmit = sectionActions.onEditSection;
      } catch (err: any) {
        $q.notify(err.message);
        router.push({ name: RoutesName.Sections });
      }
    },
    editSection(sectionId: number) {
      showPanelRight.value = true;
      sectionActions.findSection(sectionId);
    },
    onCreateSection(sectionData: SectionDto) {
      addSection(sectionData)
        .then(() => {
          showPanelRight.value = false;
          $q.notify(i18n.t("section.messages.created"));
          router.push({ name: RoutesName.Sections });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditSection(sectionData: SectionDto) {
      updateSection(sectionData)
        .then(() => {
          $q.notify(i18n.t("section.messages.updated"));
          router.push({ name: RoutesName.Sections });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
  };
  return { ...toRefs(state), actions: sectionActions };
});
