import { articleService } from "@/services/ArticleService";
import type { ArticleCartState } from "@/types/articleCart";
import { defineStore } from "pinia";

export const useArticleCartStore = defineStore({
  id: "articleCart",
  state: (): ArticleCartState => ({
    articleIds: [],
    articles: [],
  }),
  getters: {
    count: (state) => state.articleIds.length,
    needLoading: (state) => {
      return !state.articleIds.every(
        (p) => state.articles.findIndex((a) => a.id == p) >= 0
      );
    },
  },
  actions: {
    toggleArticleId(id) {
      const index = this.articleIds.indexOf(id);
      if (index < 0) {
        this.articleIds.push(id);
        return;
      }

      this.articleIds.splice(index, 1);
      const indexArticle = this.articles.findIndex((a) => a.id === id);
      if (indexArticle >= 0) this.articles.splice(indexArticle, 1);
    },
    async loadArticles() {
      if (!this.needLoading || this.articleIds.length === 0)
        return Promise.resolve();

      try {
        this.articles = await articleService.getArticles(this.articleIds);
      } catch (err) {
        for (const error of (err as AggregateError).errors) {
          console.error(error);
        }
      }
    },
    async getArticles() {
      if (this.needLoading) await this.loadArticles();

      this.articleIds = [];
      return this.articles;
    },
  },
  share: {
    enable: true,
    initialize: true,
  },
});
