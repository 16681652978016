<template>
  <q-dialog
    :model-value="props.modelValue"
    @update:model-value="(val) => $emit('update:model-value', val)"
  >
    <q-card style="width: 700px; max-width: 80vw">
      <q-card-section>
        <q-select
          v-model="newAssignments"
          :label="$t('assignment.fields.shortAssignments')"
          :options="assignmentOptions"
          multiple
          use-chips
          map-options
          emit-value
          stack-label
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          v-close-popup
          flat
          :label="$t('assignment.labels.btnCancel')"
          color="primary"
        />
        <q-btn
          v-close-popup
          flat
          :label="$t('assignment.labels.btnOk')"
          color="primary"
          @click="onSubmitNewAssignment"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script setup lang="ts">
import type { QSelectOption } from "quasar";
import { ref, computed, watch } from "vue";
import { useUserStore } from "@/stores/user";
import { useLocale } from "@/utils";

const props = withDefaults(
  defineProps<{
    modelValue: boolean;
    selected: string[];
  }>(),
  {
    modelValue: false,
    selected: () => [],
  }
);
const emits = defineEmits<{
  (e: "update:model-value", value: boolean): void;
  (e: "add:assignments", value: string[]): void;
}>();

const { isFr } = useLocale();
const { assignments } = useUserStore();

const newAssignments = ref<string[]>([...props.selected]);
const assignmentOptions = computed<QSelectOption<string>[]>(() =>
  assignments.map((assignment) => ({
    label: isFr.value ? assignment.labelFr : assignment.labelEn,
    value: assignment.id,
  }))
);

watch(
  () => props.selected,
  (newVal) => {
    if (newVal) newAssignments.value = newVal;
  }
);
/**
 * Fonction appelé au moment de rajouter un nouveau assignment
 */
function onSubmitNewAssignment() {
  emits("add:assignments", newAssignments.value);
}
</script>
