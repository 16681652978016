import { useUserStore } from "@/stores/user";
import { articleService } from "@/services/ArticleService";
import { PreviewDto } from "@/types/preview";
import {
  ContributionDto,
  ContributionType,
  ArticleContentDto,
  UserLightDto,
} from "@/types/api";

/**
 *
 */
export async function usePrintPreview(articleIds: number[], language: string) {
  const articles = await articleService.getArticles(articleIds);
  const articlesToPreview: PreviewDto[] = [];
  for (const article of articles) {
    const content =
      article.contents.find((el) => el.language == language) ??
      article.contents[0];

    const signature: UserLightDto[] = [];
    signature.push(
      ...getSignatures(
        content,
        article.contributions,
        "SIGNATUREVISIBLE",
        ContributionType.Author
      )
    );
    signature.push(
      ...getSignatures(
        content,
        article.contributions,
        "SIGNATUREPIGISTEVISIBLE",
        ContributionType.Freelancer
      )
    );
    const signatureSorted = [...signature]
      .sort(compareSignatures)
      .map((el) => el.name);
    articlesToPreview.push({
      publication: article.publication,
      content,
      text: content.text,
      signature: signatureSorted,
    });
  }

  return Promise.resolve(articlesToPreview);
}

/**
 *
 */
function getSignatures(
  content: ArticleContentDto,
  contributions: ContributionDto[],
  paramCMS: string,
  contributionType: ContributionType
) {
  const { usersLight } = useUserStore();
  const signatures: UserLightDto[] = [];
  if (content.settings.filter((s) => s.key1 == paramCMS).length > 0) {
    const signatureIds = contributions
      .filter((c) => c.type == contributionType)
      .map((el) => el.idContributor);
    console.log(contributions);

    for (const id of signatureIds) {
      const user = usersLight.find((el) => el.id == id);
      if (user != null) signatures.push(user);
    }
  }

  return signatures;
}

const compareSignatures = (a, b) => {
  if (a.lastName < b.lastName) {
    return -1;
  }
  if (a.lastName > b.lastName) {
    return 1;
  }
  return 0;
};
