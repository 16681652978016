<template>
  <q-card>
    <q-card-section>
      <q-table
        :rows="publications"
        :columns="columns"
        :grid="$q.screen.xs"
        :filter="filter"
        :title="$t('publication.labels.lblTitle')"
        bordered
        flat
        table-header-class="header_table"
        separator="cell"
        hide-bottom
        :rows-per-page-options="[0]"
        @row-click="onRowClick"
      >
        <template #top-right>
          <q-input
            v-model="filter"
            borderless
            dense
            debounce="300"
            :placeholder="$t('publication.fields.shortSearch')"
          >
            <template #append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-active="props">
          <q-td :props="props">
            <q-icon v-if="props.value" name="done" />
            <q-icon v-else name="close" />
          </q-td>
        </template>
        <template #item="props">
          <QTableGridItem
            v-slot="slotProps"
            :columns="props.cols"
            @click="actions.editPublication(props.row.id)"
          >
            <span v-if="slotProps.columnName != 'active'">{{
              slotProps.columnValue
            }}</span>
            <div v-else>
              <q-icon v-if="slotProps.columnValue" name="done" />
              <q-icon v-else name="close" />
            </div>
          </QTableGridItem>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import type { QTableColumn } from "quasar";
import type { PublicationDto } from "@/types/api";
import { ref } from "vue";
import { usePublicationStore } from "@/stores/publication";
import { usePublicationActions } from "@/composables/usePublicationActions";
import { i18n } from "@/i18n";
import QTableGridItem from "@/components/QTableGridItem.vue";

const publicationStore = usePublicationStore();
const { publications } = publicationStore;
const filter = ref("");
const columns: QTableColumn<PublicationDto>[] = [
  {
    name: "id",
    label: i18n.t("publication.fields.shortId"),
    align: "left",
    field: (row) => row.id,
  },
  {
    name: "name",
    label: i18n.t("publication.fields.shortName"),
    align: "left",
    field: (row) => row.name,
  },
  {
    name: "active",
    label: i18n.t("publication.fields.shortActive"),
    align: "center",
    sortable: true,
    field: (row) => row.active,
  },
];
const { actions } = usePublicationActions();
/**
 * Fonction appelé lors d'un click sur une des lignes du tableau des publications
 */
function onRowClick(evt: Event, row: PublicationDto) {
  actions.editPublication(row.id);
}
</script>

<style lang="scss">
.header_table {
  background-color: #f5f5f5;
  font-weight: bold;
}
.body--dark {
  .header_table {
    background-color: #454545;
    color: black;
  }
}
</style>
