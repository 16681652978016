<template>
  <div>
    <div class="q-mb-lg">
      <div class="text-subtitle1">{{ $t("import.titles.importArticle") }}</div>
      <q-form class="q-gutter-md" @submit="onSubmit" @reset="onReset">
        <div class="row q-col-gutter-sm">
          <div class="col q-pl-none">
            <q-input
              v-model="ids"
              :label="$t('import.fields.articleIds')"
              add-year
            />
          </div>
        </div>
        <div>
          <div class="q-gutter-sm">
            <q-btn
              :label="$t('import.labels.btnImport')"
              type="submit"
              color="primary"
            />
            <q-btn
              :label="$t('import.labels.btnReset')"
              type="reset"
              color="primary"
              flat
              class="q-ml-sm"
            />
          </div>
        </div>
      </q-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { useImportActions } from "@/composables/useImportActions";

const { actions } = useImportActions();

const ids = ref("");

/**
 *
 */
function onSubmit() {
  const idsArray = ids.value.split(",").map((x) => parseInt(x));
  actions.importArticlesIds(idsArray);
}

/**
 *
 */
function onReset() {
  ids.value = "";
}
</script>
