<template>
  <q-dialog ref="dialogRef" :maximized="$q.screen.lt.lg">
    <q-card
      class="q-dialog-plugin"
      :class="$q.screen.gt.sm ? 'dialog-content' : undefined"
    >
      <q-bar class="bg-primary text-white">
        <div>{{ $t("ckeditor.dialogs.titles.shortcuts") }}</div>
        <q-space />
        <q-btn v-close-popup dense flat icon="close" />
      </q-bar>
      <q-card-section>
        <div class="text-h6"></div>
      </q-card-section>
      <q-card-section>
        <ListShortcuts />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>
<script setup lang="ts">
import { useDialogPluginComponent } from "quasar";
import ListShortcuts from "@/components/ckeditor/ListShortcuts.vue";

const { dialogRef } = useDialogPluginComponent();
</script>
<style scoped>
.dialog-content {
  width: 600px;
  max-width: 80vw;
}
</style>
