<template>
  <q-card flat bordered>
    <q-card-section>
      <FormPublication
        :label-btn-submit="$t('publication.labels.btnCreate')"
        @submit="actions.onCreatePublication"
      />
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import { usePublicationActions } from "@/composables/usePublicationActions";
import FormPublication from "@/components/Publication/FormPublication.vue";

const { actions } = usePublicationActions();
</script>
