<template>
  <q-card bordered>
    <q-card-section>
      <FormFormat
        :label-btn-submit="$t('format.labels.btnCreate')"
        @submit="actions.onCreateFormat"
      />
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { useFormatActions } from "@/composables/useFormatActions";
import FormFormat from "@/components/Format/FormFormat.vue";

const { actions } = useFormatActions();
</script>
