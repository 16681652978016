<template>
  <q-dialog ref="dialogRef" persistent :maximized="$q.screen.lt.sm">
    <SearchArticle
      label-search-button=""
      class="q-dialog-plugin"
      :article-selectable="true"
    >
      <template #actions>
        <q-card-actions align="right">
          <div class="row justify-start items-center">
            <q-btn
              color="secondary"
              icon="close"
              :label="$t('search.action.closeSelectedArticles')"
              :size="$q.screen.lt.sm ? 'sm' : 'md'"
              @click="onDialogCancel()"
            />
          </div>
          <q-space />
          <div class="row justify-end items-center">
            <q-btn
              v-show="countArticlesInCart > 0"
              :label="$t('search.action.validateSelectedArticles')"
              size="md"
              icon="check"
              color="primary"
              @click="onOk()"
            />
          </div>
        </q-card-actions>
        <q-separator />
      </template>
    </SearchArticle>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent } from "quasar";
import SearchArticle from "@/components/Article/Search/SearchArticle.vue";
import { useArticleCartStore } from "@/stores/articleCart";
import { storeToRefs } from "pinia";

const { count } = storeToRefs(useArticleCartStore());
const articleCartStore = useArticleCartStore();
const countArticlesInCart = count;
const { dialogRef, onDialogCancel, onDialogOK } = useDialogPluginComponent();

/**
 * On ferme la popup en renvoyant les articles sélectionnés
 */
async function onOk() {
  var articles = await articleCartStore.getArticles();
  onDialogOK(articles);
}
</script>
<style scoped>
@media screen and (min-width: 600px) {
  .q-dialog-plugin {
    width: 70vw;
    min-width: 550px;
    max-width: 900px;
  }
}
</style>
