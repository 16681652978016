<template>
  <q-list padding>
    <template v-for="(r, idx) in navigation" :key="'Nav' + idx">
      <q-item
        v-if="!r.children.length"
        exact
        :active="r.active ?? route.name === r.name"
        :to="{ name: r.name, query: r?.query ?? {} }"
      >
        <q-item-section v-if="r.icon" avatar>
          <q-icon :name="r.icon" />
          <q-badge
            v-if="r.badge && r.badge.label && panelLeftMini"
            class="q-mr-md q-mt-xs"
            :color="r.badge.color ?? 'primary'"
            rounded
            floating
            >{{ $t(r.badge.label) }}</q-badge
          >
        </q-item-section>
        <q-item-section>
          <label>
            {{ $t(`liens.${r.label}`) }}
            <q-badge
              v-if="r.badge && r.badge.label"
              class="q-mr-md q-mt-xs"
              :color="r.badge.color ?? 'primary'"
              rounded
              floating
              >{{ $t(r.badge.label) }}</q-badge
            >
          </label>
        </q-item-section>
        <q-tooltip
          v-if="panelLeftMini"
          anchor="center right"
          self="center left"
        >
          {{ $t(`liens.${r.label}`) }}
        </q-tooltip>
      </q-item>
      <q-expansion-item
        v-else
        expand-separator
        :default-opened="adminNavigationOpened"
      >
        <template #header>
          <q-item-section avatar>
            <q-icon :name="r.icon" />
          </q-item-section>

          <q-item-section>
            {{ $t(`liens.${r.label}`) }}
          </q-item-section>
          <q-tooltip
            v-if="panelLeftMini"
            anchor="center right"
            self="center left"
          >
            {{ $t(`liens.${r.label}`) }}
          </q-tooltip>
        </template>
        <q-list padding>
          <q-item
            v-for="(children, idxChildren) in r.children"
            :key="'Nav' + idx + '_' + idxChildren"
            :to="{ name: children.name, query: children?.query ?? {} }"
          >
            <q-item-section v-if="children.icon" avatar class="q-pl-sm">
              <q-icon :name="children.icon" />
            </q-item-section>
            <q-item-section :class="{ 'q-pl-sm': !children.icon }">
              {{ $t(`liens.${children.label}`) }}
            </q-item-section>
            <q-tooltip
              v-if="panelLeftMini"
              anchor="center right"
              self="center left"
            >
              {{ $t(`liens.${children.label}`) }}
            </q-tooltip>
          </q-item>
        </q-list>
      </q-expansion-item>
    </template>
  </q-list>
</template>

<script setup lang="ts">
import type { LocationQueryRaw } from "vue-router";
import { useRoute } from "vue-router";
import { computed, ref } from "vue";
import { storeToRefs } from "pinia";
import { RoutesName } from "@/router/routesName";
import { authService } from "@/services/AuthService";
import { RightReference } from "@/types/api";
import { useConfigStore } from "@/stores/config";
import { useFormatStore } from "@/stores/format";
import { usePage } from "@/composables/usePage";
import { searchService } from "@/services/SearchService";
import { ReviewTranslationInErrorAfter } from "@/constants";

/**
 * Type Link
 */
type Link = {
  name: RoutesName;
  label: string;
  children: Omit<Link, "children">[];
  query?: LocationQueryRaw;
  icon?: string;
  active?: boolean;
  badge?: Badge;
};

/**
 * Type Badge
 */
type Badge = {
  label: string;
  color?: string;
};

const route = useRoute();
const { config, showWhatsNew } = storeToRefs(useConfigStore());
const { formats } = useFormatStore();
const { panelLeftMini } = usePage();
const homeBadge = ref<Badge>({ label: "" });
const isFeuilleton = computed(() => {
  const formatFeuilleton = formats.find((f) => f.id === "Feuilleton");
  if (!formatFeuilleton) return false;
  return formatFeuilleton.formatPublications.some(
    (fp) => fp.publication === config.value.publication
  );
});
const adminNavigation: Link = {
  name: RoutesName.Admin,
  label: "Admin",
  children: [],
  icon: "admin_panel_settings",
};

if (
  authService.hasRight(RightReference.ADMIN_FULL) ||
  authService.hasRight(RightReference.FREELANCE_MANAGEMENT) ||
  authService.hasRight(RightReference.ADMIN_USER)
) {
  adminNavigation.children.push(
    {
      name: RoutesName.Users,
      label: "Users",
      icon: "people",
    },
    {
      name: RoutesName.FreelanceManagement,
      label: "Freelances",
      icon: "savings",
    }
  );
}
if (authService.hasRight(RightReference.ADMIN_FULL)) {
  adminNavigation.children.push(
    {
      name: RoutesName.ArticleSettings,
      label: "ArticleSettings",
      icon: "tune",
    },
    {
      name: RoutesName.Publications,
      label: "Publications",
      icon: "newspaper",
    },
    { name: RoutesName.Sections, label: "Sections", icon: "source" },
    { name: RoutesName.Formats, label: "Formats", icon: "assignment" },
    { name: RoutesName.Countries, label: "Countries", icon: "flag" },
    { name: RoutesName.MailTemplate, label: "Mails", icon: "mail" },
    { name: RoutesName.Import, label: "Import", icon: "publish" }
  );
}

const adminNavigationOpened = adminNavigation.children.some(
  (c) => c.name === route.name
);

const navigation = computed<Link[]>(() => {
  const links: Link[] = [
    {
      name: RoutesName.Home,
      label: "Home",
      children: [],
      icon: "home",
      badge: homeBadge.value,
    },
    { name: RoutesName.Search, label: "Search", children: [], icon: "search" },
    {
      name: RoutesName.Planning,
      label: "Planning",
      children: [],
      icon: "date_range",
    },
    {
      name: RoutesName.Articles,
      label: "Editions",
      icon: "today",
      query: { listView: "daily" },
      active:
        route.name === RoutesName.Articles && route.query.listView === "daily",
      children: [],
    },
    {
      name: RoutesName.Articles,
      label: "CustomDates",
      icon: "article",
      query: { listView: "period" },
      active:
        route.name === RoutesName.Articles &&
        route.query.listView === "period" &&
        route.query.formats !== "Feuilleton",
      children: [],
    },
    {
      name: RoutesName.Articles,
      label: "Fridge",
      icon: "kitchen",
      query: { listView: "fridge" },
      active:
        route.name === RoutesName.Articles && route.query.listView === "fridge",
      children: [],
    },
    isFeuilleton.value
      ? {
          name: RoutesName.Articles,
          label: "Feuilletons",
          icon: "topic",
          query: { listView: "period", formats: "Feuilleton" },
          active:
            route.name === RoutesName.Articles &&
            route.query.formats === "Feuilleton",
          children: [],
        }
      : undefined,
  ].filter(Boolean) as Link[];

  if (config.value.publication != "GL") {
    links.push({
      name: RoutesName.LongFormatPlanning,
      label: "LongFormatPlanning",
      children: [],
      icon: "event",
    });
  }

  links.push(
    {
      name: RoutesName.Drafts,
      label: "Drafts",
      children: [],
      icon: "drive_file_rename_outline",
    },
    { name: RoutesName.Media, label: "Media", children: [], icon: "image" }
  );

  if (
    authService.hasRight(RightReference.EXPORT_ALL_FREELANCE) ||
    authService.hasRight(RightReference.PUBLICATION_EXPORT_FREELANCE)
  ) {
    links.push({
      name: RoutesName.ExportFreelance,
      label: "ExportFreelance",
      children: [],
      icon: "cloud_download",
    });
  }

  if (
    authService.hasRight(RightReference.EXPORT_ALL_TRANSLATION) ||
    authService.hasRight(RightReference.PUBLICATION_EXPORT_TRANSLATION)
  ) {
    links.push({
      name: RoutesName.ExportTranslation,
      label: "ExportTranslation",
      children: [],
      icon: "cloud_download",
    });
  }

  // if (
  //   authService.hasRight(RightReference.REPLACEMENT_PROCESS) ||
  //   authService.hasRight(RightReference.ADMIN_FULL)
  // ) {
  //   links.push({
  //     name: RoutesName.ListReport,
  //     label: "SearchAndReplace",
  //     children: [],
  //     icon: "content_paste_search",
  //   });
  // }

  if (adminNavigation.children.length) {
    links.push(adminNavigation);
  }

  links.push({
    name: RoutesName.Settings,
    label: "Settings",
    children: [],
    icon: "settings",
  });
  links.push({
    name: RoutesName.Help,
    label: "Help",
    children: [],
    icon: "help",
    badge: showWhatsNew.value ? { label: "help.labels.news" } : undefined,
  });

  return links;
});

/**
 * Refresh home badge counter
 */
async function refreshHomeBadgeCounter() {
  try {
    const nb = await searchService.countMyTranslationsToReview(
      config.value.publication
    );
    const color =
      new Date().getHours() >= ReviewTranslationInErrorAfter
        ? "red"
        : "primary";
    homeBadge.value = { label: nb ? `${nb}` : "", color: color };
  } catch (e) {
    console.error(e);
  }
}

setInterval(async () => {
  refreshHomeBadgeCounter();
}, 300_000);
refreshHomeBadgeCounter();
</script>
<style lang="scss">
.q-drawer--mini {
  .q-expansion-item__content {
    display: block;
  }
}
</style>
