import { storeToRefs } from "pinia";
import { toRefs, reactive } from "vue";
import { modificationType } from "@/components/Article/constant";
// Stores
import { useCurrentArticleStore } from "@/stores/currentArticle";

/**
 * Interface ArticleFormState
 */
interface ArticleFormState {
  submitting: boolean;
}
const state = reactive<ArticleFormState>(initStates());

/**
 *
 */
export function useFormArticle() {
  // Initialisation des stores
  const currentArticleStore = useCurrentArticleStore();
  const { currentArticle, modifExist } = storeToRefs(currentArticleStore);

  /**
   * Méthode a appeler lors de l'enregistrement de l'article
   */
  function onSubmit(callback: () => void) {
    if (modifExist.value === modificationType.None) return;

    state.submitting = true;
    currentArticle.value.modificationType = modifExist.value;
    callback();
  }

  return {
    ...toRefs(state),
    actions: {
      onSubmit,
    },
  };
}

/**
 * Renvoie les valeurs par défaut des états
 */
function initStates() {
  return {
    submitting: false,
  };
}
