import type { ThemeConfig } from "@/types/theme";
import { setCssVar, colors, Dark } from "quasar";
const { hexToRgb } = colors;

const theme: ThemeConfig = {
  AI: {
    primary: "#eb7c2d",
    secondary: "#694946",
  },
  LLA: {
    primary: "#cb2a2e",
    secondary: "#1d3548",
  },
  IO: {
    primary: "#b21f24",
    secondary: "#5a5a5a",
  },
  GL: {
    primary: "#652f89",
    secondary: "#272726",
  },
};

/**
 * Fonction pour récupérer le theme en fonction de la publication
 */
export function getThemeByPublication(publication: string) {
  if (publication in theme) return theme[publication];
  else return null;
}

/**
 * Fonction pour configurer le theme en fonction de la publication
 */
export function configTheme(publication: string) {
  Object.entries(theme).forEach(([pub, config]) => {
    if (config.primary) setCss(`primary-${pub}`, config.primary);

    if (pub === publication) {
      Object.entries(config).forEach(([prop, color]) => {
        if (prop && color) {
          setCss(prop, color);
        }
      });
    }
  });

  applyDarkTheme();
}

/** Definit les propriétés CSS */
function setCss(prop: string, color: string) {
  setCssVar(prop, color);
  const rgb = hexToRgb(color);
  if (rgb) {
    setCssVar(prop + "-rgb", `${rgb.r},${rgb.g},${rgb.b}`);
  }
}

/** Application du theme dark */
export function applyDarkTheme() {
  const color = Dark.isActive ? "#ff8e9a" : "#C10015";
  setCssVar("negative", color);
}
