<template>
  <!-- @ts-ignore TS2322 -->
  <q-input
    v-model="inputDate"
    filled
    v-bind="$attrs"
    :disable="disable"
    :label="label ?? ''"
    :dense="props.dense ?? false"
    :rules="[rule]"
    :error-message="$t('article.errors.invalidDate')"
    @keypress="emitEnterDate"
    @blur="emitBlurDate"
    @click="emitClickDate"
  >
    <template v-if="addDay" #before>
      <slot name="before">
        <q-btn outline round label="J-1" @click="addDayFn(-1)" />
      </slot>
    </template>
    <template v-if="addDay" #after>
      <slot name="after">
        <q-btn outline round label="J+1" @click="addDayFn(+1)" />
      </slot>
    </template>
    <template v-if="showTime" #prepend>
      <q-icon name="access_time" class="cursor-pointer">
        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
          <q-time v-model="datePickerDate" :mask="mask" format24h>
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                :label="$t('article.labels.btnClose')"
                color="primary"
                flat
              />
            </div>
          </q-time>
        </q-popup-proxy>
      </q-icon>
    </template>
    <template #append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy
          ref="qDateProxy"
          cover
          transition-show="scale"
          transition-hide="scale"
        >
          <q-date
            v-model="datePickerDate"
            today-btn
            :mask="mask"
            :options="availableDates"
          >
            <div class="row items-center justify-end">
              <q-btn
                v-close-popup
                :label="$t('article.labels.btnClose')"
                color="primary"
                flat
              />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>
  <div v-if="addYear">
    <div class="q-gutter-sm">
      <q-btn label="-5 ans" color="secondary" @click="addYearsFn(-5)" />
      <q-btn label="-1 an" color="secondary" @click="addYearsFn(-1)" />
      <q-btn label="+1 an" color="secondary" @click="addYearsFn(1)" />
      <q-btn label="+5 ans" color="secondary" @click="addYearsFn(5)" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { date, DateOptions } from "quasar";

const props = defineProps<{
  modelValue?: string;
  label?: string;
  addDay?: boolean;
  addYear?: boolean;
  showHint?: boolean;
  disable?: boolean;
  dense?: boolean;
  showTime?: boolean;
  availableDates?: string[] | ((date: string) => boolean);
}>();
const emit = defineEmits<{
  (e: "update:model-value", value: string): void;
}>();
const dateInput = ref<string>();
const mask = ref<string>(getDefaultMask());

const emitEnterDate = ({ key, target }) => {
  if (key == "Enter") {
    updateDate(target.value);
  }
};
const emitClickDate = (e) => {
  e.preventDefault();
};
const emitBlurDate = () => {
  updateDate(dateInput.value);
};
const updateDate = (newValue) => {
  let formatedDate = formatDate(newValue);
  if (formatedDate) emit("update:model-value", formatedDate);
};
const inputDate = computed({
  get() {
    return props.modelValue === undefined
      ? undefined
      : date.formatDate(props.modelValue, mask.value);
  },
  set: (newValue) => {
    dateInput.value = newValue;
  },
});

const rule = computed(() => (v) => {
  // Pattern date YYYY-MM-DD
  let pattern =
    /^(?:19\d{2}|20\d{2})-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:0[1-9])|(?:[1-2]\d)|(?:3[0-1]))$/;
  if (props.showTime) {
    // Pattern date YYYY-MM-DD HH:mm
    pattern =
      /^(?:19\d{2}|20\d{2})-(?:(?:0[1-9])|(?:1[0-2]))-(?:(?:0[1-9])|(?:[1-2]\d)|(?:3[0-1]))T(?:[0-1]\d|2[0-3]):(?:[0-5]\d)$/;
  }
  return pattern.test(v);
});

const datePickerDate = computed({
  get() {
    return date.formatDate(props.modelValue, mask.value);
  },
  set(newValue) {
    let dateFormated = formatDate(newValue);
    if (dateFormated) emit("update:model-value", dateFormated);
  },
});

watch(
  () => props.showTime,
  () => {
    mask.value = getDefaultMask();
  }
);

/**
 *
 */
function addYearsFn(nb: number) {
  addToDate({ year: nb });
}
/**
 *
 */
function addDayFn(nb: number) {
  addToDate({ day: nb });
}
/**
 *
 */
function addToDate(option: DateOptions) {
  if (inputDate.value === undefined) return;

  let dateStr = inputDate.value;
  let d = date.extractDate(dateStr, mask.value);
  d = date.addToDate(d, option);
  dateStr = date.formatDate(d, mask.value);
  let dateFormated = formatDate(dateStr);
  if (dateFormated) emit("update:model-value", dateFormated);
}

/**
 * Fonction pour formater la date en ISO
 */
function formatDate(dateString: string) {
  if (dateString == undefined || dateString == "") return undefined;
  const dateExtracted = date.extractDate(dateString, mask.value);
  if (dateExtracted.getFullYear() < 1899) return undefined;
  const utcDate = new Date(
    Date.UTC(
      dateExtracted.getFullYear(),
      dateExtracted.getMonth(),
      dateExtracted.getDate(),
      props.showTime ? dateExtracted.getHours() : 0,
      props.showTime ? dateExtracted.getMinutes() : 0
    )
  ).toISOStringWithoutZ();
  return utcDate;
}

/**
 * Fonction pour définir le mask
 */
function getDefaultMask() {
  let pattern = "YYYY-MM-DD";
  if (props.showTime) {
    pattern = "YYYY-MM-DDTHH:mm";
  }
  return pattern;
}
</script>
<style>
input[type="date"]::-webkit-calendar-picker-indicator {
  visibility: hidden !important;
  -webkit-appearance: none;
}
input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
  -webkit-appearance: none;
}
input[type="date"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  visibility: hidden !important;
  -webkit-appearance: none;
}
input[type="datetime-local"]::-webkit-input-placeholder {
  visibility: hidden !important;
  -webkit-appearance: none;
}
input[type="datetime-local"]::-webkit-clear-button {
  -webkit-appearance: none;
  display: none;
}
@-moz-document url-prefix() {
  input[type="date"],
  input[type="datetime-local"] {
    clip-path: inset(0 20px 0 0);
  }
}
</style>
