import type { QSelectOption } from "quasar";
import type { PublicationDto } from "@/types/api";
import { usePublicationStore } from "@/stores/publication";
import { authService } from "@/services/AuthService";

/**
 * Interface PublicationParams
 */
interface PublicationParams {
  publicationsActive?: boolean;
  allOption?: boolean;
  idPublicationToAdd?: string;
}

/**
 * Méthode qui sert à récupérer la liste des publications pour être utiliser dans un dropdown
 * @param params.publicationsActive - argument pour récupérer toutes les publications ou seule celles actives
 * @param params.allOption - argument pour avoir dans le sélecteur une option toutes les publications
 * @param params.idPublicationToAdd - argument pour ajouter une publication dans la liste via son id
 */
export function getPublicationOptions(params: PublicationParams = {}) {
  const { publications } = usePublicationStore();
  const {
    publicationsActive = true,
    allOption = true,
    idPublicationToAdd,
  } = params;
  let list: PublicationDto[];

  if (publicationsActive) list = publications.filter((pub) => pub.active);
  else list = publications;

  if (
    idPublicationToAdd &&
    !publications.some((p) => p.id === idPublicationToAdd)
  ) {
    const publicationFound = publications.find(
      (p) => p.id === idPublicationToAdd
    );
    if (publicationFound) list.push(publicationFound);
  }

  list = list.filter((p) => authService.checkCanAccessPublication(p.id));

  return [
    allOption ? { value: "", label: "Toutes" } : undefined,
    ...list.map((pub) => ({
      value: pub.id,
      label: pub.name,
    })),
  ].filter(Boolean) as QSelectOption<string>[];
}
