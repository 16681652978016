<!-- eslint-disable vue/no-v-html vue/no-v-text-v-html-on-component -->
<template>
  <q-dialog ref="dialogRef" persistent>
    <q-card class="q-dialog-plugin" style="width: 700px; max-width: 80vw">
      <q-card-section>
        <div class="text-h6">
          {{ title }}
        </div>
      </q-card-section>
      <q-card-section v-html="message"></q-card-section>
      <q-card-actions v-if="editableDraft" align="center" class="q-gutter-md">
        <q-btn
          :label="$t('draft.labels.btnLoadMyDraft')"
          icon="devices"
          color="green-7"
          size="md"
          @click="onDialogOK"
        />

        <q-btn
          :label="$t('draft.labels.btnLoadLast')"
          color="grey"
          icon="cloud"
          size="sm"
          @click="onDeleteDraft"
        />
      </q-card-actions>
      <q-card-actions v-if="!editableDraft" align="center" class="q-gutter-md">
        <q-btn
          :label="$t('draft.labels.btnDraftLoadLastAndRemove')"
          icon="cloud"
          size="md"
          color="yellow-9"
          @click="onDeleteDraft"
        />

        <q-btn
          :label="$t('draft.labels.btnDraftLoadMyDraft')"
          color="grey"
          @click="onDialogOK"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { useQuasar, useDialogPluginComponent } from "quasar";
import { useDraftStore } from "@/stores/draft";
import { i18n } from "@/i18n";

const props = defineProps<{
  draftId: number;
  title: string;
  message: string;
  editableDraft: boolean;
}>();
const $q = useQuasar();
const { dialogRef, onDialogOK, onDialogHide } = useDialogPluginComponent();
const draftStore = useDraftStore();

/**
 *
 */
function onDeleteDraft() {
  $q.dialog({
    title: i18n.t("draft.dialogs.titles.confirm"),
    message: i18n.t("draft.dialogs.messages.delete"),
    cancel: true,
    persistent: true,
  }).onOk(() => {
    draftStore.deleteDraft(props.draftId, true);
    onDialogHide();
    $q.notify(i18n.t("draft.messages.deleted"));
  });
}
</script>
