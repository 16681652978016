<template>
  <div v-if="language === 'all'">
    <div
      v-if="onlineStatus[FrenchLanguage] !== undefined"
      style="align-self: center"
    >
      <div>
        <q-icon
          color="primary"
          :name="onlineStatus[FrenchLanguage] ? 'check_circle' : 'unpublished'"
          size="sm"
        />
      </div>
    </div>
    <div
      v-if="onlineStatus[EnglishLanguage] !== undefined"
      style="align-self: center"
    >
      <div>
        <q-icon
          color="primary"
          :name="onlineStatus[EnglishLanguage] ? 'check_circle' : 'unpublished'"
          size="sm"
        />
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="onlineStatus[language] !== undefined" style="align-self: center">
      <div>
        <q-icon
          color="primary"
          :name="onlineStatus[language] ? 'check_circle' : 'unpublished'"
          size="sm"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs, computed } from "vue";
import { FrenchLanguage, EnglishLanguage } from "@/constants";
const props = defineProps<{
  data: ArticleODataDto;
  language: string;
  otherLanguage: string;
}>();
const { data } = toRefs(props);

const contentFr = computed(() => {
  return data.value.contents.find((c) => c.language === FrenchLanguage);
});
const contentEn = computed(() => {
  return data.value.contents.find((c) => c.language === EnglishLanguage);
});

const onlineStatus = computed(() => {
  return {
    [FrenchLanguage]: contentFr.value ? contentFr.value.online : false,
    [EnglishLanguage]: contentEn.value ? contentEn.value.online : false,
  };
});
</script>
