import type { CrudServiceInstance } from "@/types/crud";
import type { SpellCheckingServiceInstance } from "@/types/user";
import { CrudService } from "@/services/CrudService";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { AxiosResponse } from "axios";

/**
 * Ce service permet de récupérer la clé API et construire l'URL du proxy
 * Prolexis pour la correction orthographique
 */
class SpellCheckingService
  extends AxiosRequest
  implements SpellCheckingServiceInstance
{
  crudService: CrudServiceInstance<boolean>;

  /**
   * Constructeur de la class SpellChecking Service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<boolean>(rootPath);
  }

  /**
   * Récupérer la clé API qui permet d'utiliser le SpellChecker Prolexis via l'API de l'application
   */
  async getApiKey() {
    return this.axiosInstance
      .get(`${this.rootPath}/apikey`)
      .then((response: AxiosResponse<string>) => {
        if (response.status !== 200) return "";
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const spellCheckingService = new SpellCheckingService("SpellChecking");
