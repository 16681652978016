<template>
  <div class="row">
    <div v-for="(country, idx) in countries" :key="'country' + idx">
      <q-chip size="sm">
        {{ country }}
      </q-chip>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs, computed } from "vue";
import { useLocale } from "@/utils";

const props = defineProps<{
  data: ArticleODataDto;
}>();
const { data } = toRefs(props);
const { isFr } = useLocale();
const countries = computed(() => {
  if (!data.value.countries) return [];
  return data.value.countries.map((c) => (isFr.value ? c.labelFr : c.labelEn));
});
</script>
