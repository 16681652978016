<template>
  <div>
    <div class="q-mb-md">
      <div class="text-subtitle1">{{ $t("import.titles.exportArticle") }}</div>
      <q-form class="q-gutter-md" @submit="onSubmit" @reset="onReset">
        <q-select
          v-model="publication"
          filled
          :options="publications"
          :label="$t('import.fields.publications')"
          stack-label
        />

        <InputDate
          v-model="startDate"
          type="date"
          :label="$t('import.fields.startDate')"
          add-year
        />
        <InputDate
          v-model="endDate"
          type="date"
          :label="$t('import.fields.endDate')"
          add-year
        />

        <div class="q-gutter-sm">
          <q-radio
            v-model="language"
            val="fr-FR"
            :label="$t('languages.fr-FR')"
          />
          <q-radio
            v-model="language"
            val="en-EN"
            :label="$t('languages.en-EN')"
          />
        </div>

        <div>
          <div class="q-gutter-sm">
            <q-btn
              :label="$t('import.labels.btnExport')"
              type="submit"
              color="primary"
            />
            <q-btn
              :label="$t('import.labels.btnReset')"
              type="reset"
              color="primary"
              flat
              class="q-ml-sm"
            />
          </div>
        </div>
      </q-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { useImportActions } from "@/composables/useImportActions";
import { ref } from "vue";
import InputDate from "@/components/Custom/CustomInputDate.vue";

const { actions } = useImportActions();
const { exportArticles } = actions;

const publication = ref("IO");
const language = ref("fr-FR");
const startDate = ref("2022/01/01");
const endDate = ref("2023/01/01");
const publications = ["LLA", "IO", "AI", "GL"];

/**
 *
 */
function onSubmit() {
  exportArticles(
    publication.value,
    startDate.value,
    endDate.value,
    language.value
  );
}

/**
 *
 */
function onReset() {
  publication.value = "IO";
  startDate.value = "2022/01/01";
  endDate.value = "2023/01/01";
  language.value = "fr-FR";
}
</script>
