import type { CrudServiceInstance } from "@/types/crud";
import { ArticleSettingDto, SettingsType } from "@/types/api";
import type { ArticleSettingServiceInstance } from "@/types/articleSetting";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un object type articleSetting
 */
export function createArticleSetting(
  params: Partial<ArticleSettingDto> = {}
): ArticleSettingDto {
  return {
    id: params?.id ?? 0,
    active: params?.active ?? false,
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
    mainLabel: params?.mainLabel ?? "",
    idType: params?.idType ?? SettingsType.Interest,
    subType: params?.subType ?? "",
    key1: params?.key1 ?? "",
  };
}

/**
 * Service articleSetting pour récupérer les articleSettings et les modifier.
 */
class ArticleSettingService
  extends AxiosRequest
  implements ArticleSettingServiceInstance
{
  crudService: CrudServiceInstance<ArticleSettingDto>;

  /**
   * Constructeur de la class ArticleSetting service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<ArticleSettingDto>(rootPath);
  }

  /**
   * Liste les articleSettings
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre de articleSettings affichés par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer un articleSetting par son id
   * @param {number | string} id - id du articleSetting à récupérer
   */
  getSingle(id: string | number) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer un nouveau articleSetting
   * @param articleSetting - données du nouveau articleSetting à créer
   */
  insert(articleSetting: ArticleSettingDto) {
    return this.crudService.insert(articleSetting);
  }

  /**
   * Met à jour un articleSetting
   * @param articleSetting - nouvelles données du articleSetting à mettre à jour
   */
  update(articleSetting: ArticleSettingDto) {
    return this.crudService.update(articleSetting.id, articleSetting);
  }

  /**
   * Supprime un articleSetting
   * @param id - id du articleSetting à supprimer
   */
  delete(id: string | number) {
    return this.crudService.delete(id);
  }
}

export const articleSettingService = new ArticleSettingService(
  "articleSettings"
);
