<template>
  <div>
    <q-page-container :class="{ 'bg-grey-3': !Dark.isActive }">
      <q-page id="pageMedia" class="page-sections">
        <div id="ckfinder" style="min-height: inherit"></div>
      </q-page>
    </q-page-container>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { Dark } from "quasar";
import { configureCkFinder } from "@/composables/useCkEditor";

/**
 *
 */
function setStyle(iframe: HTMLElement | null, value: string) {
  if (!iframe) return;
  iframe.style.minHeight = value || "inherit";
}

/**
 *
 */
function updateMinHeight() {
  var target = document.getElementById("pageMedia");
  if (!target) return;

  let minHeight = parseInt(target.style.minHeight) - 10;
  setStyle(getIframe(), `${minHeight}px`);
}

var observer = new MutationObserver(function (mutations) {
  mutations.forEach(updateMinHeight);
});

/**
 *
 */
function getIframe(): HTMLElement | null {
  for (let iframe of document.getElementsByTagName("iframe")) {
    if (iframe.title.indexOf("keycloak") < 0) {
      return iframe;
    }
  }
  return null;
}

configureCkFinder();
onMounted(() => {
  var target = document.getElementById("pageMedia");
  if (!target) return;

  observer.observe(target, { attributes: true, attributeFilter: ["style"] });

  window.CKFinder.widget("ckfinder", {
    chooseFiles: true,
    onInit: function () {
      updateMinHeight();
    },
  });
});
</script>
