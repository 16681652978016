<template>
  <q-card flat bordered>
    <q-card-section>
      <FormSection :default-section="section" @submit="actions.onEditSection" />
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import { useRoute } from "vue-router";
import { useSectionActions } from "@/composables/useSectionActions";
import FormSection from "@/components/Section/FormSection.vue";

const route = useRoute();
const { section, actions } = useSectionActions();

actions.findSection(parseInt(route.params.id as string, 10));
</script>
