import type { CrudServiceInstance } from "@/types/crud";
import type { VersionInfoServiceInstance } from "@/types/user";
import { CrudService } from "@/services/CrudService";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { AxiosResponse } from "axios";

/**
 * Service APIVersionInfo, permet de connaitre la version de l'API et si elle est UP
 */
class VersionInfoService
  extends AxiosRequest
  implements VersionInfoServiceInstance
{
  crudService: CrudServiceInstance<boolean>;

  /**
   * Constructeur de la class SpellChecking Service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<boolean>(rootPath);
  }

  /**
   *
   */
  async checkOnline() {
    return this.axiosInstance
      .get(`${this.rootPath}/healthcheck`)
      .then((response: AxiosResponse<string>) => {
        if (response.status !== 200) return false;
        return true;
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  async getApiVersion() {
    return this.axiosInstance
      .get(`${this.rootPath}/apiversion`)
      .then((response: AxiosResponse<string>) => {
        if (response.status !== 200) return "0";
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const versionInfoService = new VersionInfoService("VersionInfo");
