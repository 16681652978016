<template>
  <q-menu anchor="bottom left" self="top start" @before-show="onBeforeShow">
    <div style="width: 600px; min-height: 50px">
      <transition
        appear
        enter-active-class="animated fadeIn"
        leave-active-class="animated fadeOut"
      >
        <q-card-section v-show="!visible">
          <div
            v-for="article in articleCartStore.articles"
            :key="article.id"
            class="row no-wrap q-pa-md"
          >
            <div class="col-1">
              <img
                :alt="article.publication"
                style="width: 32px"
                :src="`/img/${article.publication}.png`"
              />
            </div>
            <div class="col">
              <router-link
                :to="{
                  name: RoutesName.EditArticle,
                  params: { id: article.id },
                }"
                target="_blank"
                class="title"
              >
                {{ getTitle(article) }}
              </router-link>
            </div>
            <div class="col-1">
              <q-btn
                flat
                icon="remove_circle"
                size="xs"
                @click="articleCartStore.toggleArticleId(article.id)"
              />
            </div>
          </div>
        </q-card-section>
      </transition>
      <q-card-section>
        <q-inner-loading :showing="visible" class="q-pa-sm" />
      </q-card-section>
      <div class="col-1">
        <q-btn
          flat
          icon="print"
          size="sm"
          :label="$t('search.action.printListArticle')"
          @click="printArticles()"
        />
      </div>
    </div>
  </q-menu>
</template>

<script lang="ts" setup>
import { useArticleCartStore } from "@/stores/articleCart";
import { ref } from "vue";
import { RoutesName } from "@/router/routesName";
import { ArticleDto } from "@/types/api";
import { useRouter } from "vue-router";

const router = useRouter();

const articleCartStore = useArticleCartStore();

const visible = ref<boolean>(false);

/**
 * Méthode juste avant l'affichage du menu
 */
function onBeforeShow() {
  visible.value = true;
  articleCartStore.loadArticles().then(() => (visible.value = false));
}

/**
 * Récupère le titre de l'article
 */
function getTitle(article: ArticleDto) {
  const content =
    article.contents.find((c) => c.language === article.idMainLanguage) ??
    article.contents[0];
  return content.title;
}

/**
 * Imprime la liste des articles
 */
function printArticles() {
  const articles = articleCartStore.articles;
  if (articles.length > 0) {
    const articleIds = articles.map((el) => el.id).join(",");
    const routeData = router.resolve({
      name: RoutesName.Preview,
      params: { articleIds: articleIds },
    });
    window.open(routeData.href, "_blank");
  }
}
</script>

<style lang="scss" scoped>
.title {
  font-family: "CooperHewitt", sans-serif;
  font-size: 1rem;
  color: black;
  text-decoration: none;
  margin-bottom: 10px;
  &a {
    &:visited {
      color: black;
    }
    color: black;
  }
}
</style>
