import type { ProfileDto } from "@/types/api";
import type { ProfileViewActions } from "@/types/user";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { useUserStore } from "@/stores/user";
import { RoutesName } from "@/router/routesName";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions profile
 */
interface ProfileActionState {
  profile: ProfileDto | null;
}
/**
 * Fonction servant à interagir sur les différentes vues profile
 */
export const useProfileActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { findProfile, addProfile, updateProfile, deleteProfile } =
    useUserStore();

  const state = reactive<ProfileActionState>({
    profile: null,
  });
  const actions: ProfileViewActions = {
    findProfile(profileId: string) {
      return findProfile(profileId)
        .then((profile) => {
          state.profile = profile;
        })
        .catch((err: Error) => {
          $q.notify(err.message);
          router.push({ name: RoutesName.Profiles });
        });
    },
    createProfile(callback?: () => void) {
      state.profile = null;
      if (callback) callback();
    },
    editProfile(profileId: string, callback?: () => void) {
      actions.findProfile(profileId).then(() => {
        if (callback) callback();
      });
    },
    onCreateProfile(profile: ProfileDto) {
      addProfile(profile)
        .then(() => {
          $q.notify(i18n.t("profile.messages.created"));
          router.push({ name: RoutesName.Profiles });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditProfile(profile: ProfileDto) {
      updateProfile(profile)
        .then(() => {
          $q.notify(i18n.t("profile.messages.updated"));
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onDeleteProfile(profileId: string) {
      $q.dialog({
        title: i18n.t("profile.dialogs.titles.confirm"),
        message: i18n.t("profile.dialogs.messages.delete"),
        cancel: true,
        persistent: true,
      }).onOk(() => {
        deleteProfile(profileId).then(() => {
          $q.notify(i18n.t("profile.messages.deleted"));
        });
      });
    },
  };
  return { ...toRefs(state), actions };
});
