import type { CrudServiceInstance } from "@/types/crud";
import type { RightGroupDto } from "@/types/api";
import type { RightGroupServiceInstance } from "@/types/rightGroup";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un objet type groupe de droit
 */
export function createRightGroup(
  params: Partial<RightGroupDto> = {}
): RightGroupDto {
  return {
    id: params?.id ?? 0,
    order: params?.order ?? 0,
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
    initialConfig: params?.initialConfig ?? false,
  };
}

/**
 * Service groupe de droits pour récupérer les groupes et les modifier.
 */
class RightGroupService
  extends AxiosRequest
  implements RightGroupServiceInstance
{
  crudService: CrudServiceInstance<RightGroupDto>;

  /**
   * Constructeur de la class RightGroup service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<RightGroupDto>(rootPath);
  }

  /**
   * Liste les groupes
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre de groupes affichés par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer un groupe par son id
   * @param {string} id - id du groupe à récupérer
   */
  getSingle(id: string) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer un nouveau groupe
   * @param group - données du nouveau groupe à créer
   */
  insert(group: RightGroupDto) {
    return this.crudService.insert(group);
  }

  /**
   * Met à jour un groupe
   * @param group - nouvelles données du groupe à mettre à jour
   */
  update(group: RightGroupDto) {
    return this.crudService.update(group.id, group);
  }

  /**
   * Supprime un groupe
   * @param id - id du groupe à supprimer
   */
  delete(id: string) {
    return this.crudService.delete(id);
  }
}

export const rightGroupService = new RightGroupService("rightGroups");
