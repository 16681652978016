<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', right)">
    <q-input v-model="right.id" filled label="ID du droit" />
    <q-input
      v-model="right.labelFr"
      filled
      label="Intitulé du droit en français"
    />
    <q-input
      v-model="right.labelEn"
      filled
      label="Intitulé du droit en anglais"
    />
    <q-select
      v-model="right.idGroup"
      :options="rightGroups"
      :option-label="(g) => (isFr ? g.labelFr : g.labelEn)"
      :option-value="(g) => g.id"
      map-options
      emit-value
      filled
      label="Associé au groupe"
    />
    <div class="q-mt-md text-right">
      <q-btn :label="labelBtnSubmit" type="submit" color="primary" />
    </div>
  </q-form>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import type { RightDto } from "@/types/api";
import { toRefs } from "vue";
import { createRight } from "@/services/RightService";
import { useUserStore } from "@/stores/user";
import { useLocale } from "@/utils";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return "Enregistrer le droit";
    },
  },
  defaultRight: {
    type: Object as PropType<RightDto>,
    default() {
      return createRight();
    },
  },
});
defineEmits<{
  (e: "submit", value: RightDto): void;
}>();
const { rightGroups } = useUserStore();
const { isFr } = useLocale();
const { defaultRight: right } = toRefs(props);
</script>
