<!-- eslint-disable vue/no-v-html -->
<template>
  <div :class="Dark.isActive ? 'dark' : ''">
    <div class="history-menu">
      <div class="q-mb-md q-gutter-md row">
        <q-select
          v-model="type"
          :options="historyType"
          class="col"
          :label="$t('history.labels.type')"
          emit-value
          map-options
        />
        <q-select
          v-model="subtype"
          :options="historySubtypeByType[type]"
          class="col"
          :label="$t('history.labels.subtype')"
          emit-value
          map-options
        />
      </div>
      <div v-if="needToBeImported" class="q-mb-md q-gutter-md row">
        <div class="col">
          <q-banner rounded inline-actions class="text-white bg-red">
            {{ $t("history.labels.needToBeImported") }}
          </q-banner>
        </div>
      </div>
      <div class="q-mb-md q-gutter-md row">
        <q-btn
          :disable="!canMoveBefore"
          icon="navigate_before"
          rounded
          @click="navigateHistory(1)"
        />
        <q-select
          v-model="selectBefore"
          :options="SelectArticleHistory"
          class="col"
          :label="$t('history.labels.origin')"
          emit-value
          map-options
        />
        <router-link
          v-if="selectBefore"
          class="history-open-link"
          :to="{
            name: RoutesName.EditArticle,
            params: { id: selectBefore.id },
            query: { revisionId: getRevisionId(selectBefore) },
          }"
          target="_blank"
          >{{ $t("history.labels.btnOpen") }}</router-link
        >
        <q-select
          v-model="selectAfter"
          :options="SelectArticleAfterHistory"
          class="col"
          :label="$t('history.labels.modified')"
          emit-value
          map-options
        />
        <router-link
          v-if="selectAfter"
          class="history-open-link"
          :to="{
            name: RoutesName.EditArticle,
            params: { id: selectAfter.id },
            query: { revisionId: getRevisionId(selectAfter) },
          }"
          target="_blank"
          >{{ $t("history.labels.btnOpen") }}</router-link
        >
        <q-btn
          :disable="!canMoveNext"
          icon="navigate_next"
          rounded
          @click="navigateHistory(-1)"
        />
      </div>
    </div>
    <div v-if="type == 'advanced'" v-html="prettyHtml" />
    <div v-else class="q-mt-md q-gutter-y-md">
      <div v-for="option in getProps(type)" :key="option.label">
        <div class="q-mb-md q-gutter-md row">
          <div class="col fields">
            {{ option.label }}
          </div>
          <div class="col fields">
            {{ option.label }}
          </div>
        </div>
        <div class="q-mb-md q-gutter-md row">
          <div
            class="col editor compare-before ck-content"
            v-html="getDiff(beforeArticle, afterArticle, option.value)"
          ></div>
          <div
            class="col editor compare-after ck-content"
            v-html="getDiff(beforeArticle, afterArticle, option.value)"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type {
  ArticleContentDto,
  ArticleDto,
  ArticleHistoryDto,
  ArticleSectionDto,
  AssociationArticleDto,
  ContributionDto,
  CountryDto,
} from "@/types/api";
import { WorkflowState } from "@/types/api";
import type { QSelectOption } from "quasar";
import { date, Dark } from "quasar";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
// Correction d'import en erreur en mode prod https://github.com/vitejs/vite/issues/9061
import $htmlDiff from "htmldiff-js";
const HtmlDiff = $htmlDiff.default || $htmlDiff;
import * as Diff from "diff";
import * as Diff2Html from "diff2html";
import { RoutesName } from "@/router/routesName";

import "diff2html/bundles/css/diff2html.min.css";
import {
  getModificationTypeFromInt,
  sortContributionTypeMapping,
} from "@/components/Article/constant";
import {
  EnglishLanguage,
  FrenchLanguage,
  conf_elcanoapi_current,
} from "@/constants";
import { useSectionStore } from "@/stores/section";
import { useUserStore } from "@/stores/user";

const userStore = useUserStore();
const sectionStore = useSectionStore();
const i18n = useI18n();
const { query } = useRoute();

const props = defineProps<{
  histories: ArticleHistoryDto[];
}>();

const allHistories = computed(() => {
  const histories = props.histories.map((h) => createExtendedHistory(h));
  return histories;
});

const mask = "DD/MM/YYYY HH:mm";
const historyType = [
  { label: i18n.t(`languages.${FrenchLanguage}`), value: FrenchLanguage },
  { label: i18n.t(`languages.${EnglishLanguage}`), value: EnglishLanguage },
  { label: i18n.t(`history.labels.params`), value: "params" },
  { label: i18n.t(`history.labels.advanced`), value: "advanced" },
];
const historySubtypeByLanguage = [
  { label: i18n.t("history.labels.allModifications"), value: "all" },
  { label: i18n.t("history.labels.modificationsByUser"), value: "user" },
  { label: i18n.t("article.fields.format"), value: "format" },
  { label: i18n.t("article.fields.state"), value: "state" },
  { label: i18n.t("article.fields.title"), value: "title" },
  { label: i18n.t("article.fields.standfirst"), value: "standfirst" },
  { label: i18n.t("article.fields.text"), value: "text" },
  { label: i18n.t("article.fields.tweet"), value: "tweet" },
  { label: i18n.t("article.fields.summary"), value: "summary" },
  { label: i18n.t("articleSetting.fields.cms"), value: "settings" },
];
const historySubtypeByType = {
  [FrenchLanguage]: historySubtypeByLanguage,
  [EnglishLanguage]: historySubtypeByLanguage,
  params: [
    { label: i18n.t("history.labels.allModifications"), value: "all" },
    { label: i18n.t("history.labels.modificationsByUser"), value: "user" },
    { label: i18n.t("article.fields.publishedOn"), value: "publishedOn" },
    { label: i18n.t("article.fields.deadline"), value: "deadline" },
    { label: i18n.t("article.fields.sections"), value: "sections" },
    { label: i18n.t("article.fields.contributions"), value: "contributions" },
    { label: i18n.t("article.fields.countries"), value: "countries" },
    { label: i18n.t("articleSetting.fields.interest"), value: "settings" },
    {
      label: i18n.t("article.fields.publishedOnTranslationLabel"),
      value: "publishedOnTranslation",
    },
    { label: i18n.t("article.fields.summaryOfLabel"), value: "summaryOf" },
    {
      label: i18n.t("article.fields.summaryOfTranslationLabel"),
      value: "summaryOfTranslation",
    },
    {
      label: i18n.t("article.fields.nbAssociatedArticles"),
      value: "associatedArticles",
    },
  ],
  advanced: [
    { label: i18n.t("history.labels.allModifications"), value: "all" },
    { label: i18n.t("history.labels.modificationsByUser"), value: "user" },
  ],
};

const modificationTypeMap = {
  advanced: 0,
  params: 1,
  [FrenchLanguage]: 2,
  [EnglishLanguage]: 4,
};

const type = ref<string>(FrenchLanguage);
const subtype = ref("all");

const selectBefore = ref<ArticleHistoryDtoExtended | null>(null);
const selectAfter = ref<ArticleHistoryDtoExtended | null>(null);

if (query.revisionId) {
  watch(allHistories, () => {
    const revisionId = parseInt(query.revisionId as string, 10);
    const history = allHistories.value.find((h) => h.revisionId == revisionId);
    if (history) selectBefore.value = history;
  });
}

watch(type, () => {
  subtype.value = historySubType.value[0].value;
});

/**
 * Article history étendu
 */
interface ArticleHistoryDtoExtended extends ArticleHistoryDto {
  modification?: Record<string, boolean | undefined>;
  article: ArticleDto;
  [FrenchLanguage]?: ArticleContentDto;
  [EnglishLanguage]?: ArticleContentDto;
}

/**
 * Article history étendu
 */
interface ArticleHistoryParam {
  label: string;
  value: ArticleHistoryDtoExtended;
}

const historySubType = computed(() => {
  return historySubtypeByType[type.value];
});

const indexHistoryBefore = computed(() => {
  if (selectBefore.value == null) return undefined;

  const histories = SelectArticleHistory.value.map((h) => h.value);

  const currentParam = histories.find(
    (h) =>
      h.revisionId == selectBefore.value?.revisionId &&
      h.frenchRevisionId == selectBefore.value?.frenchRevisionId &&
      h.englishRevisionId == selectBefore.value?.englishRevisionId
  );
  if (!currentParam) return undefined;
  return histories.indexOf(currentParam);
});

const canMoveNext = computed(() => {
  const index = indexHistoryBefore.value;
  if (index === undefined) return false;

  return index > 1;
});

const canMoveBefore = computed(() => {
  const index = indexHistoryBefore.value;
  if (index === undefined) return false;

  return index < SelectArticleHistory.value.length - 1;
});

const needToBeImported = computed(() => {
  const typeIsLanguage =
    type.value === FrenchLanguage || type.value === EnglishLanguage;

  if (!typeIsLanguage) return false;

  const modificationType = modificationTypeMap[type.value] + 1;
  // Toutes les modifications de la langue et les paramètres
  const languageHistories = allHistories.value.filter(
    (h) => (h.modificationType & modificationType) > 0
  );

  if (languageHistories.length < 2) return false;

  const last = languageHistories[0];
  const lastDto = last.article;
  const lastContent = last[type.value];

  if (!last || !lastContent || lastContent.state !== WorkflowState.Importe)
    return false;

  const previous = languageHistories[1];
  const previousDto = previous.article;
  const previousContent = previous[type.value];
  if (
    !previous ||
    !previousContent ||
    previousContent.state !== WorkflowState.Importe
  )
    return false;

  return (
    lastDto.xmin !== previousDto.xmin ||
    lastContent.xmin !== previousContent.xmin
  );
});

/**
 * Création d'un article history étendu avec deconstuction du json.
 */
function createExtendedHistory(
  history: ArticleHistoryDto
): ArticleHistoryDtoExtended {
  const article = JSON.parse(history.jsonData);
  return {
    ...history,
    modification: {},
    article,
    [FrenchLanguage]: article.contents.find(
      (c: ArticleContentDto) => c.language === FrenchLanguage
    ),
    [EnglishLanguage]: article.contents.find(
      (c: ArticleContentDto) => c.language === EnglishLanguage
    ),
  };
}

const SelectArticleHistory = computed<
  QSelectOption<ArticleHistoryDtoExtended>[]
>(() => {
  let histories = allHistories.value.map((h) => ({
    label: getLabelHistory(h),
    value: h,
  }));

  const addToHistory = (
    histories: ArticleHistoryParam[],
    newHistory: ArticleHistoryParam
  ) => {
    const last = histories[histories.length - 1];

    if (
      subtype.value === "user" &&
      last &&
      last.value.idUser === newHistory.value.idUser
    )
      return;

    histories.push(newHistory);
    const typeIsLanguage =
      type.value === FrenchLanguage || type.value === EnglishLanguage;

    if (last && type.value) {
      const lastDto = last.value.article;
      const lastContent = !typeIsLanguage ? lastDto : last.value[type.value];

      const currentDto = newHistory.value.article;
      const currentContent = !typeIsLanguage
        ? currentDto
        : newHistory.value[type.value];

      historySubtypeByType[type.value].forEach((st) => {
        if (st.value !== "all" && st.value !== "user") {
          if (!last.value.modification) last.value.modification = {};
          last.value.modification[st.value] =
            lastContent &&
            currentContent &&
            getTextFromPropName(lastContent, st.value) !==
              getTextFromPropName(currentContent, st.value);
        }
      });
    }
  };

  let computedHistories: ArticleHistoryParam[] = [];

  for (let i = 0; i < histories.length; i++) {
    const history = histories[i];

    const modificationType = modificationTypeMap[type.value];

    if (i === histories.length - 1) {
      addToHistory(computedHistories, history);
      break;
    }

    if (
      (history.value.modificationType & modificationType) ===
      modificationType
    ) {
      addToHistory(computedHistories, history);
    }
  }

  if (computedHistories.length) {
    const lastHistory = computedHistories[computedHistories.length - 1];
    historySubtypeByType[type.value].forEach((st) => {
      if (st.value !== "all" && st.value !== "user") {
        if (!lastHistory.value.modification)
          lastHistory.value.modification = {};
        lastHistory.value.modification[st.value] = true;
      }
    });
  }

  return computedHistories.filter(
    (c) =>
      subtype.value === "all" ||
      subtype.value === "user" ||
      (c.value.modification && c.value.modification[subtype.value])
  );
});

const SelectArticleAfterHistory = computed<
  QSelectOption<ArticleHistoryDtoExtended>[]
>(() => {
  let histories: QSelectOption<ArticleHistoryDtoExtended>[] = [];

  if (indexHistoryBefore.value === undefined) return SelectArticleHistory.value;

  for (let i = 0; i <= indexHistoryBefore.value; i++) {
    histories.push(SelectArticleHistory.value[i]);
  }

  return histories;
});

const beforeArticle = computed(() => {
  if (!selectBefore.value) return null;
  return selectBefore.value.article;
});

const afterArticle = computed(() => {
  if (!selectAfter.value) return null;
  return selectAfter.value.article;
});

const beforeContent = computed(() => {
  if (!selectBefore.value) return null;
  return selectBefore.value[type.value];
});

const afterContent = computed(() => {
  if (!selectAfter.value) return null;
  return selectAfter.value[type.value];
});

/**
 * Recupère les propriétés à afficher.
 */
function getProps(typeOfObject: string) {
  return historySubtypeByType[typeOfObject].filter(
    (p) => p.value != "all" && p.value != "user"
  );
}

/**
 * Récupère la différence entre deux contenus
 * @param before Contenu avant
 * @param after Content après
 * @param prop Propriété à comparer
 * @param inverted Inverser les couleurs
 */
function getDiff(
  before: any | null | undefined,
  after: any | null | undefined,
  prop: string
) {
  if (!before || !after) return null;

  const typeIsLanguage =
    type.value === FrenchLanguage || type.value === EnglishLanguage;

  const b = typeIsLanguage ? beforeContent.value : before,
    a = typeIsLanguage ? afterContent.value : after;

  if (!b || !a) return null;

  return getDiffHtml(b, a, prop);
}

/**
 * Récupère la version text de la propriété
 * @param obj objet
 * @param prop propriété
 */
function getTextFromPropName(
  obj: ArticleDto | ArticleContentDto,
  prop: string
) {
  if (
    prop.startsWith("publishedOn") ||
    prop.startsWith("summaryOf") ||
    prop.startsWith("deadline")
  )
    return obj[prop]?.replace("T", " ")?.replace("Z", "") ?? "";

  if (prop === "sections") {
    let textSections = i18n.t("article.fields.mainSection") + " : ";
    let sections = obj[prop] as ArticleSectionDto[];
    const idMainSection = sections.find((s) => s.main)?.section;

    if (idMainSection) {
      textSections += sectionStore.sections.find((s) => s.id == idMainSection)
        ?.labelFr;
    }
    textSections += "<br>";

    const idSecondariesSections = sections
      .filter((s) => !s.main)
      .map((s) => s.section);

    if (idSecondariesSections) {
      textSections += i18n.t("article.fields.secondariesSections") + " : ";
      textSections += sectionStore.sections
        .filter((s) => idSecondariesSections.indexOf(s.id) >= 0)
        .map((s) => s.labelFr)
        .join(", ");
    }

    return textSections;
  }

  if (prop === "associatedArticles") {
    let associatedArticles = obj[prop] as AssociationArticleDto[];
    return associatedArticles.length.toString();
  }

  if (prop === "countries") {
    let countries = obj[prop] as CountryDto[];
    return countries.map((c) => c.labelFr).join(", ");
  }

  if (prop === "settings") {
    let settings = obj[prop];
    return settings.map((s) => s.labelFr).join(", ");
  }

  if (prop === "state") {
    return i18n.t(`article.states.${obj[prop]}`);
  }

  if (prop === "text") {
    let content = obj as ArticleContentDto;
    return content.textWithAcceptedSuggestions ?? content.text;
  }

  if (prop === "contributions") {
    let contributions = obj[prop] as ContributionDto[];

    let distinctContributionTypes = contributions.reduce((acc, curr) => {
      if (!acc[curr.type]) {
        acc[curr.type] = [];
      }
      try {
        acc[curr.type].push(
          userStore.findUserLight(curr.idContributor)?.name ?? ""
        );
      } catch {
        acc[curr.type].push(i18n.t("user.deleted"));
      }
      return acc;
    }, {});

    return Object.keys(distinctContributionTypes)
      .map((c) => ({
        value: c,
        label: i18n.t(`contributionType.${c}`),
        users: distinctContributionTypes[c],
      }))
      .sort((a, b) => {
        return (
          (sortContributionTypeMapping[a.value] ?? 0) -
          (sortContributionTypeMapping[b.value] ?? 0)
        );
      })
      .map((c) => {
        return `${c.label} : ${c.users.join(", ")}`;
      })
      .join("<br>");
  }

  return obj[prop] ?? "";
}

/**
 * Récupère la différence entre deux contenus
 * @param before Contenu avant
 * @param after Content après
 * @param prop Propriété à comparer
 * @param inverted Inverser les couleurs
 */
function getDiffHtml(
  before: ArticleContentDto | null | undefined,
  after: ArticleContentDto | null | undefined,
  prop: string
) {
  if (!before || !after) return null;

  return HtmlDiff.execute(
    getTextFromPropName(before, prop).replace(
      "[[API_DOMAIN]]",
      conf_elcanoapi_current
    ),
    getTextFromPropName(after, prop).replace(
      "[[API_DOMAIN]]",
      conf_elcanoapi_current
    )
  );
}

watch(
  () => SelectArticleHistory.value,
  () => {
    selectAfter.value = SelectArticleHistory.value.length
      ? SelectArticleHistory.value[0].value
      : null;
    selectBefore.value =
      SelectArticleHistory.value.length > 1
        ? SelectArticleHistory.value[1].value
        : selectAfter.value;
  }
);

const diffs = computed<string>(() => {
  if (!selectBefore.value || !selectAfter.value) return "";

  const beforeParsed = selectBefore.value.article;
  const afterParsed = selectAfter.value.article;

  return Diff.createTwoFilesPatch(
    "diff",
    "diff",
    JSON.stringify(cleanArticleJson(beforeParsed), null, " "),
    JSON.stringify(cleanArticleJson(afterParsed), null, " ")
  );
});

const prettyHtml = computed<string>(() =>
  Diff2Html.html(diffs.value, {
    drawFileList: false,
    matching: "lines",
    outputFormat: "side-by-side",
    rawTemplates: {
      "generic-file-path": "",
    },
  })
);

/**
 * Permet de générer le label d'un historique
 * @param history historique
 */
function getLabelHistory(history: ArticleHistoryDto) {
  const message: string[] = [];
  let suffix = "";
  if (type.value === "advanced") {
    getModificationTypeFromInt(history.modificationType).forEach((m) => {
      message.push(i18n.t("modificationTypes." + m));
    });
    suffix = ", " + message.join(", ");
  }
  return (
    date.formatDate(history.lastUpdate, mask) +
    " par " +
    history.user.name +
    suffix
  );
}

/**
 * Permet de naviguer plus facilement entre les différents historiques
 */
function navigateHistory(index: number) {
  if (!selectBefore.value) return;
  const histories = SelectArticleHistory.value.map((h) => h.value);
  const currentIndex = indexHistoryBefore.value;
  if (currentIndex === undefined) return;

  if (currentIndex + index < 0 || currentIndex + index >= histories.length)
    return;

  if (currentIndex + index - 1 < 0) return;

  selectBefore.value = histories[currentIndex + index];
  selectAfter.value = histories[currentIndex + index - 1];
}

/**
 * Permet de récupérer le plus grand revisionId
 * @param history historique
 */
function getRevisionId(history: ArticleHistoryDto) {
  return `${history.revisionId}/${history.frenchRevisionId ?? 0}/${
    history.englishRevisionId ?? 0
  }`;
}

/**
 * Duplique un objet sans les propriété lastUpdate et xmin
 */
function duplicateObjectForDiff(obj: any) {
  const keysToOmit = ["lastUpdate", "xmin"];
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keysToOmit.includes(key))
  );
}

/**
 * Fonction pour retirer certains champs non nécessaires dans l'historique
 */
function cleanArticleJson(article: ArticleDto) {
  const art = duplicateObjectForDiff(article);

  const contents = article.contents.map((content) => {
    return duplicateObjectForDiff(content);
  });
  return {
    ...art,
    contents,
  };
}
</script>

<style lang="scss" scoped>
@import "@/styles/_styleArticle.scss";

.history-menu {
  position: sticky;
  top: 45px;
  background-color: white;
  padding-bottom: 20px;
}

.body--dark {
  .history-menu {
    background-color: $dark;
  }
  .history-open-link {
    &,
    &:visited {
      color: white;
    }
  }
}

.fields {
  font-size: larger;
  font-weight: bold;
}

.history-open-link {
  margin: auto;
  padding-top: 20px;
  &:visited {
    color: black;
  }
  color: black;
}

:deep(.ck-content mark) {
  background-color: inherit !important;
  color: inherit !important;
}

/**
 * La lib "diff2html" n'a pas de theme dark pour l'instant. Suivre l'issue ici : https://github.com/rtfpessoa/diff2html/issues/403
 */
.dark :deep(.d2h-file-wrapper) {
  border-color: rgba(0, 0, 0, 0.3);
}
.dark :deep(.d2h-file-side-diff) {
  color-scheme: dark;
}
.dark :deep(.d2h-file-header) {
  background-color: rgba(0, 0, 0, 0.3);
  border-bottom-color: rgba(0, 0, 0, 0.3);
}
.dark :deep(.d2h-code-side-linenumber) {
  background-color: rgba(0, 0, 0, 0.87);
  border: none;
  color: rgba(255, 255, 255, 0.3);
  padding-top: 2px;
  padding-bottom: 2px;
}
.dark :deep(.d2h-info) {
  background-color: rgba(56, 139, 253, 0.15);
  color: #8b949e;
}
.dark :deep(.d2h-del) {
  background-color: rgba(248, 81, 73, 0.15);
}
.dark :deep(.d2h-del) .d2h-code-side-line del {
  background-color: rgba(248, 81, 73, 0.4);
}
.dark :deep(.d2h-ins) {
  background-color: rgba(46, 160, 67, 0.15);
}
.dark :deep(.d2h-ins) .d2h-code-side-line ins {
  background-color: rgba(46, 160, 67, 0.4);
}

:deep(.ck-content img) {
  flex-grow: 1;
  flex-shrink: 1;
  max-width: 100%;
}
</style>
