<template>
  <router-link
    v-if="title"
    :to="{
      name: RoutesName.EditArticle,
      params: { id: id },
      query: { lang: language },
    }"
    target="_blank"
    class="title-link"
  >
    <div :title="title" :class="linkClass">
      {{ title }}&nbsp;<q-icon v-if="hasMedia" name="image" style="top: -3px" />
      <q-icon
        v-if="articleSerials.includes(id)"
        name="newspaper"
        style="top: -2px"
      />
      <q-icon
        v-if="hasUpgradeParamCMS"
        name="upgrade"
        style="top: -3px"
        size="22px"
      />
      <q-icon
        v-if="isFree"
        name="money_off"
        style="top: -3px; font-weight: bold"
      />
      <CellTitleLockTemplate :id="id" :article-locks="articleLocks" />
    </div>
  </router-link>
</template>
<script setup lang="ts">
import type { ArticleLockDto } from "@/types/api";
import { computed, toRefs } from "vue";
import CellTitleLockTemplate from "./CellTitleLockTemplate.vue";
import { RoutesName } from "@/router/routesName";

const props = defineProps<{
  id: number;
  title: string | undefined;
  language: string;
  hasMedia: boolean;
  isFree: boolean;
  articleLocks: ArticleLockDto[];
  articleSerials: number[];
  subStyle?: boolean;
  hasUpgradeParamCMS: boolean;
}>();

const linkClass = computed(() => {
  return props.subStyle ? "q-mt-xs text-grey-6" : "";
});

const { articleLocks, articleSerials } = toRefs(props);
</script>
<style lang="scss" scoped>
.title-link {
  white-space: normal;
  font-size: 1.1em;
  line-height: 1.5;
  text-decoration: none;
  color: inherit;
  font-family: "CooperHewitt-SemiBold", sans-serif;
}
</style>
