<template>
  <q-list separator>
    <q-item v-for="(shortcut, idx) in listShortcuts" :key="idx">
      <q-item-section :no-wrap="$q.screen.gt.xs" class="col-3 kbd-shortcut">
        <div>
          <template v-for="(key, idx2) in shortcut.shortcut" :key="idx2">
            <small v-if="idx2 > 0"> + </small>
            <kbd>
              {{ key }}
            </kbd>
          </template>
        </div>
      </q-item-section>
      <q-item-section>{{ shortcut.label }}</q-item-section>
    </q-item>
  </q-list>
</template>

<script setup lang="ts">
import { i18n } from "@/i18n";
const listShortcuts = [
  [i18n.t("ckeditor.shortcuts.labels.gras"), ["CTRL", "B"]],
  [i18n.t("ckeditor.shortcuts.labels.italique"), ["CTRL", "I"]],
  [i18n.t("ckeditor.shortcuts.labels.souligner"), ["CTRL", "U"]],
  [i18n.t("ckeditor.shortcuts.labels.rayer"), ["CTRL", "ALT", "D"]],
  [i18n.t("ckeditor.shortcuts.labels.lien"), ["CTRL", "K"]],
  [i18n.t("ckeditor.shortcuts.labels.indice"), ["CTRL", "ALT", "D"]],
  [i18n.t("ckeditor.shortcuts.labels.exposant"), ["CTRL", "ALT", "U"]],
  [i18n.t("ckeditor.shortcuts.labels.surlignageBleu"), ["CTRL", "ALT", "B"]],
  [i18n.t("ckeditor.shortcuts.labels.surlignageJaune"), ["CTRL", "ALT", "Y"]],
  [i18n.t("ckeditor.shortcuts.labels.surlignageVert"), ["CTRL", "ALT", "G"]],
  [i18n.t("ckeditor.shortcuts.labels.surlignageRouge"), ["CTRL", "ALT", "R"]],
  [
    i18n.t("ckeditor.shortcuts.labels.retraitSurlignage"),
    ["CTRL", "ALT", "BACKSPACE"],
  ],
  [i18n.t("ckeditor.shortcuts.labels.miseEnForme"), ["CTRL", "ALT", "SPACE"]],
  [i18n.t("ckeditor.shortcuts.labels.trackchanges"), ["CTRL", "M"]],
  [i18n.t("ckeditor.shortcuts.labels.commentaire"), ["CTRL", "ALT", "C"]],
  [i18n.t("ckeditor.shortcuts.labels.text"), ["CTRL", "ALT", "T"]],
  [i18n.t("ckeditor.shortcuts.labels.intertitre"), ["CTRL", "ALT", "I"]],
  [i18n.t("ckeditor.shortcuts.labels.print"), ["CTRL", "ALT", "SHIFT", "P"]],
  [i18n.t("ckeditor.shortcuts.labels.switchDisplay"), ["CTRL", "SHIFT", ">"]],
  [i18n.t("ckeditor.shortcuts.labels.caps"), ["CTRL", "ALT", "M"]],
  [i18n.t("ckeditor.shortcuts.labels.unbreakableSpace"), ["CTRL", "SPACE"]],
].map((shortcut) => ({ label: shortcut[0], shortcut: shortcut[1] }));
</script>
<style scoped lang="scss">
kbd {
  display: inline-block;
  padding: 2px 7px;
  border: 1px solid #ccc;
  border-radius: 3px;
  box-shadow: inset 0 -1px 0 #ccc;
}
kbd + kbd {
  margin-left: 5px;
}
.kbd-shortcut {
  min-width: 200px !important;
}
</style>
