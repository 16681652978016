<template>
  <div v-if="language === 'all'">
    <div v-if="hasContent(FrenchLanguage)">
      <StateItem v-bind="stateInfos[FrenchLanguage]" />
    </div>
    <div v-if="hasContent(EnglishLanguage)">
      <StateItem v-bind="stateInfos[EnglishLanguage]" />
    </div>
  </div>
  <div v-else>
    <div v-if="hasContent(language)">
      <StateItem v-bind="stateInfos[language]" />
    </div>
    <div v-if="hasContent(otherLanguage)">
      <StateItem v-bind="stateInfos[otherLanguage]" />
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  WorkflowState,
  ArticleODataDto,
  ArticleContentODataDto,
} from "@/types/api";
import { toRefs, computed } from "vue";
import { date } from "quasar";
import { FrenchLanguage, EnglishLanguage } from "@/constants";
import { i18n } from "@/i18n";
import StateItem from "@/components/Article/ListArticles/StateItem.vue";
/**
 * Interface StateInfos
 */
export interface StateInfos {
  state: WorkflowState;
  label: string;
  languageLabel: string;
  language: string;
  publishedInfo?: string;
}

const props = defineProps<{
  data: ArticleODataDto;
  language: string;
  otherLanguage: string;
}>();
const { data } = toRefs(props);

const contentFr = computed(() => {
  return data.value.contents.find((c) => c.language === FrenchLanguage);
});
const contentEn = computed(() => {
  return data.value.contents.find((c) => c.language === EnglishLanguage);
});

const stateInfos = computed(() => {
  return {
    [FrenchLanguage]: contentFr.value
      ? getDataState(contentFr.value)
      : { state: WorkflowState.Pitch },
    [EnglishLanguage]: contentEn.value
      ? getDataState(contentEn.value)
      : { state: WorkflowState.Pitch },
  };
});

/** Verifie la présence d'une langue dans l'article */
function hasContent(language: string) {
  return data.value.contents.some((c) => c.language === language);
}

/**
 *
 */
function getDataState(content: ArticleContentODataDto) {
  const isTranslation = data.value.idMainLanguage !== content.language;
  let publishedInfo: string | undefined = undefined;

  if (isTranslation) {
    if (data.value.publishedOnTranslation && data.value.summaryOfTranslation) {
      publishedInfo = i18n.t("article.labels.publishedAndSummaryInfo", {
        publishedOn: date.formatDate(
          data.value.publishedOnTranslation,
          "DD/MM/YYYY"
        ),
        summaryOf: date.formatDate(
          data.value.summaryOfTranslation,
          "DD/MM/YYYY"
        ),
      });
    } else if (data.value.publishedOnTranslation) {
      publishedInfo = i18n.t("article.labels.publishedInfo", {
        publishedOn: date.formatDate(
          data.value.publishedOnTranslation,
          "DD/MM/YYYY"
        ),
      });
    }
  } else if (data.value.summaryOf) {
    publishedInfo = i18n.t("article.labels.summaryInfo", {
      summaryOf: date.formatDate(data.value.summaryOf, "DD/MM/YYYY"),
    });
  }

  return {
    state: content.state,
    language: content.language,
    publishedInfo,
  };
}
</script>
