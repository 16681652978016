import type { CrudServiceInstance } from "@/types/crud";
import type { FormatDto } from "@/types/api";
import type { FormatServiceInstance } from "@/types/format";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un object type format
 */
export function createFormat(params: Partial<FormatDto> = {}): FormatDto {
  return {
    id: params?.id ?? "",
    active: params?.active ?? false,
    long: params?.long ?? false,
    formatPublications: params?.formatPublications ?? [],
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
    maxLength: params?.maxLength ?? 6000,
  };
}

/**
 * Service format pour récupérer les formats et les modifier.
 */
class FormatService extends AxiosRequest implements FormatServiceInstance {
  crudService: CrudServiceInstance<FormatDto>;

  /**
   * Constructeur de la class Format service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<FormatDto>(rootPath);
  }

  /**
   * Liste les formats
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre de formats affichés par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer un format par son id
   * @param {number | string} id - id du format à récupérer
   */
  getSingle(id: string | number) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer un nouveau format
   * @param format - données du nouveau format à créer
   */
  insert(format: FormatDto) {
    return this.crudService.insert(format);
  }

  /**
   * Met à jour un format
   * @param format - nouvelles données du format à mettre à jour
   */
  update(format: FormatDto) {
    return this.crudService.update(format.id, format);
  }
}

export const formatService = new FormatService("formats");
