import type { ArticleSettingDto } from "@/types/api";
import type { ArticleSettingViewActions } from "@/types/articleSetting";
import { toRefs, reactive } from "vue";
import { useRouter } from "vue-router";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { createArticleSetting } from "@/services/ArticleSettingService";
import { useArticleSettingStore } from "@/stores/articleSetting";
import { usePage } from "@/composables/usePage";
import { RoutesName } from "@/router/routesName";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions articleSetting
 */
interface ArticeSettingActionState {
  articleSetting: ArticleSettingDto;
  callbackOnSubmit?: (articleSettingData: ArticleSettingDto) => void;
}

/**
 * Fonction servant à interagir sur les différentes vues article setting
 */
export const useArticleSettingActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const {
    deleteArticleSetting,
    findArticleSetting,
    addArticleSetting,
    updateArticleSetting,
  } = useArticleSettingStore();
  const state = reactive<ArticeSettingActionState>({
    articleSetting: createArticleSetting(),
    callbackOnSubmit: undefined,
  });
  const { showPanelRight } = usePage();

  const articleSettingActions: ArticleSettingViewActions = {
    createArticleSetting() {
      showPanelRight.value = true;
      state.articleSetting = createArticleSetting();
      state.callbackOnSubmit = articleSettingActions.onCreateArticleSetting;
    },
    findArticleSetting(articleSettingId: number) {
      findArticleSetting(articleSettingId)
        .then((form) => {
          state.articleSetting = form;
          state.callbackOnSubmit = articleSettingActions.onEditArticleSetting;
        })
        .catch((err: Error) => {
          $q.notify(err.message);
          router.push({ name: RoutesName.ArticleSettings });
        });
    },
    editArticleSetting(articleSettingId: number) {
      showPanelRight.value = true;
      articleSettingActions.findArticleSetting(articleSettingId);
    },
    onCreateArticleSetting(articleSettingData: ArticleSettingDto) {
      addArticleSetting(articleSettingData)
        .then(() => {
          showPanelRight.value = false;
          $q.notify(i18n.t("articleSetting.messages.created"));
          router.push({ name: RoutesName.ArticleSettings });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditArticleSetting(articleSettingData: ArticleSettingDto) {
      updateArticleSetting(articleSettingData)
        .then(() => {
          $q.notify(i18n.t("articleSetting.messages.updated"));
          router.push({ name: RoutesName.ArticleSettings });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onDeleteArticleSetting(articleSettingId: number) {
      $q.dialog({
        title: i18n.t("articleSetting.dialogs.titles.confirm"),
        message: i18n.t("articleSetting.dialogs.messages.delete"),
        cancel: true,
        persistent: true,
      }).onOk(() => {
        deleteArticleSetting(articleSettingId)
          .then(() => {
            showPanelRight.value = false;
            $q.notify(i18n.t("articleSetting.messages.deleted"));
            router.push({ name: RoutesName.ArticleSettings });
          })
          .catch((err: Error) => $q.notify(err.message));
      });
    },
  };
  return { ...toRefs(state), actions: articleSettingActions };
});
