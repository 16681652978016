<template>
  <q-dialog ref="dialogRef" persistent>
    <q-card class="q-dialog-plugin" style="width: 330px; max-width: 80vw">
      <q-card-section>
        <q-date v-model="newValue" :options="options" :mask="mask" />
      </q-card-section>
      <q-card-actions align="center" class="q-gutter-md">
        <q-btn
          :disable="!publishedOn"
          :label="$t('article.dialogs.messages.save')"
          color="green-7"
          size="md"
          @click="onChangePublishedOn"
        />
        <q-btn
          :label="$t('article.dialogs.messages.cancel')"
          color="grey"
          size="md"
          @click="dialogRef?.hide()"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script setup lang="ts">
import { ArticleDto } from "@/types/api";
import { useDialogPluginComponent } from "quasar";
import { ref, watch } from "vue";
const mask = ref<string>("YYYY-MM-DD");

const props = defineProps<{
  publishedOn: string;
  article: ArticleDto | undefined;
  options?: string[] | ((date: string) => boolean);
}>();
const emits = defineEmits<{
  (
    e: "update:publishedOn",
    value: {
      publishedOn: string;
      article: ArticleDto | undefined;
    }
  ): void;
}>();
watch(
  () => props.publishedOn,
  (value) => {
    newValue.value = value;
  }
);
const newValue = ref<string>(props.publishedOn);
const { dialogRef } = useDialogPluginComponent();

/**
 *
 */
function onChangePublishedOn() {
  emits("update:publishedOn", {
    publishedOn: newValue.value ? newValue.value + "T00:00:00.000" : "",
    article: props.article,
  });
  dialogRef.value?.hide();
}
</script>
