<!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div
      class="row q-gutter-x-md absolute-top-right print-hide q-mt-xs q-mr-xs"
    >
      <div>
        <div class="column">
          <q-checkbox
            v-model="withSuggestions"
            :label="$t('preview.labels.withSuggestions')"
          />
          <q-checkbox
            v-model="withMarkers"
            :label="$t('preview.labels.withMarkers')"
          />
          <q-checkbox
            v-model="withMedias"
            :label="$t('preview.labels.withMedias')"
          />
        </div>
      </div>
      <div>
        <q-btn
          color="primary"
          :label="$t('preview.labels.btnPrint')"
          icon="print"
          @click="printElement()"
        />
      </div>
    </div>
    <div v-show="false">
      <div ref="editorDiv" class="editor">
        <ckeditor
          v-if="isEnabledEditor"
          ref="currentEditor"
          :editor="editor"
          :config="editorConfig"
          @ready="onCKEditorReady"
        >
        </ckeditor>
      </div>
    </div>
    <div v-if="showArticles">
      <div
        v-for="(article, i) of previews"
        :key="i"
        :class="{
          previewContainer: true,
          'ck-content': true,
          editor: true,
          'previewContainer-without-image': !withMedias,
        }"
      >
        <h1 class="doc-title">
          <q-img
            :alt="article.publication"
            style="width: 55px; margin-top: -25px"
            :src="`/img/${article.publication}.png`"
          />
          {{ article.content.title }}
        </h1>
        <div class="doc-resume">{{ article.content.standfirst }}</div>
        <div class="article" v-html="article.text"></div>
        <div v-if="article.signature.length" class="doc-signature">
          {{ setSignature(article.signature) }}
        </div>

        <div v-if="article.content.tweet">
          <q-separator />
          <div class="row doc-tweet">
            <q-chip :label="$t('article.fields.tweet')" color="primary" />
            {{ article.content.tweet }}
          </div>
        </div>

        <div v-if="article.content.medias.length">
          <q-separator />
          <div class="row doc-medias">
            <q-chip :label="$t('article.fields.medias')" color="primary" />
            <ul>
              <li v-for="(media, j) of article.content.medias" :key="j">
                <div class="doc-media-path">
                  {{ media.filePath }}
                </div>
                <div class="doc-media-legend">
                  {{ $t("media.fields.legend") }} :
                  {{
                    article.content.language === FrenchLanguage
                      ? media.legendFr
                      : media.legendEn
                  }}
                </div>
                <div class="doc-media-copyright">
                  {{ $t("media.fields.copyright") }} :
                  {{
                    article.content.language === FrenchLanguage
                      ? media.copyrightFr
                      : media.copyrightEn
                  }}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div v-if="i < previews.length - 1" class="spacer"></div>
      </div>
    </div>
    <q-inner-loading
      :showing="!showArticles"
      :label="$t('messages.waiting')"
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script lang="ts" setup>
import { onMounted, ref, watch } from "vue";
import { usePrintPreview } from "@/composables/usePreview";
import { PreviewDto } from "@/types/preview";
import { useRoute } from "vue-router";
import { FrenchLanguage, EnglishLanguage } from "@/constants";
import { useLocale } from "@/utils";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getCkInstance, useCkEditor } from "@/composables/useCkEditor";
const { editor, editorConfig } = useCkEditor();

let editorDiv = ref<HTMLElement | null>(null);
let editorInstance: ClassicEditor | null = null;

const withSuggestions = ref<boolean>(false);
const withMarkers = ref<boolean>(false);
const withMedias = ref<boolean>(true);
const isEnabledEditor = ref<boolean>(false);
const showArticles = ref(false);
const language = ref<string>(useLocale().language.value);
const route = useRoute();
const props = defineProps<{
  articleIds: string;
}>();

let previews = ref<PreviewDto[]>([]);

onMounted(() => {
  editorConfig.collaboration = { channelId: "preview" };
  isEnabledEditor.value = true;
  if ("lang" in route.query) {
    language.value = (route.query.lang as string).startsWith("en")
      ? EnglishLanguage
      : FrenchLanguage;
  }
  usePrintPreview(
    props.articleIds.split(",").map((el) => Number(el)),
    language.value
  ).then((res) => {
    previews.value = res;
    setText();
  });
});

watch([withSuggestions, withMarkers], () => setText());

/**
 * Fonction appelé lorsque ckeditor est prêt
 */
function setText() {
  showArticles.value = false;

  if (!editorInstance) return;

  previews.value.forEach((el) => {
    let text = el.content.textWithAcceptedSuggestions ?? el.content.text;
    if (withSuggestions.value && editorInstance) {
      editorInstance.setData(el.content.text);
      const getDataOption = {
        showSuggestionHighlights: true,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore:next-line
      text = editorInstance.getData(getDataOption);
    }
    el.text = replaceMarkByStyle(text);
  });

  showArticles.value = true;
}

/**
 *
 */
function setSignature(listSignature: (string | undefined)[]) {
  return listSignature.join(", ");
}

/**
 * Fonction qui remplace le texte en paramètre par un texte avec les balises mark remplacées par un style inline
 * La fonction prend en charger les mark avec les classes marker-yellow marker-blue marker-red marker-green
 * @param text texte à traiter
 * @returns texte avec les balises mark remplacées par un style inline
 */
function replaceMarkByStyle(text: string): string {
  if (withMarkers.value) return text;
  let newText = text;
  const regex = /<mark class="marker-(\w+)">(.+?)<\/mark>/g;

  let match = regex.exec(text);
  while (match != null) {
    newText = newText.replace(match[0], match[2]);
    match = regex.exec(text);
  }
  return newText;
}

/**
 * Impression du contenu de l'article
 */
function printElement() {
  window.print();
}

/**
 * Fonction appelé lorsque ckeditor est prêt
 */
function onCKEditorReady() {
  editorInstance = getCkInstance(editorDiv.value) ?? null;
  setText();
}
</script>
<style lang="scss">
@import "@/styles/_styleArticle.scss";
.previewContainer-without-image {
  .article {
    figure,
    img {
      display: none !important;
    }
  }
}

.previewContainer {
  width: 900px;
  margin: auto;

  .article {
    font-size: 17px;
  }

  .doc-title {
    margin-top: 30px;
    margin-bottom: 30px;
    font-family: "CooperHewitt-Heavy", sans-serif;
    font-size: 46px;

    line-height: 1.1;
  }
  .doc-resume {
    margin-bottom: 30px;
    font-family: "NotoSerif-Bold", serif;
    font-size: 22px;
    line-height: normal;
  }
  .doc-body,
  .doc-resume {
    text-align: justify;
    text-align-last: left;
  }
  a {
    text-decoration: none;
  }
  .doc-body,
  .doc-copyright {
    font-family: "NotoSerif-Regular", serif;
    font-size: 18px;
    line-height: normal;
  }

  .doc-body img {
    max-width: 100%;
  }
  .doc-tweet {
    line-height: 33px;
  }

  .doc-medias {
    .q-chip {
      color: white !important;
    }
  }

  .doc-signature {
    position: relative;
    margin-top: 30px;
    margin-bottom: 40px;
    text-align: right;
    font-family: "NotoSerif-Regular", serif;
    font-size: 20px;
    &:before {
      content: "";
      position: absolute;
      bottom: -10px;
      right: 0;
      width: 90px;
      height: 4px;
      background-color: var(--q-primary);
    }
  }

  .interTitre {
    margin-bottom: 30px;
    font-family: "CooperHewitt-Heavy", sans-serif;
    font-size: 24px;
    font-weight: 600;
  }

  .label {
    padding: 4px 7px;
    font-weight: 800;
    text-shadow: none;
    line-height: 1.2 !important;
    color: #fff;
    text-align: center;
    white-space: nowrap;
  }

  .label.label-date,
  .label.label-default,
  .label.label-free,
  .label.label-important,
  .label.label-primary,
  .label.label-secondary,
  .label.label-text {
    margin-right: 4px;
    margin-bottom: 5px;
    display: inline-block;
    vertical-align: middle;
    text-transform: uppercase;
    font-family: "CooperHewitt-Semibold", sans-serif;
    font-size: 12px;
    letter-spacing: 0.4px;
  }

  .label-secondary {
    background-color: var(--q-alternative);
  }

  a,
  strong {
    text-decoration: none !important;
    color: black !important;
  }

  .encadreBlock {
    position: relative;
    padding: 0 35px 35px;
    background-color: #eaeff1;
    border: 1px solid transparent;
    break-inside: avoid;
  }

  .encadreTitre {
    margin-top: 0;
    margin-bottom: 28px;
    font-family: "CooperHewitt-Heavy", sans-serif;
    font-size: 30px;
    background: #597387;
    color: #fff;
    box-shadow:
      20px 0 0 #597387,
      -20px 0 0 #597387;
    padding: 5px 0;
    line-height: 38px;
    width: fit-content;
  }
  .spacer {
    margin: 40px auto;
    width: 50%;
    border-top: 2px solid var(--q-primary);
  }
  :deep(.ck-suggestion-marker-insertion) {
    background-color: #00ff00;
    color: #000000;
  }
  :deep(.ck-suggestion-marker-deletion) {
    background-color: #ff0000;
    color: #ffffff;
    text-decoration: line-through;
  }
  :deep(.ck-suggestion-marker-formatInline) {
    background-color: #443f3f;
    color: #ffffff;
    text-decoration: underline;
  }
  @media print {
    body {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
    :deep(.ck-suggestion-marker) {
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }
}
.loading {
  margin: auto;
  display: table;
  margin-top: 200px;
  font-size: 24px;
  font-weight: bold;
}
@media print {
  body {
    background-color: transparent !important;
    color: inherit !important;
    padding: 10px;
  }
  #app {
    height: auto !important;
  }
}
</style>
