<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="absolute wrapper">
    <div style="text-align: center">
      <img
        src="/img/logo-indigo-mini.svg"
        alt="logo"
        style="max-width: 250px; height: auto"
        class="q-mb-md"
      />
      <br />
      <p class="text-h5 text-weight-light" v-html="$t('messages.welcome')"></p>
    </div>
  </div>
</template>
<style scoped>
.wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50vh;
  height: 50vh;
  border-radius: 50%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
