<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <q-card
    class="article shadow-1 searchResultItem"
    @copy="cleanContentBeforeCopy"
  >
    <q-card-section
      :style="'background-color:' + theme?.primary"
      class="q-pa-none"
    >
      <q-item>
        <q-item-section>
          <q-item-label
            class="linkPublication text-center text-subtitle2 q-gutter-sm"
            style="display: flex; align-items: center; justify-content: center"
          >
            <span>
              {{
                $t("search.fields.publicationInfo", {
                  publication: getInfoArticle(article, localeSelected)
                    .publicationName,
                  date: getInfoArticle(article, localeSelected).publicationDate,
                })
              }}
            </span>
            <q-btn
              v-if="canCopyUrl"
              color="white"
              text-color="white"
              round
              outline
              size="xs"
              :icon="iconClipBoard"
              @click="
                copyHtml(
                  getInfoArticle(article, localeSelected)
                    .linkToArticleLabelWithLinkWithoutIgnoreValide
                );
                iconClipBoard = 'assignment_turned_in';
              "
            >
              <q-tooltip>
                {{
                  $t("search.action.copyLinkArticleTooltip", {
                    link: getInfoArticle(article, localeSelected)
                      .linkToArticleLabel,
                  })
                }}
              </q-tooltip>
            </q-btn>
            <q-btn
              v-if="getInfoArticle(article).linkArticleWebsite"
              color="white"
              text-color="white"
              round
              outline
              size="xs"
              icon="open_in_browser"
              @click="
                openLink(
                  getInfoArticle(article, localeSelected).linkArticleWebsite
                )
              "
            >
              <q-tooltip>
                {{ $t("search.action.openWebsiteLinkArticleTooltip") }}
              </q-tooltip>
            </q-btn>
          </q-item-label>
          <q-item-label caption>
            <div class="q-mb-xs">
              <q-chip
                v-for="country in article.countries"
                :key="country?.id"
                outline
                square
                :clickable="true"
                color="white"
                :style="`color: ${theme?.primary ?? 'black'}`"
                size="sm"
                @click="$emit('click-on-country', country?.id)"
              >
                <q-avatar
                  icon="flag"
                  color="white"
                  :style="`color: ${theme?.primary ?? 'black'}`"
                />
                {{ country[getLabelPropertyFromLocale()] }}
              </q-chip>
              <q-chip
                square
                icon="check"
                color="white"
                :clickable="true"
                :label="
                  getMainSection(article.sections)?.[
                    getLabelPropertyFromLocale()
                  ]
                "
                :style="`color: ${theme?.primary ?? 'black'}`"
                size="sm"
                @click="
                  $emit(
                    'click-on-section',
                    getMainSection(article.sections)?.id ?? 0
                  )
                "
              />
              <q-chip
                v-for="section in getSecondarySections(article.sections)"
                :key="section?.id"
                square
                color="white"
                :style="`color: ${theme?.primary ?? 'black'}`"
                :clickable="true"
                :label="section?.[getLabelPropertyFromLocale()]"
                size="sm"
                @click="$emit('click-on-section', section?.id ?? 0)"
              />
            </div>
            <router-link
              :to="{
                name: RoutesName.EditArticle,
                params: { id: article.id },
              }"
              target="_blank"
              class="title"
              v-html="
                getArticleContentByLanguage(article.contents, defaultLocale)
                  ?.title
              "
            />
          </q-item-label>
        </q-item-section>
        <q-item-section v-if="articleSelectable" side>
          <q-btn
            class="shadow-1"
            round
            outline
            color="black"
            :icon="
              !itemIsSelected ? 'add_shopping_cart' : 'remove_shopping_cart'
            "
            :ripple="{ color: 'yellow' }"
            @click="articleSelectionEmiter()"
          />
        </q-item-section>
      </q-item>
    </q-card-section>

    <q-separator
      :size="'5px'"
      :style="`background-color: ${theme?.primary ?? 'black'}`"
    />
    <q-card-section
      v-if="getStandFirst(article)"
      class="standfirst"
      v-html="getStandFirst(article)"
    />
    <q-card-section
      v-if="!onlyTitleAndStandFirst"
      class="editor ck-content mark--none"
      v-html="getText()"
    />
    <q-card-section v-if="!onlyTitleAndStandFirst">
      <div class="row items-center q-gutter-x-sm">
        <q-list dense>
          <q-item
            v-for="(contribType, idx) in Array.from(
              new Set(article.contributions.map((c) => c.type))
            )"
            :key="'contribution' + idx"
            class="contribution-item"
          >
            <q-item-section
              v-if="getContributorsName(article, contribType).length > 0"
            >
              <div class="row text-grey-6 no-wrap" style="font-size: 0.9em">
                <div
                  style="
                    width: 60px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  "
                >
                  {{ $t(`contributionType.${contribType}`) }}
                </div>

                <div class="col-auto col-contributor">
                  <div
                    v-for="(contribname, idxConrtibName) in getContributorsName(
                      article,
                      contribType
                    )"
                    :key="'contrib' + idxConrtibName"
                  >
                    {{ contribname }}
                  </div>
                </div>
              </div>
            </q-item-section>
          </q-item>
        </q-list>
      </div>
    </q-card-section>
    <q-card-actions v-if="articleSelectable" align="center">
      <q-btn
        outline
        color="black"
        :icon="!itemIsSelected ? 'add_shopping_cart' : 'check_circle'"
        :ripple="{ color: 'yellow' }"
        :label="
          !itemIsSelected
            ? $t('search.action.selectArticle')
            : $t('search.action.unselectArticle')
        "
        @click="articleSelectionEmiter()"
      />
    </q-card-actions>
  </q-card>
</template>

<script setup lang="ts">
// Importation des composants
import { ref, computed, toRefs } from "vue";
import { useClipboard } from "@/composables/useClipboard";
import { RoutesName } from "@/router/routesName";
import { ArticleDto } from "@/types/api";
import {
  getMainSection,
  getSecondarySections,
  getArticleContentByLanguage,
  getInfoArticle,
  getContributorsName,
} from "@/helpers/article";
import { useArticleCartStore } from "@/stores/articleCart";
import { getThemeByPublication } from "@/theme";
import { useLocale } from "@/utils";
import { conf_elcanoapi_current } from "@/constants";

const { isFr } = useLocale();
// Déclaration des constantes
const { isSupported, copyHtml } = useClipboard();
// on ne deconstruit pas ce store, sinon lors de la synchro entre
// les onglets le computed itemIsSelected ne fonctionne pas
const articleCartStore = useArticleCartStore();
const props = defineProps<{
  article: ArticleDto;
  localeSelected: string[];
  onlyTitleAndStandFirst: boolean;
  articleSelectable: boolean;
}>();
const { localeSelected } = toRefs(props);
const defaultLocale = computed(() => {
  const firstLocale = localeSelected.value[0];
  if (props.article.contents.some((c) => c.language === firstLocale))
    return firstLocale;

  return props.article.contents[0].language;
});
const theme = computed(() => getThemeByPublication(props.article.publication));

const emit = defineEmits<{
  (event: "click-on-section", value: number): void;
  (event: "click-on-country", value: number): void;
  (event: "article-selected", value: number): void;
  (event: "article-unselected", value: number): void;
}>();

/**
 *  Permet de savoir si l'article courant est sélectionné ou non
 */
const itemIsSelected = computed<boolean>(() => {
  return articleCartStore.articleIds.indexOf(props.article?.id) >= 0;
});

const canCopyUrl = computed<boolean>(() => {
  if (!isSupported.value) return false;

  const { linkArticleWebsite } = getInfoArticle(
    props.article,
    props.localeSelected
  );
  return linkArticleWebsite !== undefined;
});

const iconClipBoard = ref<string>("content_copy");

/** Recupération du text de l'article */
function getText() {
  const content = getArticleContentByLanguage(
    props.article.contents,
    defaultLocale.value
  );

  if (!content) return "";

  let text = content.text;
  // style par défaut pour les images
  try {
    // Suppose that 'html' is the string containing your HTML content
    const parser = new DOMParser();
    const doc = parser.parseFromString(text, "text/html");

    doc.querySelectorAll("img").forEach((img) => {
      // Vérifie si l'image a déjà une classe
      if (img.classList.length === 0) {
        // Vérifie si le noeud précédent est un 'figure' avec une classe
        const parentElement = img.parentElement;
        if (
          !parentElement ||
          parentElement.tagName.toLowerCase() !== "figure" ||
          !parentElement.classList.length
        ) {
          img.classList.add("image-small-left");
        }
      }
    });

    // Serialize the document back to a string
    const serializer = new XMLSerializer();
    text = serializer.serializeToString(doc);
  } catch (e) {
    console.error(e);
  }

  const mainMedia = content.medias.find((m) => content.idMainMedia === m.id);
  if (mainMedia && text.indexOf(mainMedia.filePath) === -1) {
    text = `<figure class=\"image image-medium-center\"><img src=\"${conf_elcanoapi_current}/medias/files/Images${mainMedia.filePath}\" alt="${mainMedia.copyrightFr}"></figure>${text}`;
  }

  return text;
}

/**
 *  Permet de récupérer la propriété label en fonction de la langue
 */
function getLabelPropertyFromLocale() {
  return isFr.value ? "labelFr" : "labelEn";
}

/**
 *  Permet  d'envoyer les évenements de sélection et désélection d'un article
 *  Switch l'état de selection de l'article pour modifier les btn
 */
function articleSelectionEmiter() {
  articleCartStore.toggleArticleId(props.article.id);
  if (itemIsSelected.value) emit("article-selected", props.article.id);
  else emit("article-unselected", props.article.id);
}

/**
 * Clean le contenu copier pour retirer les span highlight renvoyer par la recherche elastic
 */
function cleanContentBeforeCopy(event: ClipboardEvent) {
  event.preventDefault();
  const selectedText = window.getSelection()?.getRangeAt(0);
  if (selectedText === undefined) return;
  const modifiedText = selectedText.cloneContents();
  const div = document.createElement("div");
  div.appendChild(modifiedText);
  const spanElements = div.querySelectorAll("span.highlight");
  for (let i = 0; i < spanElements.length; i++) {
    const spanElement = spanElements[i];
    const parent = spanElement.parentNode;
    while (spanElement.firstChild) {
      parent?.insertBefore(spanElement.firstChild, spanElement);
    }
    parent?.removeChild(spanElement);
  }
  const html = div.innerHTML;
  event.clipboardData?.setData("text/plain", div.innerText);
  event.clipboardData?.setData("text/html", html);
}

/**
 * Fonction qui permet d'ouvrir un onglet vers une URL passé en paramètre
 */
function openLink(url?: string) {
  if (url != null) window.open(url, "_blank");
}
/**
 *
 */
function getStandFirst(article: ArticleDto) {
  return getArticleContentByLanguage(article.contents, defaultLocale.value)
    ?.standfirst;
}
</script>

<style lang="scss" scoped>
@import "@/styles/_styleArticle.scss";
.body--dark {
  .searchResultItem {
    border: none;
  }
  .col-contributor {
    color: white;
  }
}
.searchResultItem {
  border-bottom: 2px solid #eeeeee;
}

.col-contributor {
  color: black;
}

:deep(p img) {
  max-width: 100%;
  height: auto;
}
</style>
