import type { CountryDto } from "@/types/api";
import type { CountryServiceInstance } from "@/types/country";
import type { CrudServiceInstance } from "@/types/crud";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un object type pays
 */
export function createCountry(params: Partial<CountryDto> = {}): CountryDto {
  return {
    id: params?.id ?? 0,
    active: params?.active ?? false,
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
    realCountry: params?.realCountry ?? false,
    hashtagFr: params?.hashtagFr ?? "",
    hashtagEn: params?.hashtagEn ?? "",
    code: params?.code ?? "",
    isoCode: params?.isoCode ?? "",
    idBackOffice: params?.idBackOffice ?? 0,
  };
}
/**
 * Service country pour récupérer les pays et les modifier.
 */
class CountryService extends AxiosRequest implements CountryServiceInstance {
  crudService: CrudServiceInstance<CountryDto>;

  /**
   * Constructeur de la class Country service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<CountryDto>(rootPath);
  }
  /**
   * Liste les pays
   * @param {number} page - numéros de la pagination
   * @param {number} limit - nombre de pays affichés par page.
   */
  getList(page?: number, limit?: number) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer un pays par son id
   * @param {number | string} id - id du pays à récupérer
   */
  getSingle(id: string | number) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer un nouveau pays
   * @param country - données du nouveau pays à créer
   */
  insert(country: CountryDto) {
    return this.crudService.insert(country);
  }

  /**
   * Met à jour un pays
   * @param country - nouvelles données du pays à mettre à jour
   */
  update(country: CountryDto) {
    return this.crudService.update(country.id, country);
  }
}

export const countryService = new CountryService("countries");
