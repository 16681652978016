import { ParamProcessReplacement } from "./../types/api";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { useReportStore } from "@/stores/report";
import { usePage } from "@/composables/usePage";
import { ReportViewActions } from "@/types/report";
import { ParamSearchAndReplace, ReportSearchAndReplaceDto } from "@/types/api";
import { RoutesName } from "@/router/routesName";
import router from "@/router";
import { reactive } from "vue";
import { authService } from "@/services/AuthService";

/**
 * Interface des états pour les actions report
 */
interface ReportActionState {
  callbackOnSubmit: ((param: ParamSearchAndReplace) => void) | undefined;
}

/**
 * Fonction servant à interagir sur les différentes vues report
 */
export const useReportActions = createGlobalState(() => {
  const $q = useQuasar();
  const { showPanelRight } = usePage();
  const { insertReport, getListReports, processReport } = useReportStore();
  const state = reactive<ReportActionState>({
    callbackOnSubmit: undefined,
  });

  const reportActions: ReportViewActions = {
    createReport() {
      showPanelRight.value = true;
      state.callbackOnSubmit = reportActions.onCreateReport;
    },
    onCreateReport(param: ParamSearchAndReplace) {
      param.idAuthor = authService.getUserId();
      insertReport(param)
        .then((idReport) => {
          showPanelRight.value = false;
          $q.notify("Le rapport a bien été créé.");
          router.push({ name: RoutesName.Report, params: { id: idReport } });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    processReport(param: ParamProcessReplacement) {
      return processReport(param)
        .then(() => $q.notify("Le traitement du rapport a été lancé."))
        .catch((err: Error) => {
          $q.notify(err.message);
          return [];
        });
    },

    getReports(): Promise<ReportSearchAndReplaceDto[]> {
      return getListReports().catch((err: Error) => {
        $q.notify(err.message);
        return [];
      });
    },
  };
  return { actions: reportActions };
});
