<template>
  <Page>
    <q-toggle v-model="deleted" :label="$t('draft.labels.lbltgShowDeleted')" />
    <q-table
      :rows="draftRows"
      :columns="columns"
      :grid="$q.screen.xs"
      table-header-class="header_table"
      separator="cell"
      :filter="filter"
      bordered
      :rows-per-page-options="[50, 100, 0]"
      @row-click="onRowClick"
    >
      <template #top-right>
        <q-input
          v-model="filter"
          borderless
          dense
          debounce="300"
          :placeholder="$t('draft.fields.shortSearch')"
        >
          <template #append>
            <q-icon name="search" />
          </template>
        </q-input>
      </template>
      <template #body-cell-actions="props">
        <q-td :props="props">
          <q-btn
            v-if="!deleted"
            :icon="'delete'"
            flat
            @click.stop="onDelete(props.row)"
          />
        </q-td>
      </template>
      <template #item="props">
        <QTableGridItem
          v-slot="slotProps"
          :columns="props.cols"
          @click="onRowClick"
        >
          <span v-if="slotProps.columnName != 'actions'">{{
            slotProps.columnValue
          }}</span>
          <div v-else>
            <q-btn
              :icon="'delete'"
              round
              @click.stop="onDelete(slotProps.columnValue as ArticleDto)"
            />
          </div>
        </QTableGridItem>
      </template>
    </q-table>
  </Page>
</template>

<script setup lang="ts">
import type { QTableColumn } from "quasar";
import type { ArticleDto } from "@/types/api";
import { useQuasar, date } from "quasar";
import { computed, ref } from "vue";
import { useDraftStore } from "@/stores/draft";
import { useLocale } from "@/utils";
import Page from "@/components/Layouts/Page.vue";
import QTableGridItem from "@/components/QTableGridItem.vue";
import { getArticleContentByLanguage } from "@/helpers/article";
import { useArticleActions } from "@/composables/useArticleActions";
import { i18n } from "@/i18n";

const $q = useQuasar();
const { actions } = useArticleActions();
const { language, isFr } = useLocale();
const draftStore = useDraftStore();
const deleted = ref(false);
const filter = ref("");
const mask = isFr.value ? "DD/MM/YYYY à HH:mm" : "DD/MM/YYYY, h:mm A";
const draftRows = computed<ArticleDto[]>(() =>
  deleted.value
    ? Object.values(draftStore.draftsDeleted)
    : Object.values(draftStore.drafts)
);
const columns: QTableColumn<ArticleDto>[] = [
  {
    name: "id",
    label: i18n.t("draft.fields.id"),
    align: "left",
    field: (row) => row.id,
  },
  {
    name: "publication",
    label: i18n.t("draft.fields.publication"),
    align: "left",
    field: (row) => row.publication,
  },
  {
    name: "title",
    label: i18n.t("draft.fields.title"),
    sortable: true,
    align: "left",
    field: (row) => {
      const content = getArticleContentByLanguage(row.contents, language.value);
      if (content) return content.title;
      return row.contents[0].title;
    },
  },
  {
    name: "lastUpdate",
    label: i18n.t("draft.fields.lastModifications"),
    align: "center",
    sortable: true,
    field: (row) => date.formatDate(row.lastUpdate, mask),
  },
  {
    name: "actions",
    label: i18n.t("draft.fields.actions"),
    align: "center",
    field: (row) => row,
  },
];
/**
 *
 */
function onRowClick(evt: Event, row: ArticleDto) {
  actions.editArticle(row.id, undefined, undefined, deleted.value);
}
/**
 *
 */
function onDelete(row: ArticleDto) {
  $q.dialog({
    title: i18n.t("draft.dialogs.titles.confirm"),
    message: i18n.t("draft.dialogs.messages.delete"),
    cancel: true,
    persistent: true,
  }).onOk(() => {
    draftStore.deleteDraft(row.id, true);
    $q.notify(i18n.t("draft.messages.deleted"));
  });
}
</script>
