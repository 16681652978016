import type { App, Plugin } from "vue";
import type {
  KeycloakConfig,
  KeycloakInitOptions,
  KeycloakError,
} from "keycloak-js";
import type {
  VueKeycloakInstance,
  VueKeycloakTokenParsed,
  VueKeycloakOptions,
} from "@/types/keycloak";
import Keycloak from "keycloak-js";
import { reactive } from "vue";
import {
  conf_keycloak_clientid_current,
  conf_keycloak_realm_current,
  conf_keycloak_redirecturl_current,
  conf_url_keycloak_current,
  conf_front_only,
} from "@/constants";

const keycloakConfig: KeycloakConfig = {
  clientId: conf_keycloak_clientid_current,
  realm: conf_keycloak_realm_current,
  url: conf_url_keycloak_current,
};
const keycloakInitOptions: KeycloakInitOptions = {
  onLoad: "login-required",
};
const defaultEmptyVueKeycloakInstance: VueKeycloakInstance = {
  ready: false,
  authenticated: false,
};
export const keycloakState: VueKeycloakInstance = createKeycloakState(
  defaultEmptyVueKeycloakInstance
);

export const KeycloakSymbol = Symbol("keycloak");
export const vueKeycloak: Plugin = {
  async install(app: App) {
    app.config.globalProperties.$keycloak = keycloakState;
    app.provide(KeycloakSymbol, keycloakState);
  },
};
/**
 * Fonction servant à instancié keycloak avant l'initialisation de l'application
 */
export async function createKeycloak(options: VueKeycloakOptions) {
  const keycloak = new Keycloak(keycloakConfig);
  if (conf_front_only) {
    if (options.onReady) options.onReady(keycloak, undefined);
    return;
  }

  initKeycloakHandlers(keycloak, options);

  return keycloak
    .init(keycloakInitOptions)
    .then((auth) => {
      updateKeycloakState(keycloak, auth);
      typeof options.onInitSuccess === "function" &&
        options.onInitSuccess(auth);
      return keycloak;
    })
    .catch((err: KeycloakError) => {
      updateKeycloakState(keycloak, false);
      const error = Error(
        "Failure during initialization of keycloak-js adapter"
      );
      typeof options.onInitError === "function"
        ? options.onInitError(error, err)
        : console.error(error, err);
      return Promise.reject(error);
    });
}
/**
 * Fonction servant a déclaré les callbacks pour chaque événements de keycloak
 */
function initKeycloakHandlers(keycloak: Keycloak, options: VueKeycloakOptions) {
  keycloak.onReady = function (auth) {
    updateKeycloakState(keycloak, auth);
    keycloakState.ready = true;
    typeof options.onReady === "function" &&
      options.onReady(keycloak, keycloakState);
  };

  keycloak.onAuthSuccess = function () {
    // Check token validity every 10 seconds (10 000 ms) and, if necessary, update the token.
    // Refresh token if it's valid for less then 60 seconds
    const updateTokenInterval = setInterval(() => {
      keycloak
        .updateToken(60)
        .then((refreshed) => {
          if (refreshed) {
            console.log("Token was successfully refreshed");
          } else {
            console.log("Token is still valid");
          }
        })
        .catch(() => {
          keycloak.clearToken();
        });
    }, 10000);
    keycloakState.logoutFn = () => {
      clearInterval(updateTokenInterval);
      keycloak.logout({
        redirectUri: conf_keycloak_redirecturl_current,
      });
    };
  };
  keycloak.onAuthRefreshSuccess = function () {
    updateKeycloakState(keycloak, true);
    typeof options.onAuthRefreshSuccess === "function" &&
      options.onAuthRefreshSuccess(keycloak);
  };
  keycloak.onAuthRefreshError = function () {
    updateKeycloakState(keycloak, false);
    typeof options.onAuthRefreshError === "function" &&
      options.onAuthRefreshError(keycloak);
  };
}
/**
 * Permet de créer un object keycloak reactif
 */
function createKeycloakState(object: VueKeycloakInstance): VueKeycloakInstance {
  return reactive(object);
}

/**
 * Fonction servant à mettre à jour l'état de l'object réactif keycloak
 */
function updateKeycloakState(keycloak: Keycloak, isAuthenticated = false) {
  keycloakState.authenticated = isAuthenticated;
  keycloakState.loginFn = keycloak.login;
  keycloakState.login = keycloak.login;
  keycloakState.createLoginUrl = keycloak.createLoginUrl;
  keycloakState.createLogoutUrl = keycloak.createLogoutUrl;
  keycloakState.createRegisterUrl = keycloak.createRegisterUrl;
  keycloakState.register = keycloak.register;
  keycloakState.keycloak = keycloak;
  if (isAuthenticated) {
    keycloakState.accountManagement = keycloak.accountManagement;
    keycloakState.createAccountUrl = keycloak.createAccountUrl;
    keycloakState.hasRealmRole = keycloak.hasRealmRole;
    keycloakState.hasResourceRole = keycloak.hasResourceRole;
    keycloakState.loadUserProfile = keycloak.loadUserProfile;
    keycloakState.token = keycloak.token;
    keycloakState.subject = keycloak.subject;
    keycloakState.idToken = keycloak.idToken;
    keycloakState.idTokenParsed = keycloak.idTokenParsed;
    keycloakState.realmAccess = keycloak.realmAccess;
    keycloakState.resourceAccess = keycloak.resourceAccess;
    keycloakState.refreshToken = keycloak.refreshToken;
    keycloakState.refreshTokenParsed = keycloak.refreshTokenParsed;
    keycloakState.timeSkew = keycloak.timeSkew;
    keycloakState.responseMode = keycloak.responseMode;
    keycloakState.responseType = keycloak.responseType;
    keycloakState.tokenParsed = keycloak.tokenParsed;
    keycloakState.userName = (keycloak.tokenParsed as VueKeycloakTokenParsed)[
      "preferred_username"
    ];
    keycloakState.fullName = (keycloak.tokenParsed as VueKeycloakTokenParsed)[
      "name"
    ];
  }
}
