import { prolexis_core_path, prolexis_url_proxy } from "@/constants";
import { ProlexisServiceObject, ProlexisServiceResult } from "@/types/prolexis";
import { useConfigStore } from "@/stores/config";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Ref } from "vue";

/**
 *
 */
export function useProlexis(publication: string) {
  const configStore = useConfigStore();
  const languageCheck = (language: string) => {
    language = language ?? "fr";
    language = language.split("-")[0];
    return language;
  };

  const prolexisInit = (callback: (result: ProlexisServiceResult) => void) => {
    window.DiagPlWs.init({
      sUrlProxy: `${prolexis_url_proxy}/${publication}/${configStore.config.proLexisApiKey}`,
      sCorePath: prolexis_core_path,
      hCallBack: callback,
    });
  };

  const analyzeString = (
    language: string,
    text: string,
    callback: (data: string) => void
  ) => {
    language = languageCheck(language);

    const toAnalyze: ProlexisServiceObject[] = [
      {
        type: "string",
        src: text,
      },
    ];

    prolexisInit(() => {
      if (toAnalyze[0].changed && typeof toAnalyze[0].src === "string")
        return callback(toAnalyze[0].src);
    });

    window.DiagPlWs.analyze(toAnalyze, {
      language,
    });
  };

  const analyzeRef = <TObj, TKey extends keyof TObj>(
    language: string,
    reference: Ref<TObj>,
    properties: TKey[],
    ckeditor: ClassicEditor | null = null
  ) => {
    language = languageCheck(language);

    const toAnalyze: ProlexisServiceObject<TKey>[] = properties.map((s) => ({
      type: "string",
      src: reference.value[s],
      property: s,
    }));

    if (ckeditor) {
      toAnalyze.push({
        type: "ckeditor",
        src: ckeditor,
      });
    }

    prolexisInit(() => {
      toAnalyze.forEach((data) => {
        if (data.changed && data.property && typeof data.src === "string") {
          reference.value[data.property] = data.src as TObj[TKey];
        }
      });
    });

    window.DiagPlWs.analyze(toAnalyze, {
      language,
    });
  };

  return { analyzeString, analyzeRef };
}
