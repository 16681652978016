<template>
  <q-menu>
    <q-list v-if="!disable" style="min-width: 100px">
      <q-item
        v-for="(option, idx) in triggersOption"
        :key="'trigger' + idx"
        v-close-popup
        clickable
        @click="handleClick(option)"
      >
        <q-item-section :class="{ 'item-disabled': option.disabled }">{{
          $t(`article.triggers.${option.trigger}`)
        }}</q-item-section>
        <q-tooltip v-if="getTriggerMessage(option) !== undefined">
          {{ getTriggerMessage(option) }}
        </q-tooltip>
      </q-item>
    </q-list>
  </q-menu>
  <DialogSetReviewer
    v-model="showDialogSetReviewer"
    :article="article"
    :article-content="articleContent"
  />
</template>
<script setup lang="ts">
import {
  WorkflowTrigger,
  WorkflowFireResult,
  RightReference,
  ArticleDto,
  ArticleContentDto,
  ContributionType,
} from "@/types/api";
import { useTriggers } from "@/composables/useTriggers";
import { computed, inject, onMounted, ref, watch } from "vue";
import { authService } from "@/services/AuthService";
import { i18n } from "@/i18n";
import DialogSetReviewer from "@/components/Article/DialogSetReviewer.vue";
import { useDebounceFn } from "@vueuse/core";
import { EventBus, useQuasar } from "quasar";
const { dialog } = useQuasar();
const bus = inject<EventBus>("bus");

const props = defineProps<{
  article: ArticleDto;
  articleContent: ArticleContentDto;
  disable?: boolean;
}>();
const emits = defineEmits<{
  (e: "loading", value: boolean): void;
  (e: "update:state", value: WorkflowFireResult): void;
}>();

const loading = ref(false);
const showDialogSetReviewer = ref(false);
const triggerForMessage = ref(WorkflowTrigger.Pitcher);
const { actions, triggersOption, loading: loadingTriggers } = useTriggers(bus);
const nbReviewer = computed(
  () =>
    props.article.contributions.filter(
      (c) =>
        c.type === ContributionType.Reviewer &&
        c.idLanguage == props.articleContent.language
    ).length
);

onMounted(() => getTriggersByArticleId());

watch(
  [
    () => props.articleContent.xmin,
    () => props.articleContent.id,
    () => props.article.xmin,
  ],
  () => getTriggersByArticleId()
);
watch(loadingTriggers, () =>
  emits("loading", loading.value || loadingTriggers.value)
);
watch(loading, () => emits("loading", loading.value || loadingTriggers.value));

const getTriggersByArticleId = useDebounceFn(() => {
  if (!props.article || props.article.id === 0) return;

  actions.getTriggers(props.article.id, props.articleContent.language);
}, 100);

/**
 *
 */
function handleClick(option: { trigger: WorkflowTrigger; disabled: boolean }) {
  if (option.disabled) return;

  if (option.trigger === WorkflowTrigger.Relire && nbReviewer.value === 0) {
    showDialogSetReviewer.value = true;
    return;
  }

  if (askForMesssage(option.trigger)) {
    triggerForMessage.value = option.trigger;
    dialog({
      title: i18n.t("triggerMessage.title"),
      message: i18n.t("triggerMessage.message"),
      prompt: {
        model: "",
        type: "textarea",
      },
      cancel: {
        color: "negative",
        label: i18n.t("triggerMessage.btnCancel"),
      },
      ok: {
        color: "primary",
        label: i18n.t("triggerMessage.btnOk"),
      },
      persistent: true,
    }).onOk((data: string) => {
      sendTrigger(option.trigger, data);
    });

    return;
  }

  sendTrigger(option.trigger);
}

/**
 * fonction pour savoir si le trigger doit être accompagné d'un message
 */
function askForMesssage(trigger: WorkflowTrigger) {
  if (trigger === WorkflowTrigger.Corriger) return true;

  if (trigger === WorkflowTrigger.Relire && nbReviewer.value === 1) return true;

  return false;
}

/**
 * fonction pour executer une action
 */
function sendTrigger(
  trigger: WorkflowTrigger,
  message: string | undefined = undefined
) {
  loading.value = true;
  actions.sendTrigger(
    {
      id: props.article.id,
      lang: props.articleContent.language,
      trigger: trigger,
      xmin: props.articleContent.xmin,
      message: message,
    },
    (newState) => {
      if (newState !== undefined) emits("update:state", newState);
      loading.value = false;
    },
    trigger === WorkflowTrigger.Relire && nbReviewer.value > 1
      ? "WithoutNotification"
      : undefined
  );
}

/**
 *
 */
function getTriggerMessage(triggerOption: {
  trigger: WorkflowTrigger;
  disabled: boolean;
}) {
  if (triggerOption.disabled) {
    const key = authService.hasRight(
      RightReference.PUBLICATION_ARTICLE_WORKFLOW_ACTION_ +
        triggerOption.trigger,
      props.article.publication
    )
      ? `article.disabledTriggers.${props.articleContent.state}_${triggerOption.trigger}`
      : "article.disabledTriggers.rightMissing";
    return i18n.t(key);
  }

  const i18nKey = `article.helpTriggers.${triggerOption.trigger}`;
  const message = i18n.t(i18nKey);

  return message === i18nKey ? undefined : message;
}
</script>
<style lang="scss" scoped>
.item-disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
