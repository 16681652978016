export const defaultKeys = [
  "ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightba",
  "ArrowUpArrowUpArrowDownArrowDownArrowLeftArrowRightArrowLeftArrowRightBA",
  "88224646ba",
  "88224646BA",
];

/**
 * Permet de s'abonner à un raccourci
 */
export function useKeyboardListener(keys: string[], callback: () => void) {
  let timer;
  let buffer = "";
  const chars: string[] = []; //NOSONAR

  if (!keys || !keys.length) keys = defaultKeys;

  const resetBuffer = () => {
    if (timer) clearTimeout(timer);
    buffer = "";
  };

  const onKeyUp = (e: {
    key: string;
    altKey: boolean;
    ctrlKey: boolean;
    shiftKey: boolean;
  }) => {
    if (timer) clearTimeout(timer);
    chars.push(e.key);
    let key = "";

    if (e.altKey) key += "Alt+";
    if (e.ctrlKey) key += "Control+";
    if (e.shiftKey) key += "Shift+";

    key += e.key;

    buffer = `${buffer}${key}`;
    for (const key of keys) {
      if (buffer !== key) continue;
      chars.length = 0;
      buffer = "";
      callback();
      return;
    }
    timer = setTimeout(resetBuffer, 1000);
  };

  return {
    startListening: function () {
      window.addEventListener("keyup", onKeyUp);
    },
    stopListening: function () {
      window.removeEventListener("keyup", onKeyUp);
    },
  };
}
