<template>
  <q-field
    v-if="articleContent.id"
    :loading="stateChanging"
    :disable="disable"
    dense
    for="inputArticleContentStatus"
    color="teal"
    filled
  >
    <template #control>
      <q-chip
        class="self-center"
        dense
        square
        :style="`background: ${getWorkflowStateColor(
          articleContent.state
        )}; color: ${getWorkflowStateTextColor(articleContent.state)};`"
      >
        {{ $t(`article.states.${articleContent.state}`) }}
      </q-chip>
    </template>
    <template #append>
      <q-btn id="inputArticleContentStatus" round dense flat icon="expand_more">
        <MenuTriggers
          :article="article"
          :article-content="articleContent"
          :disable="disable"
          @loading="(loading) => (stateChanging = loading)"
          @update:state="onUpdateState"
        />
      </q-btn>
    </template>
  </q-field>
</template>

<script setup lang="ts">
import type {
  ArticleContentDto,
  ArticleDto,
  WorkflowFireResult,
} from "@/types/api";
import { ref } from "vue";
import MenuTriggers from "@/components/Article/MenuTriggers.vue";
import {
  getWorkflowStateColor,
  getWorkflowStateTextColor,
} from "@/helpers/workflowState";
const stateChanging = ref<boolean>(false);

defineProps<{
  articleContent: ArticleContentDto;
  article: ArticleDto;
  disable?: boolean;
}>();
const emits = defineEmits<{
  (e: "update:state", value: WorkflowFireResult): void;
}>();
/**
 *
 */
function onUpdateState(stateUpdated: WorkflowFireResult) {
  emits("update:state", stateUpdated);
}
</script>
