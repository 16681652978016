import type { ArticleDto } from "@/types/api";
import { defineStore } from "pinia";
import { useLocalStorage } from "@vueuse/core";

export const useDraftStore = defineStore({
  id: "draft",
  state: () => ({
    drafts: useLocalStorage<Record<number, ArticleDto>>("drafts", {}),
    draftsDeleted: useLocalStorage<Record<number, ArticleDto>>(
      "draftsdeleted",
      {}
    ),
  }),
  actions: {
    findDraft(articleId: number, fromDeletedDraft: boolean) {
      if (fromDeletedDraft) {
        return this.draftsDeleted[articleId];
      } else {
        return this.drafts[articleId];
      }
    },
    putDraft(article: ArticleDto) {
      this.drafts[article.id] = article;
    },
    deleteDraft(articleId: number, putToDeletedItem: boolean) {
      if (putToDeletedItem) {
        this.draftsDeleted[articleId] = JSON.parse(
          JSON.stringify(this.drafts[articleId])
        );
      }
      delete this.drafts[articleId];
    },
  },
});
