import { MailTemplateDto } from "./../types/api";
import type { AxiosResponse } from "axios";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { MailTemplateServiceInstance } from "@/types/mailTemplate";

/** Mail Template Service */
class MailTemplateService
  extends AxiosRequest
  implements MailTemplateServiceInstance
{
  /** Constructeur de la class Mail service */
  constructor(public rootPath: string) {
    super();
  }

  /** Récupère l'ensemble des mails */
  get() {
    return this.axiosInstance
      .get(`${this.rootPath}`)
      .then((response: AxiosResponse<MailTemplateDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /** Met à jour un template */
  update(mail: MailTemplateDto): Promise<MailTemplateDto> {
    return this.axiosInstance
      .put(`${this.rootPath}/${mail.id}`, mail)
      .then((response: AxiosResponse<MailTemplateDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const mailTemplateService = new MailTemplateService("mailTemplates");
