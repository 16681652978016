<template>
  <div class="container">
    <div
      v-show="showAddComment"
      id="createNotificationContainer"
      ref="NotificationContainer"
      :style="showAddComment ? 'width: 0' : undefined"
      class="notification-container"
    >
      <q-btn
        :label="$t('article.action.notify')"
        type="submit"
        color="primary"
        @click="addCommentButton"
      />
    </div>
    <div
      id="createNotificationSidebar"
      ref="NotificationSidebar"
      class="notificationSidebar"
    ></div>
  </div>
</template>

<script lang="ts" setup>
import { onMounted, onUnmounted, ref } from "vue";
import { useCkEditorContext } from "@/composables/useCkEditor";

const props = defineProps<{
  id: number;
}>();

const NotificationContainer = ref<HTMLElement | null>(null);
const NotificationSidebar = ref<HTMLElement | null>(null);
const showAddComment = ref(false);
let addComment: (() => void) | null = null;
let destroyContext: (() => void) | null = null;

/**
 *
 */
function addCommentButton() {
  if (addComment) addComment();
}

onMounted(async () => {
  const { addCommentThread, destroy } = await useCkEditorContext(
    `article:${props.id}`,
    `article:${props.id}:notifications`,
    NotificationSidebar.value,
    NotificationContainer.value,
    false,
    "notification-container-existing-thread"
  );
  showAddComment.value = true;
  addComment = addCommentThread;
  destroyContext = destroy;
});

onUnmounted(() => {
  if (destroyContext) destroyContext();
});
</script>

<style lang="scss">
.notification-container {
  height: 150px;
  width: 200px;
}

.notification-container-existing-thread {
  visibility: hidden;
}

.notificationSidebar {
  min-width: 500px;
}

.notificationSidebar .ck-comment--remove,
.ck-comment--resolve {
  display: none !important;
}
</style>
