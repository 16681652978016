import { FrenchLanguage } from "@/constants";
import { ArticleModificationTypes, ContributionType } from "@/types/api";

export const modificationType: { [key in ArticleModificationTypes]: number } = {
  None: 0,
  Base: 1,
  FrenchContent: 2,
  EnglishContent: 4,
};

export const getModificationTypeFromInt = (i: number) => {
  const message: ArticleModificationTypes[] = [];
  for (const m in ArticleModificationTypes) {
    if (m !== ArticleModificationTypes.None) {
      if (modificationType[m] & i) message.push(ArticleModificationTypes[m]);
    }
  }
  return message;
};

export const getModificationTypeFromLanguage = (lng: string) => {
  return lng === FrenchLanguage
    ? modificationType.FrenchContent
    : modificationType.EnglishContent;
};

export const sortContributionTypeMapping = {
  [ContributionType.Unknown]: 0,
  [ContributionType.Author]: 1,
  [ContributionType.Freelancer]: 2,
  [ContributionType.Editor]: 3,
  [ContributionType.Reviewer]: 4,
  [ContributionType.LeadTranslator]: 5,
  [ContributionType.Translator]: 6,
};

// The minimum number of associated articles to not display the warning
export const MINIMUM_ASSOCIATED_ARTICLES = 4;

export const dayAfterPlanningGoToNextWeek = {
  GL: 4,
  IO: 1,
  LLA: 5,
  AI: 2,
};
