Date.prototype.toISOStringWithoutZ = function () {
  return this.toISOString().replace("Z", "");
};

Date.prototype.toLocaleISOString = function () {
  return new Date(
    Date.UTC(
      this.getFullYear(),
      this.getMonth(),
      this.getDate(),
      this.getHours(),
      this.getMinutes(),
      this.getSeconds()
    )
  ).toISOStringWithoutZ();
};

Date.prototype.toUTCISOString = function () {
  return new Date(
    Date.UTC(
      this.getFullYear(),
      this.getMonth(),
      this.getDate(),
      this.getHours(),
      this.getMinutes(),
      this.getSeconds()
    )
  ).toISOString();
};

Date.prototype.addDays = function (days: number) {
  return new Date(this.getFullYear(), this.getMonth(), this.getDate() + days);
};

Date.prototype.addMonths = function (months: number) {
  return new Date(this.getFullYear(), this.getMonth() + months, this.getDate());
};
