<template>
  <q-card bordered>
    <q-card-section>
      <FormPublication
        :default-publication="publication"
        @submit="actions.onEditPublication"
      />
    </q-card-section>
  </q-card>
</template>

<script lang="ts" setup>
import { useRoute } from "vue-router";
import { usePublicationActions } from "@/composables/usePublicationActions";
import FormPublication from "@/components/Publication/FormPublication.vue";

const route = useRoute();

const { publication, actions } = usePublicationActions();

actions.findPublication(route.params.id as string);
</script>
