import { ArticleDto } from "@/types/api";
import { ref, computed, watch } from "vue";
import { useConfigStore } from "@/stores/config";

/**
 * Methode pour simplifier la création de block pour la home avec une liste d'article.
 */
export function useHomeBlock(
  rightCheck: (string) => boolean,
  promise: (string) => Promise<ArticleDto[]>
) {
  const configStore = useConfigStore();

  const articles = ref<ArticleDto[]>([]);
  const visibility = computed(() => articles.value.length > 0);

  /**
   * Charge les articles pour le bloc.
   */
  const loadArticles = async () => {
    const publication = configStore.$state.config.publication;
    if (!rightCheck(publication)) return;

    const results = await promise(publication);

    articles.value.splice(0, articles.value.length, ...results);
  };

  watch(
    () => configStore.$state.config.publication,
    () => {
      loadArticles();
    }
  );

  return { loadArticles, articles, visibility };
}
