<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', assignment)">
    <q-input
      v-model="assignment.id"
      filled
      :label="$t('assignment.fields.id')"
    />
    <q-input
      v-model="assignment.labelFr"
      filled
      :label="$t('assignment.fields.labelFr')"
    />
    <q-input
      v-model="assignment.labelEn"
      filled
      :label="$t('assignment.fields.labelEn')"
    />
    <q-checkbox
      v-model="assignment.redactor"
      :label="$t('assignment.fields.redactor')"
    />
    <q-checkbox
      v-model="assignment.editor"
      :label="$t('assignment.fields.editor')"
    />
    <q-checkbox
      v-model="assignment.translator"
      :label="$t('assignment.fields.translator')"
    />
    <q-checkbox
      v-model="assignment.freelance"
      :label="$t('assignment.fields.freelance')"
    />
    <ListRightsByGroup>
      <template #actions="right">
        <q-checkbox v-model="rightsUsed" :val="right.id" />
      </template>
    </ListRightsByGroup>
    <div class="q-mt-md text-right">
      <q-btn
        v-if="assignment.initialConfig === false"
        :label="labelBtnSubmit"
        type="submit"
        color="primary"
      />
    </div>
  </q-form>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import type { RightDto, AssignmentDto } from "@/types/api";
import { ref, watch, onUpdated, toRefs } from "vue";
import { createAssignment } from "@/services/AssignmentService";
import { useUserStore } from "@/stores/user";
import { i18n } from "@/i18n";
import ListRightsByGroup from "@/components/Right/ListRightsByGroup.vue";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("assignment.labels.btnEdit");
    },
  },
  defaultAssignment: {
    type: Object as PropType<AssignmentDto>,
    default() {
      return createAssignment();
    },
  },
});
defineEmits<{
  (e: "submit", value: AssignmentDto): void;
}>();
const { defaultAssignment: assignment } = toRefs(props);
const rightsUsed = ref(assignment.value.rights.map((r) => r.id));
const { rights } = useUserStore();

onUpdated(() => {
  rightsUsed.value = assignment.value.rights.map((right) => right.id);
});

watch(
  () => rightsUsed.value,
  (newVal) => {
    assignment.value.rights = newVal
      .map((v) => rights.find((r) => r.id === v))
      .filter(Boolean) as RightDto[];
  }
);
</script>
