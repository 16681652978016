<template>
  <div v-if="sections">
    <div v-for="(section, idx) in sections" :key="'section' + idx" class="row">
      <q-chip
        size="sm"
        :outline="!Dark.isActive && !section.main"
        :style="GetCssRubriqueChip(section.id, section.main)"
      >
        {{ section.label }}
      </q-chip>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs, computed } from "vue";
import { useLocale } from "@/utils";
import { getSectionCSSColors } from "@/helpers/section";
import { Dark } from "quasar";
/**
 * Interface SectionItem
 */
interface SectionItem {
  id: number;
  label: string;
  main: boolean;
}
const props = defineProps<{
  data: ArticleODataDto;
}>();
const { data } = toRefs(props);
const { isFr } = useLocale();
const sections = computed(() => {
  if (!data.value.sections || data.value.sections.length === 0) return [];
  const sectionsMapped = data.value.sections.map((s) => ({
    id: s.section,
    label: isFr.value ? s.labelFr : s.labelEn,
    main: s.main,
  }));
  const mainSection = sectionsMapped.find((s) => s.main);
  const secondariesSection = sectionsMapped.filter((s) => !s.main);
  return [mainSection, ...secondariesSection].filter(Boolean) as SectionItem[];
});
/**
 * Permet de d'indiquer la couleur et la taille des textes des rubriques
 * en fonction de la section et du fait qu'elle soit main ou no
 */
function GetCssRubriqueChip(sectionId: number, main: boolean) {
  let styleCouleur = getSectionCSSColors(sectionId, main || Dark.isActive);
  // si main alors on affiche la rubrique en plus gros
  if (main) return styleCouleur + " font-weight: bold;font-size: 1em;";
  return styleCouleur + "font-size: 0.9em;";
}
</script>
