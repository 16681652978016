<template>
  <!-- Search -->
  <q-select
    ref="selectOptionRecherche"
    v-model="searchPart"
    :options="filteredOptions"
    :label="$t('search.labels.searchCaption')"
    multiple
    use-chips
    use-input
    dense
    outlined
    square
    input-debounce="50"
    new-value-mode="add-unique"
    hide-dropdown-icon
    class="col advanced-search-input"
    @popup-show="setSelectItemOpened(true)"
    @popup-hide="setSelectItemOpened(false)"
    @new-value="addNewValue"
    @filter="filterFn"
    @keyup.enter="keyUpEnter"
  >
    <template #option="scope">
      <q-item
        v-close-popup="1"
        v-bind="scope.itemProps"
        @click="clearInputSelect"
      >
        <q-item-section avatar>
          <q-icon :name="scope.opt.value.icon" :color="scope.opt.value.color" />
        </q-item-section>
        <q-item-section>
          <q-item-label caption>{{ scope.opt.value.label }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
    <template #selected-item="scope">
      <q-chip
        removable
        dense
        square
        :tabindex="scope.tabindex"
        color="#010101"
        :text-color="scope.opt.value.color"
        class="q-ma-xs"
        @remove="scope.removeAtIndex(scope.index)"
      >
        <q-avatar
          :color="scope.opt.value.color"
          text-color="white"
          :icon="scope.opt.value.icon"
        />
        {{ scope.opt.value.label }}
      </q-chip>
    </template>
  </q-select>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from "vue";
import { QSelect, QSelectOption, date, QItem } from "quasar";
import { useCountryStore } from "@/stores/country";
import { useArticleSettingStore } from "@/stores/articleSetting";
import { useUserStore } from "@/stores/user";
import { useSectionStore } from "@/stores/section";
import { usePublicationStore } from "@/stores/publication";
import { useFormatStore } from "@/stores/format";
import { useLanguageStore } from "@/stores/language";
import {
  ContributionType,
  SettingsType,
  UserLightDto,
  WorkflowState,
} from "@/types/api";
import { useLocale, removeDiacritics } from "@/utils";
import { FrenchLanguage } from "@/constants";
import { i18n } from "@/i18n";
import { authService } from "@/services/AuthService";
import { SearchPart, convertToDate } from "@/components/Article/Search/utils";

//Déclaration des variables
const { articleSettings } = useArticleSettingStore();
const { isFr } = useLocale();
const filter = ref<string>("");

const selectItemOpened = ref<boolean>(false);
const selectOptionRecherche = ref<QSelect | null>(null);

const emits = defineEmits<{
  (e: "onSubmit"): void;
  (e: "update:modelValue", value: QSelectOption<SearchPart>[]): void;
}>();

const props = defineProps<{ modelValue: QSelectOption<SearchPart>[] }>();
const { modelValue: search } = toRefs(props);
const searchPart = computed<QSelectOption<SearchPart>[]>({
  get() {
    return search.value;
  },
  set(newValue): void {
    highlightInvalidSection(newValue, search.value);
    newValue = deduplicateDatePart(newValue, search.value);

    // On ne peut avoir qu'une seule langue de renseigné pour la recherche.
    if (newValue.filter((s) => s.value.type === "Language").length == 2) {
      newValue = newValue.filter((s) => s.value.type !== "Language");
    }

    newValue.sort((s1, s2) => {
      if (s1.value.type === "Text") return 10;

      if (s2.value.type === "Text") return -10;

      return s2.value.order - s1.value.order;
    });
    emits("update:modelValue", newValue);
  },
});

const availableOptions: SearchPart[] = [];
const iconText = "title";
const colorText = "grey-8";
const iconDate = "calendar_month";
const colorDate = "deep-orange-5";
// l'ordre des appels ci-dessous, influe sur l'ordre de présentation des éléments sélectionnés.
addLanguagesToOptions("translate", "yellow-9");
addPublicationsToOptions("newspaper", "light-green-9");
addFormatsToOptions("feed", "lime-9");
addSectionsToOptions("bookmark", "brown-5");
addCountriesToOptions("flag", "orange-7");
addCmsSettingsToOptions("settings", "blue-grey-5");
addInterestsToOptions("interests", "teal-5");
addStatesToOptions("label_important", "indigo-5");
addUsersToOptions("people", "purple-5");

const filteredOptions = computed<QSelectOption<SearchPart>[]>(() => {
  const needle = filter.value
    .normalize("NFD")
    .replace(/\p{Diacritic}/gu, "")
    .toLowerCase();

  const filtered = getOptionsFilteredByPublicationAndInput(needle);

  filtered.push({
    type: "Text",
    value: filter.value,
    searchValue: filter.value,
    label: i18n.t("search.type.text") + " : " + filter.value,
    icon: iconText,
    publications: [],
    color: colorText,
    order: 100,
  });

  if (Number(filter.value)) {
    filtered.push({
      type: "ID_Workflow",
      value: filter.value,
      searchValue: filter.value,
      label: i18n.t("search.type.idWorkflow") + " : " + filter.value,
      icon: iconText,
      publications: [],
      color: "cyan-7",
      order: 5,
    });

    filtered.push({
      type: "ID_BackOffice",
      value: filter.value,
      searchValue: filter.value,
      label: i18n.t("search.type.idBackOffice") + " : " + filter.value,
      icon: iconText,
      publications: [],
      color: "cyan-8",
      order: 6,
    });

    filtered.push({
      type: "ID_ContentWorkflow",
      value: filter.value,
      searchValue: filter.value,
      label: i18n.t("search.type.idContentWorkflow") + " : " + filter.value,
      icon: iconText,
      publications: [],
      color: "cyan-9",
      order: 6,
    });
  }

  addDateOptions(needle, filtered);

  return [...filtered]
    .sort((s1, s2) => s2.order - s1.order)
    .map((s) => ({
      label: s.label,
      value: s,
    }));
});

const addOrRemoveFilterStateImported = function (valueToSet: boolean) {
  // retirer tous les occurences de filtres sur les états
  let removeItem = searchPart.value.find((s) => s.value.type == "State");
  while (removeItem) {
    searchPart.value.splice(searchPart.value.indexOf(removeItem), 1);
    removeItem = searchPart.value.find((s) => s.value.type == "State");
  }

  if (valueToSet) {
    const stateOption = availableOptions.find(
      (o) => o.type == "State" && o.value == WorkflowState.Importe
    );

    if (!stateOption) return;

    searchPart.value.push({
      label: WorkflowState.Importe,
      value: stateOption,
    });
  }

  emits("update:modelValue", searchPart.value);
};

const addOrRemoveFilterLanguage = function (valueToSet: string | null) {
  // retirer tous le filtre sur la langue
  let removeItem = searchPart.value.find((s) => s.value.type == "Language");
  if (removeItem)
    searchPart.value.splice(searchPart.value.indexOf(removeItem), 1);

  if (valueToSet) {
    const languageOption = availableOptions.find(
      (o) => o.type == "Language" && o.value == valueToSet
    );

    if (!languageOption) return;

    searchPart.value.push({
      label: languageOption.label,
      value: languageOption,
    });
  }

  emits("update:modelValue", searchPart.value);
};

const addOrRemoveFilterSpotlights = function (valueToSet: boolean) {
  // retirer tous le filtre sur la langue
  let removeItem = searchPart.value.find((s) => s.value.type == "Format");
  if (removeItem)
    searchPart.value.splice(searchPart.value.indexOf(removeItem), 1);

  if (valueToSet) {
    const formatOption = availableOptions.find(
      (o) => o.type == "Format" && o.value == "Feuilleton"
    );

    if (!formatOption) return;

    searchPart.value.push({
      label: formatOption.label,
      value: formatOption,
    });
  }

  emits("update:modelValue", searchPart.value);
};

/**
 *
 */
const addOrRemoveFilterToday = function (valueToSet: boolean) {
  //Date du jour au format DD/MM/YYYY
  const todayDate = new Date().toLocaleDateString(FrenchLanguage, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  const valueToAdd = "PublishedAt:" + todayDate;

  let removeItem = searchPart.value.find((s) => s.value.type == "PublishedAt");
  if (removeItem) {
    searchPart.value.splice(searchPart.value.indexOf(removeItem), 1);
  }

  if (valueToSet) {
    searchPart.value.push({
      label: "PublishedAt:" + todayDate,
      value: {
        type: "PublishedAt",
        value: valueToAdd,
        searchValue: todayDate,
        label: i18n.t("search.type.publishedAt") + " : " + todayDate,
        icon: iconDate,
        publications: [],
        color: colorDate,
        order: 95,
      },
    });
  }

  emits("update:modelValue", searchPart.value);
};

/**
 * Permet d'ajouter un filtre sur les articles de l'utilisateur courant
 */
const addOrRemoveFilterCurrentUser = function (valueToSet: boolean) {
  const selectOptionUser = availableOptions.find(
    (o) =>
      o.type == "User" &&
      (o.value as string).split(":")[1] == authService.currentUser?.id &&
      (o.value as string).split(":")[0] == ContributionType.Unknown
  );

  const removeItem = search.value.find((s) => s.value.type == "User");
  if (removeItem) {
    search.value.splice(search.value.indexOf(removeItem), 1);
  }

  if (valueToSet && selectOptionUser != undefined) {
    search.value.push({
      label: selectOptionUser.label,
      value: selectOptionUser,
    });

    emits("update:modelValue", searchPart.value);
  }
};

/**
 * Permet d'ajouter de nouveau élements dans la liste des options de recherche.
 * Utiliser pour enrichir la recherche à partir de clic sur les résulats de recherche
 */
const addFilterToSelect = function (type: string, value: unknown) {
  const filterOption = availableOptions.find(
    (o) => o.type == type && o.value == value
  );
  // On ne peut pas ajouter deux fois la même option.
  if (filterOption) {
    const itemExist = searchPart.value.find(
      (s) => s.value.type == type && s.value.value == value
    );

    if (!itemExist) {
      searchPart.value.push({
        label: filterOption.label,
        value: filterOption,
      });

      emits("update:modelValue", searchPart.value);
    }
  }
};

defineExpose({
  addOrRemoveFilterToday,
  addOrRemoveFilterCurrentUser,
  addOrRemoveFilterStateImported,
  addOrRemoveFilterLanguage,
  addOrRemoveFilterSpotlights,
  addFilterToSelect,
});

/**
 * Change la couleur des rubriques invalide pour la publication selectionné.
 */
function highlightInvalidSection(newValue, oldValue) {
  const oldPublicationIds = extractPubSIdFromSearch(oldValue);
  const publicationIds = extractPubSIdFromSearch(newValue);

  if (publicationIds.length != oldPublicationIds.length) {
    const sectionsInError = newValue.filter((s) => s.value.type == "Section");

    sectionsInError.forEach((s) => {
      if (
        publicationIds.length &&
        s.value.publications.every((p) => publicationIds.indexOf(p) < 0)
      )
        s.value.color = "negative";
      else s.value.color = "secondary";
    });
  }
}

/**
 * Deduplicate les dates en fonction de la saisie utilisateur.
 */
function deduplicateDatePart(newValue, oldValue) {
  const dateType = ["PublishedAt", "From", "To"];
  if (newValue.every((s) => dateType.indexOf(s.value.type) < 0))
    return newValue;

  if (
    oldValue.every((s) => s.value.type != "PublishedAt") &&
    newValue.some((s) => s.value.type === "PublishedAt")
  ) {
    newValue = newValue.filter(
      (s) => s.value.type != "From" && s.value.type != "To"
    );
  }

  if (
    oldValue.some((s) => s.value.type === "PublishedAt") &&
    newValue.some((s) => s.value.type === "From" || s.value.type === "To")
  ) {
    newValue = newValue.filter((s) => s.value.type != "PublishedAt");
  }

  dateType.forEach((dt) => {
    if (newValue.filter((s) => s.value.type === dt).length > 1) {
      const o = oldValue.find((s) => s.value.type === dt);
      if (o) {
        const index = newValue.findIndex(
          (s) => s.value.type === o.value.type && s.value === o.value
        );
        newValue.splice(index, 1);
      }
    }
  });

  return newValue;
}

/**
 * Obtient les options filtré selon l'input utilisateur et
 * les eventuelles publications déjà renseignés.
 */
function getOptionsFilteredByPublicationAndInput(needle: string) {
  const publicationIds = extractPubSIdFromSearch(search.value);

  return availableOptions.filter((s) => {
    if (
      publicationIds &&
      s.publications.length &&
      publicationIds.some((id) => s.publications.indexOf(id) < 0)
    )
      return false;

    if (needle === "") return true;
    const label = removeDiacritics(s.label);
    return label.toLowerCase().indexOf(needle) > -1;
  });
}

/**
 * Ajoute les options de dates si la saisie utilisateur correspond.
 */
function addDateOptions(needle: string, filtered: SearchPart[]) {
  let dateInput = convertToDate(needle);

  //Si la saisie de l'utilisateur ne correspond pas à une date valide, on tente de la compléter avec les informations de la date du jour.
  if (!dateInput) {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();

    //Si la saisie de l'utilisateur correspond à un jour du mois, on tente de compléter avec le mois et l'année courant.
    if (
      needle.length <= 2 &&
      parseInt(needle) <= 31 &&
      needle.split("/").length == 1
    ) {
      dateInput = new Date(Date.UTC(year, month - 1, parseInt(needle)));
    }

    //Si la saisie de l'utilisateur correspond à un jour/mois, on tente de compléter avec l'année courant.
    if (needle.length <= 5 && needle.split("/").length == 2) {
      dateInput = new Date(
        Date.UTC(
          year,
          (parseInt(needle.split("/")[1]) - 1, parseInt(needle.split("/")[0]))
        )
      );
    }
  }

  if (!dateInput) return;

  const input = date.formatDate(dateInput, "DD/MM/YYYY");

  filtered.push({
    type: "PublishedAt",
    value: `PublishedAt:${input}`,
    searchValue: input,
    label: i18n.t("search.type.publishedAt") + " : " + input,
    icon: iconDate,
    publications: [],
    color: colorDate,
    order: 95,
  });

  filtered.push({
    type: "From",
    value: `From:${input}`,
    searchValue: input,
    label: i18n.t("search.type.from") + " : " + input,
    icon: iconDate,
    publications: [],
    color: colorDate,
    order: 94,
  });

  filtered.push({
    type: "To",
    value: `To:${input}`,
    searchValue: input,
    label: i18n.t("search.type.to") + " : " + input,
    icon: iconDate,
    publications: [],
    color: colorDate,
    order: 93,
  });
}

/**
 *  Permet de trigger le filtrage des intérêts pour simplifier l'utilisation.
 */
function filterFn(val, update, abort) {
  if (val.length < 2) {
    abort();
    return;
  }

  update(() => {
    filter.value = val;
  });
}

/**
 * Ajoute une nouvelle entrée text à la recherche.
 */
function addNewValue(val, done) {
  const obj = {
    type: "Text",
    value: val,
    searchValue: val,
    label: i18n.t("search.type.text") + " : " + val,
    icon: iconText,
    publications: [],
    color: colorText,
    order: 100,
  };
  done({
    label: obj.label,
    value: obj,
  });
}

/**
 * Permet de soumettre le formulaire avec la touche entrée (si popup du select item fermé)
 * ou de fermer le popup du select item (si popup du select item ouvert) et vider le champ de recherche.
 */
function keyUpEnter(event) {
  if (!selectItemOpened.value) {
    event.preventDefault();
    emits("onSubmit");
  } else {
    selectOptionRecherche.value?.hidePopup();
    clearInputSelect();
  }
}

/**
 * Vide le champ de recherche du select item.
 */
function clearInputSelect() {
  selectOptionRecherche.value?.updateInputValue("");
}

/**
 * Permet de definir si le popup du select item est ouvert ou non.
 */
function setSelectItemOpened(value: boolean) {
  selectItemOpened.value = value;
}

/**
 * Extrait les PubSId depuis la recherche courante.
 */
function extractPubSIdFromSearch(searchValue: QSelectOption<SearchPart>[]) {
  return searchValue
    .filter((s) => s.value.type === "Publication")
    .map((s) => `${s.value.value}`);
}

/**
 * Ajoute les pays aux options de recherche.
 */
function addCountriesToOptions(icon: string | null, color: string) {
  const { countries } = useCountryStore();
  countries
    .sort((a, b) => (a.labelFr > b.labelFr ? 1 : -1))
    .forEach((c) => {
      const label = isFr ? c.labelFr : c.labelEn;
      availableOptions.push({
        type: "Country",
        value: c.id,
        searchValue: label,
        label: i18n.t("search.type.country") + " : " + label,
        icon: icon ?? "person",
        publications: [],
        color: color ?? "secondary",
        order: 55,
      });
    });
}

/**
 * Ajoute les intérêts aux options de recherche.
 */
function addInterestsToOptions(icon: string | null, color: string) {
  articleSettings
    .filter((s) => s.idType == SettingsType.Interest)
    .sort((a, b) => (a.labelFr > b.labelFr ? 1 : -1))
    .forEach((interest) => {
      const label = isFr ? interest.labelFr : interest.labelEn;
      availableOptions.push({
        type: "Interest",
        value: interest.id,
        searchValue: label,
        label: i18n.t("search.type.interest") + " : " + label,
        icon: icon ?? "person",
        publications: [],
        color: color ?? "secondary",
        order: 25,
      });
    });
}

/**
 * Ajoute les paramètres CMS aux options de recherche.
 */
function addCmsSettingsToOptions(icon: string | null, color: string) {
  articleSettings
    .filter((s) => s.idType == SettingsType.CMS)
    .sort((a, b) => (a.labelFr > b.labelFr ? 1 : -1))
    .forEach((cms) => {
      const label = isFr ? cms.labelFr : cms.labelEn;
      availableOptions.push({
        type: "CMS",
        value: cms.id,
        searchValue: label,
        label: i18n.t("search.type.cms") + " : " + label,
        icon: icon ?? "person",
        publications: [],
        color: color ?? "secondary",
        order: 30,
      });
    });
}

/**
 * Ajoute les utilisateurs aux options de recherche.
 */
function addUsersToOptions(icon: string | null, color: string) {
  const { usersLight } = useUserStore();
  for (const contributionType in ContributionType) {
    const prefixLabel =
      contributionType == ContributionType.Unknown
        ? i18n.t("search.type.user")
        : i18n.t(`contributionType.${contributionType}`);
    usersLight
      .filter((u) => checkUserWithContributionType(u, contributionType))
      .sort((a, b) => (a.firstName > b.firstName ? 1 : -1))
      .forEach((user) => {
        availableOptions.push({
          type: "User",
          value: `${contributionType}:${user.id}`,
          searchValue: user.name,
          label: `${prefixLabel} : ${user.name}`,
          icon: icon ?? "person",
          publications: [],
          color: color ?? "secondary",
          order: 20,
        });
      });
  }
}

/**
 * Vérifie si l'utilisateur doit apparaitre pour un type de contribution passé en paramètre.
 */
function checkUserWithContributionType(
  user: UserLightDto,
  contributionType: string
) {
  switch (contributionType) {
    case ContributionType.Unknown:
      return true;
    case ContributionType.Editor:
      return user.editor.length > 0;
    case ContributionType.Author:
    case ContributionType.Reviewer:
      return user.redactor.length > 0;
    case ContributionType.Translator:
    case ContributionType.LeadTranslator:
      return user.translator.length > 0;
    case ContributionType.Freelancer:
      return user.freelance.length > 0;
  }

  return false;
}

/**
 * Ajoute les rubriques aux options de recherche.
 */
function addSectionsToOptions(icon: string | null, color: string) {
  const { sections } = useSectionStore();
  sections
    .sort((a, b) => (a.labelFr > b.labelFr ? 1 : -1))
    .forEach((section) => {
      const label = isFr ? section.labelFr : section.labelEn || section.labelFr;
      availableOptions.push({
        type: "Section",
        value: section.id,
        searchValue: label,
        label: i18n.t("search.type.section") + " : " + label,
        icon: icon ?? "person",
        publications: section.sectionPublications
          .filter((sp) => sp.active)
          .map((sp) => sp.publication),
        color: color ?? "secondary",
        order: 80,
      });
    });
}

/**
 * Ajoute les publications aux options de recherche.
 */
function addPublicationsToOptions(icon: string | null, color: string) {
  const { publications } = usePublicationStore();
  publications
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .forEach((publication) => {
      availableOptions.push({
        type: "Publication",
        value: publication.id,
        searchValue: publication.name,
        label: i18n.t("search.type.publication") + " : " + publication.name,
        icon: icon ?? "person",
        publications: [],
        color: color ?? "secondary",
        order: 90,
      });
    });
}

/**
 * Ajoute les formats aux options de recherche.
 */
function addFormatsToOptions(icon: string | null, color: string) {
  const { formats } = useFormatStore();
  formats
    .sort((a, b) => (a.labelFr > b.labelFr ? 1 : -1))
    .forEach((format) => {
      const label = isFr ? format.labelFr : format.labelEn;
      availableOptions.push({
        type: "Format",
        value: format.id,
        searchValue: label,
        label: i18n.t("search.type.format") + " : " + label,
        icon: icon ?? "person",
        publications: [],
        color: color ?? "secondary",
        order: 70,
      });
      availableOptions.push({
        type: "FormatExcluded",
        value: format.id,
        searchValue: label,
        label: i18n.t("search.type.formatExcluded") + " : " + label,
        icon: icon ?? "person",
        publications: [],
        color: color ?? "secondary",
        order: 70,
      });
    });
}

/**
 * Ajoute les langues aux options de recherche.
 */
function addLanguagesToOptions(icon: string | null, color: string) {
  const { languages } = useLanguageStore();
  languages
    .sort((a, b) => (a > b ? 1 : -1))
    .forEach((language) => {
      availableOptions.push({
        type: "Language",
        value: language,
        searchValue: language,
        label:
          i18n.t("search.type.language") +
          " : " +
          i18n.t("languages." + language),
        icon: icon ?? "person",
        publications: [],
        color: color ?? "secondary",
        order: 60,
      });
    });
}

/**
 * Ajoute les états aux options de recherche.
 */
function addStatesToOptions(icon: string | null, color: string) {
  for (const state in WorkflowState) {
    availableOptions.push({
      type: "State",
      value: state,
      searchValue: state,
      label:
        i18n.t("search.type.state") + " : " + i18n.t(`article.states.${state}`),
      icon: icon ?? "person",
      publications: [],
      color: color ?? "secondary",
      order: 10,
    });
  }
}
</script>

<style lang="scss">
label.advanced-search-input {
  min-height: 45px;

  div.q-field__inner {
    div.q-field__control {
      min-height: 45px !important;
    }
  }
}
</style>
