import type { AxiosResponse } from "axios";
import type {
  ArticleServiceInstance,
  ArticleForm,
  TriggerArticleParam,
} from "@/types/article";
import type { CrudServiceInstance } from "@/types/crud";
import {
  ArticleContentDto,
  ArticleDto,
  ArticleLockDto,
  ArticleHistoryDto,
  EventTrackingObjectDto,
  WorkflowFireResult,
  WorkflowState,
  ArticleSettingDto,
  ArticleVersionDto,
  TriggerResult,
  WorkflowTriggerParam,
} from "@/types/api";
import {
  downcastArticleDto,
  getNextEditionDay,
  upcastArticleDto,
} from "@/helpers/article";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";
import { signalRService } from "./SignalrService";
import { useConfigStore } from "@/stores/config";
import { authService } from "./AuthService";

/**
 * Fonction pour créer un object type article
 */
export function createArticle(params: Partial<ArticleDto> = {}): ArticleDto {
  const articleContentDefault = createArticleContent();

  return {
    id: params?.id ?? 0,
    contents: params?.contents ?? [articleContentDefault],
    countries: params?.countries ?? [],
    deleted: params?.deleted ?? false,
    publishedOn: params?.publishedOn ?? new Date().toISOString(),
    lastUpdate: params?.lastUpdate ?? new Date().toISOString(),
    publication: params?.publication ?? "",
    sections: params?.sections ?? [],
    xmin: params?.xmin ?? 0,
    score: params?.score ?? 0,
    modificationType: params?.modificationType ?? 0,
    settings: params?.settings ?? [],
    contributions: params?.contributions ?? [],
    idMainLanguage: params?.idMainLanguage ?? "fr-FR",
    associatedArticles: params?.associatedArticles ?? [],
  };
}

/**
 * Récupère un article de type ArticleForm avec les valeurs par défaut
 */
export function createArticleForm(
  articleDefault = createArticle(),
  setDefaultDate = true
): ArticleForm {
  const configStore = useConfigStore();

  const nextEdition = getNextEditionDay();

  if (setDefaultDate)
    articleDefault.publishedOn = nextEdition.toLocaleISOString();

  return {
    ...articleDefault,
    publication:
      articleDefault.publication !== ""
        ? articleDefault.publication
        : configStore.$state.config.publication,
    sectionPrimary:
      articleDefault.sections.find((s) => s.main === true)?.section ??
      undefined,
    sectionSecondaries: articleDefault.sections
      .filter((s) => s.main === false)
      .map((s) => s.section),
  };
}

/**
 * Fonction pour créer un object type article content
 */
export function createArticleContent(
  params: Partial<ArticleContentDto> = {}
): ArticleContentDto {
  return {
    id: params?.id ?? 0,
    idArticle: params?.idArticle ?? 0,
    invalidated: params?.invalidated ?? false,
    online: params?.online ?? false,
    idBackOffice: params?.idBackOffice,
    language: params?.language ?? "fr-FR",
    state: params?.state ?? WorkflowState.Pitch,
    text: params?.text ?? "",
    title: params?.title ?? "",
    xmin: params?.xmin ?? 0,
    lastUpdate: params?.lastUpdate ?? new Date().toISOString(),
    format: params?.format ?? "",
    charCount: params?.charCount ?? 0,
    wordCount: params?.wordCount ?? 0,
    settings: params?.settings ?? [],
    standfirst: params?.standfirst ?? "",
    isTranslation: params?.isTranslation ?? false,
    medias: params?.medias ?? [],
  };
}

/**
 * Service article pour récupérer les articles et les modifier.
 */
class ArticleService extends AxiosRequest implements ArticleServiceInstance {
  crudService: CrudServiceInstance<ArticleDto>;
  /**
   * Constructeur de la class Article service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<ArticleDto>(rootPath);
  }

  /**
   * Liste les articles
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre d'articles affichés par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService
      .getList(page, limit)
      .then((response) => response.map(downcastArticleDto));
  }

  /**
   * Récupérer un article par son id
   * @param {number | string} id - id de l'article à récupérer
   */
  getSingle(id: string | number) {
    return this.crudService
      .getSingle(id)
      .then((response) => downcastArticleDto(response));
  }

  /**
   * Créer un nouvel article
   * @param article - données du nouvel article à créer
   */
  insert(article: ArticleDto) {
    return this.crudService.insert(upcastArticleDto(article));
  }

  /**
   * Met à jour un article
   * @param article - nouvelles données de l'article à mettre à jour
   */
  update(article: ArticleDto) {
    return this.crudService
      .update(article.id, upcastArticleDto(article))
      .then((response) => downcastArticleDto(response));
  }

  /**
   * Supprime un article
   * @param id - id de l'article à supprimer
   */
  delete(id: string | number) {
    return this.crudService.delete(id);
  }

  /**
   * Duplique un article
   * @param id - Id de l'article a dupliquer
   * @param publication - Publication de destination
   */
  duplicate(id: number | string, publication: string) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/duplicate/${publication}`)
      .then((response: AxiosResponse<number>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Crée la traduction pour l'article
   * @param id - id de l'article à traduire
   * @param language - langue de la traduction
   * @param translatorId - identifiant du traducteur
   */
  translate(
    id: number | string,
    language: string,
    translatorId?: string | null
  ) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/translate/${language}`, translatorId)
      .then((response: AxiosResponse<ArticleDto>) => {
        return downcastArticleDto(response.data);
      })
      .catch(this.handleError);
  }

  /**
   * Crée la traduction pour l'article
   * @param id - id de l'article à traduire
   */
  switchMainLanguage(id: number | string) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/switchMainLanguage`)
      .then(() => {
        return true;
      })
      .catch(this.handleError);
  }

  /**
   * Récupération des logs d'exportation de l'article.
   * @param id - id de l'article
   */
  getLogs(id: string | number) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/logs`)
      .then((response: AxiosResponse<EventTrackingObjectDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupération des verrous de l'article.
   * @param id - id de l'article
   */
  getLocks(id: string | number) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/locks`)
      .then((response: AxiosResponse<ArticleLockDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Demande la création d'une preview de l'article.
   * @param id - id de l'article
   * @param language - langue de l'article pour la preview
   */
  createPreview(id: number, language: string) {
    return this.axiosInstance
      .post(
        `${this.rootPath}/${id}/preview/${language}`,
        signalRService.getConnectionId()
      )
      .then(() => {
        return true;
      })
      .catch(this.handleError);
  }

  /**
   * Insert un verrouillage pour l'utilisateur sur l'article indiqué.
   * @param id - id de l'article
   */
  insertLock(id: string | number, modificationType: number) {
    return this.axiosInstance
      .put(`${this.rootPath}/${id}/locks?modificationType=${modificationType}`)
      .then((response: AxiosResponse<boolean>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Suppression des verrouillage pour l'utilisateur courant.
   * @param id - id de l'article
   * @param errorHandling - méthode de gestion des erreurs par défaut
   */
  deleteLock(
    id: string | number,
    errorHandling: (() => boolean) | undefined = undefined
  ) {
    return this.axiosInstance
      .delete(`${this.rootPath}/${id}/locks`)
      .then((response: AxiosResponse<boolean>) => {
        return response.data;
      })
      .catch(errorHandling ?? this.handleError);
  }

  /**
   * Suppression des verrouillage pour l'utilisateur courant.
   * @param id - id de l'article
   */
  syncDeleteLock(id: string | number) {
    const token = authService.keycloakInstance?.token;
    const url = `${this.axiosInstance.getUri()}/${
      this.rootPath
    }/${id}/locks/delete?access_token=${token}`;
    navigator.sendBeacon(url, null);
  }

  /**
   * Permet la libération de l'article.
   * @param id - id de l'article
   */
  freeLock(id: number) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/locks/free`)
      .then((response: AxiosResponse<boolean>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupère l'historique de l'article
   * @param id - id de l'article
   */
  histories(id: string | number) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/histories`)
      .then((response: AxiosResponse<ArticleHistoryDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupère l'historique de l'article
   * @param id - id de l'article
   */
  history(id: string | number, allRevisionId: string) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/histories/${allRevisionId}`)
      .then((response: AxiosResponse<ArticleDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupère les triggers applicable à l'article
   * @param id - id de l'article
   * @param lang - langue de l'article
   */
  getTriggers(id: string | number, lang: string) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/contents/${lang}/triggers`)
      .then((response: AxiosResponse<TriggerResult>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   * Applique un trigger à un article
   * @param param - TriggerArticleParam
   * @returns Le nouvel état de l'article
   */
  triggerArticle(param: TriggerArticleParam) {
    const post: WorkflowTriggerParam = {
      connectionId: signalRService.getConnectionId(),
      contributorIds: [],
      message: param.message,
    };

    if (param.userIds) post.contributorIds.push(...param.userIds);

    return this.axiosInstance
      .post(
        `${this.rootPath}/${param.id}/contents/${param.lang}/triggers/${param.trigger}/${param.xmin}`,
        post
      )
      .then(async (response: AxiosResponse<WorkflowFireResult>) => {
        await this.deleteLock(param.id, () => {
          console.error("Erreur lors de la suppression du verrouillage.");
          return false;
        });
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupération des enrichissements.
   * @param id - id de l'article
   */
  getAssociatedArticles(id: string | number) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/associatedArticles`)
      .then((response: AxiosResponse<ArticleDto[]>) => {
        return response.data.map(downcastArticleDto);
      })
      .catch(this.handleError);
  }

  /**
   * Récupération des articles en paramètres.
   * @param id - id de l'article
   */
  getArticles(ids: string[] | number[]) {
    return this.axiosInstance
      .post(`${this.rootPath}/getMultipleById`, ids)
      .then((response: AxiosResponse<ArticleDto[]>) => {
        return response.data.map(downcastArticleDto);
      })
      .catch(this.handleError);
  }

  /**
   * Récupération des enrichissements via l'enrichissement auto.
   * @param id - id de l'article
   */
  getAssociatedArticlesFromAutoAssociation(id: string | number) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/autoAssociationArticle`)
      .then((response: AxiosResponse<ArticleDto[]>) => {
        return response.data.map(downcastArticleDto);
      })
      .catch(this.handleError);
  }
  /**
   * Récupération des intérets depuis le texte de l'article.
   * @param id - id de l'article
   */
  getInterestsFromText(id: string | number) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}/autoInterestArticle`)
      .then((response: AxiosResponse<ArticleSettingDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Applique un montant pour une contribution
   * @param articleId - id de l'article
   * @param contributionId - id de la contribution
   * @param amount - montant de la contribution
   * @returns vrai si sauvegardé
   */
  setAmountToContribution(
    articleId: number,
    contributionId: number,
    amount: number
  ) {
    return this.axiosInstance
      .post(
        `${this.rootPath}/${articleId}/setAmountToContribution/${contributionId}/${amount}`
      )
      .then((response: AxiosResponse<ArticleDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Met a jour le tweet d'un contenu
   * @param articleId - id de la contribution
   * @param xmin - xmin du contenu lié
   * @param language - langue du contenu
   * @param tweet - contenu du tweet
   * @returns vrai si sauvegardé
   */
  updateTweet(
    articleId: number,
    xmin: number,
    language: string,
    tweet: string
  ) {
    return this.axiosInstance
      .put(
        `${this.rootPath}/${articleId}/content/${language}/tweet/${xmin}`,
        tweet
      )
      .then((response: AxiosResponse<ArticleDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupère la version d'un article et de ses contents.
   * @param articleId Id de l'article.
   * @returns Version de l'article et des contents.
   */
  getVersion(articleId: number) {
    return this.axiosInstance
      .get(`${this.rootPath}/${articleId}/version`)
      .then((response: AxiosResponse<ArticleVersionDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupère les ids des articles liés à des feuilletons.
   * @param articleIds Ids des articles.
   * @returns Ids des articles liés à des feuilletons.
   */
  getLinkedToSerials(articleIds: number[]) {
    return this.axiosInstance
      .post(`${this.rootPath}/linkedToSerials`, articleIds)
      .then((response: AxiosResponse<number[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupère l'url d'un article dans une autre langue.
   * @param url Url à traduire.
   * @returns L'url traduire ou vide si l'autre langue n'existe pas.
   */
  getUrlInOtherLanguage(url: string) {
    return this.axiosInstance
      .post(`${this.rootPath}/getUrlInOtherLanguage`, url)
      .then((response: AxiosResponse<string>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Modifie la date de parution d'un article
   * @param id Id de l'article.
   * @param mainLanguage Contenu principale ou traduction.
   * @param date Nouvelle date de parution.
   * @returns Articles liés à l'article.
   */
  updatePublishedOn(id: number, mainLanguage: boolean, date: Date) {
    return this.axiosInstance
      .post(`${this.rootPath}/${id}/publishedOn`, {
        mainLanguage,
        publishedOn: date.toLocaleISOString(),
      })
      .then((response: AxiosResponse<boolean>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const articleService = new ArticleService("articles");
