import { AxiosRequest } from "@/services/ElcanoAPI";
import {
  PaginatedResultReportSearchAndReplace,
  ParamProcessReplacement,
  ParamSearchAndReplace,
  ParamUpdateResultModel,
  ReportSearchAndReplaceDto,
} from "@/types/api";
import { ReportServiceInstance } from "@/types/report";
import { AxiosResponse } from "axios";

/**
 *
 */
export function createParam(): ParamSearchAndReplace {
  return {
    searchText: "",
    replaceText: "",
    idAuthor: "",
    publications: [],
    idPays: [],
    language: undefined,
    mainSections: [],
    subSections: [],
  };
}

/**
 * Service publication pour récupérer les publications et les modifier.
 */
class ReportService extends AxiosRequest implements ReportServiceInstance {
  /**
   * Constructeur de la class Publication service
   */
  constructor(public rootPath: string) {
    super();
  }

  /**
   * Créer un rapport pour un remplacement de texte
   *
   */
  createReport(param: ParamSearchAndReplace) {
    return this.axiosInstance
      .post(`${this.rootPath}/createReport`, param)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  getReport(id: string) {
    return this.axiosInstance
      .get(`${this.rootPath}/getReport?id=${id}`)
      .then((response: AxiosResponse<ReportSearchAndReplaceDto>) => {
        return response.data;
      });
  }

  /**
   *
   */
  getReports() {
    return this.axiosInstance
      .get(`${this.rootPath}/getReports`)
      .then((response: AxiosResponse<ReportSearchAndReplaceDto[]>) => {
        return response.data;
      });
  }

  /**
   *
   */
  getResults(id: string, nbPage: number) {
    return this.axiosInstance
      .get(`${this.rootPath}/getResults?id=${id}&nbPage=${nbPage}`)
      .then(
        (response: AxiosResponse<PaginatedResultReportSearchAndReplace>) => {
          return response.data;
        }
      );
  }

  /**
   *
   */
  updateResultReportStatus(param: ParamUpdateResultModel) {
    return this.axiosInstance
      .post(`${this.rootPath}/UpdateReportResultStatus`, param)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }

  /**
   *
   */
  processReport(param: ParamProcessReplacement) {
    return this.axiosInstance
      .post(`${this.rootPath}/processReport`, param)
      .then((response: AxiosResponse) => {
        return response.data;
      });
  }
}

export const reportService = new ReportService("report");
