import type { AxiosResponse } from "axios";
import type { CrudServiceInstance } from "@/types/crud";
import type { GenericBody } from "@/types/generic";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { isEmpty } from "@/utils";

/**
 * Service crud pour récupérer les items et les modifier.
 */
export class CrudService<T>
  extends AxiosRequest
  implements CrudServiceInstance<T>
{
  public rootPath: string;

  /**
   * Constructeur du service
   */
  constructor(rootPath: string) {
    super();
    this.rootPath = rootPath;
  }

  /**
   * Liste les items
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre d'items affiché par page.
   */
  getList(page = 1, limit = 20, withCache = false) {
    return this.axiosInstance
      .get(`${this.rootPath}?page=${page}&limit=${limit}`, {
        cache: withCache ? { methods: ["get"] } : false,
      })
      .then((response: AxiosResponse<T[]>) => {
        if (response.status !== 200) return [];
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Récupérer un item par son id
   * @param {number | string} id - id de l'item à récupérer
   */
  getSingle(id: number | string) {
    return this.axiosInstance
      .get(`${this.rootPath}/${id}`)
      .then((response: AxiosResponse<T>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Créer un item
   * @param item - DTO item
   */
  insert(item: T) {
    return this.axiosInstance
      .post(`${this.rootPath}/`, item)
      .then((response: AxiosResponse<T>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Met à jour un item
   * @param {number | string} id - id de l'item
   * @param item - DTO item
   */
  update(id: number | string, item: T) {
    return this.axiosInstance
      .put(`${this.rootPath}/${id}`, item)
      .then((response: AxiosResponse<T>) => {
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Créer l'item si l'id n'est pas défini ou est égale à 0 ou vide sinon il fait un update
   */
  upsert(item: GenericBody<T>) {
    if (isEmpty(item.id)) return this.insert(item);
    else return this.update(item.id as string | number, item);
  }

  /**
   * Supprime un item
   * @param {number | string} id - id de l'item
   */
  delete(id: number | string) {
    return this.axiosInstance
      .delete(`${this.rootPath}/${id}`)
      .then((response) => {
        return response.status === 200;
      })
      .catch(this.handleError);
  }
}
