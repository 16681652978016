<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <Page class="help">
    <!--Generate a FAQ with quasar component, with 10 topics-->
    <h6>{{ $t("help.sections.0.titleHeader") }}</h6>
    <q-list bordered class="rounded-borders">
      <q-expansion-item expand-separator icon="group_work">
        <template #header>
          <q-item-section avatar>
            <q-icon name="group_work" />
          </q-item-section>
          <q-item-section>
            {{ $t("help.sections.0.questions.1.question") }}
          </q-item-section>
          <q-item-section side>
            <q-badge v-if="showWhatsNew" rounded
              >{{ $t("help.labels.news") }}
            </q-badge>
          </q-item-section>
        </template>
        <q-card>
          <q-card-section>
            <vue-markdown
              :source="srcChangeLog"
              :options="options"
              class="markdown-body"
            ></vue-markdown>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
    <h6>{{ $t("help.sections.1.titleHeader") }}</h6>
    <q-list bordered class="rounded-borders">
      <q-expansion-item
        expand-separator
        icon="group_work"
        :label="$t('help.sections.1.questions.1.question')"
      >
        <q-card>
          <q-card-section>
            {{ $t("help.sections.1.questions.1.content") }}
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item
        expand-separator
        icon="checklist_rtl"
        :label="$t('help.sections.1.questions.2.question')"
      >
        <q-card>
          <q-card-section>
            {{ $t("help.sections.1.questions.2.content") }}
            <ListShortcuts />
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>

    <h6>{{ $t("help.sections.2.titleHeader") }}</h6>
    <q-list bordered class="rounded-borders">
      <q-expansion-item
        expand-separator
        icon="spellcheck"
        :label="$t('help.sections.2.questions.1.question')"
      >
        <q-card>
          <q-card-section>
            {{ $t("help.sections.2.questions.1.content") }}
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item
        expand-separator
        icon="rate_review"
        :label="$t('help.sections.2.questions.3.question')"
      >
        <q-card>
          <q-card-section>
            <div v-html="$t('help.sections.2.questions.3.content0')"></div>
            <div v-html="$t('help.sections.2.questions.3.content1')"></div>
            <div v-html="$t('help.sections.2.questions.3.content2')"></div>
            <div v-html="$t('help.sections.2.questions.3.content3')"></div>
            <div v-html="$t('help.sections.2.questions.3.content4')"></div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
      <q-expansion-item
        expand-separator
        icon="keyboard"
        :label="$t('help.sections.2.questions.2.question')"
      >
        <q-card>
          <q-card-section>
            <q-list bordered class="rounded-borders">
              <div class="row">
                <div class="col q-pa-sm">
                  {{ $t("help.states.mainLanguage") }}
                </div>
                <div class="col q-pa-sm">
                  {{ $t("help.states.translation") }}
                </div>
              </div>
              <div
                v-for="(state, index) in mainStates"
                :key="index"
                class="row"
              >
                <div class="col q-pa-sm" :style="getStyle(state)">
                  <span>
                    {{ !state ? "&nbsp;" : $t("article.states." + state) }}
                  </span>
                </div>
                <div
                  class="col q-pa-sm"
                  :style="getStyle(translationStates[index])"
                >
                  {{
                    !translationStates[index]
                      ? "&nbsp;"
                      : $t("article.states." + translationStates[index])
                  }}
                </div>
              </div>
            </q-list>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>

    <h6>{{ $t("help.sections.3.titleHeader") }}</h6>
    <q-list bordered class="rounded-borders">
      <q-expansion-item
        expand-separator
        icon="search"
        :label="$t('help.sections.3.questions.1.question')"
      >
        <q-card>
          <q-card-section>
            <div v-html="$t('help.sections.3.questions.1.content0')"></div>
            <div v-html="$t('help.sections.3.questions.1.content1')"></div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>

    <h6>{{ $t("help.sections.4.titleHeader") }}</h6>
    <q-list bordered class="rounded-borders">
      <q-expansion-item
        v-for="i in [1, 2, 3, 4, 5, 6, 7]"
        :key="i"
        expand-separator
        icon="event"
        :label="$t(`help.sections.4.questions.${i}.question`)"
      >
        <q-card>
          <q-card-section>
            <div
              class="question-content"
              v-html="$t(`help.sections.4.questions.${i}.content`)"
            ></div>
          </q-card-section>
        </q-card>
      </q-expansion-item>
    </q-list>
  </Page>
</template>

<script setup lang="ts">
import Page from "@/components/Layouts/Page.vue";
import VueMarkdown, { Options } from "vue-markdown-render";
import { useConfigStore } from "@/stores/config";

import ListShortcuts from "@/components/ckeditor/ListShortcuts.vue";
import {
  getWorkflowStateColor,
  getWorkflowStateTextColor,
} from "@/helpers/workflowState";
import { ref } from "vue";
import { WorkflowState } from "@/types/api";
const srcChangeLog = ref<string>("");
const { showWhatsNew } = useConfigStore();

// full options list (defaults)
const options: Options = {
  html: true,
  xhtmlOut: false,
  linkify: true,
};

const mainStates = [
  WorkflowState.Pitch,
  WorkflowState.Redaction,
  WorkflowState.Relecture,
  WorkflowState.Correction,
  null,
  WorkflowState.AttenteCorrectionSr,
  WorkflowState.CorrectionSr,
  WorkflowState.Importation,
  WorkflowState.Importe,
  WorkflowState.ImportationEnErreur,
  WorkflowState.Invalidation,
  WorkflowState.Invalide,
  WorkflowState.InvalidationEnErreur,
];

const translationStates = [
  WorkflowState.AttenteTraduction,
  WorkflowState.Traduction,
  WorkflowState.Relecture,
  WorkflowState.Correction,
  WorkflowState.RelectureTraducteurReferent,
  WorkflowState.AttenteCorrectionSr,
  WorkflowState.CorrectionSr,
  WorkflowState.Importation,
  WorkflowState.Importe,
  WorkflowState.ImportationEnErreur,
  WorkflowState.Invalidation,
  WorkflowState.Invalide,
  WorkflowState.InvalidationEnErreur,
];

/**
 * Style
 * @param state Statut
 */
function getStyle(state?: WorkflowState | null) {
  if (!state) {
    return "";
  }

  return (
    "background: " +
    getWorkflowStateColor(state) +
    "; color: " +
    getWorkflowStateTextColor(state)
  );
}

//Load srcChangelog from changelog.md
fetch("/changelog.md")
  .then((response) => response.text())
  .then((text) => (srcChangeLog.value = text));
</script>
<style lang="scss">
.question-content {
  .help-error {
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 0.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  img {
    display: block;
    margin: 1rem;
  }
}

.help {
  h6:first-child {
    margin-top: 0.5rem;
  }
  h6 {
    margin-bottom: 1rem;
  }
}
.markdown-body {
  a {
    color: #0366d6;
    text-decoration: none;
    font-weight: bold;
  }

  h4 + p > img {
    margin-top: 1em;
  }

  p + h4 {
    padding-top: 2.5rem;
  }

  h1 {
    font-size: 2.5rem;
    font-weight: 400;
    line-height: 2.625rem;
    letter-spacing: normal;
  }

  h2 {
    font-size: 2rem;
    font-weight: 400;
    line-height: 2.125rem;
    letter-spacing: normal;
  }

  h3 {
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.625rem;
    letter-spacing: normal;
  }

  h4 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.275rem;
    letter-spacing: normal;
    margin-block-start: 0.5em;
    margin-block-end: 0.5em;
  }
}
</style>
