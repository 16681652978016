import type { KeycloakInstance } from "keycloak-js";
import type {
  AxiosResponse,
  AxiosError,
  AxiosInstance,
  InternalAxiosRequestConfig,
} from "axios";

export const setupInterceptorsTo = (
  axiosInstance: AxiosInstance,
  keycloak: KeycloakInstance
) => {
  axiosInstance.interceptors.request.use(
    (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
      if (keycloak.authenticated && config.headers) {
        config.headers.Authorization = `Bearer ${keycloak.token}`;
      }
      return config;
    },
    (error: AxiosError): Promise<AxiosError> => {
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (res: AxiosResponse): AxiosResponse => {
      return res;
    },
    async (err: AxiosError): Promise<AxiosError> => {
      const originalConfig = err.config;

      if (
        originalConfig &&
        originalConfig.url !== "/auth/signin" &&
        err.response
      ) {
        // Access Token was expired
        if (err.response.status === 401) {
          // originalConfig._retry = true;
          keycloak.updateToken(300);
        }
      }
      return Promise.reject(err);
    }
  );
};
