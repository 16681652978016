<template>
  <q-card flat bordered>
    <q-card-section>
      <FormArticleSetting
        :default-article-setting="articleSetting"
        @submit="actions.onEditArticleSetting"
      />
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import { useRoute } from "vue-router";
import { useArticleSettingActions } from "@/composables/useArticleSettingsActions";
import FormArticleSetting from "@/components/ArticleSetting/FormArticleSetting.vue";

const route = useRoute();
const { articleSetting, actions } = useArticleSettingActions();

actions.findArticleSetting(Number(route.params.id));
</script>
