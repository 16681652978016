<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', articleSetting)">
    <q-input
      ref="id"
      v-model="articleSetting.id"
      for="articleSettingId"
      filled
      disable
      :label="$t('articleSetting.fields.id')"
    />
    <div class="q-ma-none q-gutter-md row items-start">
      <q-input
        ref="mainLabel"
        v-model="articleSetting.mainLabel"
        for="articleSettingMainLabel"
        filled
        :label="$t('articleSetting.fields.mainLabel')"
        class="col"
        :rules="[(val) => !!val || $t('rules.required')]"
      />
    </div>
    <div class="q-ma-none q-gutter-md row items-start">
      <q-select
        ref="idType"
        v-model="articleSetting.idType"
        filled
        :options="typeOptions"
        :label="$t('articleSetting.fields.idType')"
        class="col"
      />
      <q-select
        ref="subType"
        v-model="articleSetting.subType"
        :options="subTypeOptions"
        :rules="[
          (val) =>
            articleSetting.idType == SettingsType.CMS ||
            !!val ||
            $t('rules.required'),
        ]"
        reactive-rules
        filled
        :label="$t('articleSetting.fields.subType')"
        class="col"
      />
    </div>
    <div class="q-ma-none q-gutter-md row items-start">
      <q-input
        ref="labelFr"
        v-model="articleSetting.labelFr"
        for="articleSettingLabelFr"
        filled
        :label="$t('articleSetting.fields.labelFr')"
        class="col"
        :rules="[(val) => !!val || $t('rules.required')]"
      />
      <q-input
        ref="labelEn"
        v-model="articleSetting.labelEn"
        for="farticleSettingLabelEn"
        filled
        :label="$t('articleSetting.fields.labelEn')"
        class="col"
        :rules="[(val) => !!val || $t('rules.required')]"
      />
    </div>
    <div class="q-ma-none q-gutter-md row items-start">
      <q-input
        ref="labelFr"
        v-model="articleSetting.key1"
        for="formatLabelFr"
        filled
        :label="$t('articleSetting.fields.shortKey1')"
        class="col"
        :rules="[(val) => !!val || $t('rules.required')]"
      />
    </div>
    <q-checkbox
      v-model="articleSetting.active"
      for="formatActive"
      :label="$t('articleSetting.fields.active')"
    />
    <div class="q-mt-md q-gutter-md text-right">
      <q-btn :label="labelBtnSubmit" type="submit" color="primary" />
      <q-btn
        v-if="articleSetting.id !== 0"
        :label="$t('articleSetting.labels.btnDelete')"
        type="button"
        color="secondary"
        icon="delete"
        outline
        @click="$emit('delete', articleSetting.id)"
      />
    </div>
  </q-form>
</template>
<script lang="ts" setup>
import { ArticleSettingDto, SettingsType } from "@/types/api";
import { toRefs } from "vue";
import type { PropType } from "vue";
import { createArticleSetting } from "@/services/ArticleSettingService";
import { i18n } from "@/i18n";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("articleSetting.labels.btnEdit");
    },
  },
  defaultArticleSetting: {
    type: Object as PropType<ArticleSettingDto>,
    default() {
      return createArticleSetting();
    },
  },
});

defineEmits<{
  (e: "submit", value: ArticleSettingDto): void;
  (e: "delete", value: ArticleSettingDto["id"]): void;
}>();

const { defaultArticleSetting: articleSetting } = toRefs(props);
const typeOptions = [SettingsType.CMS, SettingsType.Interest];
const subTypeOptions = [null, "GEO", "THE"];
</script>
