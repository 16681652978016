<template>
  <div v-if="language === 'all'">
    <div v-if="contentFr?.title">
      <CellTitleLink v-bind="titles[FrenchLanguage]" />
    </div>
    <div v-if="contentEn?.title">
      <CellTitleLink v-bind="titles[EnglishLanguage]" />
    </div>
  </div>
  <div v-else>
    <div v-if="titles[language].title">
      <div>
        <CellTitleLink v-bind="titles[language]" />
        <CellTitleLink v-bind="titles[otherLanguage]" sub-style />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type {
  ArticleODataDto,
  ArticleLockDto,
  ArticleContentODataDto,
} from "@/types/api";
import { toRefs, computed } from "vue";
import {
  ID_ARTICLE_SETTING_FREE,
  ID_ARTICLE_SETTING_FREE_ACCESS,
  ID_ARTICLE_SETTING_UPGRADE_TO_SPOTLIGHT,
} from "@/constants";
import { FrenchLanguage, EnglishLanguage } from "@/constants";
import CellTitleLink from "./CellTitleLink.vue";

const props = defineProps<{
  data: ArticleODataDto;
  language: string;
  otherLanguage: string;
  articleLocks: ArticleLockDto[];
  articleSerials: number[];
}>();

const { data, language, otherLanguage, articleLocks, articleSerials } =
  toRefs(props);

const contentFr = computed(() => {
  return data.value.contents.find((c) => c.language === FrenchLanguage);
});
const contentEn = computed(() => {
  return data.value.contents.find((c) => c.language === EnglishLanguage);
});
/**
 * Indique si l'article est gratuit
 * @param content contenu de l'article
 */
function isFree(content: ArticleContentODataDto | undefined) {
  if (!content) return false;
  return (
    content.settings.some(
      (s) =>
        s.id === ID_ARTICLE_SETTING_FREE ||
        s.id === ID_ARTICLE_SETTING_FREE_ACCESS
    ) || false
  );
}

/**
 * Indique si l'article a un paramètre de mise en avant
 * @param content contenu de l'article
 */
function hasUpgradeParamCMS(content: ArticleContentODataDto | undefined) {
  return (
    content?.settings.some(
      (s) => s.id === ID_ARTICLE_SETTING_UPGRADE_TO_SPOTLIGHT
    ) || false
  );
}

const titles = computed(() => {
  return {
    [FrenchLanguage]: {
      id: data.value.id,
      title: contentFr.value?.title ?? "",
      hasMedia: contentFr.value?.medias || false,
      isFree: isFree(contentFr.value),
      language: FrenchLanguage,
      articleLocks: articleLocks.value,
      articleSerials: articleSerials.value,
      hasUpgradeParamCMS: hasUpgradeParamCMS(contentFr.value),
    },
    [EnglishLanguage]: {
      id: data.value.id,
      title: contentEn.value?.title ?? "",
      hasMedia: contentEn.value?.medias || false,
      isFree: isFree(contentEn.value),
      language: EnglishLanguage,
      articleLocks: articleLocks.value,
      articleSerials: articleSerials.value,
      hasUpgradeParamCMS: hasUpgradeParamCMS(contentEn.value),
    },
  };
});
</script>
