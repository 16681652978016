import { useQuasar } from "quasar";
import { contributionService } from "@/services/ContributionService";
import { ContributionViewActions } from "@/types/contribution";
import { ContributionParamDto, CancelProcessingParamDto } from "@/types/api";
import { i18n } from "@/i18n";

/**
 * Fonction servant à interagir sur les différentes vues article
 */
export function useContributionActions() {
  const $q = useQuasar();

  const contributionActions: ContributionViewActions = {
    async getLockedContributions() {
      return await contributionService.getLockedContributions();
    },
    async removeProcessedDateOnContributions(param: CancelProcessingParamDto) {
      const result =
        await contributionService.removeProcessedDateOnContributions(param);

      if (result) {
        $q.notify({
          type: "positive",
          message: i18n.t("contribution.freelances.notifications.canceled", [
            result.nbContributions,
            result.amount,
          ]),
        });
      } else {
        i18n.t("contribution.freelances.notifications.errorCancel");
      }
      return result;
    },
    async lockContributionsToBeProcessed(param: ContributionParamDto) {
      const result =
        await contributionService.lockContributionsToBeProcessed(param);

      if (result) {
        $q.notify({
          type: "positive",
          message: i18n.t("contribution.freelances.notifications.locked"),
        });
      } else {
        i18n.t("contribution.freelances.notifications.errorLock");
      }
      return result;
    },
    async unlockContributionsBeingProcessed() {
      const result =
        await contributionService.unlockContributionsBeingProcessed();

      if (result) {
        $q.notify({
          type: "positive",
          message: i18n.t("contribution.freelances.notifications.unlock"),
        });
      } else {
        $q.notify({
          type: "negative",
          message: i18n.t("contribution.freelances.notifications.errorUnlock"),
        });
      }

      return result;
    },
    async setContributionsAsProcessed(dateProcessed: string) {
      const result =
        await contributionService.setContributionsAsProcessed(dateProcessed);

      if (result) {
        $q.notify(i18n.t("contribution.freelances.notifications.processed"));
      } else {
        $q.notify(
          i18n.t("contribution.freelances.notifications.errorProcessed")
        );
      }

      return result;
    },
    async refreshContributionsToLock(param: ContributionParamDto) {
      const result =
        await contributionService.refreshContributionsToLock(param);

      if (result) {
        $q.notify({
          type: "positive",
          message: i18n.t("contribution.freelances.notifications.newLock", {
            nbPiges: result.nbNewContributionsLocked,
          }),
        });
      } else {
        $q.notify(i18n.t("contribution.freelances.notifications.errorLock"));
      }
      return result;
    },
    async isYearLocked(year: number) {
      return await contributionService.isYearLocked(year);
    },
    async lockYear(year: number) {
      const result = await contributionService.lockYear(year);

      if (!result) {
        $q.notify({
          type: "negative",
          message: i18n.t(
            "contribution.freelances.notifications.errorLockYear"
          ),
        });
      }

      return result;
    },
    async unlockYear(year: number) {
      const result = await contributionService.unlockYear(year);

      if (!result) {
        $q.notify({
          type: "negative",
          message: i18n.t(
            "contribution.freelances.notifications.errorUnlockYear"
          ),
        });
      }

      return result;
    },
  };

  return { actions: contributionActions };
}
