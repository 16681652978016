import type { App } from "vue";
import { EventBus } from "quasar";

export default {
  install: (app: App) => {
    const bus = new EventBus();

    // for Options API
    app.config.globalProperties.$bus = bus;

    // for Composition API
    app.provide("bus", bus);
  },
};
