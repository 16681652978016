<template>
  <q-card flat bordered>
    <q-card-section>
      <FormSection
        :label-btn-submit="$t('section.labels.btnCreate')"
        @submit="actions.onCreateSection"
      />
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import { useSectionActions } from "@/composables/useSectionActions";
import FormSection from "@/components/Section/FormSection.vue";

const { actions } = useSectionActions();
</script>
