<template>
  <PageWithPanel :actions="pageActions">
    <template #panelRight>
      <div class="q-pa-md">
        <FormFormat
          v-if="callbackOnSubmit"
          :label-btn-submit="
            $t(
              format.id !== ''
                ? 'format.labels.btnEdit'
                : 'format.labels.btnCreate'
            )
          "
          :default-format="format"
          @submit="callbackOnSubmit"
        />
      </div>
    </template>
  </PageWithPanel>
</template>

<script setup lang="ts">
import type { PageAction } from "@/types/page";
import { computed } from "vue";
import { useFormatActions } from "@/composables/useFormatActions";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import FormFormat from "@/components/Format/FormFormat.vue";
import { i18n } from "@/i18n";

const { actions, format, callbackOnSubmit } = useFormatActions();

const pageActions = computed<PageAction[]>(() =>
  [
    {
      label: i18n.t("format.labels.btnNew"),
      shortLabel: "Créer",
      icon: "add",
      disable: false,
      handler: actions.createFormat,
      visible: true,
    },
  ].filter((act) => act.visible)
);
</script>
