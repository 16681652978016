import { ContributionType } from "@/types/api";
import { useUserStore } from "@/stores/user";

/**
 * Interface ContributorParams
 */
interface ContributorParams {
  publication: string | string[];
  type?: ContributionType;
  idUserToAdd?: string;
  allOption?: boolean;
}

/**
 * Méthode qui sert à récupérer la liste des contributeurs pour être utiliser dans un dropdown
 * @param params.publication - argument obligatoire pour filtrer par publication
 * @param params.type - argument optionnel pour filtrer par type de contribution
 * @param params.idUserToAdd - argument optionnel pour ajouter un utilisateur dans la liste via son id
 */
export function getContributorOptions(params: ContributorParams) {
  const { publication, type, idUserToAdd } = params;
  const { filterUsersLightBycontributionType } = useUserStore();
  const list = filterUsersLightBycontributionType(
    type || null,
    publication,
    idUserToAdd
  );
  const options = params.allOption ? [{ value: "", label: "Tous" }] : [];
  options.push(...list.map((c) => ({ value: c.id, label: c.name })));
  return options;
}
