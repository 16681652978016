<template>
  <Page>
    <q-card bordered>
      <q-card-section>
        <div style="max-width: 400px">
          <q-list>
            <q-item-label header>{{
              $t("setting.headers.application")
            }}</q-item-label>
            <q-item v-ripple tag="label">
              <q-item-section>
                <q-item-label>{{ $t("setting.fields.language") }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-select
                  v-model="$i18n.locale"
                  :options="$i18n.availableLocales"
                  :display-value="`${$i18n.locale?.toUpperCase() ?? 'FR'}`"
                  dense
                  borderless
                  @update:model-value="onChangeLang"
                />
              </q-item-section>
            </q-item>
            <q-item v-ripple tag="label">
              <q-item-section>
                <q-item-label>{{ $t("setting.fields.darkMode") }}</q-item-label>
              </q-item-section>
              <q-item-section side>
                <q-toggle
                  v-model="config.darkMode"
                  checked-icon="check"
                  :color="Dark.isActive ? 'green' : 'primary'"
                  keep-color
                  dense
                  unchecked-icon="clear"
                  @update:model-value="onChangeTheme"
                />
              </q-item-section>
            </q-item>
            <q-item-label header>{{
              $t("setting.fields.darkModeInfo")
            }}</q-item-label>
            <q-item>
              <q-item-section>
                <q-item-label>{{ $t("setting.fields.cache") }}</q-item-label>
              </q-item-section>
              <q-item-section>
                <q-btn
                  :label="$t('setting.labels.btnClearCache')"
                  @click="configStore.clearCache"
                />
              </q-item-section>
            </q-item>
            <q-separator spaced />
            <q-item-label header>{{
              $t("setting.headers.article")
            }}</q-item-label>
            <q-item>
              <q-item-section>
                <q-item-label>{{
                  $t("setting.fields.listArticle")
                }}</q-item-label>
              </q-item-section>
              <q-item-section>
                <q-btn
                  :label="$t('setting.labels.btnResetListeArticleSettings')"
                  @click="configStore.resetListArticleSettings"
                />
              </q-item-section>
            </q-item>
            <q-separator spaced />
            <q-item-label header>{{
              $t("setting.headers.profile")
            }}</q-item-label>
            <q-item>
              <q-item-section>
                <q-item-label>{{
                  $t("setting.fields.refreshToken")
                }}</q-item-label>
              </q-item-section>
              <q-item-section>
                <q-btn
                  :label="$t('setting.labels.btnRefreshToken')"
                  @click="$keycloak.keycloak?.updateToken(300)"
                />
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </q-card-section>
    </q-card>
  </Page>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { Dark } from "quasar";
import { useConfigStore } from "@/stores/config";
import Page from "@/components/Layouts/Page.vue";
import { applyDarkTheme } from "@/theme";

const configStore = useConfigStore();
const { config } = storeToRefs(configStore);

/**
 * Fonction pour changer le thème de l'application
 */
function onChangeTheme() {
  Dark.set(config.value.darkMode);
  configStore.toggleDarkMode(config.value.darkMode);
  applyDarkTheme();
}
/**
 * Fonction appelé lors du changement de langue
 */
function onChangeLang() {
  window.location.reload();
}
</script>
