<template>
  <div>
    <Page>
      <div class="q-gutter-sm q-px-xs-sm q-px-sm-none">
        <q-card bordered class="q-mb-md">
          <q-tabs
            v-model="tab"
            class="bg-primary text-white"
            align="left"
            narrow-indicator
          >
            <q-tab name="articles" :label="$t('import.tabs.articles')" />
            <q-tab name="articlesIds" :label="$t('import.tabs.articlesIds')" />
            <q-tab
              name="exportArticle"
              :label="$t('import.tabs.exportArticle')"
            />
            <q-tab
              name="illustrations"
              :label="$t('import.tabs.illustrations')"
            />
            <q-tab name="settings" :label="$t('import.tabs.settings')" />
            <q-tab
              name="associatedArticles"
              :label="$t('import.tabs.associatedArticles')"
            />
            <q-tab name="suivi" :label="$t('import.tabs.suivi')" />
          </q-tabs>
          <q-separator />

          <q-tab-panels v-model="tab" animated>
            <q-tab-panel name="articles">
              <ImportArticles />
            </q-tab-panel>

            <q-tab-panel name="articlesIds">
              <ImportArticlesIds />
            </q-tab-panel>

            <q-tab-panel name="exportArticle">
              <ExportArticle />
            </q-tab-panel>

            <q-tab-panel name="illustrations">
              <ImportIllustrations />
            </q-tab-panel>

            <q-tab-panel name="settings">
              <ImportArticlesSettings />
            </q-tab-panel>

            <q-tab-panel name="suivi">
              <ImportTrackEvent />
            </q-tab-panel>

            <q-tab-panel name="associatedArticles">
              <ImportAssociatedArticles />
            </q-tab-panel>
          </q-tab-panels>
        </q-card>

        <q-card v-if="Object.keys(eventIds).length">
          <q-card-section class="bg-primary text-white">
            <div class="text-subtitle2 text-uppercase">
              {{ $t("import.titles.informations") }}
            </div>
          </q-card-section>
          <TrackEvents />
        </q-card>
      </div>
    </Page>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useImportActions } from "@/composables/useImportActions";

import ImportArticles from "@/views/Import/ImportArticles.vue";
import ImportArticlesIds from "@/views/Import/ImportArticlesIds.vue";
import ExportArticle from "@/views/Import/ExportArticle.vue";
import ImportIllustrations from "@/views/Import/ImportIllustrations.vue";
import ImportArticlesSettings from "@/views/Import/ImportArticlesSettings.vue";
import ImportTrackEvent from "@/views/Import/ImportTrackEvent.vue";
import ImportAssociatedArticles from "@/views/Import/ImportAssociatedArticles.vue";
import TrackEvents from "@/components/Import/TrackEvents.vue";
import Page from "@/components/Layouts/Page.vue";

const tab = ref("articles");
const { eventIds } = useImportActions();
</script>
