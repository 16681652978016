import { AxiosRequest } from "@/services/ElcanoAPI";
import { ExportParam } from "@/types/api";
import { CrudServiceInstance } from "@/types/crud";
import { ExportDataServiceInstance } from "@/types/exportData";
import { CrudService } from "@/services/CrudService";
import { fileDownload, getFilenameFromHeader } from "@/fileDownload";

/**
 * Service Export pour exporter les piges / traductions.
 */
class ExportDataService
  extends AxiosRequest
  implements ExportDataServiceInstance
{
  crudService: CrudServiceInstance<boolean>;

  /**
   * Constructeur de la class User service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<boolean>(rootPath);
  }

  /**
   * Export des données
   */
  exportData(param: ExportParam): Promise<any> {
    return this.axiosInstance
      .post("/exportData", param, { responseType: "blob" })
      .then((response) => {
        const filename = getFilenameFromHeader(response.headers, "test.xlsx");
        fileDownload(response.data, filename);
      })
      .catch(this.handleError);
  }
}

export const exportDataService = new ExportDataService("exportData");
