import type { MethodType } from "broadcast-channel";
import { BroadcastChannel as BroadcastChannelImpl } from "broadcast-channel";
import type { PiniaPluginContext, Store } from "pinia";
import * as devalue from "devalue";
import { watch } from "vue";

/**
 * Share state across browser tabs.
 * https://github.com/wobsoriano/pinia-shared-state/blob/master/src/index.ts
 * Modifier pour nos besoins pour charger les données provenant du localStorage à l'initialisation.
 *
 * @example
 *
 * ```ts
 * import useStore from './store'
 *
 * const counterStore = useStore()
 *
 * share('counter', counterStore, { initialize: true })
 * ```
 *
 * @param key - A property of a store state.
 * @param store - The store the plugin will augment.
 * @param options - Share state options.
 * @param options.initialize - Immediately recover the shared state from another tab.
 * @param options.type - 'native', 'idb', 'localstorage', 'node'.
 */
export function share<T extends Store, K extends keyof T["$state"]>(
  key: K,
  store: T,
  { initialize, type }: { initialize: boolean; type?: MethodType }
): { sync: () => void; unshare: () => void } {
  const channelName = `${store.$id}-${key.toString()}`;
  let dataFromPersistency: any = undefined;

  if (type === "localstorage") {
    const jsonFromPersistency = localStorage.getItem(
      `pubkey.broadcastChannel-${channelName}`
    );
    if (jsonFromPersistency && jsonFromPersistency?.length > 0) {
      dataFromPersistency = JSON.parse(jsonFromPersistency);

      if (
        dataFromPersistency &&
        dataFromPersistency.data &&
        dataFromPersistency.data.data
      ) {
        dataFromPersistency = dataFromPersistency.data.data;
        store[key] = dataFromPersistency.state;
      } else {
        dataFromPersistency = undefined;
      }
    }
  }

  const channel = new BroadcastChannelImpl(channelName, {
    type,
  });
  let externalUpdate = false;
  let timestamp = 0;

  watch(
    () => store[key],
    (state) => {
      if (!externalUpdate) {
        timestamp = Date.now();
        channel.postMessage({
          timestamp,
          state: devalue.parse(devalue.stringify(state)),
        });
      }
      externalUpdate = false;
    },
    { deep: true }
  );

  channel.onmessage = (evt) => {
    if (evt === undefined) {
      channel.postMessage({
        timestamp,
        state: devalue.parse(devalue.stringify(store[key])),
      });
      return;
    }
    if (evt.timestamp <= timestamp) return;

    externalUpdate = true;
    timestamp = evt.timestamp;
    store[key] = evt.state;
  };

  const sync = () => channel.postMessage(dataFromPersistency);
  const unshare = () => {
    return channel.close();
  };

  // fetches any available state
  if (initialize) sync();

  return { sync, unshare };
}

const stateHasKey = (
  key: string,
  $state: PiniaPluginContext["store"]["$state"]
) => {
  return Object.keys($state).includes(key);
};

/**
 * Adds a `share` option to your store to share state across browser tabs.
 *
 * @example
 *
 * ```ts
 * import { PiniaSharedState } from 'pinia-shared-state'
 *
 * // Pass the plugin to your application's pinia plugin
 * pinia.use(PiniaSharedState({ enable: true, initialize: false, type: 'localstorage' }))
 * ```
 *
 * @param options - Global plugin options.
 * @param options.enable - Enable/disable sharing of state for all stores.
 * @param options.initialize - Immediately recover the shared state from another tab.
 * @param options.type - 'native', 'idb', 'localstorage', 'node'.
 */
export const PiniaSharedState = ({
  initialize = true,
  enable = true,
  type = "localstorage",
}: {
  initialize?: boolean;
  enable?: boolean;
  type?: MethodType;
}) => {
  return ({ store, options }: PiniaPluginContext) => {
    const isEnabled = options?.share?.enable ?? enable;
    const omittedKeys = options?.share?.omit ?? [];
    if (!isEnabled) return;

    Object.keys(store.$state).forEach((key) => {
      if (omittedKeys.includes(key) || !stateHasKey(key, store.$state)) return;
      share(key, store, {
        initialize: options?.share?.initialize ?? initialize,
        type,
      });
    });
  };
};
