import { ContributionType } from "@/types/api";

export const contributionTypeOrder: Record<ContributionType, number> = {
  Unknown: 0,
  Author: 1,
  Freelancer: 2,
  Reviewer: 3,
  Editor: 4,
  Translator: 5,
  LeadTranslator: 6,
} as const;

/**
 * Check si la clé en paramètre est dans l'objet ContributionType
 */
export function isContributionType(
  key: ContributionType | string
): key is ContributionType {
  return key in ContributionType;
}

/**
 * Fonction pour récupérer l'ordre du statut du workflow
 */
export function getContributionTypeOrder(key: ContributionType | string) {
  if (isContributionType(key)) return contributionTypeOrder[key];
  return 0;
}
