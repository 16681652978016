<template>
  <div>
    <q-drawer
      v-model="showPanelRight"
      side="right"
      bordered
      :width="panelWidth"
    >
      <q-scroll-area class="fit">
        <slot name="panelRight"></slot>
      </q-scroll-area>
      <div class="q-mini-drawer-hide absolute">
        <q-btn
          dense
          round
          unelevated
          color="secondary"
          icon="chevron_right"
          size="sm"
          @click="showPanelRight = false"
        />
      </div>
    </q-drawer>
    <Page :title="title" :actions="actions" :no-padding="noPadding">
      <template #titlePrepend>
        <slot name="titlePrepend"></slot>
      </template>
      <template #menuTop>
        <slot name="menuTop"></slot>
      </template>
      <slot></slot>
    </Page>
  </div>
</template>

<script setup lang="ts">
import type { PageAction } from "@/types/page";
import { computed } from "vue";
import { useQuasar } from "quasar";
import { usePage } from "@/composables/usePage";
import Page from "@/components/Layouts/Page.vue";

withDefaults(
  defineProps<{
    showPanel?: boolean;
    title?: string;
    actions?: PageAction[];
    noPadding?: boolean;
  }>(),
  {
    title: undefined,
    actions: undefined,
    noPadding: false,
  }
);
const { showPanelRight } = usePage();
const $q = useQuasar();
const panelWidth = computed(() => ($q.screen.gt.sm ? 500 : 300));
</script>
<style scoped>
.q-mini-drawer-hide {
  top: 15px;
  left: -12px;
}
</style>
