<template>
  <q-dialog
    :model-value="props.modelValue"
    @update:model-value="(val) => $emit('update:model-value', val)"
  >
    <q-card style="width: 700px; max-width: 80vw">
      <q-card-section>
        <q-select
          ref="publication"
          v-model="newPublications"
          :label="$t('publication.fields.publications')"
          :options="publicationOptions"
          multiple
          use-chips
          emit-value
          map-options
          stack-label
        />
      </q-card-section>
      <q-card-actions align="right">
        <q-btn
          v-close-popup
          flat
          :label="$t('publication.labels.btnCancel')"
          color="primary"
        />
        <q-btn
          v-close-popup
          flat
          :label="$t('publication.labels.btnOk')"
          color="primary"
          @click="onSubmitNewPublications"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<script lang="ts" setup>
import { ref, watch } from "vue";
import { getPublicationOptions } from "@/helpers/publication";

const props = defineProps<{
  modelValue: boolean;
  selected?: string[];
}>();
const emits = defineEmits<{
  (e: "update:model-value", value: boolean): void;
  (e: "add:publications", value: string[]): void;
}>();
const newPublications = ref<string[]>(props.selected || []);
const publicationOptions = getPublicationOptions({ allOption: false });

watch(
  () => props.selected,
  (newVal) => {
    if (newVal) newPublications.value = newVal;
  }
);

/**
 * Fonction appelé au moment de rajouter une nouvelle publication
 */
function onSubmitNewPublications() {
  emits("add:publications", newPublications.value);
  newPublications.value = [];
}
</script>
