<template>
  <span class="dx-adaptive-item-text">
    <q-chip v-for="(section, idx) in sections" :key="'section' + idx" size="sm">
      {{ section }}
    </q-chip>
  </span>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs, computed } from "vue";
import { useLocale } from "@/utils";

const props = defineProps<{
  data: ArticleODataDto;
}>();
const { data } = toRefs(props);
const { isFr } = useLocale();
const sections = computed(() => {
  if (!data.value.sections || data.value.sections.length === 0) return [];
  const sectionSecondaries = data.value.sections.filter((s) => !s.main);
  if (sectionSecondaries.length === 0) return [];
  return sectionSecondaries.map((s) => (isFr.value ? s.labelFr : s.labelEn));
});
</script>
