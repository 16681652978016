import type { PublicationDto } from "@/types/api";
import type { PublicationState } from "@/types/publication";
import { defineStore } from "pinia";
import { publicationService } from "@/services/PublicationService";
import { i18n } from "@/i18n";

export const usePublicationStore = defineStore({
  id: "publication",
  state: (): PublicationState => ({
    publications: [],
  }),
  actions: {
    async fetchPublications() {
      this.publications = await publicationService.getList();
    },
    async findPublication(id: string): Promise<PublicationDto> {
      const publication = this.publications.find((p) => p.id === id);
      if (publication) return publication;
      else throw new Error(i18n.t("publication.errors.notFound"));
    },
    async addPublication(newPublication: PublicationDto) {
      try {
        const publication = await publicationService.insert(newPublication);
        this.publications.push(publication);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("publication.errors.create"));
      }
    },
    async updatePublication(publication: PublicationDto) {
      try {
        const publicationUpdated = await publicationService.update(publication);
        const publicationIndex = this.publications.findIndex(
          (p) => p.id === publication.id
        );
        this.publications[publicationIndex] = publicationUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("publication.errors.update"));
      }
    },
  },
});
