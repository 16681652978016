import type { AssignmentDto } from "@/types/api";
import type { AssignmentViewActions } from "@/types/user";
import { useRouter } from "vue-router";
import { reactive, toRefs } from "vue";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { useUserStore } from "@/stores/user";
import { RoutesName } from "@/router/routesName";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions Assignment
 */
interface AssignmentActionState {
  assignment: AssignmentDto | null;
}
/**
 * Fonction servant à interagir sur les différentes vues assignment
 */
export const useAssignmentActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { findAssignment, addAssignment, updateAssignment, deleteAssignment } =
    useUserStore();

  const state = reactive<AssignmentActionState>({
    assignment: null,
  });
  const actions: AssignmentViewActions = {
    findAssignment(assignmentId: string) {
      return findAssignment(assignmentId)
        .then((assignment) => {
          state.assignment = assignment;
        })
        .catch((err: Error) => {
          $q.notify(err.message);
          router.push({ name: RoutesName.Assignments });
        });
    },
    createAssignment(callback?: () => void) {
      state.assignment = null;
      if (callback) callback();
    },
    editAssignment(assignmentId: string, callback?: () => void) {
      actions.findAssignment(assignmentId).then(() => {
        if (callback) callback();
      });
    },
    onCreateAssignment(assignment: AssignmentDto) {
      addAssignment(assignment)
        .then(() => {
          $q.notify(i18n.t("assignment.messages.created"));
          router.push({ name: RoutesName.Assignments });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditAssignment(assignment: AssignmentDto) {
      updateAssignment(assignment)
        .then(() => {
          $q.notify(i18n.t("assignment.messages.updated"));
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onDeleteAssignment(assignmentId: string) {
      $q.dialog({
        title: i18n.t("assignment.dialogs.titles.confirm"),
        message: i18n.t("assignment.dialogs.messages.delete"),
        cancel: true,
        persistent: true,
      }).onOk(() => {
        deleteAssignment(assignmentId).then(() => {
          $q.notify(i18n.t("assignment.messages.deleted"));
          router.push({ name: RoutesName.Assignments });
        });
      });
    },
  };
  return { ...toRefs(state), actions };
});
