import { authService } from "@/services/AuthService";
import type { Editor } from "@ckeditor/ckeditor5-core";
import MD5 from "crypto-js/md5";
import { useUserStore } from "@/stores/user";
import { i18n } from "@/i18n";

// An example of a plugin that provides user data for an editor
// that uses `Comments` and `RevisionHistory` plugins.
/**
 *
 */
class UsersIntegration {
  editor: Editor;

  /**
   *
   */
  static get requires() {
    return ["Users"];
  }

  /**
   *
   */
  constructor(editor: Editor) {
    this.editor = editor;
  }

  /**
   * Pour rendre le plugin compatible avec le Context CkEditor.
   */
  static get isContextPlugin() {
    return true;
  }

  /**
   *
   */
  init() {
    const userStore = useUserStore();
    const users = this.editor.plugins.get("Users");
    const userId = authService.getUserId();
    let defineMe = false;

    // Provide user data from your database.
    userStore.usersLight.forEach((u) => {
      if (u.id === userId) {
        defineMe = true;
      }

      const email = u.email.trim().toLowerCase();
      const emailHash = MD5(email).toString();
      users.addUser({
        id: u.id,
        name: u.name,
        avatar: `https://www.gravatar.com/avatar/${emailHash}`,
      });
    });
    users.addUser({
      id: "deleted",
      name: i18n.t("user.deleted"),
    });

    if (defineMe) users.defineMe(userId ?? "");

    const initialGetUser = users.getUser.bind(users);

    users.getUser = (u) => initialGetUser(u) ?? initialGetUser("deleted");
  }
}

export default UsersIntegration;
