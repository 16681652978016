import type { UserState } from "@/types/user";
import type {
  ProfileDto,
  UserDto,
  RightGroupDto,
  RightDto,
  AssignmentDto,
  UserLightDto,
} from "@/types/api";
import { ContributionType } from "@/types/api";
import { defineStore } from "pinia";
import { userService } from "@/services/UserService";
import { profileService } from "@/services/ProfileService";
import { assignmentService } from "@/services/AssignmentService";
import { rightGroupService } from "@/services/RightGroupService";
import { rightService } from "@/services/RightService";
import { i18n } from "@/i18n";
import { formatErrorMessage } from "@/utils";
import { FrenchLanguage } from "@/constants";

/**
 * Verifie certain champs obligatoires
 * @param user
 * @returns user
 */
function fixUserDtoForSave(user: UserLightDto): UserLightDto {
  if (!user.idBackOffice) user.idBackOffice = undefined;
  user.name = `${user.firstName} ${user.lastName}`;
  return user;
}

export const useUserStore = defineStore({
  id: "user",
  state: (): UserState => ({
    users: [],
    usersLight: [],
    profiles: [],
    assignments: [],
    rightGroups: [],
    rights: [],
  }),
  getters: {
    rightsByGroup(state) {
      return state.rightGroups.map((group) => ({
        ...group,
        rights: state.rights.filter((right) => right.idGroup === group.id),
      }));
    },
    getAllFreelances(state) {
      return state.usersLight.filter((u) => u.freelance.length > 0);
    },
  },
  actions: {
    async fetchUsers() {
      this.users = await userService.getList(false, false);
      console.log("Utilisateur chargé");
    },
    async fetchUserWithParam(withDeleted, withDisabled) {
      this.users = await userService.getList(withDeleted, withDisabled);
      // Add a console log to show "Utilisateur chargé" and show the value of withDeleted and withDisabled
      console.log(
        "Utilisateur chargé (delete/disabled):",
        withDeleted,
        withDisabled
      );
    },
    findUser(id: string): UserDto {
      const user = this.users.find((u) => u.id === id);
      if (user) return user;
      throw new Error(i18n.t("user.errors.notFound"));
    },
    findUserLight(id: string): UserLightDto {
      const user = this.usersLight.find((u) => u.id === id);
      if (user) return user;
      throw new Error(i18n.t("user.errors.notFound"));
    },
    async fetchUsersLight() {
      this.usersLight = await userService.getUsersLight();
      console.log("Utilisateur simplifié chargé");
    },
    async fetchProfiles() {
      this.profiles = (await profileService.getList()).sort((a, b) => {
        if (a.labelFr > b.labelFr) return 1;
        return -1;
      });
    },
    async findProfile(id: string): Promise<ProfileDto> {
      const profile = await profileService.getSingle(id);
      if (profile) return profile;
      else throw new Error(i18n.t("profile.errors.notFound"));
    },
    async fetchAssignments() {
      this.assignments = await assignmentService.getList();
    },
    async findAssignment(id: string): Promise<AssignmentDto> {
      const assignment = await assignmentService.getSingle(id);
      if (assignment) return assignment;
      else throw new Error(i18n.t("assignment.errors.notFound"));
    },
    async fetchRightGroups() {
      this.rightGroups = await rightGroupService.getList();
    },
    async findRightGroup(id: number | string): Promise<RightGroupDto> {
      const group = this.rightGroups.find(
        (g) => g.id === parseInt(`${id}`, 10)
      );
      if (group) return group;
      else throw new Error(i18n.t("rightGroup.errors.notFound"));
    },
    async fetchRights() {
      this.rights = await rightService.getList();
    },
    async findRight(id: string): Promise<RightDto> {
      const right = await rightService.getSingle(id);
      if (right) return right;
      else throw new Error(i18n.t("right.errors.notFound"));
    },
    async insertUser(user: UserLightDto) {
      try {
        const insertedUser = await userService.insertUser(
          fixUserDtoForSave(user)
        );

        this.users.push(insertedUser);
        return insertedUser;
      } catch (error) {
        const message = formatErrorMessage(error, "user.errors.insert");
        throw new Error(message);
      }
    },
    async updateUser(user: UserLightDto) {
      try {
        const updatedUser = await userService.updateUser(
          fixUserDtoForSave(user)
        );
        const indexUser = this.users.findIndex((u) => u.id === user.id);

        if (indexUser !== -1) this.users.splice(indexUser, 1);

        this.users.push(updatedUser);
        return updatedUser;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("user.errors.update"));
      }
    },
    async addProfile(newProfile: ProfileDto) {
      try {
        const profile = await profileService.insert(newProfile);
        this.profiles.push(profile);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("profile.errors.create"));
      }
    },
    async updateProfile(profile: ProfileDto) {
      try {
        const profileUpdated = await profileService.update(profile);
        const profileIndex = this.profiles.findIndex(
          (p) => p.id === profile.id
        );
        this.profiles[profileIndex] = profileUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("profile.errors.update"));
      }
    },
    async deleteProfile(id: string) {
      try {
        await profileService.delete(id);
        this.profiles = this.profiles.filter((s) => s.id !== id);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("profile.errors.delete"));
      }
    },
    async addRightGroup(newRightGroup: RightGroupDto) {
      try {
        const rightGroup = await rightGroupService.insert(newRightGroup);
        this.rightGroups.push(rightGroup);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("rightGroup.errors.create"));
      }
    },
    async updateRightGroup(rightGroup: RightGroupDto) {
      try {
        const rightGroupUpdated = await rightGroupService.update(rightGroup);
        const rightGroupIndex = this.rightGroups.findIndex(
          (g) => g.id === rightGroup.id
        );
        this.rightGroups[rightGroupIndex] = rightGroupUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("rightGroup.errors.update"));
      }
    },
    async deleteRightGroup(id: number | string) {
      try {
        await rightGroupService.delete(`#{id}`);
        this.rightGroups = this.rightGroups.filter(
          (g) => g.id !== parseInt(`${id}`, 10)
        );
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("rightGroup.errors.delete"));
      }
    },
    async addRight(newRight: RightDto) {
      try {
        const right = await rightService.insert(newRight);
        this.rights.push(right);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("right.errors.create"));
      }
    },
    async updateRight(right: RightDto) {
      try {
        const rightUpdated = await rightService.update(right);
        const rightIndex = this.rights.findIndex((g) => g.id === right.id);
        this.rights[rightIndex] = rightUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("right.errors.update"));
      }
    },
    async deleteRight(id: string) {
      try {
        await rightService.delete(id);
        this.rights = this.rights.filter((r) => r.id !== id);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("right.errors.delete"));
      }
    },
    async addAssignment(newAssignment: AssignmentDto) {
      try {
        const assignment = await assignmentService.insert(newAssignment);
        this.assignments.push(assignment);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("assignment.errors.create"));
      }
    },
    async updateAssignment(assignment: AssignmentDto) {
      try {
        const assignmentUpdated = await assignmentService.update(assignment);
        const assignmentIndex = this.assignments.findIndex(
          (g) => g.id === assignment.id
        );
        this.assignments[assignmentIndex] = assignmentUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("assignment.errors.update"));
      }
    },
    async deleteAssignment(id: string) {
      try {
        await assignmentService.delete(id);
        this.assignments = this.assignments.filter((r) => r.id !== id);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("assignment.errors.delete"));
      }
    },
    async addUserProfile(id: string, profile: ProfileDto) {
      try {
        const user = this.findUser(id);
        if (user.profiles.findIndex((p) => p.id === profile.id) >= 0) return;
        await userService.addProfile(id, profile.id);
        user.profiles.push(profile);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("user.errors.addProfile"));
      }
    },
    async removeUserProfile(id: string, profile: ProfileDto) {
      try {
        const user = this.findUser(id);
        if (user.profiles.findIndex((p) => p.id === profile.id) < 0) return;
        await userService.removeProfile(id, profile.id);
        const profilIndex = user.profiles.findIndex((p) => p.id === profile.id);
        if (profilIndex >= 0) user.profiles.splice(profilIndex, 1);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("user.errors.removeProfile"));
      }
    },
    filterUsersLightBycontributionType(
      type: ContributionType | null,
      publication: string | string[],
      idUserToAdd?: string
    ) {
      const searchPublications = Array.isArray(publication)
        ? publication
        : [publication];
      const usersFiltered = this.usersLight.filter((u) => {
        if (u.id === idUserToAdd) return true;
        if (u.deleted) return false;
        if (!u.enabled) return false;
        let publications = [] as string[];
        switch (type) {
          case ContributionType.Author:
          case ContributionType.Reviewer:
            publications.push(...u.redactor);
            break;
          case ContributionType.Editor:
            publications.push(...u.editor);
            break;
          case ContributionType.Freelancer:
            publications.push(...u.freelance);
            break;
          case ContributionType.LeadTranslator:
          case ContributionType.Translator:
            publications.push(...u.translator);
            break;
          case ContributionType.Unknown:
            break;
          default:
            publications.push(...u.redactor);
            publications.push(...u.editor);
            publications.push(...u.freelance);
            publications.push(...u.translator);
            break;
        }
        publications = [...new Set(publications)];
        return publications.some(
          (p) => p === "ALL" || searchPublications.indexOf(p) >= 0
        );
      });
      if (idUserToAdd && !usersFiltered.some((u) => u.id === idUserToAdd)) {
        usersFiltered.push({
          id: idUserToAdd,
          editor: [],
          email: "",
          firstName: "",
          lastName: "",
          name: idUserToAdd,
          freelance: [],
          redactor: [],
          translator: [],
          login: "",
          language: FrenchLanguage,
          deleted: true,
          enabled: false,
        });
      }
      return usersFiltered.sort((a, b) => (a.name > b.name ? 1 : -1));
    },
  },
});
