import type { CountryDto } from "@/types/api";
import type { CountryState } from "@/types/country";
import { defineStore } from "pinia";
import { countryService } from "@/services/CountryService";
import { i18n } from "@/i18n";

export const useCountryStore = defineStore({
  id: "country",
  state: (): CountryState => ({
    countries: [],
  }),
  actions: {
    async fetchCountries() {
      this.countries = await countryService.getList();
    },
    async findCountry(id: string): Promise<CountryDto> {
      const country = this.countries.find((c) => c.id === parseInt(id, 10));
      if (country) return country;
      else throw new Error(i18n.t("country.errors.notFound"));
    },
    async addCountry(newCountry: CountryDto) {
      try {
        const country = await countryService.insert(newCountry);
        this.countries.push(country);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("country.errors.create"));
      }
    },
    async updateCountry(country: CountryDto) {
      try {
        const countryUpdated = await countryService.update(country);
        const countryIndex = this.countries.findIndex(
          (c) => c.id === country.id
        );
        this.countries[countryIndex] = countryUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("country.errors.update"));
      }
    },
  },
});
