import type { QSelectOption } from "quasar";
import type { SectionDto } from "@/types/api";
import { useSectionStore } from "@/stores/section";
import { useLocale, removeDiacritics } from "@/utils";
const { isFr } = useLocale();
/**
 * Interface SectionParams
 */
interface SectionParams {
  publication?: string;
  sectionActive?: boolean;
  alphabeticalOrder?: boolean;
  treeMode?: boolean;
  allOption?: boolean;
  idSectionsToAdd?: number[];
  main?: boolean;
}

/**
 * Interface SectionGrouped
 */
interface SectionGrouped extends SectionDto {
  children: SectionDto[];
}

/**
 * Méthode qui sert à récupérer la liste des rubriques pour être utiliser dans un dropdown
 * @param params.publication - argument optionnel pour filtrer par publication
 * @param params.sectionActive - argument pour récupérer toutes les rubriques ou seule celles actives
 * @param params.allOption - argument pour avoir dans le sélecteur une option toutes les rubriques
 * @param params.alphabeticalOrder - argument pour trier les rubriques par ordre alphabétique
 * @param params.treeMode - argument pour afficher les rubriques sous forme d'arbre à l'aide de la rubrique parente
 * @param params.idSectionsToAdd - argument pour ajouter plusieurs rubriques dans la liste via leurs id
 * @param params.main - argument pour sélectionner les rubriques pouvant être principales
 */
export function getSectionOptions(params: SectionParams = {}) {
  const {
    publication,
    sectionActive = true,
    allOption = true,
    alphabeticalOrder = true,
    treeMode = true,
    idSectionsToAdd,
    main,
  } = params;

  const { filterSectionsByPublication, sections } = useSectionStore();
  const { isFr } = useLocale();
  let list: SectionDto[];
  if (publication)
    list = filterSectionsByPublication(publication, sectionActive);
  else list = sections;

  list = list.filter((s) => {
    if (idSectionsToAdd?.includes(s.id)) return true;
    if (main != null) return s.main == main && (!sectionActive || s.active);

    if (sectionActive) return s.active;
    return true;
  });

  if (treeMode) {
    // group list by idParent
    const grouped = list.reduce((acc, section) => {
      const idParent = section.idParent ?? section.id;
      const s = sections.find((s) => s.id === idParent);

      if (!acc[idParent]) {
        acc[idParent] = { ...s, children: [] };
      }

      if (idParent != section.id) acc[idParent].children.push(section);
      return acc;
    }, {}) as Record<number, SectionGrouped>;

    list = Object.values(grouped)
      .sort(compareSectionsByLabel)
      .map((group: SectionGrouped) => [
        group,
        ...group.children.sort(
          alphabeticalOrder ? compareSectionsByLabel : compareSectionsByWeight
        ),
      ])
      .flat();
  } else {
    list = list.sort(
      alphabeticalOrder ? compareSectionsByLabel : compareSectionsByWeight
    );
  }

  return [
    allOption ? { value: "", label: "Toutes" } : undefined,
    ...list.map((section) => ({
      value: section.id,
      label:
        (treeMode && section.idParent ? " -- " : "") +
        (isFr.value ? section.labelFr : section.labelEn),
    })),
  ].filter(Boolean) as QSelectOption<number>[];
}

/**
 * Méthode pour récupérer la couleur de la rubrique
 */
export function getSectionColors(sectionId: number) {
  const { sections } = useSectionStore();
  const section = sections.find((s) => s.id === sectionId);
  if (!section) return null;
  const { backgroundColor, fontColor } = section;
  return {
    backgroundColor,
    fontColor,
  };
}
/**
 * Méthode pour récupérer le css des couleurs de la rubrique
 */
export function getSectionCSSColors(sectionId: number, main: boolean) {
  const colors = getSectionColors(sectionId);
  const backgroundColor = main ? colors?.backgroundColor ?? "#f5f5f5" : "#fff";
  const color = main
    ? colors?.fontColor ?? "#000"
    : colors?.backgroundColor ?? "#f5f5f5";
  return `background-color: ${backgroundColor}80; color: ${color};`;
}

/**
 * Méthode pour trier les rubriques par ordre alphabétique
 */
export function compareSectionsByLabel(a, b) {
  const key = isFr.value ? "labelFr" : "labelEn";
  const aKey = removeDiacritics(a[key]);
  const bKey = removeDiacritics(b[key]);

  if (aKey && bKey) {
    if (aKey > bKey) return 1;
    else if (bKey > aKey) return -1;
  }
  return 0;
}

/**
 * Méthode pour trier les rubriques par poids
 */
export function compareSectionsByWeight(a, b) {
  if (a.weight && b.weight) {
    if (a.weight > b.weight) return 1;
    else if (b.weight > a.weight) return -1;
  }
  return 0;
}

/**
 * Méthode pour récupérer les rubriques via leurs identifiants
 * @param sectionIds identifiants des rubriques
 * @returns rubriques
 */
export function getSections(sectionIds: number[]) {
  const { sections } = useSectionStore();
  return sections.filter((s) => sectionIds.includes(s.id));
}
