<template>
  <q-card>
    <q-card-section>
      <q-table
        :rows="sectionsFilteredByPublication"
        :columns="columns"
        :grid="$q.screen.xs"
        :title="$t('sections.labels.lblTitle')"
        table-header-class="header_table"
        separator="cell"
        :filter="filter"
        bordered
        flat
        hide-bottom
        :rows-per-page-options="[0]"
        @row-click="onRowClick"
      >
        <template #top-left>
          <q-select
            v-model="publication"
            :options="publicationOptions"
            :label="$t('section.fields.publication')"
            filled
            emit-value
            map-options
            style="width: 250px"
          />
          <q-checkbox
            v-model="inactive"
            :label="$t('section.fields.inactive')"
            emit-value
            style="width: 250px"
          />
        </template>
        <template #top-right>
          <q-input
            v-model="filter"
            borderless
            dense
            debounce="300"
            :placeholder="$t('section.fields.shortSearch')"
          >
            <template #append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-main="props">
          <q-td :props="props">
            <q-icon v-if="props.value" name="done" />
          </q-td>
        </template>
        <template #body-cell-active="props">
          <q-td :props="props">
            <q-icon v-if="props.value" name="done" />
          </q-td>
        </template>
        <template #item="props">
          <QTableGridItem
            v-slot="slotProps"
            :columns="props.cols"
            @click="actions.editSection(props.row.id)"
          >
            <span
              v-if="
                slotProps.columnName != 'active' &&
                slotProps.columnName != 'main'
              "
              >{{ slotProps.columnValue }}</span
            >
            <div v-else>
              <q-icon v-if="slotProps.columnValue" name="done" />
            </div>
          </QTableGridItem>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import type { QTableColumn } from "quasar";
import type { SectionDto } from "@/types/api";
import { ref, computed } from "vue";
import { useSectionStore } from "@/stores/section";
import { useSectionActions } from "@/composables/useSectionActions";
import { i18n } from "@/i18n";
import QTableGridItem from "@/components/QTableGridItem.vue";
import { getPublicationOptions } from "@/helpers/publication";

const sectionStore = useSectionStore();
const { filterSectionsByPublication } = sectionStore;
const { sections } = sectionStore;
const publicationOptions = getPublicationOptions();
const publication = ref<string>();
let inactive = ref<boolean>(false);

const sectionsFilteredByPublication = computed<SectionDto[]>(() => {
  if (!publication.value) {
    return sections.filter((x) => inactive.value || x.active);
  }

  return filterSectionsByPublication(publication.value, !inactive.value);
});
const filter = ref("");
const columns: QTableColumn<SectionDto>[] = [
  {
    name: "id",
    label: i18n.t("section.fields.shortId"),
    align: "left",
    field: (row) => row.id,
  },
  {
    name: "labelFr",
    label: i18n.t("section.fields.shortLabelFr"),
    sortable: true,
    align: "left",
    sortOrder: "ad",
    field: (row) => row.labelFr,
    style: (row) => rowStyle(row),
  },
  {
    name: "labelEn",
    label: i18n.t("section.fields.shortLabelEn"),
    sortable: true,
    align: "left",
    field: (row) => row.labelEn,
    style: (row) => rowStyle(row),
  },
  {
    name: "weight",
    label: i18n.t("section.fields.shortWeight"),
    align: "center",
    sortable: true,
    field: (row) => row.weight,
  },
  {
    name: "main",
    label: i18n.t("section.fields.shortMain"),
    align: "center",
    sortable: true,
    field: (row) => row.main,
  },
  {
    name: "active",
    label: i18n.t("section.fields.shortActive"),
    align: "center",
    sortable: true,
    field: (row) => row.active,
  },
];
const { actions } = useSectionActions();
/**
 * Fonction appelé lors d'un click sur une des lignes du tableau des rubriques
 */
function onRowClick(evt: Event, row: SectionDto) {
  actions.editSection(row.id);
}
/**
 * Fonction pour ajouter un style à la ligne du tableau
 */
function rowStyle(row: SectionDto) {
  return row.main ? "font-weight:bold" : "";
}
</script>
