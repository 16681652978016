<!-- eslint-disable vue/no-v-text-v-html-on-component -->
<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="q-gutter-md">
    <div v-if="!splitMode" class="alert-important">
      <q-banner
        v-if="
          !isHistory &&
          isVersionObsolete[articleContent.language] &&
          getLastModificationIdAuthor != authService.currentUser?.id
        "
        class="q-mb-lg bg-red text-white"
        @click="refreshArticle"
      >
        <template #avatar>
          <q-icon name="error" color="white" />
        </template>
        <span style="font-size: 20px">{{ titleCartoucheVersionModified }}</span>
      </q-banner>
    </div>
    <div class="row">
      <q-chip
        v-for="(country, idx) in currentArticleStore.currentArticle.countries"
        :key="idx"
        clickable
        dense
        color="primary"
        square
        :label="isFr ? country.labelFr : country.labelEn"
        style="font-size: 0.8rem; color: white"
        @click="showPanelRight = !showPanelRight"
      />
      <q-chip
        clickable
        square
        :color="missingSection ? 'warning' : 'secondary'"
        :label="
          missingSection
            ? $t('article.labels.missingMainSection')
            : getSectionLabel(currentArticleStore.currentArticle.sectionPrimary)
        "
        style="font-size: 0.8rem; font-weight: bold; color: white"
        dense
        @click="showPanelRight = !showPanelRight"
      />
      <q-chip
        v-for="(section, idx) in currentArticleStore.currentArticle
          .sectionSecondaries"
        :key="idx"
        :label="getSectionLabel(section)"
        style="font-size: 0.8rem; color: white"
        dense
        square
        clickable
        color="secondary"
        @click="showPanelRight = !showPanelRight"
      />
      <q-chip
        square
        dense
        icon="event"
        color="grey-5"
        clickable
        style="font-size: 0.8rem"
        :label="
          date.formatDate(currentArticleStore.currentArticle.publishedOn, mask)
        "
        @click="showPanelRight = !showPanelRight"
      ></q-chip>
      <q-chip
        v-if="articleContent.id"
        square
        dense
        icon="pin"
        clickable
        style="font-size: 0.8rem"
        :label="articleContent.id.toString()"
        @click="copyHtml(articleContent.id.toString())"
      >
        <q-tooltip>
          {{ $t("article.fields.idContentElcanoTooltip") }}
        </q-tooltip>
      </q-chip>
      <q-chip
        v-if="articleContent.idBackOffice"
        square
        dense
        icon="tag"
        clickable
        style="font-size: 0.8rem"
        :label="articleContent.idBackOffice.toString()"
        @click="copyHtml(articleContent.idBackOffice.toString())"
      >
        <q-tooltip>
          {{ $t("article.fields.idIndigoTooltip") }}
        </q-tooltip>
      </q-chip>
      <q-chip
        v-if="articleContent.articleUrl"
        square
        dense
        icon="open_in_browser"
        clickable
        style="font-size: 0.8rem"
        :label="$t('article.fields.articleUrlShort')"
        @click="copyHtml(articleContent.articleUrl)"
      >
        <q-tooltip>
          {{ $t("article.fields.articleUrlTooltip") }}
        </q-tooltip>
      </q-chip>
      <StateReview
        :contributions="currentArticleStore.currentArticle.contributions"
        :state="articleContent.state"
        :language="articleContent.language"
      />
    </div>
    <div class="row">
      <div v-if="hiddenPropWithValue" class="alert-hidden-property">
        {{ hiddenPropWithValue }}
      </div>
      <div ref="elementTitle" class="col-11">
        <q-input
          ref="title"
          v-model="articleContent.title"
          type="textarea"
          filled
          :autogrow="autogrowMode"
          :readonly="readonly"
          :label="$t('article.fields.title')"
          :rules="[
            (val) => !!val || $t('rules.required'),
            (val) => val.length <= 125 || $t('rules.maxlength', [125]),
          ]"
          :input-style="{
            fontFamily: 'CooperHewitt-Heavy',
            fontSize: '28px',
            lineHeight: '1.2',
          }"
        >
          <template #append>
            <q-btn
              v-show="!readonly"
              class="btn-add-comment"
              round
              dense
              flat
              icon="chat"
              @click="addCommentTitle"
            />
          </template>
        </q-input>
      </div>
      <div ref="sidebarElementTitle" class="col-1 notificationSidebar"></div>
    </div>
    <div v-show="isFeuilleton(articleContent)" class="row">
      <div ref="elementShortTitle" class="col-11">
        <q-input
          ref="shortTitle"
          v-model="articleContent.shortTitle"
          filled
          counter
          maxlength="30"
          :readonly="readonly"
          :label="$t('article.fields.shortTitle')"
          :rules="[(val) => !!val || $t('rules.required')]"
          :input-style="{ fontFamily: 'CooperHewitt-Heavy', fontSize: '28px' }"
        >
          <template #append>
            <q-btn
              v-show="!readonly"
              class="btn-add-comment"
              round
              dense
              flat
              icon="chat"
              @click="addCommentShortTitle"
            />
          </template>
        </q-input>
      </div>
      <div
        ref="sidebarElementShortTitle"
        class="col-1 notificationSidebar"
      ></div>
    </div>
    <div v-if="isFeuilleton(articleContent)" class="row">
      <div ref="elementSerialUrl" class="col-11">
        <q-input
          ref="serialUrl"
          v-model="articleContent.serialUrl"
          filled
          :readonly="readonly"
          :label="$t('article.fields.serialUrl')"
          :rules="[
            (val) => !!val || $t('rules.required'),
            (val) => validateSerialUrl(val) || $t('rules.invalidCharacter'),
          ]"
        >
        </q-input>
      </div>
    </div>
    <div v-show="withStandFirst(articleContent)" class="row">
      <div ref="elementChapo" class="col-11">
        <q-input
          ref="chapo"
          v-model="articleContent.standfirst"
          class="articleContentWidth"
          type="textarea"
          filled
          :autogrow="autogrowMode"
          :readonly="readonly"
          :label="$t('article.fields.standfirst')"
          :rules="[(val) => val.length <= 300 || $t('rules.maxlength', [300])]"
          :input-style="{
            fontFamily: 'NotoSerif-Regular',
            fontSize: '20px',
            lineHeight: '1.6',
          }"
        >
          <template #append>
            <q-btn
              v-show="!readonly && showSetDefaultStandfirst"
              class="btn-add-comment"
              round
              dense
              flat
              icon="change_circle"
              @click="setDefaultStandfirst"
            >
              <q-tooltip>
                {{ $t("article.action.setDefaultStandfirstFromFormat") }}
              </q-tooltip>
            </q-btn>
            <q-btn
              v-show="!readonly"
              class="btn-add-comment"
              round
              dense
              flat
              icon="chat"
              @click="addCommentChapo"
            />
          </template>
        </q-input>
      </div>
      <div ref="sidebarElementChapo" class="col-1 notificationSidebar"></div>
    </div>
    <div class="row">
      <q-card flat bordered class="col-6">
        <q-splitter v-model="imageSplitterModel" unit="px">
          <template #before>
            <q-tabs v-model="imageTab" vertical>
              <q-tab
                v-if="showMainIllustration(articleContent)"
                name="main"
                :label="$t('article.tabs.mainMedia')"
              />
              <q-tab
                name="social"
                :label="$t('article.tabs.socialMedia')"
                :disable="true"
              />
            </q-tabs>
          </template>

          <template #after>
            <q-tab-panels
              v-model="imageTab"
              animated
              swipeable
              vertical
              transition-prev="jump-up"
              transition-next="jump-up"
            >
              <q-tab-panel
                v-if="showMainIllustration(articleContent)"
                name="main"
              >
                <div class="q-mb-md q-gutter-md">
                  <q-btn
                    color="white"
                    text-color="black"
                    icon="add_photo_alternate"
                    :disable="readonly"
                    @click="openFinder(addMainMedia)"
                  >
                    <q-tooltip class="bg-secondary">{{
                      $t("article.action.selectMainMedia")
                    }}</q-tooltip>
                  </q-btn>
                  <q-btn
                    v-if="articleContent.idMainMedia"
                    color="white"
                    text-color="black"
                    icon="delete"
                    :disable="readonly"
                    @click="removeMainMedia"
                  >
                    <q-tooltip class="bg-secondary">{{
                      $t("article.action.removeMainMedia")
                    }}</q-tooltip>
                  </q-btn>
                </div>
                <div>
                  <q-img
                    v-if="articleContent.idMainMedia"
                    alt="Main media"
                    :src="mainMediaUrl"
                    spinner-color="white"
                    style="max-height: 200px; max-width: 250px"
                    :class="readonly ? 'readonly' : ''"
                  />
                </div>
              </q-tab-panel>

              <q-tab-panel name="social" :disable="true">
                <div class="q-mb-md q-gutter-md">
                  <q-btn
                    color="white"
                    text-color="black"
                    icon="add_photo_alternate"
                    @click="openFinder(addSocialMedia)"
                  >
                    <q-tooltip class="bg-secondary">{{
                      $t("article.action.selectSocialMedia")
                    }}</q-tooltip>
                  </q-btn>
                  <q-btn
                    v-if="articleContent.idSocialMedia"
                    color="white"
                    text-color="black"
                    icon="delete"
                    :disable="readonly"
                    @click="removeSocialMedia"
                  >
                    <q-tooltip class="bg-secondary">{{
                      $t("article.action.removeSocialMedia")
                    }}</q-tooltip>
                  </q-btn>
                </div>
                <div>
                  <q-img
                    v-if="articleContent.idSocialMedia"
                    alt="Main media"
                    :src="socialMediaUrl"
                    spinner-color="white"
                    style="max-height: 200px; max-width: 250px"
                    :class="readonly ? 'readonly' : ''"
                  />
                </div>
              </q-tab-panel>
            </q-tab-panels>
          </template>
        </q-splitter>
      </q-card>
      <q-btn
        v-if="
          !isMainContent(articleContent) &&
          articleContent.text.length === 0 &&
          !readonly
        "
        class="q-ml-sm"
        color="white"
        text-color="black"
        icon="content_copy"
        @click="copyTextForTranslation(articleContent)"
      >
        <q-tooltip class="bg-secondary">{{
          $t("article.action.copyMainContent")
        }}</q-tooltip>
      </q-btn>
    </div>
    <div class="container q-ml-sm">
      <div
        ref="editorDiv"
        class="editor"
        :class="
          (Dark.isActive ? 'editor-dark' : '') +
          ' ' +
          (readonly ? 'readonly' : '') +
          ' ' +
          toolbarSticky
        "
      >
        <div class="row">
          <div class="selection-count col">
            {{
              $t("article.labels.selectionLength", {
                count: selectionCharCount,
              })
            }}
          </div>
          <div class="word-count col">{{ charCount }} / {{ maxCharCount }}</div>
        </div>
        <ckeditor
          v-if="showEditor"
          ref="currentEditor"
          v-model="articleContent.text"
          :editor="editor"
          :config="editorConfig"
          @focus="handleAnnotation"
          @ready="onCKEditorReady"
          @input="refreshCkEditorInfo"
        >
        </ckeditor>
        <div class="row">
          <div class="selection-count col">
            {{
              $t("article.labels.selectionLength", {
                count: selectionCharCount,
              })
            }}
          </div>
          <div class="word-count col">{{ charCount }} / {{ maxCharCount }}</div>
        </div>
      </div>
      <div
        v-show="sideBarCommentEnabled"
        ref="sidebarElement"
        class="sidebar mobile-hide"
      ></div>
    </div>
    <div v-if="isMainContent(articleContent)" class="row">
      <div class="column">
        <div
          class="q-ml-md"
          v-html="getContributorsByType(ContributionType.Author)"
        ></div>
        <q-toggle
          v-model="showAuthorSignature"
          :label="$t('article.labels.toggleAuthorSignature')"
          :disable="readonly"
          @update:model-value="onUpdateToggleSignatureAuthor"
        ></q-toggle>
      </div>
      <div class="column">
        <div
          class="q-ml-md"
          v-html="getContributorsByType(ContributionType.Freelancer)"
        ></div>
        <q-toggle
          v-model="showFreelanceSignature"
          :label="$t('article.labels.toggleFreelanceSignature')"
          :disable="readonly"
          @update:model-value="onUpdateToggleSignatureFreelance"
        ></q-toggle>
      </div>
    </div>
    <div v-show="!isFeuilleton(articleContent)" class="row">
      <div class="col-11">
        <q-input
          v-model="articleContent.tweet"
          class="articleContentWidth"
          type="textarea"
          counter
          filled
          :autogrow="autogrowMode"
          :readonly="readonly"
          :label="$t('article.fields.tweet')"
          :rules="[(val) => val.length < 256 || $t('rules.maxlength', [256])]"
          :input-style="{
            fontFamily: 'NotoSerif-Regular',
            fontSize: '20px',
            lineHeight: '1.6',
          }"
        />
      </div>
      <q-btn
        v-if="readonly && canUpdateTweet()"
        :label="$t('articleTweet.labels.btnUpdate')"
        color="primary"
        @click="openDialogUpdateTweet"
      />
    </div>
    <div v-show="withSummary(articleContent)" class="row">
      <div class="col-11">
        <q-input
          v-model="articleContent.summary"
          class="articleContentWidth"
          type="textarea"
          maxlength="256"
          counter
          filled
          :autogrow="autogrowMode"
          :readonly="readonly"
          :label="$t('article.fields.summary')"
          :rules="[(val) => val.length < 256 || $t('rules.maxlength', [256])]"
          :input-style="{
            fontFamily: 'NotoSerif-Regular',
            fontSize: '20px',
            lineHeight: '1.6',
          }"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, inject } from "vue";
import { toRefs } from "vue";
import {
  ArticleContentDto,
  ContributionType,
  MediaDto,
  RightReference,
  WorkflowState,
} from "@/types/api";
import type { EventBus } from "quasar";
import { Dark, useQuasar, debounce, date } from "quasar";
import { watch, onMounted, onUnmounted, ref, computed } from "vue";
import {
  createArticleContent,
  articleService,
} from "@/services/ArticleService";
import { useDebounceFn } from "@vueuse/core";
import { useClipboard } from "@/composables/useClipboard";
import {
  getColorForPercentage,
  useLocale,
  formatErrorMessage,
  removeDiacritics,
} from "@/utils";
import {
  useCkEditor,
  useCkEditorContext,
  switchAnnotationToInline,
  switchInlineAnnotationToSidebar,
  getCkInstance,
  configurePlugins,
  configureCommentsOnly,
  onEditorReady,
  getTextWithAcceptedSuggestionsOnEditor,
} from "@/composables/useCkEditor";
import { mediaService } from "@/services/MediaService";
import { useI18n } from "vue-i18n";
import {
  ArticleFormat,
  conf_elcanoapi_current,
  FrenchLanguage,
  ID_ARTICLE_SETTING_SIGNATURE_AUTHOR,
  ID_ARTICLE_SETTING_SIGNATURE_FREELANCE,
} from "@/constants";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { useProlexis } from "@/composables/useProlexis";
import { useFormatStore } from "@/stores/format";
import { usePage } from "@/composables/usePage";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useSectionStore } from "@/stores/section";
import DialogAide from "@/components/ckeditor/DialogAide.vue";
import StateReview from "@/components/Article/StateReview.vue";
import DialogUpdateTweet from "./DialogUpdateTweet.vue";
import { authService } from "@/services/AuthService";
import { storeToRefs } from "pinia";
import { useUserStore } from "@/stores/user";
import { useKeyboardListener } from "@/composables/useKeyboardListener";

const { startListening, stopListening } = useKeyboardListener(
  ["Control+A", "Control+a"],
  () => {
    updateSelectionCharCount();
  }
);
const currentArticleStore = useCurrentArticleStore();
const { showPanelRight } = usePage();
const { findFormat } = useFormatStore();
const { findSection } = useSectionStore();
const { copyHtml } = useClipboard();
const bus = inject<EventBus>("bus");

const i18n = useI18n();

const props = defineProps({
  defaultArticleContent: {
    type: Object as PropType<ArticleContentDto>,
    default() {
      return createArticleContent();
    },
  },
  publication: {
    type: String,
    default() {
      return null;
    },
  },
  readonly: {
    type: Boolean,
    default() {
      return false;
    },
  },
  splitMode: {
    type: Boolean,
    default() {
      return false;
    },
  },
  showCommentSidebar: {
    type: Boolean,
    default() {
      return true;
    },
  },
});

const { editor, editorConfig } = useCkEditor(props.publication);
const { analyzeRef } = useProlexis(props.publication);

let intervalToolbar;
let showEditor = ref<boolean>(false);
const autogrowMode = ref<boolean>(false);
const sidebarElement = ref<HTMLElement | null>(null);
const elementTitle = ref<HTMLElement | null>(null);
const sidebarElementTitle = ref<HTMLElement | null>(null);
const elementShortTitle = ref<HTMLElement | null>(null);
const sidebarElementShortTitle = ref<HTMLElement | null>(null);
const elementChapo = ref<HTMLElement | null>(null);
const sidebarElementChapo = ref<HTMLElement | null>(null);
let editorDiv = ref<HTMLElement | null>(null);
let editorInstance: ClassicEditor | null = null;
const title = ref<HTMLElement | null>(null);
const toolbarSticky = ref("");
const toolbarWidth = ref<string>("auto");
const stopAlertMaxChar = ref<boolean>(false);
const $q = useQuasar();
const { defaultArticleContent: articleContent } = toRefs(props);
const { isFr } = useLocale();
const mask = "DD/MM/YYYY";
const charCount = ref(0);
const selectionCharCount = ref(0);
const showAuthorSignature = ref(
  props.defaultArticleContent.settings.some(
    (e) => e.id == ID_ARTICLE_SETTING_SIGNATURE_AUTHOR
  )
);
const showFreelanceSignature = ref(
  props.defaultArticleContent.settings.some(
    (e) => e.id == ID_ARTICLE_SETTING_SIGNATURE_FREELANCE
  )
);
const imageSplitterModel = ref(200);
const imageTab = ref(
  showMainIllustration(articleContent.value) ? "main" : "social"
);
const {
  modifExist,
  getLastModificationAuthor,
  getLastModificationIdAuthor,
  isVersionObsolete,
  isHistory,
  computingTextWithSuggestions,
} = storeToRefs(currentArticleStore);

// eslint-disable-next-line @typescript-eslint/no-empty-function
let addCommentTitle: () => void = () => {}; //NOSONAR
// eslint-disable-next-line @typescript-eslint/no-empty-function
let destroyContextTitle: () => void = () => {}; //NOSONAR
// eslint-disable-next-line @typescript-eslint/no-empty-function
let addCommentShortTitle: () => void = () => {}; //NOSONAR
// eslint-disable-next-line @typescript-eslint/no-empty-function
let destroyContextShortTitle: () => void = () => {}; //NOSONAR
// eslint-disable-next-line @typescript-eslint/no-empty-function
let addCommentChapo: () => void = () => {}; //NOSONAR
// eslint-disable-next-line @typescript-eslint/no-empty-function
let destroyContextChapo: () => void = () => {}; //NOSONAR

const refreshCkEditorInfoDebounce = useDebounceFn(() => {
  if (!editorInstance) return;

  const wordCountPlugin = editorInstance.plugins.get("WordCount");
  charCount.value = wordCountPlugin ? wordCountPlugin.characters : 0;

  getTextWithAcceptedSuggestionsOnEditor(editorInstance).then((text) => {
    computingTextWithSuggestions.value = false;
    if (articleContent.value.text === text)
      articleContent.value.textWithAcceptedSuggestions = undefined;
    else articleContent.value.textWithAcceptedSuggestions = text;
  });
}, 300);

const openFinder = function (callback: (media: MediaDto) => void) {
  window.CKFinder.popup({
    chooseFiles: true,
    width: 800,
    height: 600,
    onInit: function (finder) {
      finder.on("files:choose", function (evt) {
        var file = evt.data.files.first();
        const url = file.getUrl();
        const basePath = "/medias/files/Images";
        const filename = url.substr(url.indexOf(basePath) + basePath.length);

        mediaService
          .getSingleByPath(filename)
          .then((media) => {
            if (media) {
              callback(media);
            } else {
              $q.notify({
                type: "negative",
                message: i18n.t("media.select.error"),
              });
            }
          })
          .catch((reason) => console.log(reason));
      });
    },
  });
};
const mainMediaUrl = computed<string>(
  () =>
    conf_elcanoapi_current +
    "/medias/files/Images" +
    articleContent.value.pathMainMedia
);
const socialMediaUrl = computed<string>(
  () =>
    conf_elcanoapi_current +
    "/medias/files/Images" +
    articleContent.value.pathSocialMedia
);
const missingSection = computed<boolean>(
  () => !currentArticleStore.currentArticle.sectionPrimary
);

const hiddenPropWithValue = computed<string>(() => {
  let property: string[] = [];

  if (!isFeuilleton(articleContent.value))
    property.push("shortTitle", "serialUrl");

  if (!withStandFirst(articleContent.value)) {
    property.push("standfirst");
  }

  if (isFeuilleton(articleContent.value)) property.push("tweet");

  if (!withSummary(articleContent.value)) property.push("summary");

  property = property.filter(
    (p) =>
      articleContent.value[p] !== undefined &&
      articleContent.value[p] !== null &&
      articleContent.value[p] !== ""
  );

  if (property.length === 0) return "";

  return i18n.t("article.messages.hiddenPropWithValue", {
    count: property.length,
    properties: property.map((p) => i18n.t(`article.fields.${p}`)).join(", "),
  });
});

const titleCartoucheVersionModified = computed(() => {
  return i18n.t("article.dialogs.titles.notifyArticleMustBeRefresh", {
    userName: getLastModificationAuthor.value,
    interpolation: { escapeValue: false },
  });
});

const sideBarCommentEnabled = computed<boolean>(() => {
  handleAnnotation();
  return props.showCommentSidebar;
});
watch(
  () => props.readonly,
  (newValue) => {
    if (!editorInstance) return;
    configureCommentsOnly(editorInstance, newValue);
  }
);

const maxCharCount = computed(() => {
  try {
    return findFormat(articleContent.value.format).maxLength;
  } catch {
    return 6000;
  }
});

const marginCharCount = computed(() => {
  return $q.screen.lt.md ? "12px" : "8px";
});

const colorCharCount = computed(() => {
  return getColorForPercentage(charCount.value / maxCharCount.value);
});

const showSetDefaultStandfirst = computed(() => {
  try {
    const format = findFormat(articleContent.value.format);

    const standfirstProperty =
      articleContent.value.language === FrenchLanguage
        ? "standfirstFr"
        : "standfirstEn";

    const defaultStandfirst = format[standfirstProperty];

    if (!defaultStandfirst) return false;

    return defaultStandfirst !== articleContent.value.standfirst;
  } catch {
    return false;
  }
});

watch(charCount, (newValue) => {
  if (
    newValue > maxCharCount.value &&
    !stopAlertMaxChar.value &&
    $q.screen.gt.sm
  ) {
    $q.notify({
      type: "warning",
      message: i18n.t("rules.maxlength", [maxCharCount.value]),
      actions: [
        {
          label: i18n.t("rules.labels.btnStopShowing"),
          color: "black",
          handler: () => {
            stopAlertMaxChar.value = true;
          },
        },
      ],
    });
  }
});

onMounted(async () => {
  startListening();
  setTimeout(() => {
    handleAutoGrowInput();
  }, 800);
  if (bus)
    bus.on("resize", () => {
      handleAnnotation();
    });

  if ($q.screen.lt.sm) {
    editorConfig.toolbar = {
      shouldNotGroupWhenFull: false,
    };
  }

  if (!editorConfig.removePlugins) {
    editorConfig.removePlugins = [];
  }

  if (!isInsiders(articleContent.value)) {
    editorConfig.removePlugins?.push("Insiders");
    editorConfig.removePlugins?.push("Resume");
  }

  editorConfig.language = isFr.value ? "fr" : "en";

  if (editorConfig.sidebar === undefined) {
    editorConfig.sidebar = {};
  }
  editorConfig.sidebar.container = sidebarElement.value ?? undefined;

  editorConfig.collaboration = {
    channelId: `article:${articleContent.value.idArticle}:${articleContent.value.language}`,
  };

  const translationAnnotation = getNeedTranslationForAnnotation()
    ? isMainContent(articleContent.value)
    : false;

  if (editorConfig.comments === undefined) {
    editorConfig.comments = {
      editorConfig: {
        needTranslationForAnnotation: translationAnnotation,
      },
    };
  } else {
    editorConfig.comments.editorConfig.needTranslationForAnnotation =
      translationAnnotation;
  }

  showEditor.value = true;
  intervalToolbar = setInterval(() => {
    if (!editorDiv.value) return;
    const rect = editorDiv.value.getBoundingClientRect();
    if (rect.top <= 56) {
      toolbarSticky.value = "sticky";
    } else toolbarSticky.value = "";

    toolbarWidth.value = editorDiv.value.clientWidth + "px";
  }, 800);
  const {
    addCommentThread: fnAddCommentTitle,
    destroy: fnDestroyContextTitle,
  } = await useCkEditorContext(
    `article:${articleContent.value.idArticle}:${articleContent.value.language}`,
    `article:${articleContent.value.idArticle}:${articleContent.value.language}:title:notifications`,
    sidebarElementTitle.value,
    elementTitle.value,
    true
  );
  addCommentTitle = fnAddCommentTitle;
  destroyContextTitle = fnDestroyContextTitle;
  const {
    addCommentThread: fnAddCommentChapo,
    destroy: fnDestroyContextChapo,
  } = await useCkEditorContext(
    `article:${articleContent.value.idArticle}:${articleContent.value.language}`,
    `article:${articleContent.value.idArticle}:${articleContent.value.language}:standfirst:notifications`,
    sidebarElementChapo.value,
    elementChapo.value,
    true
  );
  addCommentChapo = fnAddCommentChapo;
  destroyContextChapo = fnDestroyContextChapo;
  const {
    addCommentThread: fnAddCommentShortTitle,
    destroy: fnDestroyContextShortTitle,
  } = await useCkEditorContext(
    `article:${articleContent.value.idArticle}:${articleContent.value.language}`,
    `article:${articleContent.value.idArticle}:${articleContent.value.language}:shortTitle:notifications`,
    sidebarElementShortTitle.value,
    elementShortTitle.value,
    true
  );
  addCommentShortTitle = fnAddCommentShortTitle;
  destroyContextShortTitle = fnDestroyContextShortTitle;

  const wordCountPlugin = editorInstance?.plugins.get("WordCount");
  charCount.value = wordCountPlugin ? wordCountPlugin.characters : 0;
});

onUnmounted(() => {
  stopListening();
  clearInterval(intervalToolbar);
  destroyContextTitle();
  destroyContextChapo();
  destroyContextShortTitle();
  bus?.off("resize");
});

window.addEventListener("resize", () => {
  debounce(handleAnnotation, 300)();
  debounce(handleAutoGrowInput, 300)();
});
/**
 * Méthode pour forcer le calcul de la hauteur du champ en mode autogrow
 */
function handleAutoGrowInput() {
  autogrowMode.value = false;
  setTimeout(() => {
    autogrowMode.value = true;
  }, 100);
}

/**
 * Méthode permettant de mettre à jour le chapo avec les informations du format
 */
function setDefaultStandfirst() {
  try {
    const format = findFormat(articleContent.value.format);

    const standfirstProperty =
      articleContent.value.language === FrenchLanguage
        ? "standfirstFr"
        : "standfirstEn";

    const defaultStandfirst = format[standfirstProperty];

    if (!defaultStandfirst) return;

    articleContent.value.standfirst = defaultStandfirst;
  } catch {
    return;
  }
}

/**
 * Méthode permettant de mettre à jour le nombre de caractère sélectionné.
 */
function updateSelectionCharCount() {
  if (!editorInstance) return;
  const selection = editorInstance.model.document.selection;

  const s = editorInstance.model.getSelectedContent(selection);
  let selectedText = "";

  const getTextFromNode = function (node) {
    if (node.is("text")) {
      return node.data;
    } else if (node.is("element")) {
      let text = "";
      for (const child of node.getChildren()) {
        text += getTextFromNode(child);
      }
      return text;
    }
  };

  for (const child of s.getChildren()) {
    selectedText += getTextFromNode(child);
  }

  selectionCharCount.value = selectedText.length;
}

/**
 * Fonction appelé lorsque ckeditor est prêt
 */
function onCKEditorReady() {
  editorInstance = getCkInstance(editorDiv.value) ?? null;

  if (!editorInstance) return;

  configurePlugins(
    editorInstance,
    props.readonly,
    prolexisAll,
    helpCallback,
    linkTranslationCallback
  );
  onEditorReady(editorInstance);

  editorInstance.editing.view.document.on("selectionChange", () => {
    updateSelectionCharCount();
  });

  title.value?.focus();
}

/**
 * Permet de savoir si l'on doit afficher le bouton de validation de la traduction pour les annotations.
 */
function getNeedTranslationForAnnotation() {
  for (let content of currentArticleStore.currentArticle.contents) {
    if (
      content.language !== currentArticleStore.currentArticle.idMainLanguage &&
      content.state !== WorkflowState.AttenteTraduction
    ) {
      return true;
    }
  }

  return false;
}

/**
 * Ajoute/retire le paramètre CMS Signature auteur.
 */
function onUpdateToggleSignatureAuthor(value) {
  if (value) {
    currentArticleStore.addSetting(
      ID_ARTICLE_SETTING_SIGNATURE_AUTHOR,
      articleContent.value.id
    );
  } else {
    currentArticleStore.removeSetting(
      ID_ARTICLE_SETTING_SIGNATURE_AUTHOR,
      articleContent.value.id
    );
  }
}

/**
 * Ajoute/retire le paramètre CMS Signature pigiste.
 */
function onUpdateToggleSignatureFreelance(value) {
  if (value) {
    currentArticleStore.addSetting(
      ID_ARTICLE_SETTING_SIGNATURE_FREELANCE,
      articleContent.value.id
    );
  } else {
    currentArticleStore.removeSetting(
      ID_ARTICLE_SETTING_SIGNATURE_FREELANCE,
      articleContent.value.id
    );
  }
}
/**
 * Fonction appelé lorsque l'on clique sur le bouton "Aide" de la barre d'outils
 */
function helpCallback() {
  $q.dialog({
    component: DialogAide,
  });
}

/**
 * Récupère l'url de l'article dans l'autre langue et rappelle la fonction de callback avec l'url en paramètre
 */
async function linkTranslationCallback(url, callback) {
  if (!url) return;

  try {
    callback(await articleService.getUrlInOtherLanguage(url));
  } catch (error) {
    $q.notify({
      type: "negative",
      message: formatErrorMessage(error, "default"),
    });
  }
}

/**
 * Supprimer l'illustration principale de l'article
 */
function removeMainMedia() {
  articleContent.value.idMainMedia = undefined;
  articleContent.value.pathMainMedia = undefined;
}

/**
 * Supprimer l'illustration des réseaux sociaux de l'article
 */
function removeSocialMedia() {
  articleContent.value.idSocialMedia = undefined;
  articleContent.value.pathSocialMedia = undefined;
}

/**
 * Lance l'analyse par Prolexis de tous les champs.
 */
function prolexisAll() {
  analyzeRef(
    articleContent.value.language,
    articleContent,
    ["title", "standfirst"],
    editorInstance
  );
}
/**
 * Fonction pour rendre visible ou non le champ séléction de l'illustration principale
 */
function showMainIllustration(content: ArticleContentDto) {
  return ["Breve"].indexOf(content.format) < 0;
}

/**
 * Fonction pour rendre visible ou non les champs associés à un Insiders
 */
function isInsiders(content: ArticleContentDto) {
  return content.format === "Insider";
}

/**
 * Indique que le contenu doit posséder un resumé personnalisé
 */
function withSummary(articleContent: ArticleContentDto) {
  return isBreve(articleContent) || isFeuilleton(articleContent);
}

/**
 * Indique que le  contenu doit posséder un chapo
 */
function withStandFirst(articleContent: ArticleContentDto) {
  return (
    !isFeuilleton(articleContent) &&
    !isBreve(articleContent) &&
    !isInsiders(articleContent)
  );
}

/**
 * Fonction pour rendre visible ou non les champs associés à une Breve
 */
function isBreve(content: ArticleContentDto) {
  return content.format === "Breve";
}

/**
 * Fonction pour rendre visible ou non les champs associés à un feuilleton
 */
function isFeuilleton(content: ArticleContentDto) {
  return content.format == ArticleFormat.Feuilleton;
}

/**
 * Permet de savoir si on est sur le contenu principal (langue) d'un article
 */
function isMainContent(content: ArticleContentDto) {
  return currentArticleStore.currentArticle.idMainLanguage == content.language;
}

/**
 * Copie le text, le tweet, le resumé personnalisé et le chapo de l'article originale dans la traduction.
 */
function copyTextForTranslation(content: ArticleContentDto) {
  const mainLanguage = currentArticleStore.currentArticle.idMainLanguage;
  content.text =
    currentArticleStore.currentArticle.contents.find(
      (c) => c.language === mainLanguage
    )?.text ?? "";

  content.tweet =
    currentArticleStore.currentArticle.contents.find(
      (c) => c.language === mainLanguage
    )?.tweet ?? "";
  if (withSummary(content)) {
    content.summary =
      currentArticleStore.currentArticle.contents.find(
        (c) => c.language === mainLanguage
      )?.summary ?? "";
  }
  if (withStandFirst(content)) {
    content.standfirst =
      currentArticleStore.currentArticle.contents.find(
        (c) => c.language === mainLanguage
      )?.standfirst ?? "";
  }
}

/**
 * Gestion de l'affichage des commentaires
 */
function handleAnnotation() {
  editorInstance = getCkInstance(editorDiv.value) ?? null;
  if (!editorInstance) return;
  if ($q.screen.lt.lg || !sideBarCommentEnabled.value || showPanelRight.value)
    switchAnnotationToInline(editorInstance);
  else switchInlineAnnotationToSidebar(editorInstance);
}

/**
 *
 */
function getSectionLabel(sectionId: number | undefined) {
  if (!sectionId) return "";
  const section = findSection(sectionId);
  if (!section) return "";
  return isFr.value ? section.labelFr : section.labelEn;
}

/**
 * Ouvre la modal pour mettre a jour le tweet
 */
function openDialogUpdateTweet() {
  $q.dialog({
    component: DialogUpdateTweet,
    componentProps: {
      articleId: currentArticleStore.currentArticle.id,
      language: articleContent.value.language,
      xmin: articleContent.value.xmin,
      tweet: articleContent.value.tweet,
    },
  }).onOk(() => {
    window.location.reload();
  });
}

/**
 * Permet de savoir si l'utilisateur courant peut mettre à jour le tweet
 */
function canUpdateTweet() {
  return authService.hasRight(
    RightReference.PUBLICATION_ARTICLE_UpdateTweet,
    currentArticleStore.currentArticle.publication
  );
}

/**
 * Utilisé par la chip qui indique que le français a bougé
 */
async function refreshArticle() {
  if (modifExist.value > 0) await currentArticleStore.updateArticle();
  else await currentArticleStore.refreshArticle();
}

/**
 * Méthode appelé par CkEditor lors de l'édition de l'article.
 */
function refreshCkEditorInfo() {
  // Block la sauvegarde le temps de mettre a jour le text avec les suggestions acceptées.
  computingTextWithSuggestions.value = true;
  refreshCkEditorInfoDebounce();
}

/**
 *
 */
function getContributorsByType(contributionType: ContributionType) {
  const { usersLight } = useUserStore();
  const contributors: string[] = [];
  const signatureIds = currentArticleStore.currentArticle.contributions
    .filter((c) => c.type == contributionType)
    .map((el) => el.idContributor);

  if (signatureIds.length == 0) return "&nbsp;";

  for (const id of signatureIds) {
    try {
      const user = usersLight.find((el) => el.id == id);
      if (user != null) contributors.push(user.name);
    } catch (error) {
      console.error(error);
    }
  }

  return contributors.join(", ");
}

/**
 * Verifie si l'url est valide pour un article
 * @param url url
 */
function validateSerialUrl(url: string) {
  if (!url) return false;

  const invalidCharacters = [
    "#",
    "?",
    "&",
    ":",
    ";",
    "=",
    "+",
    "$",
    ",",
    " ",
    "'",
    "`",
    '"',
    "<",
    ">",
    "`",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
  ];

  if (invalidCharacters.map((i) => url.indexOf(i)).some((i) => i >= 0))
    return false;

  if (url !== removeDiacritics(url)) return false;

  return true;
}
/**
 *
 */
function addMainMedia(media: MediaDto) {
  articleContent.value.idMainMedia = media.id;
  articleContent.value.pathMainMedia = media.filePath;
}
/**
 *
 */
function addSocialMedia(media: MediaDto) {
  articleContent.value.idSocialMedia = media.id;
  articleContent.value.pathSocialMedia = media.filePath;
}
</script>
<style lang="scss">
@import "@/styles/_styleArticle.scss";
:root {
  --editor-size: 100%;
}
.body--dark {
  .container .ck.ck-editor {
    a {
      color: #89c8ff;
      mark {
        &.marker-yellow {
          color: #a2a2c5;
        }
      }
    }
  }
  .q-field--readonly > *,
  .readonly .ck-content,
  .q-img.readonly {
    --ck-color-base-background: #{$red-7};
    background-color: $red-7;
  }
}
.alert-important {
  position: fixed;
  top: 100px;
  left: 50%;
  z-index: 999;
  transform: translate(-50%);
}
.alert-hidden-property {
  color: $red-7;
}
.container {
  /* To create the column layout. */
  display: flex;

  /* To make the container relative to its children. */
  position: relative;
  overflow-x: auto;
}

.container .ck.ck-editor {
  /* To stretch the editor to max 600px
      (just to look nice for this example but it can be any size). */
  min-width: var(--editor-size);
  border-left: v-bind(marginCharCount) solid v-bind(colorCharCount);

  a {
    text-decoration: none;
    mark {
      color: white;
      &.marker-yellow {
        color: blue;
      }
    }
  }
}
.q-field--readonly > *,
.readonly .ck-content,
.q-img.readonly {
  --ck-color-base-background: #{$red-1};
  background-color: $red-1;
}
.q-splitter .ck.ck-editor,
.q-splitter .editor {
  min-width: 0;
}

.ck-body-wrapper .ck-comment--remove,
.ck-comment--resolve {
  display: none !important;
}

.has-comment .btn-add-comment {
  display: none;
}

.editor.sticky .ck-editor__top {
  position: fixed;
  top: 56px;
  width: v-bind(toolbarWidth);
  z-index: 100;
}
.editor.sticky .ck-editor__top .ck-sticky-panel__content {
  box-shadow:
    var(--ck-drop-shadow),
    0 0;
}
.editor .ck-sticky-panel__content_sticky {
  position: absolute !important;
  top: 0 !important;
}
.editor .ck.ck-content.ck-editor__editable {
  font-family: "NotoSerif-Regular";
  line-height: 1.6;
  font-size: 19px;
  text-align: justify;
}

.editor {
  min-width: var(--editor-size);
  width: 100%;
}
.mobile .editor {
  width: 100%;
}

.sidebar {
  display: none;
  /* Set some size for the sidebar (it can be any). */
  min-width: 300px;

  /* Add some distance. */
  padding: 0 10px;

  overflow-x: hidden;
}

[data-tooltip]:before {
  /* needed - do not touch */
  content: attr(data-tooltip);
  position: absolute;
  opacity: 0;

  /* customizable */
  transition: all 0.15s ease;
  padding: 8px 10px;
  color: #333;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: 600;
  line-height: 1;
  border-radius: 4px;
  box-shadow: 2px 2px 1px silver;
}

[data-tooltip]:hover:before {
  /* needed - do not touch */
  opacity: 1;

  /* customizable */
  background: rgba(255, 255, 255, 0.8);
  margin-top: -30px;
  margin-left: 0px;
}

[data-tooltip]:not([data-tooltip-persistent]):before {
  pointer-events: none;
}

.editor-dark {
  --ck-color-base-background: rgba(255, 255, 255, 0.07);
  /* Overrides the border radius setting in the theme. */
  --ck-border-radius: 4px;

  /* Overrides the default font size in the theme. */
  --ck-font-size-base: 14px;

  /* Helper variables to avoid duplication in the colors. */
  --ck-custom-background: hsl(270, 1%, 29%);
  --ck-custom-foreground: hsl(255, 3%, 18%);
  --ck-custom-border: hsl(300, 1%, 22%);
  --ck-custom-white: hsl(0, 0%, 100%);

  /* -- Overrides generic colors. ------------------------------------------------------------- */

  --ck-color-base-foreground: var(--ck-custom-background);
  --ck-color-focus-border: hsl(208, 90%, 62%);
  --ck-color-text: hsl(0, 0%, 98%);
  --ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
  --ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

  /* -- Overrides the default .ck-button class colors. ---------------------------------------- */

  --ck-color-button-default-background: var(--ck-custom-background);
  --ck-color-button-default-hover-background: hsl(270, 1%, 22%);
  --ck-color-button-default-active-background: hsl(270, 2%, 20%);
  --ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
  --ck-color-button-default-disabled-background: var(--ck-custom-background);

  --ck-color-button-on-background: var(--ck-custom-foreground);
  --ck-color-button-on-hover-background: hsl(255, 4%, 16%);
  --ck-color-button-on-active-background: hsl(255, 4%, 14%);
  --ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
  --ck-color-button-on-disabled-background: var(--ck-custom-foreground);

  --ck-color-button-action-background: hsl(168, 76%, 42%);
  --ck-color-button-action-hover-background: hsl(168, 76%, 38%);
  --ck-color-button-action-active-background: hsl(168, 76%, 36%);
  --ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
  --ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
  --ck-color-button-action-text: var(--ck-custom-white);

  --ck-color-button-save: hsl(120, 100%, 46%);
  --ck-color-button-cancel: hsl(15, 100%, 56%);

  /* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

  --ck-color-dropdown-panel-background: var(--ck-custom-background);
  --ck-color-dropdown-panel-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

  --ck-color-split-button-hover-background: var(
    --ck-color-button-default-hover-background
  );
  --ck-color-split-button-hover-border: var(--ck-custom-foreground);

  /* -- Overrides the default .ck-input class colors. ----------------------------------------- */

  --ck-color-input-background: var(--ck-custom-background);
  --ck-color-input-border: hsl(257, 3%, 43%);
  --ck-color-input-text: hsl(0, 0%, 98%);
  --ck-color-input-disabled-background: hsl(255, 4%, 21%);
  --ck-color-input-disabled-border: hsl(250, 3%, 38%);
  --ck-color-input-disabled-text: hsl(0, 0%, 78%);

  /* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

  --ck-color-labeled-field-label-background: var(--ck-custom-background);

  /* -- Overrides the default .ck-list class colors. ------------------------------------------ */

  --ck-color-list-background: var(--ck-custom-background);
  --ck-color-list-button-hover-background: var(--ck-color-base-foreground);
  --ck-color-list-button-on-background: var(--ck-color-base-active);
  --ck-color-list-button-on-background-focus: var(--ck-color-base-active-focus);
  --ck-color-list-button-on-text: var(--ck-color-base-background);

  /* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

  --ck-color-panel-background: var(--ck-custom-background);
  --ck-color-panel-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

  --ck-color-toolbar-background: var(--ck-custom-background);
  --ck-color-toolbar-border: var(--ck-custom-border);

  /* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

  --ck-color-tooltip-background: hsl(252, 7%, 14%);
  --ck-color-tooltip-text: hsl(0, 0%, 93%);

  /* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

  --ck-color-image-caption-background: hsl(0, 0%, 97%);
  --ck-color-image-caption-text: hsl(0, 0%, 20%);

  /* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

  --ck-color-widget-blurred-border: hsl(0, 0%, 87%);
  --ck-color-widget-hover-border: hsl(43, 100%, 68%);
  --ck-color-widget-editable-focus-background: var(--ck-custom-white);

  /* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

  --ck-color-link-default: hsl(190, 100%, 75%);
}

.selection-count {
  color: #959393;
  font-size: 14px;
}

.word-count {
  color: v-bind(colorCharCount);
  font-size: 14px;
  text-align: right;
  padding: 0 12px;
}
@media only screen and (max-width: 1300px) {
  :root {
    --ck-toolbar-dropdown-max-width: 250px;
  }
}
@media only screen and (min-width: 1441px) {
  :root {
    --editor-size: 850px;
  }
  .articleContentWidth,
  .editor {
    max-width: 900px;
  }
  .q-splitter .articleContentWidth,
  .q-splitter .editor {
    max-width: 100%;
  }
  .sidebar {
    display: block;
  }
}
</style>
