<template>
  <q-card bordered>
    <q-toolbar class="bg-primary text-white">
      <q-tabs
        v-model="tab"
        align="left"
        dense
        inline-label
        narrow-indicator
        shrink
        stretch
      >
        <q-tab
          :name="RoutesName.EditArticle"
          :label="$t('article.tabs.article')"
        />
        <q-tab
          v-if="!isHistory && $q.screen.lt.sm"
          :name="RoutesName.NotificationsArticle"
          :class="flashNotificationTabs ? 'flash' : ''"
          :label="
            $t('article.tabs.notifications', { count: notificationsCount })
          "
        />
        <q-tab
          :name="RoutesName.AssociatedArticles"
          :label="
            $t('article.tabs.enrichissements', {
              interestCount: article.settings.map(
                (p) => p.idType == SettingsType.Interest
              ).length,
              associatedArticlesCount: article.associatedArticles.length,
            })
          "
        />
        <q-tab
          :name="RoutesName.Media"
          :label="
            $t('article.tabs.medias', {
              count: countMedias,
            })
          "
        />
        <q-tab
          :name="RoutesName.HistoryArticle"
          :label="$t('article.tabs.historique')"
        />
        <q-tab
          v-if="!isHistory"
          :name="RoutesName.LogsArticle"
          :label="$t('article.tabs.logs')"
          :icon="logAlert ? 'warning' : undefined"
        />
        <q-tab
          v-if="!isHistory"
          :name="RoutesName.LocksArticle"
          :label="lockTabName"
        />
      </q-tabs>
      <q-space />
      <q-btn
        v-if="!isHistory && $q.screen.gt.xs"
        flat
        href="#notifications"
        :label="
          $q.screen.gt.sm
            ? $t('article.tabs.notifications', { count: notificationsCount })
            : undefined
        "
        icon="comment"
      />
      <q-btn
        flat
        :label="$q.screen.gt.sm ? $t('article.tabs.parametres') : undefined"
        icon="settings"
        @click="showPanelRight = !showPanelRight"
      />
    </q-toolbar>
    <q-separator />
    <q-tab-panels
      v-model="tab"
      animated
      :keep-alive="true"
      style="min-height: 200px"
      :class="{ 'tabs-without-overflow': tab === RoutesName.HistoryArticle }"
    >
      <q-tab-panel :name="RoutesName.EditArticle">
        <FormArticle
          v-if="!articleLoadingVisible && article.id != 0"
          :default-article="article"
          @submit="actions.onEditArticle"
        />
        <template v-if="article.id !== 0">
          <q-card v-show="$q.screen.gt.xs" id="notifications" flat>
            <q-card-section>
              <div class="text-subtitle1">
                {{
                  $t("article.tabs.notifications", {
                    count: notificationsCount,
                  })
                }}
              </div>
            </q-card-section>
            <q-card-section>
              <NotificationsArticle :id="article.id" />
            </q-card-section>
          </q-card>
        </template>
        <q-inner-loading
          :showing="articleLoadingVisible"
          :label="$t('messages.waiting')"
          label-class="text-teal"
          label-style="font-size: 1.1em"
        />
      </q-tab-panel>
      <q-tab-panel :name="RoutesName.HistoryArticle">
        <HistoryArticle :histories="articleHistories" />
        <q-inner-loading
          :showing="historiesLoadingVisible"
          :label="$t('messages.waiting')"
          label-class="text-teal"
          label-style="font-size: 1.1em"
        />
      </q-tab-panel>
      <q-tab-panel :name="RoutesName.NotificationsArticle">
        <NotificationsArticle :id="article.id" />
      </q-tab-panel>
      <q-tab-panel :name="RoutesName.LogsArticle">
        <LogsArticle :id="article.id" />
      </q-tab-panel>
      <q-tab-panel :name="RoutesName.LocksArticle">
        <LocksArticle :publication="article.publication" :locks="locks" />
      </q-tab-panel>
      <q-tab-panel :name="RoutesName.AssociatedArticles">
        <AssociatedArticles />
      </q-tab-panel>
      <q-tab-panel :name="RoutesName.Media">
        <Medias :medias-props="getMedias()" />
      </q-tab-panel>
    </q-tab-panels>
    <DialogModifySaveState />
  </q-card>
</template>

<script setup lang="ts">
import {
  ArticleHistoryDto,
  ElcanoNotificationType,
  MediaDto,
  SettingsType,
} from "@/types/api";
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { articleService } from "@/services/ArticleService";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { useArticleActions } from "@/composables/useArticleActions";
import { usePage } from "@/composables/usePage";
import FormArticle from "@/components/Article/FormArticle.vue";
import HistoryArticle from "@/components/Article/HistoryArticle.vue";
import NotificationsArticle from "@/components/Article/NotificationsArticle.vue";
import LogsArticle from "@/components/Article/LogsArticle.vue";
import LocksArticle from "@/components/Article/LocksArticle.vue";
import Medias from "@/components/Article/Medias.vue";
import DialogModifySaveState from "@/components/Article/DialogModifySaveState.vue";
import { RoutesName } from "@/router/routesName";
import AssociatedArticles from "@/components/Article/AssociatedArticles.vue";
import { useI18n } from "vue-i18n";
import { commentService } from "@/services/CommentService";
import { signalRService } from "@/services/SignalrService";

const {
  currentArticle: article,
  locks,
  logAlert,
} = storeToRefs(useCurrentArticleStore());
const route = useRoute();
const router = useRouter();
const { name } = useRoute();
const $q = useQuasar();
const i18n = useI18n();
const tab = ref<string>(name as string);
const notificationsCount = ref<number>(0);
const historiesLoadingVisible = ref<boolean>(true);
const articleHistories = ref<ArticleHistoryDto[]>([]);
const { loading: articleLoadingVisible, actions } = useArticleActions();
const { showPanelRight } = usePage();
const lockTabName = computed(() => `${locks.value.length} 🔒`);
const isHistory = ref(false);
const flashNotificationTabs = ref(false);
// eslint-disable-next-line @typescript-eslint/no-empty-function
let removeAfterEachHook: (() => void) | undefined = undefined;
let currentArticleId: string | string[] = "";
let currentRevisionId: string | undefined = "";

const medias = computed<Map<number, MediaDto>>(() => {
  return new Map(
    article.value.contents.flatMap((c) => c.medias).map((e) => [e.id, e])
  );
});

const filteredMediasId = computed<number[]>(() => {
  return [...new Set(medias.value.keys())];
});

const countMedias = computed<number>(() => {
  if (article.value.contents.every((c) => c.medias.length == 0)) return 0;

  return filteredMediasId.value.length;
});

onMounted(() => {
  removeAfterEachHook = router.afterEach((to) => {
    if (to.name != RoutesName.EditArticle) {
      return;
    }
    tab.value = route.name as string;
    loadArticles();
  });
  loadArticles();
});

onUnmounted(() => {
  if (removeAfterEachHook) removeAfterEachHook();
  unsubcribeToNotificationUpdate();
});

/**
 * Charge l'article.
 */
function loadArticles() {
  if (
    currentArticleId === route.params.id &&
    currentRevisionId === route.query.revisionId
  )
    return;

  unsubcribeToNotificationUpdate();
  currentArticleId = route.params.id;
  currentRevisionId = route.query.revisionId?.toString();

  loadNotificationsCount(true);
  subcribeToNotificationUpdate();
  const articleId = parseInt(route.params.id as string);
  actions.loadArticle(articleId, currentRevisionId);
}

/**
 * Charge le nombre de notification.
 */
async function loadNotificationsCount(firstLoad = false) {
  const thread = await commentService.getCommentThread(
    `article:${currentArticleId}:notifications`,
    `${currentArticleId}`
  );
  notificationsCount.value = thread.comments.length;
  if (firstLoad === true) return;
  if (tab.value === RoutesName.NotificationsArticle) return;

  $q.notify({
    message: i18n.t("article.messages.newNotifications"),
    color: "teal",
    timeout: 2000,
  });

  if (thread.comments.length > 0) flashNotificationTabs.value = true;
  setTimeout(() => {
    flashNotificationTabs.value = false;
  }, 2000);
}

/**
 * Ecoute les notifications.
 */
function subcribeToNotificationUpdate() {
  signalRService.subscribeToMessageType(
    `article:${currentArticleId}`,
    ElcanoNotificationType.ArticleGeneralComment,
    loadNotificationsCount
  );
}

/**
 * Ecoute les notifications.
 */
function unsubcribeToNotificationUpdate() {
  signalRService.unsubscribeToMessageType(
    `article:${currentArticleId}`,
    ElcanoNotificationType.ArticleGeneralComment,
    loadNotificationsCount
  );
}

/**
 * Récupères les medias liés aux contents.
 */
function getMedias() {
  return filteredMediasId.value
    .map((id) => medias.value.get(id))
    .filter(Boolean) // Empêche d'avoir des valeurs undefined et typescript va gueuler mais Olivier valide !
    .map((e) => JSON.parse(JSON.stringify(e))) as MediaDto[];
}

watch(tab, (newVal) => {
  if (newVal !== RoutesName.HistoryArticle) return;
  historiesLoadingVisible.value = true;
  articleService.histories(route.params.id as string).then((histories) => {
    articleHistories.value = histories;
    historiesLoadingVisible.value = false;
  });
});
watch(logAlert, (newVal) => {
  if (!newVal) return;
  $q.notify({
    message: i18n.t("article.errors.import"),
    color: "warning",
    position: "top",
  });
});
</script>
<style lang="scss">
.tabs-without-overflow {
  overflow: visible !important;
}

.tabs-without-overflow > div.scroll {
  overflow: inherit !important;
}

.flash {
  animation: flash 2s;
}

@keyframes flash {
  0% {
    background-color: var(--q-primary);
  }
  25% {
    background-color: var(--q-secondary);
  }
  50% {
    background-color: var(--q-primary);
  }
  75% {
    background-color: var(--q-secondary);
  }
  100% {
    background-color: var(--q-primary);
  }
}
</style>
