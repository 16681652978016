import type { CrudServiceInstance } from "@/types/crud";
import type { RightDto } from "@/types/api";
import type { RightServiceInstance } from "@/types/right";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un objet type droit
 */
export function createRight(params: Partial<RightDto> = {}): RightDto {
  return {
    id: params?.id ?? "",
    idGroup: params?.idGroup ?? 0,
    order: params?.order ?? 0,
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
  };
}

/**
 * Service droit pour récupérer les droits et les modifier.
 */
class RightService extends AxiosRequest implements RightServiceInstance {
  crudService: CrudServiceInstance<RightDto>;

  /**
   * Constructeur de la class Right service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<RightDto>(rootPath);
  }

  /**
   * Liste les doits
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre de droits affichés par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer un droit par son id
   * @param {string} id - id du droit à récupérer
   */
  getSingle(id: string) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer un nouveau droit
   * @param right - données du nouveau droit à créer
   */
  insert(right: RightDto) {
    return this.crudService.insert(right);
  }

  /**
   * Met à jour un droit
   * @param right - nouvelles données du droit à mettre à jour
   */
  update(right: RightDto) {
    return this.crudService.update(right.id, right);
  }

  /**
   * Supprime un droit
   * @param id - id du droit à supprimer
   */
  delete(id: string) {
    return this.crudService.delete(id);
  }
}

export const rightService = new RightService("rights");
