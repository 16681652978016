import type { EventBus } from "quasar";
import { debounce, useQuasar } from "quasar";
import { ref, inject, watch } from "vue";
import { createGlobalState } from "@vueuse/core";
import { useRouter } from "vue-router";

/**
 *
 */
export const usePage = createGlobalState(() => {
  const bus = inject<EventBus>("bus");
  const router = useRouter();
  const showPanelLeft = ref(false);
  const showPanelRight = ref(false);
  const panelLeftMini = ref(false);
  const $q = useQuasar();

  router.beforeResolve(() => {
    showPanelRight.value = false;
  });

  const actions = {
    onToggleMenuLeft() {
      showPanelLeft.value = !showPanelLeft.value;
    },
    onToggleMenuRight() {
      showPanelRight.value = !showPanelRight.value;
    },
    onTogglePanelLeftMini() {
      showPanelLeft.value = !showPanelLeft.value;
    },
  };
  watch([showPanelLeft, showPanelRight, panelLeftMini], () => {
    if (bus) bus.emit("resize");
  });

  window.addEventListener(
    "resize",
    debounce(function () {
      if ($q.screen.lt.sm) {
        panelLeftMini.value = true;
      }
    }, 300 /*ms to wait*/)
  );

  return {
    showPanelLeft,
    showPanelRight,
    panelLeftMini,
    actions,
  };
});
