<template>
  <q-card>
    <q-card-section>
      <q-table
        :rows="formatsFilteredByPublication"
        :columns="columns"
        :grid="$q.screen.xs"
        :filter="filter"
        :title="$t('format.labels.lblTitle')"
        table-header-class="header_table"
        separator="cell"
        bordered
        flat
        hide-bottom
        :rows-per-page-options="[0]"
        @row-click="onRowClick"
      >
        <template #top-left>
          <q-select
            v-model="publication"
            :options="publicationOptions"
            label="Publication"
            filled
            emit-value
            map-options
            style="width: 250px"
          />
        </template>
        <template #top-right>
          <q-input
            v-model="filter"
            borderless
            dense
            debounce="300"
            :placeholder="$t('format.fields.shortSearch')"
          >
            <template #append>
              <q-icon name="search" />
            </template>
          </q-input>
        </template>
        <template #body-cell-active="props">
          <q-td :props="props">
            <q-icon v-if="props.value" name="done" />
            <q-icon v-else name="close" />
          </q-td>
        </template>
        <template #item="props">
          <QTableGridItem
            v-slot="slotProps"
            :columns="props.cols"
            @click="actions.editFormat(props.row.id)"
          >
            <span v-if="slotProps.columnName != 'active'">{{
              slotProps.columnValue
            }}</span>
            <div v-else>
              <q-icon v-if="slotProps.columnValue" name="done" />
              <q-icon v-else name="close" />
            </div>
          </QTableGridItem>
        </template>
      </q-table>
    </q-card-section>
  </q-card>
</template>
<script lang="ts" setup>
import type { QTableColumn } from "quasar";
import type { FormatDto } from "@/types/api";
import { ref, computed } from "vue";
import { useFormatStore } from "@/stores/format";
import { useFormatActions } from "@/composables/useFormatActions";
import { i18n } from "@/i18n";
import QTableGridItem from "@/components/QTableGridItem.vue";
import { getPublicationOptions } from "@/helpers/publication";

const formatStore = useFormatStore();
const { filterFormatsByPublication } = formatStore;
const { formats } = formatStore;
const publicationOptions = getPublicationOptions();
const publication = ref<string>();
const filter = ref("");
const formatsFilteredByPublication = computed<FormatDto[]>(() => {
  if (!publication.value) return formats;

  return filterFormatsByPublication(publication.value);
});
const columns: QTableColumn<FormatDto>[] = [
  {
    name: "id",
    label: i18n.t("format.fields.shortId"),
    align: "left",
    field: (row) => row.id,
  },
  {
    name: "labelFr",
    label: i18n.t("format.fields.shortLabel"),
    sortable: true,
    align: "left",
    field: (row) => row.labelFr,
  },
  {
    name: "active",
    label: i18n.t("format.fields.shortActive"),
    align: "center",
    sortable: true,
    field: (row) => row.active,
  },
  {
    name: "long",
    label: i18n.t("format.fields.shortLong"),
    align: "center",
    sortable: true,
    field: (row) => row.long,
  },
];
const { actions } = useFormatActions();
/**
 * Fonction appelé lors d'un click sur une des lignes du tableau des formats
 */
function onRowClick(evt: Event, row: FormatDto) {
  actions.editFormat(row.id);
}
</script>
