<template>
  <template v-if="$q.screen.gt.sm">
    <template v-for="slotName in allItems">
      <slot v-if="$slots[slotName]" :name="slotName" />
    </template>
  </template>
  <template v-else>
    <div class="float-left">
      <template v-for="slotName in nonResponsiveItems">
        <slot v-if="$slots[slotName]" :name="slotName" />
      </template>
    </div>

    <q-btn-dropdown
      v-if="$q.screen.gt.sm == false"
      :icon="icon"
      :color="color"
      class="q-ml-sm"
      content-style="max-width: 320px"
      persistent
    >
      <q-list>
        <template v-for="(slotName, idx) in responsiveItems" :key="idx">
          <q-item v-if="$slots[slotName]">
            <q-item-section>
              <slot :name="slotName" />
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </q-btn-dropdown>
  </template>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps<{
  items: string[];
  label: string;
  icon: string;
  color: string;
}>();

const filterItems = (value: string) => {
  if (value === "")
    return props.items.map((item) => {
      const [name] = item.split(":");
      return name;
    });
  return props.items
    .filter((item) => {
      const [, itemValue] = item.split(":");
      return itemValue === value;
    })
    .map((item) => {
      const [name] = item.split(":");
      return name;
    });
};

const responsiveItems = computed<string[]>(() => filterItems("responsive"));
const nonResponsiveItems = computed<string[]>(() =>
  filterItems("notresponsive")
);
const allItems = computed<string[]>(() => filterItems(""));
</script>
