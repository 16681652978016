import type { PublicationDto } from "@/types/api";
import type { PublicationViewActions } from "@/types/publication";
import { toRefs, reactive } from "vue";
import { useRouter } from "vue-router";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { createPublication } from "@/services/PublicationService";
import { usePublicationStore } from "@/stores/publication";
import { RoutesName } from "@/router/routesName";
import { usePage } from "@/composables/usePage";
import { i18n } from "@/i18n";

/**
 * Interface des états pour les actions publication
 */
interface PublicationActionState {
  publication: PublicationDto;
  callbackOnSubmit: ((publicationData: PublicationDto) => void) | undefined;
}

/**
 * Fonction servant à interagir sur les différentes vues publication
 */
export const usePublicationActions = createGlobalState(() => {
  const $q = useQuasar();
  const router = useRouter();
  const { addPublication, findPublication, updatePublication } =
    usePublicationStore();
  const { showPanelRight } = usePage();
  const state = reactive<PublicationActionState>({
    publication: createPublication(),
    callbackOnSubmit: undefined,
  });

  const publicationActions: PublicationViewActions = {
    createPublication() {
      showPanelRight.value = true;
      state.publication = createPublication();
      state.callbackOnSubmit = publicationActions.onCreatePublication;
    },
    findPublication(publicationId: string) {
      findPublication(`${publicationId}`)
        .then((pub) => {
          state.publication = pub;
          state.callbackOnSubmit = publicationActions.onEditPublication;
        })
        .catch((err: Error) => {
          $q.notify(err.message);
          router.push({ name: RoutesName.Publications });
        });
    },
    editPublication(publicationId: string) {
      showPanelRight.value = true;
      publicationActions.findPublication(publicationId);
    },
    onCreatePublication(publicationData: PublicationDto) {
      addPublication(publicationData)
        .then(() => {
          showPanelRight.value = false;
          $q.notify(i18n.t("publication.messages.created"));
          router.push({ name: RoutesName.Publications });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
    onEditPublication(publicationData: PublicationDto) {
      updatePublication(publicationData)
        .then(() => {
          $q.notify(i18n.t("publication.messages.updated"));
          router.push({ name: RoutesName.Publications });
        })
        .catch((err: Error) => $q.notify(err.message));
    },
  };

  return { ...toRefs(state), actions: publicationActions };
});
