<template>
  <q-expansion-item
    v-model="expanded"
    class="rounded-borders shadow-1 overflow-hidden q-mb-md"
    switch-toggle-side
    :label="$t(titleKey, [articlesRef.length])"
  >
    <template #header>
      <q-item-section>
        <div class="text-h6">
          {{ $t(titleKey) }}
          <q-badge
            rounded
            align="middle"
            color="primary"
            :label="articlesRef.length"
          />
        </div>
      </q-item-section>
      <q-item-section side>
        <router-link
          v-if="articles.length > 0"
          :to="{
            name: RoutesName.Preview,
            params: {
              articleIds: articles.map((a) => a.id).join(','),
            },
            query: { lang: language },
          }"
          target="_blank"
        >
          <q-btn
            round
            size="sm"
            color="primary"
            icon="print"
            class="float-right"
            @click.stop
          >
            <q-tooltip v-if="$q.screen.gt.xs" :delay="200">
              {{ $t("home.actions.printArticlesTooltip") }}
            </q-tooltip></q-btn
          >
        </router-link>
      </q-item-section>
    </template>
    <q-card class="rounded-borders">
      <q-card-section>
        <q-list separator>
          <template v-for="article in articlesRef" :key="article.id">
            <slot name="row" v-bind="article">
              <HomeArticleRow
                v-bind="$props"
                :article="article"
                @remove-article="removeArticle"
              />
            </slot>
          </template>
        </q-list>
      </q-card-section>
    </q-card>
  </q-expansion-item>
</template>

<script setup lang="ts">
import { computed, ref, toRefs } from "vue";
import HomeArticleRow from "@/components/Home/HomeArticleRow.vue";
import { ArticleDto, ContributionType, WorkflowState } from "@/types/api";
import { RoutesName } from "@/router/routesName";
import { FrenchLanguage } from "@/constants";
import { HomeRowStyle } from "@/types/home";

const props = defineProps<{
  titleKey: string;
  contributionType?: ContributionType | null;
  state?: WorkflowState | null;
  setFreelanceAmount?: boolean | null;
  showSigns?: boolean | null;
  showContributor?: ContributionType[] | null;
  showPublication?: boolean | null;
  showTranslation?: boolean | null;
  articles: ArticleDto[];
  getClasses?: ((article: ArticleDto) => HomeRowStyle) | null;
}>();
const { articles: articlesRef } = toRefs(props);

const privateExpanded = ref<boolean | null>(null);

const expanded = computed({
  get() {
    return privateExpanded.value != null
      ? privateExpanded.value
      : props.articles.length <= 5;
  },
  set(newValue) {
    privateExpanded.value = newValue;
  },
});

const language = computed(() => {
  if (props.articles.length === 0) return FrenchLanguage;
  return props.articles[0].idMainLanguage;
});

/**
 * Suppression d'un article de la liste
 */
function removeArticle(article: ArticleDto) {
  const index = props.articles.findIndex((a) => a.id === article.id);
  if (index >= 0) {
    articlesRef.value.splice(index, 1);
  }
}
</script>
