<template>
  <q-select
    ref="select"
    v-model="inputSelect"
    :disable="disable"
    :label="label"
    :options="options"
    map-options
    emit-value
    stack-label
    filled
    multiple
    use-input
    use-chips
    input-debounce="50"
    :option-disable="optionDisable"
    :class="props.class"
    @filter="filterInput"
    @add="handleInput"
  >
    <template #selected-item="scope">
      <slot name="selected-item" :scope="scope">
        <q-chip
          removable
          dense
          square
          :tabindex="scope.tabindex"
          :text-color="scope.opt.value.color"
          :label="scope.opt.label"
          @remove="scope.removeAtIndex(scope.index)"
        />
      </slot>
    </template>
  </q-select>
</template>

<script setup lang="ts">
import type { QSelect, QSelectOption } from "quasar";
import { useQuasar } from "quasar";
import { ref, toRefs } from "vue";
import { useVModel } from "@vueuse/core";
import { removeDiacritics } from "@/utils";

const props = withDefaults(
  defineProps<{
    modelValue: unknown[];
    options: QSelectOption<unknown>[];
    label?: string;
    class?: string | string[] | Record<string, unknown>;
    optionDisable?: (item: QSelectOption<unknown>) => boolean;
    disable?: boolean;
  }>(),
  {
    label: undefined,
    class: "",
    options: () => [],
    optionDisable: () => false,
  }
);

const $q = useQuasar();
const select = ref<QSelect | undefined>(undefined);
const { options } = toRefs(props);

const emit = defineEmits(["update:modelValue"]);
const inputSelect = useVModel(props, "modelValue", emit);

/**
 * Fonction qui sert a filtrer dans les options
 */
function filterInput(val, update) {
  if (val === "") {
    update(() => {
      options.value = props.options;
    });
    return;
  }

  update(() => {
    const needle = removeDiacritics(val).toLowerCase();
    options.value = props.options.filter(
      (v) => removeDiacritics(v.label).toLowerCase().indexOf(needle) > -1
    );
  });
}

/**
 * Fonction qui sert a cacher le popup
 */
function handleInput() {
  clearInputSelect();
  if (!select.value || $q.screen.gt.xs) return;
  select.value.hidePopup();
}

/** Vide le champ de recherche du select item. */
function clearInputSelect() {
  select.value?.updateInputValue("");
}
</script>
