import type { CrudServiceInstance } from "@/types/crud";
import type { SectionDto } from "@/types/api";
import type { SectionServiceInstance } from "@/types/section";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un object type section
 */
export function createSection(params: Partial<SectionDto> = {}): SectionDto {
  return {
    id: params?.id ?? 0,
    active: params?.active ?? false,
    main: params?.main ?? false,
    idBackOffice: params?.idBackOffice ?? 0,
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
    sectionPublications: params?.sectionPublications ?? [],
    weight: params?.weight,
  };
}

/**
 * Service rubrique pour récupérer les rubriques et les modifier.
 */
class SectionService extends AxiosRequest implements SectionServiceInstance {
  crudService: CrudServiceInstance<SectionDto>;

  /**
   * Constructeur de la class Section service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<SectionDto>(rootPath);
  }

  /**
   * Liste les rubriques
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre de rubriques affichées par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer une rubrique par son id
   * @param {number | string} id - id de la rubrique à récupérer
   */
  getSingle(id: string | number) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer une nouvelle rubrique
   * @param section - données de la nouvelle rubrique à créer
   */
  insert(section: SectionDto) {
    return this.crudService.insert(section);
  }

  /**
   * Met à jour une rubrique
   * @param section - nouvelles données de la rubrique à mettre à jour
   */
  update(section: SectionDto) {
    return this.crudService.update(section.id, section);
  }
}

export const sectionService = new SectionService("sections");
