import type { AxiosResponse } from "axios";
import type { CrudServiceInstance } from "@/types/crud";
import { ArticleLockDto } from "@/types/api";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";
import { LockServiceInstance } from "@/types/lock";
import { getModificationTypeFromInt } from "@/components/Article/constant";
import { i18n } from "@/i18n";

/**
 * Permet d'avoir la traduction des types de modification.
 */
export function getTypeLock(i: number) {
  return getModificationTypeFromInt(i)
    .map((m) => i18n.t("modificationTypes." + m))
    .join(", ");
}

/**
 * Service pour récupérer les locks sur les articles.
 */
class LockService extends AxiosRequest implements LockServiceInstance {
  crudService: CrudServiceInstance<ArticleLockDto>;
  /**
   * Constructeur de la class Lock service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<ArticleLockDto>(rootPath);
  }

  /**
   * Liste les locks pour un ensemble d'articles.
   * @param {number} ids - Identifiant des articles.
   */
  getList(ids: number[]) {
    return this.axiosInstance
      .post(`${this.rootPath}/byArticleIds`, ids)
      .then((response: AxiosResponse<ArticleLockDto[]>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const lockService = new LockService("locks");
