import type { WorkflowTrigger, WorkflowFireResult } from "@/types/api";
import { toRefs, reactive } from "vue";
import { articleService } from "@/services/ArticleService";
import { formatErrorMessage } from "@/utils";
import { EventBus, Notify } from "quasar";
import { i18n } from "@/i18n";
import { TriggerArticleParam } from "@/types/article";

/**
 *
 */
interface TriggerState {
  triggers: WorkflowTrigger[];
  triggersOption: {
    trigger: WorkflowTrigger;
    disabled: boolean;
  }[];
  loading: boolean;
  newState: WorkflowFireResult | null;
}

/**
 *
 */
export function useTriggers(bus: EventBus | undefined) {
  const state = reactive<TriggerState>({
    triggers: [],
    triggersOption: [],
    loading: false,
    newState: null,
  });
  const actions = {
    getTriggers(articleId: number, lang: string) {
      state.loading = true;
      state.triggers = [];
      state.triggersOption = [];

      return articleService
        .getTriggers(articleId, lang)
        .then((triggers) => {
          state.triggers = triggers.valid;
          state.triggersOption = triggers.valid.map((trigger) => ({
            trigger,
            disabled: false,
          }));
          state.triggersOption.push(
            ...triggers.invalid.map((trigger) => ({
              trigger,
              disabled: true,
            }))
          );
        })
        .finally(() => (state.loading = false));
    },
    sendTrigger(
      param: TriggerArticleParam,
      callback?: (newState: WorkflowFireResult | undefined) => void,
      suffixKey?: string
    ) {
      state.loading = true;
      return articleService
        .triggerArticle(param)
        .then((result) => {
          state.newState = result;
          state.triggers = result.triggers ?? [];

          let key = `article.helpOnNewStates.${state.newState.state}${suffixKey}`;
          let message = i18n.t(key);

          if (key === message && suffixKey) {
            key = `article.helpOnNewStates.${state.newState.state}`;
            message = i18n.t(key);
          }

          if (key === message)
            message = i18n.t("article.helpOnNewStates.Saved");

          Notify.create({
            type: "info",
            message,
          });

          bus?.emit(`article:stateChange`, result);

          if (callback) callback(state.newState);
        })
        .catch((e) => {
          const message = formatErrorMessage(e, "article.errors.onTrigger");
          Notify.create({
            type: "warning",
            message,
          });

          if (callback) callback(undefined);
        })
        .finally(() => (state.loading = false));
    },
  };

  return { ...toRefs(state), actions };
}
