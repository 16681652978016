<template>
  <PageWithPanel>
    <template #panelRight>
      <div v-if="articleShowing.id != 0">
        <PanelEditArticle />
      </div>
      <div v-else class="q-pa-md">
        <FormSimpleArticle :key="formSimpleArticle" @submit="onCreate" />
      </div>
      <q-inner-loading
        :showing="loading"
        :label="$t('messages.waiting')"
        label-class="text-teal"
        label-style="font-size: 1.1em"
      />
    </template>
    <PlanningLongFormat />
  </PageWithPanel>
</template>

<script setup lang="ts">
import { type ArticleDto } from "@/types/api";
import { ref } from "vue";
import { onBeforeRouteUpdate } from "vue-router";
import { storeToRefs } from "pinia";
import { useArticleActions } from "@/composables/useArticleActions";
import { RoutesName } from "@/router/routesName";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import FormSimpleArticle from "@/components/Article/FormSimpleArticle.vue";
import PanelEditArticle from "@/components/Article/PanelEditArticle.vue";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import PlanningLongFormat from "@/components/PlanningLongFormat/PlanningLongFormat.vue";

const { loading, actions } = useArticleActions();
const currentArticleStore = useCurrentArticleStore();
const { currentArticle: articleShowing } = storeToRefs(currentArticleStore);

let formSimpleArticle = ref<number>(0);

onBeforeRouteUpdate((to) => {
  if (to.name !== RoutesName.EditArticle) currentArticleStore.initArticle();
});

/**
 *
 */
function onCreate(article: ArticleDto) {
  actions.onCreateArticle(article, () => {
    formSimpleArticle.value += 1;
  });
}
</script>
