import { AxiosResponse } from "axios";
import type { ImportServiceInstance } from "@/types/import";
import { AxiosRequest } from "@/services/ElcanoAPI";

/**
 *
 */
function handlePromise(response: AxiosResponse) {
  if (response.data) return Promise.resolve(response.data);

  return Promise.reject(false);
}

/**
 * Service article pour récupérer les articles et les modifier.
 */
class ImportService extends AxiosRequest implements ImportServiceInstance {
  /**
   * Constructeur de la class Article service
   */
  constructor(public rootPath: string) {
    super();
  }

  /**
   * Relance les imports en attente ou en echec
   */
  retryImport(id: string): Promise<boolean> {
    return this.axiosInstance
      .post(`${this.rootPath}/retryImport/${id}`)
      .then((response) => {
        return response.status === 200;
      })
      .catch(this.handleError);
  }

  /**
   * Lance l'importation d'article
   */
  importArticles(
    publication: string,
    dateMin: string,
    dateMax: string,
    onlyUpdatedArticles: boolean
  ): Promise<string> {
    const request = {
      pubSId: publication,
      dateMin: dateMin.replaceAll("/", "-"),
      dateMax: dateMax.replaceAll("/", "-"),
      onlyUpdated: onlyUpdatedArticles,
    };
    return this.axiosInstance
      .post(`${this.rootPath}/importArticles`, request)
      .then(handlePromise)
      .catch(this.handleError);
  }

  /**
   * Lance l'importation d'article par leur identifiant
   */
  importArticlesIds(ids: number[]): Promise<string> {
    return this.axiosInstance
      .post(`${this.rootPath}/importArticlesIds`, ids)
      .then(handlePromise)
      .catch(this.handleError);
  }

  /**
   * Lance l'exportation d'un article
   */
  exportArticles(
    publication: string,
    dateMin: string,
    dateMax: string,
    languageId: string
  ): Promise<string> {
    const request = {
      pubSId: publication,
      dateMin: dateMin.replaceAll("/", "-"),
      dateMax: dateMax.replaceAll("/", "-"),
      languageId,
    };
    return this.axiosInstance
      .post(`${this.rootPath}/exportArticles`, request)
      .then(handlePromise)
      .catch(this.handleError);
  }

  /**
   * Lance l'importation d'illustration
   */
  importIllustrations(): Promise<string> {
    return this.axiosInstance
      .post(`${this.rootPath}/importIllustrations`)
      .then(handlePromise)
      .catch(this.handleError);
  }

  /**
   * Lance l'importation des enrichissements
   */
  importAssociatedArticles(): Promise<string> {
    return this.axiosInstance
      .post(`${this.rootPath}/importAssociatedArticles`)
      .then(handlePromise)
      .catch(this.handleError);
  }

  /**
   * Lance l'importation des intérêts
   */
  importArticlesSettings(): Promise<boolean> {
    return this.axiosInstance
      .post(`${this.rootPath}/importArticlesSettings`)
      .then((response) => {
        return response.status === 200;
      })
      .catch(this.handleError);
  }

  /**
   * Récupère les infos d'un process d'import
   */
  getInfos(id: string): Promise<string> {
    return this.axiosInstance
      .post(`${this.rootPath}/getImportState/${id}`)
      .then(handlePromise)
      .catch(this.handleError);
  }
}

export const importService = new ImportService("import");
