<template>
  <q-dialog ref="dialogRef" persistent @hide="onDialogHide">
    <div class="q-dialog-plugin">
      <q-card>
        <q-card-section>
          <div class="text-h6">
            {{ $t("articleTweet.popupUpdate.title") }}
          </div>
        </q-card-section>
        <q-card-section>
          <q-input
            v-model="tweetRef"
            class="articleContentWidth"
            type="textarea"
            maxlength="256"
            counter
            filled
            autogrow
            :label="$t('article.fields.tweet')"
            :rules="[(val) => val.length < 256 || $t('rules.maxlength', [256])]"
            :input-style="{
              fontFamily: 'NotoSerif-Regular',
              fontSize: '20px',
              lineHeight: '1.6',
            }"
          />
        </q-card-section>
        <q-card-section class="q-gutter-md on-right">
          <q-btn
            color="secondary"
            :label="$t('articleTweet.labels.btnCancel')"
            @click="onDialogCancel()"
          />
          <q-btn
            color="primary"
            :label="$t('articleTweet.labels.btnSave')"
            @click="onSaveTweet()"
          />
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { ref } from "vue";
import { articleService } from "@/services/ArticleService";
import { formatErrorMessage } from "@/utils";

const $q = useQuasar();

const { dialogRef, onDialogCancel, onDialogOK, onDialogHide } =
  useDialogPluginComponent();

const props = defineProps<{
  articleId: number;
  xmin: number;
  tweet: string;
  language: string;
}>();

defineEmits([...useDialogPluginComponent.emits]);
const tweetRef = ref(props.tweet);

/**
 * Sauvegarde le montant pour la contribution
 */
function onSaveTweet() {
  articleService
    .updateTweet(props.articleId, props.xmin, props.language, tweetRef.value)
    .then(() => onDialogOK())
    .catch((err) => {
      $q.notify({
        type: "negative",
        message: formatErrorMessage(err, "articleTweet.errors.onSaveTweet"),
      });
    });
}
</script>
<style lang="scss" scoped>
@media screen and (min-width: 600px) {
  .q-dialog-plugin {
    width: 70vw;
    min-width: 550px;
    max-width: 900px;
  }
}
</style>
