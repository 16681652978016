<!-- eslint-disable vue/attribute-hyphenation -->
<template>
  <q-dialog
    ref="dialogRef"
    persistent
    :maximized="$q.screen.lt.sm"
    medium
    @hide="onDialogHide"
  >
    <q-card style="width: 1000px; max-width: 1500px">
      <q-card-section class="q-pa-sm">
        <div>
          <q-stepper
            ref="stepper"
            v-model="step"
            header-nav
            color="primary"
            animated
          >
            <q-step
              :name="1"
              :title="$t('mails.dialogs.titles.generateEmail')"
              icon="settings"
              prefix="1"
              :done="step > 1"
              :header-nav="step > 1"
            >
              <div v-if="showErrorMessage" class="row q-mb-sm">
                <div class="col-12">
                  <q-banner
                    :class="'bg-red-8'"
                    class="text-white"
                    rounded
                    dense
                  >
                    {{ errorMessage }}
                  </q-banner>
                </div>
              </div>
              <div v-if="showWarningNotPublished" class="row q-mb-sm">
                <div class="col-12">
                  <q-banner class="bg-warning text-white" rounded dense>
                    {{ $t("mails.dialogs.messages.warningNotPublished") }}
                  </q-banner>
                </div>
              </div>
              <div class="row q-gutter-sm">
                <q-btn
                  color="primary"
                  :label="$t('mails.dialogs.buttons.btnGenerateEmail')"
                  :loading="loading"
                  @click="generateEmails()"
                />
                <div
                  v-if="isAdminUser"
                  style="margin-left: auto; margin-right: 0"
                >
                  <q-select
                    v-model="adminChoice"
                    :options="adminOptions"
                    filled
                    dense
                  >
                    <template #before>
                      <q-chip square color="primary" text-color="white"
                        >Admin</q-chip
                      ></template
                    >
                    <template #after>
                      <q-btn
                        label="Choisir"
                        color="primary"
                        @click="ChooseAdminOption()"
                      />
                    </template>
                  </q-select>
                </div>
              </div>
              <div class="row">
                <span class="q-mt-md">
                  {{ $t("mails.dialogs.messages.generateEmail") }}
                </span>
              </div>

              <q-stepper-navigation align="right" class="q-gutter-sm">
                <q-btn
                  color="secondary"
                  :label="$t('mails.dialogs.buttons.btnQuit')"
                  @click="onDialogCancel"
                />
                <q-btn
                  color="primary"
                  :label="$t('mails.dialogs.buttons.btnNext')"
                  :disable="loading || !mailGenerated"
                  @click="goToStep2()"
                />
              </q-stepper-navigation>
            </q-step>

            <q-step
              :name="2"
              :title="$t('mails.dialogs.titles.modifySubject')"
              prefix="2"
              :done="step > 2"
              :header-nav="step > 2"
            >
              <div v-if="showErrorMessage" class="row q-mb-sm">
                <div class="col-12">
                  <q-banner
                    :class="'bg-red-8'"
                    class="text-white"
                    rounded
                    dense
                    >{{ errorMessage }}</q-banner
                  >
                </div>
              </div>
              <div v-if="showUpdatedSubjectMessage" class="row q-mb-sm">
                <div class="col-12">
                  <q-banner
                    :class="'bg-green-8'"
                    class="text-white"
                    rounded
                    dense
                    >{{ $t("mails.dialogs.messages.modifiedSubject") }}
                  </q-banner>
                </div>
              </div>
              <div>
                <q-input
                  ref="sujet"
                  v-model="subject"
                  filled
                  dense
                  :label="$t('mails.dialogs.labels.mailSubject')"
                />
              </div>

              <q-stepper-navigation align="right" class="q-gutter-sm">
                <q-btn
                  color="secondary"
                  :label="$t('mails.dialogs.buttons.btnQuit')"
                  @click="onDialogCancel"
                />
                <q-btn
                  color="primary"
                  :label="$t('mails.dialogs.buttons.btnNext')"
                  :disable="loading"
                  @click="goToStep3()"
                />
              </q-stepper-navigation>
            </q-step>

            <q-step
              :name="3"
              :title="$t('mails.dialogs.titles.planifyEmail')"
              prefix="3"
              :header-nav="step > 3"
            >
              <div v-if="showErrorMessage" class="row q-mb-sm">
                <div class="col-12">
                  <q-banner
                    :class="'bg-red-8'"
                    class="text-white"
                    rounded
                    dense
                  >
                    {{ errorMessage }}
                  </q-banner>
                </div>
              </div>
              <div v-if="showPlanifiedMessage" class="row q-mb-sm">
                <div class="col-12">
                  <q-banner
                    :class="'bg-green-8'"
                    class="text-white"
                    rounded
                    dense
                    >{{
                      $t("mails.dialogs.messages.planifiedEmail", {
                        nbContacts: nbContacts,
                      })
                    }}
                  </q-banner>
                </div>
              </div>
              <div class="q-gutter-sm row">
                <div class="col-10">
                  <CustomInputDate
                    ref="publishedOn"
                    v-model="datePlanif"
                    class="col"
                    dense
                    type="datetime-local"
                    showTime
                    :label="$t('mails.dialogs.labels.planifyDate')"
                  />
                </div>
                <div class="col">
                  <q-btn
                    color="primary"
                    :label="$t('mails.dialogs.buttons.btnPlanify')"
                    :disable="showPlanifiedMessage"
                    :loading="loading"
                    @click="planifyMails()"
                  />
                </div>
              </div>

              <q-stepper-navigation align="right">
                <q-btn
                  color="secondary"
                  :label="$t('mails.dialogs.buttons.btnQuit')"
                  @click="onDialogOK"
                />
              </q-stepper-navigation>
            </q-step>
          </q-stepper>
        </div>
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent, date, QSelectOption } from "quasar";
import { ref, onMounted, computed } from "vue";
import CustomInputDate from "@/components/Custom/CustomInputDate.vue";
import { mailService } from "@/services/MailService";
import {
  TypeDeMail,
  ArticleContentDto,
  ArticleSectionDto,
  RightReference,
} from "@/types/api";
import {
  ArticleFormat,
  FrenchLanguage,
  ID_ARTICLE_SETTING_JUST_RELEASED,
} from "@/constants";
import { formatErrorMessage } from "@/utils";
import { i18n } from "@/i18n";
import { authService } from "@/services/AuthService";
import { getSections } from "@/helpers/section";

const { addToDate } = date;

const step = ref(1);
const subject = ref("");
const datePlanif = ref("");
const errorMessage = ref("");
const showErrorMessage = ref(false);
const showWarningNotPublished = ref(false);
const showUpdatedSubjectMessage = ref(false);
const loading = ref(false);
const showPlanifiedMessage = ref(false);
const nbContacts = ref(0);
const mailGenerated = ref(false);
const adminChoice = ref<QSelectOption>();
const isAdminUser = authService.hasRight(RightReference.ADMIN_USER);

let mailTypes: TypeDeMail[] = [];

const adminOptions = computed<QSelectOption<string>[]>(() =>
  mailTypes.map((t) => ({
    label: i18n.t(`mails.types.${t}`),
    value: t,
  }))
);

defineEmits([...useDialogPluginComponent.emits]);

const props = defineProps<{
  content: ArticleContentDto;
  sections: ArticleSectionDto[];
  publishedOn: string;
}>();

const { dialogRef, onDialogCancel, onDialogHide, onDialogOK } =
  useDialogPluginComponent();

onMounted(() => {
  filterMailTypes();

  if (new Date(props.publishedOn) >= new Date()) {
    showWarningNotPublished.value = true;
  }
});

/**
 *
 */
function ChooseAdminOption() {
  if (adminChoice.value) mailTypes = [adminChoice.value.value as TypeDeMail];
}

/**
 *
 */
async function generateEmails() {
  loading.value = true;

  for (let i = 0; i < mailTypes.length; i++) {
    const success = await generateEmailByType(mailTypes[i]);
    if (!success) break;
  }

  loading.value = false;
}

/**
 *
 */
async function generateEmailByType(type: TypeDeMail): Promise<boolean> {
  try {
    await mailService.generateEmail(
      props.content.idBackOffice ?? -1,
      type ?? TypeDeMail.Article_abo
    );
    mailGenerated.value = true;
    return true;
  } catch (err) {
    errorMessage.value = formatErrorMessage(
      err,
      "exceptions.GenerationEmailException.NoMessage"
    );
    errorMessage.value += " Type: " + i18n.t(`mails.types.${type}`);
    showErrorMessage.value = true;
    return false;
  }
}

/**
 *
 */
async function modifySubjects() {
  loading.value = true;

  for (let i = 0; i < mailTypes.length; i++) {
    const success = await modifySubjectByType(mailTypes[i]);
    if (!success) break;
  }

  loading.value = false;
}

/**
 *
 */
async function modifySubjectByType(type: TypeDeMail): Promise<boolean> {
  try {
    await mailService.updateSubject(
      props.content.idBackOffice ?? -1,
      type ?? TypeDeMail.Article_abo,
      subject.value
    );
    showUpdatedSubjectMessage.value = true;
    return true;
  } catch (err) {
    errorMessage.value = formatErrorMessage(
      err,
      "exceptions.GenerationEmailException.NoMessage"
    );
    errorMessage.value += " Type: " + i18n.t(`mails.types.${type}`);
    showErrorMessage.value = true;
    return false;
  }
}

/**
 *
 */
async function planifyMails() {
  loading.value = true;

  for (let i = 0; i < mailTypes.length; i++) {
    const success = await planifyMailByType(mailTypes[i]);
    if (!success) break;
  }

  loading.value = false;
}

/**
 *
 */
async function planifyMailByType(type: TypeDeMail): Promise<boolean> {
  showErrorMessage.value = false;
  showPlanifiedMessage.value = false;

  let calculatedDate = new Date(datePlanif.value);

  if (
    type == TypeDeMail.Alert_prosp ||
    type == TypeDeMail.Dossier_prosp ||
    type == TypeDeMail.Entourage_prosp ||
    type == TypeDeMail.Feuilleton_prosp ||
    type == TypeDeMail.JustReleased_prosp ||
    type == TypeDeMail.Insiders_prosp ||
    type == TypeDeMail.GrandeEnquete_prosp
  ) {
    // Permet de s'assurer que les abos partent avant les prospects
    calculatedDate = addToDate(calculatedDate, { minutes: 5 });
  }

  try {
    nbContacts.value += await mailService.planifyMail(
      props.content.idBackOffice ?? -1,
      type ?? TypeDeMail.Article_abo,
      date.formatDate(calculatedDate, "YYYY-MM-DDTHH:mm")
    );
    showPlanifiedMessage.value = true;
    return true;
  } catch (err) {
    errorMessage.value = formatErrorMessage(
      err,
      "exceptions.GenerationEmailException.NoMessage"
    );
    errorMessage.value += " Type: " + i18n.t(`mails.types.${type}`);
    showErrorMessage.value = true;
    return false;
  }
}

/**
 *
 */
async function goToStep2() {
  showErrorMessage.value = false;
  step.value = 2;

  let sections = getSections(props.sections.map((s) => s.section));

  if (sections.some((s) => s.labelFr.toUpperCase().startsWith("ALERT"))) {
    const language = props.content.language == FrenchLanguage ? "fr" : "en";

    subject.value =
      i18n.t("mails.dialogs.labels.subjectBreakingNews", 1, {
        locale: language,
      }) + props.content.title;
  } else {
    subject.value = props.content.title;
  }
}

/**
 *
 */
async function goToStep3() {
  showErrorMessage.value = false;
  step.value = 3;
  modifySubjects();
  const calculatedDate = addToDate(Date.now(), { minutes: 2 });
  datePlanif.value = date.formatDate(calculatedDate, "YYYY-MM-DDTHH:mm");
}

/**
 *
 */
function filterMailTypes() {
  let sections = getSections(props.sections.map((s) => s.section));

  if (sections.some((s) => s.labelFr.toUpperCase().startsWith("ALERT"))) {
    mailTypes.push(TypeDeMail.Alert_abo);
    mailTypes.push(TypeDeMail.Alert_prosp);
  } else if (
    props.content.settings.some((s) => s.id == ID_ARTICLE_SETTING_JUST_RELEASED)
  ) {
    mailTypes.push(TypeDeMail.JustReleased_abo);
    mailTypes.push(TypeDeMail.JustReleased_prosp);
  } else if (props.content.format == ArticleFormat.Dossier) {
    mailTypes.push(TypeDeMail.Dossier_abo);
    mailTypes.push(TypeDeMail.Dossier_prosp);
  } else if (props.content.format == ArticleFormat.Feuilleton) {
    mailTypes.push(TypeDeMail.Feuilleton_abo);
    mailTypes.push(TypeDeMail.Feuilleton_prosp);
  } else if (props.content.format == ArticleFormat.Insider) {
    mailTypes.push(TypeDeMail.Insiders_abo);
    mailTypes.push(TypeDeMail.Insiders_prosp);
  } else if (props.content.format == ArticleFormat.Enquete) {
    mailTypes.push(TypeDeMail.GrandeEnquete_abo);
    mailTypes.push(TypeDeMail.GrandeEnquete_prosp);
  } else if (props.content.format == ArticleFormat.Entourage) {
    mailTypes.push(TypeDeMail.Entourage_abo);
    mailTypes.push(TypeDeMail.Entourage_prosp);
  } else {
    mailTypes.push(TypeDeMail.Article_abo);
  }
}
</script>
