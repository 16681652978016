import type { CrudServiceInstance } from "@/types/crud";
import type { AssignmentDto } from "@/types/api";
import type { AssignmentServiceInstance } from "@/types/assignment";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un objet type assignment
 */
export function createAssignment(
  params: Partial<AssignmentDto> = {}
): AssignmentDto {
  return {
    id: params?.id ?? "",
    rights: params?.rights ?? [],
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
    redactor: params?.redactor ?? false,
    editor: params?.editor ?? false,
    translator: params?.translator ?? false,
    freelance: params?.freelance ?? false,
    initialConfig: params?.initialConfig ?? false,
  };
}

/**
 * Service assignment pour récupérer les assignments et les modifier.
 */
class AssignmentService
  extends AxiosRequest
  implements AssignmentServiceInstance
{
  crudService: CrudServiceInstance<AssignmentDto>;

  /**
   * Constructeur de la class Assignment service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<AssignmentDto>(rootPath);
  }

  /**
   * Liste les assignments
   * @param {number} page - numéros de la pagination.
   * @param {number} limit - nombre de assignments affichés par page.
   */
  getList(page = 1, limit = 20) {
    return this.crudService.getList(page, limit, true);
  }

  /**
   * Récupérer un assignment par son id
   * @param {string} id - id du assignment à récupérer
   */
  getSingle(id: string) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer un nouveau assignment
   * @param assignment - données du nouveau assignment à créer
   */
  insert(assignment: AssignmentDto) {
    return this.crudService.insert(assignment);
  }

  /**
   * Met à jour un assignment
   * @param assignment - nouvelles données du assignment à mettre à jour
   */
  update(assignment: AssignmentDto) {
    return this.crudService.update(assignment.id, assignment);
  }

  /**
   * Supprime un assignment
   * @param id - id du assignment à supprimer
   */
  delete(id: string) {
    return this.crudService.delete(id);
  }
}

export const assignmentService = new AssignmentService("assignments");
