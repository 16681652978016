import type { AxiosError } from "axios";
import axios from "axios";
import {
  AxiosCacheInstance,
  setupCache,
  buildWebStorage,
} from "axios-cache-interceptor";
import { instanceOfElcanoApiError } from "@/utils";
import { conf_elcanoapi_current } from "@/constants";
export const axiosInstance = axios.create({
  baseURL: conf_elcanoapi_current,
  headers: {
    "Content-Type": "application/json",
  },
});
const axiosCacheInstance = setupCache(axiosInstance, {
  //debug: console.log,
  storage: buildWebStorage(localStorage, "axios-cache:"),
  methods: [],
});

/**
 * class AxiosRequest
 */
export class AxiosRequest {
  public axiosInstance: AxiosCacheInstance;

  /**
   * Constructeur du service
   */
  constructor() {
    this.axiosInstance = axiosCacheInstance;
  }
  /**
   * Permet de gérer de la même manière l'ensemble des requêtes échouées
   * @param reason reason
   * @returns Promise.reject soit false soit les données de l'exception provenant de l'API
   */
  handleError(reason: Error | AxiosError) {
    console.error(reason);
    if (axios.isAxiosError(reason) && reason.code === "ERR_NETWORK") {
      //showAPIUnevailableDialog();
    }
    if (
      axios.isAxiosError(reason) &&
      reason.response &&
      instanceOfElcanoApiError(reason.response.data)
    ) {
      console.log(reason.response.status, reason.response.data);
      return Promise.reject(reason.response.data);
    }

    return Promise.reject(false);
  }
}
