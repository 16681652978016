<template>
  <PageWithPanel :actions="pageActions">
    <template #panelRight>
      <div class="q-pa-md">
        <FormCountry
          v-if="callbackOnSubmit"
          :label-btn-submit="
            $t(
              country.id !== 0
                ? 'country.labels.btnEdit'
                : 'country.labels.btnCreate'
            )
          "
          :default-country="country"
          @submit="callbackOnSubmit"
        />
      </div>
    </template>
  </PageWithPanel>
</template>

<script setup lang="ts">
import type { PageAction } from "@/types/page";
import { computed } from "vue";
import { useCountryActions } from "@/composables/useCountryActions";
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import FormCountry from "@/components/Country/FormCountry.vue";
import { i18n } from "@/i18n";

const { callbackOnSubmit, country, actions } = useCountryActions();

const pageActions = computed<PageAction[]>(() =>
  [
    {
      label: i18n.t("country.labels.btnNew"),
      shortLabel: i18n.t("country.labels.shortBtnNew"),
      icon: "add",
      disable: false,
      handler: actions.createCountry,
      visible: true,
    },
  ].filter((act) => act.visible)
);
</script>
