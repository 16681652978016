<template>
  <q-form class="q-gutter-md column" @submit="$emit('submit', country)">
    <div class="q-ma-none q-gutter-md row items-start">
      <q-input
        ref="labelFr"
        v-model="country.labelFr"
        for="countryLabelFr"
        filled
        :label="$t('country.fields.labelFr')"
        class="col"
        :rules="[(val) => !!val || $t('rules.required')]"
      />
      <q-input
        ref="labelEn"
        v-model="country.labelEn"
        for="countryLabelEn"
        filled
        :label="$t('country.fields.labelEn')"
        class="col"
      />
    </div>
    <div class="q-ma-none q-gutter-md row items-start">
      <q-input
        ref="hashtagFr"
        v-model="country.hashtagFr"
        for="countryHashtagFr"
        filled
        :label="$t('country.fields.hashtagFr')"
        class="col"
      />
      <q-input
        ref="hashtagEn"
        v-model="country.hashtagEn"
        for="countryHashtagEn"
        filled
        :label="$t('country.fields.hashtagEn')"
        class="col"
      />
    </div>
    <div class="q-ma-none q-gutter-md row items-start">
      <q-input
        ref="code"
        v-model="country.code"
        for="countryCode"
        filled
        :label="$t('country.fields.code')"
        class="col"
      />
      <q-input
        ref="isoCode"
        v-model="country.isoCode"
        for="countryIsoCode"
        filled
        :label="$t('country.fields.codeISO')"
        class="col"
      />
    </div>
    <q-checkbox
      ref="active"
      v-model="country.active"
      for="countryActive"
      :label="$t('country.fields.active')"
    />
    <q-checkbox
      ref="realCountry"
      v-model="country.realCountry"
      for="countryRealCountry"
      :label="$t('country.fields.realCountry')"
    />
    <div class="q-mt-md q-gutter-md text-right">
      <q-btn :label="labelBtnSubmit" type="submit" color="primary" />
    </div>
  </q-form>
</template>
<script lang="ts" setup>
import type { PropType } from "vue";
import type { CountryDto } from "@/types/api";
import { toRefs } from "vue";
import { i18n } from "@/i18n";
import { createCountry } from "@/services/CountryService";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("country.labels.btnEdit");
    },
  },
  defaultCountry: {
    type: Object as PropType<CountryDto>,
    default() {
      return createCountry();
    },
  },
});
defineEmits<{
  (e: "submit", value: CountryDto): void;
}>();
const { defaultCountry: country } = toRefs(props);
</script>
