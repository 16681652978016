import type { VueKeycloakInstance } from "@/types/keycloak";
import { inject, toRefs } from "vue";
import { KeycloakSymbol } from "./index";

export const useKeycloak = () => {
  const defaultKeycloak = {
    ready: false,
    authenticated: false,
  };
  const keycloak = inject<VueKeycloakInstance>(KeycloakSymbol, defaultKeycloak);

  return toRefs(keycloak);
};
