/**
 * HomeRowStyle
 */
export enum HomeRowStyle {
  /**
   * Default
   */
  Default = "Default",
  /**
   * Success
   */
  Success = "Success",
  /**
   * Error
   */
  Error = "Error",
  /**
   * Warning
   */
  Warning = "Warning",
}
