import type {
  CommentDto,
  CommentThreadDto,
  UpdateCommentDto,
} from "@/types/api";
import type { CommentServiceInstance } from "@/types/comment";
import { AxiosRequest } from "@/services/ElcanoAPI";
import type { AxiosResponse } from "axios";

/**
 * Service pour récupérer la liste des utilisateurs
 */
class CommentService extends AxiosRequest implements CommentServiceInstance {
  rootPath: string;
  /**
   * Constructeur de la class Comment service
   */
  constructor(rootPath: string) {
    super();
    this.rootPath = rootPath;
  }

  /**
   *
   */
  getCommentThread(
    threadId: string,
    channelId: string
  ): Promise<CommentThreadDto> {
    return this.axiosInstance
      .get(`${this.rootPath}/thread/${threadId}/${channelId}`)
      .then((response: AxiosResponse<CommentThreadDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  addComment(comment: CommentDto): Promise<CommentDto> {
    return this.axiosInstance
      .post(`${this.rootPath}`, comment)
      .then((response: AxiosResponse<CommentDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  addCommentThread(thread: CommentThreadDto): Promise<CommentThreadDto> {
    return this.axiosInstance
      .post(`${this.rootPath}/thread`, thread)
      .then((response: AxiosResponse<CommentThreadDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  updateComment(comment: UpdateCommentDto): Promise<CommentDto> {
    return this.axiosInstance
      .put(`${this.rootPath}/${String(comment.commentId)}`, comment)
      .then((response: AxiosResponse<CommentDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  updateCommentThread(
    thread: Omit<CommentThreadDto, "comments" | "resolvedAt" | "resolvedBy">
  ): Promise<CommentThreadDto> {
    return this.axiosInstance
      .put(
        `${this.rootPath}/thread/${thread.threadId}/${thread.channelId}`,
        thread
      )
      .then((response: AxiosResponse<CommentThreadDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  resolveCommentThread(
    threadId: string,
    channelId: string
  ): Promise<CommentThreadDto> {
    return this.axiosInstance
      .post(`${this.rootPath}/thread/${threadId}/${channelId}/resolve`)
      .then((response: AxiosResponse<CommentThreadDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  reopenCommentThread(
    threadId: string,
    channelId: string
  ): Promise<CommentThreadDto> {
    return this.axiosInstance
      .post(`${this.rootPath}/thread/${threadId}/${channelId}/reopen`)
      .then((response: AxiosResponse<CommentThreadDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  removeCommentThread(
    threadId: string,
    channelId: string
  ): Promise<CommentThreadDto> {
    return this.axiosInstance
      .delete(`${this.rootPath}/thread/${threadId}/${channelId}`)
      .then((response: AxiosResponse<CommentThreadDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
  /**
   *
   */
  async removeComment(commentId: string) {
    return this.axiosInstance
      .delete(`${this.rootPath}/${commentId}`)
      .then((response: AxiosResponse<CommentDto>) => {
        return response.data;
      })
      .catch(this.handleError);
  }
}

export const commentService = new CommentService("comments");
