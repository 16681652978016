<template>
  <q-card bordered>
    <q-card-section>
      <div class="q-mb-sm">
        <q-btn
          v-if="
            results.some((a) =>
              a.contents.some((s) => s.status == ReportStatus.ToReplace)
            )
          "
          :label="$t('searchAndReplace.labels.btnProcessReport')"
          color="primary"
          @click="processReport"
        />
        <q-btn
          v-if="
            results.some((a) =>
              a.contents.some((s) => s.status == ReportStatus.Error)
            )
          "
          :label="$t('searchAndReplace.labels.btnProcessArticlesInError')"
          color="primary"
          class="q-ml-sm"
          @click="processArticlesInError"
        />
      </div>
      <div class="noResults">
        <span v-if="results.length == 0">
          {{ $t("searchAndReplace.labels.noResults") }}
        </span>
      </div>

      <q-list dense>
        <q-item v-for="(r, idx) in results" :key="idx">
          <q-item-section class="q-mb-sm">
            <q-card>
              <q-card-section class="bg-grey-2">
                <div class="fit row justify-start items-center content-start">
                  <div>
                    <router-link
                      :to="{
                        name: RoutesName.EditArticle,
                        params: { id: r.idArticle },
                      }"
                      target="_blank"
                    >
                      <q-chip
                        square
                        dense
                        icon="tag"
                        clickable
                        style="font-size: 0.8rem"
                        :label="r.idArticle.toString()"
                      />
                    </router-link>
                  </div>
                  <div class="q-ml-sm">
                    <strong>{{ r.contents[0].title }}</strong>
                  </div>
                  <div style="margin-left: auto">
                    <img
                      :alt="FrenchLanguage"
                      class="img-flag"
                      :src="getFlagPath(FrenchLanguage)"
                    />
                    <q-chip :class="getCssClass(r, FrenchLanguage)" square>
                      {{ getStatus(r, FrenchLanguage) }}
                    </q-chip>
                    <img
                      :alt="EnglishLanguage"
                      class="img-flag"
                      :src="getFlagPath(EnglishLanguage)"
                    />
                    <q-chip :class="getCssClass(r, EnglishLanguage)" square>
                      {{ getStatus(r, EnglishLanguage) }}
                    </q-chip>
                    <q-toggle
                      v-if="
                        r.contents[0].status == ReportStatus.ToReplace ||
                        r.contents[0].status == ReportStatus.NotToReplace
                      "
                      v-model="r.toggled"
                      :label="$t('searchAndReplace.labels.toggleToReplace')"
                      align="right"
                      @update:model-value="toggleStatus(r, $event)"
                    />
                  </div>
                </div>
              </q-card-section>
              <q-separator />
              <q-card-section>
                <q-list>
                  <q-item v-for="sn in r.contents[0].snippets" :key="sn">
                    <q-item-section>
                      <div v-html="getSanitizedSnippet(sn)"></div>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-card-section>
            </q-card>
          </q-item-section>
        </q-item>
      </q-list>
      <div class="q-pa-lg flex flex-center">
        <q-pagination
          v-model="pagination"
          :min="1"
          :max="pageMax"
          @update:model-value="loadResult()"
        />
      </div>
    </q-card-section>
  </q-card>
</template>

<script setup lang="ts">
import { reportService } from "@/services/reportService";
import {
  ResultReportSearchAndReplaceDto,
  ParamProcessReplacement,
  ReportStatus,
  ParamUpdateResultModel,
  ReportSearchAndReplaceDto,
} from "@/types/api";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import { RoutesName } from "@/router/routesName";
import { getFlagPath, EnglishLanguage, FrenchLanguage } from "@/constants";
import { useReportActions } from "@/composables/useReportActions";
import * as Diff from "diff";
import { i18n } from "@/i18n";

/**
 *
 */
interface MixedResultReportSearchAndReplaceDto {
  toggled: boolean;
  idArticle: number;
  contents: ResultReportSearchAndReplaceDto[];
}

const route = useRoute();
const { actions } = useReportActions();
const results = ref<MixedResultReportSearchAndReplaceDto[]>([]);
const pagination = ref(1);
const pageMax = ref(1);

let report: ReportSearchAndReplaceDto;

onMounted(async () => {
  report = await reportService.getReport(route.params.id as string);
  loadResult();
});

/**
 *
 */
function getSanitizedSnippet(sn: string) {
  let snReplaced = sn.replaceAll(report.searchedText, report.replacingText);
  let finalSn = sn;

  const diff = Diff.diffWordsWithSpace(sn, snReplaced);

  diff.forEach((part) => {
    if (part.added) {
      finalSn = finalSn.replace(
        part.value,
        `<ins><span class="bg-green-4 text-white">${part.value}</span></ins>`
      );
    }
    if (part.removed) {
      finalSn = finalSn.replace(
        part.value,
        `<del><span class="bg-red-4 text-white">${part.value}</span></del>`
      );
    }
  });

  return finalSn;
}

/**
 *
 */
function getCssClass(
  result: MixedResultReportSearchAndReplaceDto,
  language: string
) {
  let content = result.contents.find((c) => c.language == language);

  if (!content) return "";

  switch (content.status) {
    case ReportStatus.ToReplace:
      return "bg-green-4";
    case ReportStatus.NotToReplace:
      return "bg-red-6 text-white";
    case ReportStatus.Error:
      return "bg-red-6 text-white";
    case ReportStatus.Exported:
      return "bg-green-4";
  }
}

/**
 *
 */
function getStatus(
  result: MixedResultReportSearchAndReplaceDto,
  language: string
) {
  let content = result.contents.find((c) => c.language == language);

  if (!content) return ReportStatus.NotToReplace;

  return i18n.t(`searchAndReplace.status.${content.status}`);
}

/**
 *
 */
function toggleStatus(val: MixedResultReportSearchAndReplaceDto, evt) {
  let newStatus = evt ? ReportStatus.ToReplace : ReportStatus.NotToReplace;
  let oldStatus = evt ? ReportStatus.NotToReplace : ReportStatus.ToReplace;

  let idArticle = val.idArticle;

  const param: ParamUpdateResultModel = {
    idReport: report.id,
    idArticle: idArticle,
    status: newStatus,
  };
  reportService.updateResultReportStatus(param);
  val.toggled = evt;
  val.contents.forEach((c) => {
    if (c.status == oldStatus) c.status = newStatus;
  });
}

/**
 *
 */
async function loadResult() {
  const resultsDb = await reportService.getResults(report.id, pagination.value);
  pageMax.value = resultsDb.pageMax;

  let groupedResults = resultsDb.results.reduce((acc, curr) => {
    if (!acc[curr.idArticle]) {
      acc[curr.idArticle] = {
        contents: [],
        idArticle: curr.idArticle,
        toggled: curr.status == ReportStatus.ToReplace,
      };
    }

    acc[curr.idArticle].contents.push(curr);

    return acc;
  }, {});

  results.value = Object.values(groupedResults);
}

/**
 *
 */
function processReport() {
  const param: ParamProcessReplacement = {
    idReport: report.id,
    statusToProcess: ReportStatus.ToReplace,
  };
  actions.processReport(param);
}

/**
 *
 */
function processArticlesInError() {
  const param: ParamProcessReplacement = {
    idReport: report.id,
    statusToProcess: ReportStatus.Error,
  };
  actions.processReport(param);
}
</script>
<style lang="scss" scoped>
.noResults {
  font-weight: 600;
  text-align: center;
}

.img-flag {
  width: 24px;
  height: 24px;
  margin: auto 0px;
  vertical-align: middle;
}
</style>
