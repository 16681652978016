<template>
  <div class="text-h6">
    {{ i18n.t("contribution.freelances.titles.parameters") }}
  </div>
  <q-separator />
  <div
    class="fit row wrap justify-start items-start content-start q-gutter-md q-mt-md"
  >
    <InputDate
      v-model="selectedDateLock"
      type="date"
      add-day
      dense
      :label="$t('contribution.freelances.labels.inputDate')"
      style="max-width: 500px; width: 500px"
    />
    <div class="row items-center q-ml-lg-xl">
      <span>
        {{ i18n.t("contribution.freelances.titles.freelanceChoice") }}</span
      >
      <q-select
        v-model="selectedFreelance"
        :options="contributorsFiltered"
        emit-value
        map-options
        use-input
        filled
        dense
        clearable
        input-debounce="50"
        autocomplete="mainLabel"
        class="q-ml-md"
        style="max-width: 320px; width: 320px"
        @filter="filterInput"
      >
      </q-select>
      <span class="q-ml-lg-xl">{{
        i18n.t("contribution.freelances.titles.lockYear")
      }}</span>
      <q-select
        v-model="selectedYearToLock"
        :options="availableYearsToLockOptions"
        class="q-ml-md"
        style="max-width: 320px; width: 140px"
        emit-value
        map-options
        filled
        dense
        @update:model-value="isYearLocked"
      ></q-select>
      <q-btn
        class="q-ml-sm"
        color="primary"
        :icon="isYearLockedForIcon ? 'lock' : 'lock_open'"
        @click="lockYear()"
      ></q-btn>
    </div>
  </div>

  <div class="q-mt-sm q-mb-sm">
    <div class="text-h6">
      {{ i18n.t("contribution.freelances.titles.actions") }}
    </div>
    <q-separator />
    <div
      class="fit row wrap justify-start items-start content-start q-gutter-md q-mt-md"
    >
      <q-btn color="red" :disable="lockAsked" @click="lockContributions">{{
        i18n.t("contribution.freelances.buttons.lock")
      }}</q-btn>
      <q-btn color="green" :disable="!lockAsked" @click="unlockContributions">
        {{ i18n.t("contribution.freelances.buttons.unlock") }}
      </q-btn>
      <q-separator vertical />
      <InputDate
        v-model="selectedDateProcessed"
        type="date"
        add-day
        dense
        label="Date de traitement"
        style="max-width: 500px; width: 500px"
      />
      <q-btn color="blue" :disable="!lockAsked" @click="setAsProcessed">
        {{ i18n.t("contribution.freelances.buttons.setProcessed") }}
      </q-btn>
    </div>
  </div>

  <div class="q-mt-md">
    <div class="text-h6">Infos:</div>
    <q-separator class="q-mb-md" />
    <p>
      {{
        lockAsked ? lockInfo : i18n.t("contribution.freelances.labels.noLocks")
      }}
    </p>
    <q-table
      :rows="freelanceContributions"
      :columns="columns"
      :grid="$q.screen.xs"
      table-header-class="header_table"
      bordered
      wrap-cells
      flat
      :rows-per-page-options="[50, 100, 0]"
    >
      <template #body-cell-title="{ row }">
        <q-td>
          <router-link
            :to="{
              name: RoutesName.EditArticle,
              params: { id: row.idArticle },
            }"
            target="_blank"
          >
            {{ row.title }}
          </router-link>
        </q-td>
      </template>
    </q-table>
  </div>

  <div class="q-mt-xl">
    <div class="text-h6">
      {{ $t("contribution.freelances.titles.caseOfError") }}
    </div>
    <q-separator class="q-mb-md" />
    <div
      class="fit row wrap justify-start items-center content-start q-gutter-md q-mt-md"
    >
      <div>
        {{ i18n.t("contribution.freelances.labels.cancelProcessOnArticle") }}
      </div>
      <q-input
        v-model.number="inputIdBackOffice"
        type="number"
        filled
        dense
        :placeholder="$t('article.fields.idBackOffice')"
      />
      <div>ou</div>
      <q-input
        v-model.number="inputIdWorkflowOrContent"
        type="number"
        filled
        dense
        :placeholder="$t('article.fields.idWorkflowOrContent')"
      />
      <q-btn color="blue" @click="cancelProcessing">
        {{ i18n.t("contribution.freelances.buttons.cancelProcessed") }}
      </q-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import InputDate from "@/components/Custom/CustomInputDate.vue";
import { ref, computed, onMounted } from "vue";
import { QSelectOption, QTableColumn } from "quasar";
import { i18n } from "@/i18n";
import { removeDiacritics, getToday, upcastDatetime } from "@/utils";
import { useContributionActions } from "@/composables/useContributionActions";
import {
  ContributionParamDto,
  ContributionItemDto,
  CancelProcessingParamDto,
} from "@/types/api";
import { useUserStore } from "@/stores/user";
import { RoutesName } from "@/router/routesName";

const selectedDateLock = ref<string>(getToday().toLocaleISOString());
const selectedDateProcessed = ref<string>(getToday().toLocaleISOString());
const inputIdBackOffice = ref<number>();
const inputIdWorkflowOrContent = ref<number>();

const { actions } = useContributionActions();

const columns: QTableColumn<ContributionItemDto>[] = [
  {
    name: "title",
    label: i18n.t("article.fields.title"),
    field: (row) => row.title,
    align: "left",
  },
  {
    name: "freelanceName",
    label: i18n.t("contributionType.Freelancer"),
    field: (row) => row.freelanceName,
    align: "left",
  },
  {
    name: "amount",
    label: i18n.t("articleContribution.fields.amount"),
    field: (row) => row.amount,
    align: "left",
  },
];

const lockInfo = computed<string>(() => {
  return i18n.t("contribution.freelances.labels.lock", [
    nbContributions.value,
    amount.value,
  ]);
});

const amount = ref<number>(0);
const nbContributions = ref<number>(0);

const userStore = useUserStore();
const freelanceContributors = computed<QSelectOption<string>[]>(() => {
  return userStore.getAllFreelances.map((usr) => ({
    value: usr.id,
    label: usr.name,
  }));
});

const contributorsFiltered = ref<QSelectOption<string>[]>(
  freelanceContributors.value
);

const availableYearsToLock = (function () {
  let year = getToday().getFullYear();

  return [year - 2, year - 1, year, year + 1, year + 2];
})();

const availableYearsToLockOptions = computed<QSelectOption<number>[]>(() => {
  return availableYearsToLock.map((usr) => ({
    value: usr,
    label: usr.toString(),
  }));
});

const selectedFreelance = ref<string>();
const selectedYearToLock = ref<number>();
const isYearLockedForIcon = ref<boolean>();

const lockAsked = ref<boolean>(false);
let freelanceContributions = ref<ContributionItemDto[]>([]);

onMounted(async () => {
  const result = await actions.getLockedContributions();
  selectedYearToLock.value = availableYearsToLock[1];
  isYearLocked();

  if (result.nbContributions > 0) {
    freelanceContributions.value = result.contributions;
    nbContributions.value = result.nbContributions;
    amount.value = result.amount;
    lockAsked.value = true;
  }
});

/**
 * Verrouille l'année
 */
async function lockYear() {
  if (!selectedYearToLock.value) return;

  console.log(selectedYearToLock.value, isYearLockedForIcon.value);

  if (isYearLockedForIcon.value) {
    isYearLockedForIcon.value = !(await actions.unlockYear(
      selectedYearToLock.value
    ));
  } else {
    isYearLockedForIcon.value = await actions.lockYear(
      selectedYearToLock.value
    );
  }
}

/**
 * Vérifie si l'année est verrouillée
 */
async function isYearLocked() {
  if (!selectedYearToLock.value) return;
  isYearLockedForIcon.value = await actions.isYearLocked(
    selectedYearToLock.value
  );
}

/**
 * Verrouille les piges
 */
async function lockContributions() {
  const param: ContributionParamDto = {
    untilDate: upcastDatetime(selectedDateLock.value) ?? "",
    idFreelance: selectedFreelance.value,
  };

  const result = await actions.lockContributionsToBeProcessed(param);

  if (result) {
    freelanceContributions.value = result.contributions;
    nbContributions.value = result.nbContributions;
    amount.value = result.amount;
    lockAsked.value = true;
  }
}

/**
 * Déverrouille les piges.
 */
async function unlockContributions() {
  lockAsked.value = !(await actions.unlockContributionsBeingProcessed());
  freelanceContributions.value = [];
}

/**
 * Marque les piges comme traitées.
 */
async function setAsProcessed() {
  lockAsked.value = !(await actions.setContributionsAsProcessed(
    upcastDatetime(selectedDateProcessed.value) ?? ""
  ));
  freelanceContributions.value = [];
}

/**
 *
 */
async function cancelProcessing() {
  if (
    inputIdBackOffice.value === undefined &&
    inputIdWorkflowOrContent.value === undefined
  )
    return;

  const param: CancelProcessingParamDto = {};
  if (typeof inputIdWorkflowOrContent.value === "number")
    param.idArticle = inputIdWorkflowOrContent.value;
  if (typeof inputIdBackOffice.value === "number")
    param.idBackOffice = inputIdBackOffice.value;

  await actions.removeProcessedDateOnContributions(param);
  inputIdBackOffice.value = undefined;
  inputIdWorkflowOrContent.value = undefined;
}

/**
 * Fonction qui sert a filtrer dans les options
 */
function filterInput(val, update) {
  if (val === "") {
    update(() => {
      contributorsFiltered.value = freelanceContributors.value;
    });
    return;
  }

  update(() => {
    const needle = removeDiacritics(val).toLowerCase();
    contributorsFiltered.value = freelanceContributors.value.filter(
      (v) => removeDiacritics(v.label).toLowerCase().indexOf(needle) > -1
    );
  });
}
</script>
