import type { FormatState } from "@/types/format";
import { defineStore } from "pinia";
import { formatService } from "@/services/FormatService";
import { FormatDto } from "@/types/api";
import { usePublicationStore } from "@/stores/publication";
import { i18n } from "@/i18n";

export const useFormatStore = defineStore({
  id: "format",
  state: (): FormatState => ({
    formats: [],
  }),
  getters: {
    groupByPublications(state) {
      const publicationStore = usePublicationStore();
      return publicationStore.publications.map((publication) => ({
        ...publication,
        formats: state.formats.filter((format) =>
          format.formatPublications.some(
            (p) => p.publication === publication.id
          )
        ),
      }));
    },
  },
  actions: {
    filterFormatsByPublication(publicationId: string, formatActive = false) {
      return this.formats.filter((f) =>
        f.formatPublications.some(
          (p) =>
            p.publication === publicationId &&
            (!formatActive || p.active === formatActive)
        )
      );
    },
    async fetchFormats() {
      this.formats = await formatService.getList();
    },
    findFormat(id: string): FormatDto {
      const format = this.formats.find((f) => f.id === id);
      if (format) return format;
      else throw new Error(i18n.t("format.errors.notFound"));
    },
    async addFormat(newFormat: FormatDto) {
      try {
        const format = await formatService.insert(newFormat);
        this.formats.push(format);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("format.errors.create"));
      }
    },
    async updateFormat(format: FormatDto) {
      try {
        const formatUpdated = await formatService.update(format);
        const formatIndex = this.formats.findIndex((f) => f.id === format.id);
        this.formats[formatIndex] = formatUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("format.errors.update"));
      }
    },
  },
});
