<template>
  <q-list
    bordered
    class="rounded-borders"
    :class="!Dark.isActive ? 'bg-white' : ''"
  >
    <q-item-label header>Droits</q-item-label>
    <q-expansion-item
      v-for="(group, idx) in rightsByGroup"
      :key="'group' + idx"
      :label="isFr ? group.labelFr : group.labelEn"
      expand-separator
    >
      <q-list>
        <q-item v-for="(right, index) in group.rights" :key="'right' + index">
          <q-item-section>
            <q-item-label>{{
              isFr ? right.labelFr : right.labelEn
            }}</q-item-label>
          </q-item-section>
          <q-item-section side>
            <slot name="actions" v-bind="right"></slot>
          </q-item-section>
        </q-item>
      </q-list>
    </q-expansion-item>
  </q-list>
</template>
<script setup lang="ts">
import { Dark } from "quasar";
import { useLocale } from "@/utils";
import { useUserStore } from "@/stores/user";

const { rightsByGroup } = useUserStore();
const { isFr } = useLocale();
</script>
