import type { CrudServiceInstance } from "@/types/crud";
import type { ProfileDto, ProfileAssignmentDto } from "@/types/api";
import type { ProfileServiceInstance } from "@/types/profile";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Fonction pour créer un objet type profile
 */
export function createProfile(params: Partial<ProfileDto> = {}): ProfileDto {
  return {
    id: params?.id ?? "",
    labelFr: params?.labelFr ?? "",
    labelEn: params?.labelEn ?? "",
    profileAssignments: params?.profileAssignments ?? [],
    initialConfig: params?.initialConfig ?? false,
  };
}
/**
 * Fonction pour créer un objet type profileAssignment
 */
export function createProfileAssignment(
  params: Partial<ProfileAssignmentDto> = {}
): ProfileAssignmentDto {
  return {
    id: params?.id ?? 0,
    idAssignment: params?.idAssignment ?? "",
    sections: params?.sections ?? [],
    idPublication: params?.idPublication ?? undefined,
    initialConfig: params?.initialConfig ?? false,
  };
}

/**
 * Service pour récupérer la liste des profiles
 */
class ProfileService extends AxiosRequest implements ProfileServiceInstance {
  crudService: CrudServiceInstance<ProfileDto>;
  /**
   * Constructeur de la class Profile service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<ProfileDto>(rootPath);
  }
  /**
   * Récupère la liste des profiles
   */
  getList() {
    return this.crudService.getList(undefined, undefined, true);
  }

  /**
   * Récupérer un profile par son id
   * @param {string} id - id du profile à récupérer
   */
  getSingle(id: string) {
    return this.crudService.getSingle(id);
  }

  /**
   * Créer un nouveau profile
   * @param profile - données du nouveau profile à créer
   */
  insert(profile: ProfileDto) {
    return this.crudService.insert(profile);
  }

  /**
   * Met à jour un profile
   * @param profile - nouvelles données du profile à mettre à jour
   */
  update(profile: ProfileDto) {
    return this.crudService.update(profile.id, profile);
  }

  /**
   * Supprime un profile
   * @param id - id du profile à supprimer
   */
  delete(id: string) {
    return this.crudService.delete(id);
  }
}

export const profileService = new ProfileService("profiles");
