export const url_keycloak_default =
  "https://keycloak.test.microsrv.indigo-net.com/";
export const keycloak_clientid_default = "elcano_local";
export const keycloak_realm_default = "indigo";
export const keycloak_redirecturl_default = "https://localhost:3000";
export const elcanoapi_default =
  "https://elcano-api.local.test.microsrv.indigo-net.com:7443";
export const facility_default = "DEV";
export const api_version_compatibility = "1";
export const prolexis_core_path = "/DiagPlWs";

export const conf_url_keycloak_current = applyConfigOrSetDefault(
  window.conf_url_keycloak,
  url_keycloak_default
);
export const conf_keycloak_clientid_current = applyConfigOrSetDefault(
  window.conf_keycloak_clientid,
  keycloak_clientid_default
);
export const conf_keycloak_realm_current = applyConfigOrSetDefault(
  window.conf_keycloak_realm,
  keycloak_realm_default
);
export const conf_keycloak_redirecturl_current = applyConfigOrSetDefault(
  window.conf_keycloak_redirecturl,
  keycloak_redirecturl_default
);
export const conf_elcanoapi_current = applyConfigOrSetDefault(
  window.conf_elcanoapi,
  elcanoapi_default
);
export const conf_facility_current = applyConfigOrSetDefault(
  window.conf_facility,
  facility_default
);
export const conf_api_version_compatibility = applyConfigOrSetDefault(
  window.conf_api_version_compatibility,
  api_version_compatibility
);

export const conf_front_only = false;
export const conf_magellanweb_url =
  conf_facility_current === "PROD"
    ? "https://magellanweb.indigo-net.com"
    : "https://3.test.magellanweb.indigo-net.com";

/**
 * Applique la valeur déclarée côté serveur si ne commence pas par "@" sinon la valeur par défaut
 */
function applyConfigOrSetDefault(configKey: unknown, defaultValue: string) {
  return typeof configKey == "string" && !configKey.startsWith("@")
    ? configKey
    : defaultValue;
}

export const prolexis_url_proxy = conf_elcanoapi_current + "/SpellChecking";

export const FrenchLanguage = "fr-FR";
export const EnglishLanguage = "en-EN";

export const ID_ARTICLE_SETTING_SIGNATURE_AUTHOR = 7;
export const ID_ARTICLE_SETTING_SIGNATURE_FREELANCE = 8;
export const ID_ARTICLE_SETTING_JUST_RELEASED = 9;
export const ID_ARTICLE_SETTING_FREE = 1;
export const ID_ARTICLE_SETTING_FREE_ACCESS = 10;
export const ID_ARTICLE_SETTING_UPGRADE_TO_SPOTLIGHT = 512;

export const ID_SECTIONS_ALERTE = [536, 469];

/**
 * Heure après laquelle les traductions en attente de relecture sont considérées comme en erreur
 */
export const ReviewTranslationInErrorAfter = 15;

/**
 * A synchroniser avec la base de données
 */
export const ArticleFormat = {
  Article: "Article",
  ArticleLeader: "Article leader",
  Breve: "Breve",
  ClinDOeil: "Clin d'oeil",
  Dossier: "Dossier",
  EchoVillage: "EchoVillage",
  Enquete: "Enquete",
  Entourage: "Entourage",
  Evenement: "Evenement",
  Feuilleton: "Feuilleton",
  FrontRow: "FrontRow",
  Gazette: "Gazette",
  Insider: "Insider",
  MaitreEspion: "Maitre Espion",
  Mouvement: "Mouvement",
  NouvelleGarde: "NouvelleGarde",
  SecretsPalais: "SecretsPalais",
  SpyWayOfLife: "Spy Way Of Life",
  Video: "Video",
  Watchlist: "Watchlist",
} as const;

let suffixEnglishFlag = 0;
/**
 * Récupère l'url du drapeau du pays
 * @param language Language
 * @returns L'url du drapeau du pays
 */
export function getFlagPath(language: string) {
  if (language === FrenchLanguage || suffixEnglishFlag === 0)
    return `/img/flag-${language}.png`;

  return `/img/flag-${language}-${suffixEnglishFlag}.png`;
}

// fetes national pays anglophone
export const NationalHolidays = {
  "26-1": 2,
  "17-3": 1,
  "30-11": 3,
};

/** Modifier le suffixe du drapeau anglais */
export function changeEnglishFlag() {
  const today = new Date();
  const key = `${today.getDate()}-${today.getMonth() + 1}`;
  if (NationalHolidays[key] !== undefined)
    suffixEnglishFlag = NationalHolidays[key];
  // 1 chance sur 90 de changer le drapeau
  else if (Math.floor(Math.random() * 90) === 0)
    suffixEnglishFlag = Math.ceil(Math.random() * 3);
}
