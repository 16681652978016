<template>
  <q-layout
    :view="'lHh LpR lff'"
    container
    style="min-height: 300px; height: 100%"
    class="shadow-2"
  >
    <q-drawer
      v-model="showPanelLeft"
      show-if-above
      :overlay="false"
      :width="220"
      :mini-width="60"
      :breakpoint="500"
      :mini="panelLeftMini"
      bordered
    >
      <q-scroll-area class="scroll-area">
        <slot name="navigation"></slot>
      </q-scroll-area>

      <div v-if="userProfile" class="absolute-top">
        <q-select
          v-model="config.publication"
          filled
          square
          emit-value
          map-options
          dense
          :hide-dropdown-icon="panelLeftMini"
          :options="publicationOptions"
          bg-color="primary"
        >
          <template #selected>
            <div
              :style="selectPublicationStyles"
              style="color: white; width: 100%"
            >
              {{
                !panelLeftMini
                  ? publicationSelected?.label
                  : publicationSelected?.value
              }}
            </div>
          </template>
        </q-select>
        <div class="profile-wrapper bg-secondary">
          <div class="absolute-center full-width text-center">
            <q-avatar
              v-if="userProfile.firstName && userProfile.lastName"
              color="primary"
              text-color="white"
              class="q-mb-sm"
              :size="panelLeftMini ? 'md' : 'xl'"
            >
              {{ userProfile.firstName[0] + userProfile.lastName[0] }}
            </q-avatar>
            <div v-if="!panelLeftMini" class="text-weight-bold">
              {{ userProfile.firstName }} {{ userProfile.lastName }}
            </div>
          </div>
        </div>
      </div>

      <div class="absolute-bottom text-center q-pa-sm menu-bottom">
        <q-separator class="mobile-only q-mb-sm" />
        <q-btn
          color="red"
          :round="panelLeftMini"
          :label="
            !panelLeftMini ? $t('common.labels.btnDisconnect') : undefined
          "
          icon="logout"
          no-wrap
          :size="panelLeftMini ? 'sm' : undefined"
          @click="$keycloak.logoutFn"
        >
          <q-tooltip
            v-if="panelLeftMini"
            anchor="center right"
            self="center left"
          >
            {{ $t("common.labels.btnDisconnect") }}
          </q-tooltip>
        </q-btn>
      </div>
    </q-drawer>
    <slot></slot>
  </q-layout>
</template>

<script setup lang="ts">
import { watch, StyleValue, computed } from "vue";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { Dark } from "quasar";
import { useConfigStore } from "@/stores/config";
import { useAccountStore } from "@/stores/account";
import { getPublicationOptions } from "@/helpers/publication";
import { usePage } from "@/composables/usePage";
import { authService } from "@/services/AuthService";

const accountStore = useAccountStore();
const { userProfile } = accountStore;
const { locale } = useI18n();
const configStore = useConfigStore();
const { showPanelLeft, panelLeftMini } = usePage();
const { config } = storeToRefs(configStore);

const publicationOptions = getPublicationOptions({
  publicationsActive: true,
  allOption: false,
});
const publicationSelected = computed(() => {
  return publicationOptions.find((p) => p.value === config.value.publication);
});

if (
  !config.value.publication ||
  publicationOptions.every((p) => p.value != config.value.publication)
) {
  if (publicationOptions.length == 1)
    config.value.publication = publicationOptions[0].value;
  else {
    // on recherche la publication la plus adapté a l'utilisateur
    var wantedPub = "";
    if (authService.currentUser) {
      if (authService.currentUser.redactor.length)
        wantedPub = authService.currentUser.redactor[0];

      if (authService.currentUser.translator.length)
        wantedPub = authService.currentUser.translator[0];
    }

    if (publicationOptions.some((p) => p.value === wantedPub))
      config.value.publication = wantedPub;
    else if (publicationOptions.length)
      config.value.publication = publicationOptions[0].value;
  }
}

const selectPublicationStyles = computed<StyleValue>(() => ({
  fontSize: "16px",
  fontWeight: 600,
  textAlign: panelLeftMini.value ? "center" : "left",
}));

watch(locale, (newValue) => {
  if (newValue) configStore.setLocale(newValue as "fr" | "en");
});
</script>

<style lang="scss">
.profile-wrapper {
  position: relative;
  height: 100px;
  background-color: #021652;

  @media screen and (min-width: 1024px) {
    height: 130px;
  }

  > * {
    padding: 16px 8px;
    color: white;
  }
}
.mobile .q-page {
  padding-left: 0;
  padding-right: 0;
}

span[data-user-id] {
  color: dodgerblue !important;
}

.marker-red {
  background-color: #fa6464 !important;
}
.marker-yellow {
  background-color: #ffff00 !important;
}

mark {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
}

.scroll-area {
  height: calc(100% - 190px);
  margin-top: 140px;
  margin-bottom: 0px;

  @media screen and (min-width: 1024px) {
    margin-top: 170px;
    height: calc(100% - 220px);
  }
}

.menu-above-tooltip {
  z-index: 9001;
}

.q-badge--floating.q-badge--left {
  left: -3px;
  right: auto;
}

.body--dark {
  .menu-bottom {
    background-color: $dark;
  }
}

.menu-bottom {
  background-color: white;
}
</style>
