import { toRefs, reactive } from "vue";
import { createGlobalState } from "@vueuse/core";
import { useQuasar } from "quasar";
import { importService } from "@/services/ImportService";
import { i18n } from "@/i18n";

/**
 *
 */
export const useImportActions = createGlobalState(() => {
  const $q = useQuasar();
  const state = reactive<{
    eventIds: Record<string, string>;
  }>({
    eventIds: {},
  });
  const notifSuccess = (message: string) => {
    notif(message, "positive");
  };

  const notifError = (message: string) => {
    notif(message, "negative");
  };

  const notif = (
    message: string,
    type: "positive" | "negative" | "warning" | "info" | "ongoing"
  ) => {
    $q.notify({
      type,
      message,
    });
  };
  const importActions = {
    importArticles(
      publication: string,
      dateMin: string,
      dateMax: string,
      onlyUpdatedArticles: boolean
    ) {
      importService
        .importArticles(publication, dateMin, dateMax, onlyUpdatedArticles)
        .then((eventId) => {
          state.eventIds[eventId] = "";
          notifSuccess(i18n.t("import.messages.importArticles"));
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    importArticlesIds(ids: number[]) {
      importService
        .importArticlesIds(ids)
        .then((eventId) => {
          state.eventIds[eventId] = "";
          notifSuccess(i18n.t("import.messages.importArticles"));
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    importIllustrations() {
      importService
        .importIllustrations()
        .then((eventId) => {
          state.eventIds[eventId] = "";
          notifSuccess(i18n.t("import.messages.importIllustrations"));
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    importArticleSettings() {
      importService
        .importArticlesSettings()
        .then((data) => {
          if (data)
            notifSuccess(i18n.t("import.messages.importArticlesSettings"));
          else notifError(i18n.t("import.errors.failed"));
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    importAssociatedArticles() {
      importService
        .importAssociatedArticles()
        .then((eventId) => {
          state.eventIds[eventId] = "";
          notifSuccess(i18n.t("import.messages.importAssociatedArticles"));
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    retryImport(eventId: string) {
      importService
        .retryImport(eventId)
        .then((data) => {
          if (data) notifSuccess(i18n.t("import.messages.retryImport"));
          else notifError(i18n.t("import.errors.failed"));
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    getInfos(eventId: string) {
      return importService
        .getInfos(eventId)
        .then((data) => {
          state.eventIds[eventId] = JSON.stringify(data, null, 2);
          notifSuccess(i18n.t("import.messages.loadingInfosSuccess"));
          return data;
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    exportArticles(
      publication: string,
      dateMin: string,
      dateMax: string,
      languageId: string
    ) {
      importService
        .exportArticles(publication, dateMin, dateMax, languageId)
        .then((data) => {
          state.eventIds[data] = "";
          notifSuccess(i18n.t("import.messages.exportArticles"));
        })
        .catch(() => notifError(i18n.t("import.errors.failed")));
    },
    removeImport(eventId: string) {
      delete state.eventIds[eventId];
    },
  };

  return { ...toRefs(state), actions: importActions };
});
