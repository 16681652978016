<template>
  <q-form class="q-gutter-y-md column" @submit="$emit('submit', section)">
    <div class="q-gutter-x-md row">
      <q-input
        ref="labelFr"
        v-model="section.labelFr"
        for="sectionLabelFr"
        filled
        :label="$t('section.fields.labelFr')"
        class="col"
        :rules="[(val) => !!val || $t('rules.required')]"
      />
      <q-input
        ref="labelEn"
        v-model="section.labelEn"
        for="sectionLabelEn"
        filled
        :label="$t('section.fields.labelEn')"
        class="col"
      />
    </div>
    <div class="q-gutter-x-md row">
      <q-checkbox
        v-model="section.active"
        for="sectionActif"
        class="col"
        :label="$t('section.fields.active')"
      />
      <q-checkbox
        v-model="section.main"
        for="sectionMain"
        class="col"
        :label="$t('section.fields.main')"
      />
    </div>
    <q-select
      v-model="section.idParent"
      filled
      square
      emit-value
      map-options
      :options="sectionOptions"
      :label="$t('section.fields.parent')"
    />
    <div class="q-gutter-x-md row">
      <q-input
        v-model="section.idBackOffice"
        type="number"
        class="col"
        :label="$t('section.fields.idBackOffice')"
        stack-label
        filled
      />
      <q-input
        v-model="section.weight"
        type="number"
        class="col"
        :label="$t('section.fields.weight')"
        stack-label
        filled
      />
    </div>
    <div class="q-gutter-x-md row">
      <q-input
        ref="backgroundColor"
        v-model="section.backgroundColor"
        for="sectionBackgroundColor"
        filled
        :label="$t('section.fields.backgroundColor')"
        class="col"
      >
        <template #append>
          <q-icon name="colorize" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-color v-model="section.backgroundColor" />
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
      <q-input
        ref="fontColor"
        v-model="section.fontColor"
        for="sectionFontColor"
        filled
        :label="$t('section.fields.fontColor')"
        class="col"
      >
        <template #append>
          <q-icon name="colorize" class="cursor-pointer">
            <q-popup-proxy
              cover
              transition-show="scale"
              transition-hide="scale"
            >
              <q-color v-model="section.fontColor" />
            </q-popup-proxy>
          </q-icon>
        </template>
      </q-input>
    </div>
    <q-btn
      :label="$t('section.labels.btnAddPublication')"
      color="primary"
      @click="showDialogAddPublication = true"
    />
    <q-list v-if="section.sectionPublications.length" separator>
      <q-item-label header>Publications</q-item-label>
      <q-item
        v-for="(pub, index) in section.sectionPublications"
        :key="'publication' + index"
        tag="label"
      >
        <q-item-section>
          <q-item-label>{{
            getLabelPublication(pub.publication)
          }}</q-item-label>
        </q-item-section>
        <q-item-section side top>
          <q-toggle v-model="pub.active" />
        </q-item-section>
      </q-item>
    </q-list>
    <div class="q-mt-md q-gutter-md text-right">
      <q-btn :label="labelBtnSubmit" type="submit" color="primary" />
    </div>
    <DialogAddPublication
      v-model="showDialogAddPublication"
      :selected="section.sectionPublications.map((p) => p.publication)"
      @add:publications="onNewPublications"
    />
  </q-form>
</template>
<script lang="ts" setup>
import type { PropType } from "vue";
import type { SectionDto } from "@/types/api";
import { ref, toRefs } from "vue";
import { i18n } from "@/i18n";
import { createSection } from "@/services/SectionService";
import { usePublicationStore } from "@/stores/publication";
import DialogAddPublication from "@/components/Publication/DialogAddPublication.vue";
import { getSectionOptions } from "@/helpers/section";

const props = defineProps({
  labelBtnSubmit: {
    type: String,
    default() {
      return i18n.t("section.labels.btnEdit");
    },
  },
  defaultSection: {
    type: Object as PropType<SectionDto>,
    default() {
      return createSection();
    },
  },
});
defineEmits<{
  (e: "submit", value: SectionDto): void;
}>();
const sectionOptions = getSectionOptions({
  allOption: false,
});
const showDialogAddPublication = ref<boolean>(false);
const { defaultSection: section } = toRefs(props);
const publicationStore = usePublicationStore();
const { publications } = publicationStore;

/**
 * Fonction appelé lors du choix des publications associés
 */
function onNewPublications(newPublications: string[]) {
  const getActiveStatus = (publicationId: string) => {
    const pub = section.value.sectionPublications.find(
      (p) => p.publication === publicationId
    );
    return pub ? pub.active : true;
  };
  section.value.sectionPublications = newPublications.map((pub) => ({
    publication: pub,
    active: getActiveStatus(pub),
  }));
}

/**
 * Fonction pour récupérer le label de la publication en fonction de l'id
 */
function getLabelPublication(publicationId: string) {
  const pub = publications.find((p) => p.id === publicationId);
  return pub?.name ?? publicationId;
}
</script>
