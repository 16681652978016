<template>
  <q-chip
    v-for="(review, idx) in contribs"
    :key="idx"
    square
    dense
    clickable
    :icon="getIconLeft(review.type)"
    :icon-right="getIconRight(review, locks)"
    style="font-size: 0.8rem"
    :class="getClassFromState(review.state, review.idContributor)"
    :label="getUserName(review.idContributor, review.type)"
  >
    <q-tooltip
      v-if="idx === 0"
      v-model="showTooltip"
      :delay="60_000"
      class="bg-primary text-body2"
    >
      {{ $t("articleContribution.labels.dontForgetToNotify") }}
    </q-tooltip>
    <q-menu
      v-if="
        (state === WorkflowState.Relecture &&
          review.type === ContributionType.Reviewer) ||
        (state === WorkflowState.RelectureTraducteurReferent &&
          review.type === ContributionType.LeadTranslator) ||
        (state === WorkflowState.Importe &&
          review.type === ContributionType.Editor)
      "
    >
      <q-list>
        <q-item
          v-if="review.idContributor === userIdForNewContribution"
          v-close-popup
          clickable
          :disable="baseIsLocked"
          @click="addContribution(review.type)"
        >
          <q-item-section>{{
            review.type === ContributionType.Editor
              ? $t("articleContribution.labels.btnEditorReviewStart")
              : $t("articleContribution.labels.btnStart")
          }}</q-item-section>
        </q-item>
        <q-item
          v-if="
            review.idContributor === authService.getUserId() &&
            review.type !== ContributionType.Editor
          "
          v-close-popup
          clickable
          :disable="lockedByMe || lockedByOther"
          @click="startReview()"
        >
          <q-item-section>{{
            lockedByMe
              ? $t("articleContribution.labels.btnReviewing")
              : $t("articleContribution.labels.btnStart")
          }}</q-item-section>
        </q-item>
        <q-item
          v-if="
            review.idContributor === authService.getUserId() &&
            (review.type === ContributionType.Reviewer ||
              review.type === ContributionType.Editor)
          "
          v-close-popup
          :disable="needToSave"
          clickable
          @click="saveRateReview(review.id, true)"
        >
          <q-item-section>{{
            review.type === ContributionType.Editor
              ? $t("articleContribution.labels.btnEditorReviewOk")
              : $t("articleContribution.labels.btnAccept")
          }}</q-item-section>
        </q-item>
        <q-item
          v-if="
            review.idContributor === authService.getUserId() &&
            (review.type === ContributionType.Reviewer ||
              review.type === ContributionType.Editor)
          "
          v-close-popup
          :disable="needToSave"
          clickable
          @click="saveRateReview(review.id, false)"
        >
          <q-item-section>{{
            review.type === ContributionType.Editor
              ? $t("articleContribution.labels.btnEditorReviewReset")
              : $t("articleContribution.labels.btnReject")
          }}</q-item-section>
        </q-item>
        <q-item
          v-if="
            review.idContributor !== authService.getUserId() &&
            review.idContributor !== userIdForNewContribution &&
            review.type === ContributionType.Reviewer
          "
          v-close-popup
          clickable
          @click="askForNotificationMessage(review.id)"
        >
          <q-item-section>{{
            $t("articleContribution.labels.btnNotify")
          }}</q-item-section>
        </q-item>
        <q-tooltip v-if="getTooltipInfo(review) !== ''">
          {{ getTooltipInfo(review) }}
        </q-tooltip>
      </q-list>
    </q-menu>
  </q-chip>
</template>

<script setup lang="ts">
import { computed, inject, onMounted, onUnmounted, ref, watch } from "vue";
import {
  ContributionDto,
  ContributionType,
  WorkflowState,
  ContributionState,
  ArticleLockDto,
  WorkflowFireResult,
} from "@/types/api";
import { useI18n } from "vue-i18n";
import { authService } from "@/services/AuthService";
import { useUserStore } from "@/stores/user";
import { contributionService } from "@/services/ContributionService";
import { FrenchLanguage } from "@/constants";
import { EventBus, useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { getModificationTypeFromLanguage } from "@/components/Article/constant";

const { insertLock, deleteLock } = useCurrentArticleStore();
const {
  locks,
  lockType,
  modifExist,
  baseIsLocked,
  currentArticle,
  languagesLocked,
} = storeToRefs(useCurrentArticleStore());
const { notify, dialog } = useQuasar();

const bus = inject<EventBus>("bus");
const i18n = useI18n();
const userIdForNewContribution = "NEW_CONTRIBUTION";

let triggerToReview = false;
const showTooltip = ref(false);
const contribs = ref<ContributionDto[]>([]);
const props = defineProps({
  contributions: {
    type: Array<ContributionDto>,
    default() {
      return [];
    },
  },
  state: {
    type: String,
    default: WorkflowState.Relecture,
  },
  language: {
    type: String,
    default: FrenchLanguage,
  },
});

onMounted(() => {
  if (!bus) return;

  bus.on(`article:stateChange`, (newState: WorkflowFireResult) => {
    if (newState.state !== WorkflowState.Relecture) return;
    triggerToReview = true;
  });
});

onUnmounted(() => {
  if (!bus) return;
  bus.off("article:stateChange");
});

const lockedByMe = computed(() => {
  return (
    locks.value.findIndex(
      (l) =>
        l.userId === authService.getUserId() &&
        (l.modificationType & getModificationTypeFromLanguage(props.language)) >
          0
    ) >= 0
  );
});

const lockedByOther = computed(() => {
  return (
    locks.value.findIndex(
      (l) =>
        l.userId !== authService.getUserId() &&
        (l.modificationType & getModificationTypeFromLanguage(props.language)) >
          0
    ) >= 0
  );
});

const needToSave = computed(() => {
  return modifExist.value > 0;
});

updateContribs(props.contributions);
watch(
  () => props.contributions,
  (newVal) => {
    updateContribs(newVal);
  }
);

watch(
  () => props.state,
  (newVal, oldValue) => {
    if (!triggerToReview) return;

    triggerToReview = false;
    if (newVal === oldValue) return;
    if (newVal !== WorkflowState.Relecture) return;

    if (oldValue === WorkflowState.Relecture) return;

    if (contribs.value.length <= 1) return;

    showTooltip.value = true;

    setTimeout(() => {
      showTooltip.value = false;
    }, 5_000);
  }
);

/**
 * On crée une copie des contributions pour ne pas modifier les props
 * Sinon ca provoquerait la modification de l'article
 */
function updateContribs(contributions: ContributionDto[]) {
  const copy = JSON.parse(JSON.stringify(contributions));
  const typeFilter = [ContributionType.Reviewer, ContributionType.Editor];

  if (props.state === WorkflowState.RelectureTraducteurReferent) {
    typeFilter.push(ContributionType.LeadTranslator);
  }

  const filteredCopy = copy.filter(
    (c) => c.idLanguage === props.language && typeFilter.indexOf(c.type) >= 0
  );

  addLeadTranslatorReviewOption(filteredCopy);

  AddEditorReviewOption(filteredCopy);

  contribs.value = filteredCopy;
}

/** Permet d'ajouter une contribution pour permettre au lead translator d'ajouter sa relecture */
function addLeadTranslatorReviewOption(filteredCopy: ContributionDto[]) {
  if (
    props.state === WorkflowState.RelectureTraducteurReferent &&
    !filteredCopy.filter((c) => c.type === ContributionType.LeadTranslator)
      .length &&
    authService.isTranslator(currentArticle.value.publication)
  ) {
    filteredCopy.push({
      id: 0,
      idContributor: userIdForNewContribution,
      idLanguage: props.language,
      state: ContributionState.Unknown,
      type: ContributionType.LeadTranslator,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });
  }
}

/** Permet d'ajouter une contribution pour permettre au SR d'ajouter sa relecture lors du bouclage */
function AddEditorReviewOption(filteredCopy: ContributionDto[]) {
  if (
    props.state === WorkflowState.Importe &&
    !filteredCopy.filter(
      (c) =>
        c.type === ContributionType.Editor &&
        c.idContributor === authService.getUserId()
    ).length &&
    authService.isEditor(currentArticle.value.publication)
  ) {
    filteredCopy.push({
      id: 0,
      idContributor: userIdForNewContribution,
      idLanguage: props.language,
      state: ContributionState.Unknown,
      type: ContributionType.Editor,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    });
  }
}

/** Retourne le message à afficher dans le tooltip */
function getTooltipInfo(review: ContributionDto) {
  // impossible d'ajouter le contributeur si la base est verrouillée
  if (review.idContributor === userIdForNewContribution && baseIsLocked.value)
    return i18n.t("articleContribution.errors.cannotEditParams");

  // les actions pour le relecteur necessite la sauvegarde avant d'être effectuées
  // Accepter / A retravailler
  if (
    review.type === ContributionType.Reviewer ||
    review.type === ContributionType.Editor
  ) {
    if (review.idContributor === authService.getUserId() && needToSave.value)
      return i18n.t("articleContribution.labels.actionDisableBeforeSave");
  }

  // impossible de démarrer la relecture si la langue est verrouillée
  if (
    review.idContributor === authService.getUserId() &&
    languagesLocked.value[props.language]
  )
    return i18n.t("articleContribution.errors.cannotEditLanguage");

  return "";
}

/** Retourne le nom de l'utilisateur */
function getUserName(userId: string, contributionType: ContributionType) {
  if (userId === userIdForNewContribution) {
    if (contributionType === ContributionType.Editor)
      return i18n.t("contributionType.Editor");
    else i18n.t("articleContribution.labels.leadTranslatorToAssign");
  }

  const { usersLight } = useUserStore();
  const user = usersLight.find((el) => el.id == userId);
  if (user != null) return user.name;
  return i18n.t("user.deleted");
}

/** Retourne la classe à appliquer au chip */
function getClassFromState(state: ContributionState, userId: string) {
  let className = userId === authService.getUserId() ? "text-bold " : "";

  if (state === ContributionState.Unknown) return className;

  className += "text-white ";

  if (state === ContributionState.ReviewAccepted) className += "bg-teal";
  else className += "bg-red";

  return className;
}

/**
 * Retourne l'icone à afficher à gauche du chip.
 */
function getIconLeft(contributionType: ContributionType) {
  if (contributionType === ContributionType.Reviewer) return "rate_review";
  if (contributionType === ContributionType.Editor) return "reviews";

  return "translate";
}

/**
 * Retourne l'icone à afficher à droite du chip.
 * Si l'utilisateur est en cours de modification, on affiche une icône d'oeil.
 */
function getIconRight(contribution: ContributionDto, locks: ArticleLockDto[]) {
  if (
    props.state !== WorkflowState.Relecture &&
    props.state !== WorkflowState.RelectureTraducteurReferent
  )
    return "";

  const lock = locks.find(
    (l) =>
      l.userId === contribution.idContributor &&
      (l.modificationType & getModificationTypeFromLanguage(props.language)) > 0
  );
  if (lock) return "remove_red_eye";

  if (contribution.lastNotification) return "notifications";

  return "";
}

/**
 * Dialog pour demander le message a ajouter à la notification
 */
function askForNotificationMessage(contributionId: number) {
  dialog({
    title: i18n.t("reviewerMessage.title"),
    message: i18n.t("reviewerMessage.message"),
    prompt: {
      model: "",
      type: "textarea",
    },
    cancel: {
      color: "negative",
      label: i18n.t("reviewerMessage.btnCancel"),
    },
    ok: {
      color: "primary",
      label: i18n.t("reviewerMessage.btnOk"),
    },
    persistent: true,
  }).onOk((data: string) => {
    notifyReviewer(contributionId, data);
  });
}

/** Notification d'un relecteur */
async function notifyReviewer(contributionId: number, message: string) {
  const success = await contributionService.notify(contributionId, message);

  if (success) {
    notify({
      message: i18n.t("articleContribution.labels.notified"),
      color: "positive",
    });

    const contrib = contribs.value.find((c) => c.id === contributionId);

    if (!contrib) return;
    contrib.lastNotification = new Date().toISOString();
  }
}

/** Demarrer la review */
function startReview() {
  if (languagesLocked.value[props.language]) {
    notify({
      message: i18n.t("articleContribution.errors.cannotEditLanguage"),
      color: "negative",
    });
    return;
  }

  lockType.value |= getModificationTypeFromLanguage(props.language);
  insertLock();
}

/** Ajoute une contribution avec l'utilisateur courant d'un type précis */
function addContribution(type: ContributionType) {
  if (!canAddContributions()) return;

  currentArticle.value.contributions.push({
    id: 0,
    idContributor: authService.getUserId(),
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    idLanguage: props.language,
    state: ContributionState.Unknown,
    type,
  });

  updateContribs(props.contributions);
  startReview();
}

/** Verifie si l'on peut ajouter une contributions */
function canAddContributions() {
  if (baseIsLocked.value) {
    notify({
      message: i18n.t("articleContribution.errors.cannotEditParams"),
      color: "negative",
    });
    return false;
  }
  if (languagesLocked.value[props.language]) {
    notify({
      message: i18n.t("articleContribution.errors.cannotEditLanguage"),
      color: "negative",
    });
    return false;
  }

  return true;
}

/**
 * Notification d'un relecteur
 */
async function saveRateReview(contributionId: number, valid: boolean) {
  const contrib = contribs.value.find((c) => c.id === contributionId);

  if (!contrib) return;

  let newState = valid
    ? ContributionState.ReviewAccepted
    : ContributionState.ReviewRejected;

  if (!valid && contrib.type === ContributionType.Editor)
    newState = ContributionState.Unknown;

  const success = await contributionService.updateState(
    contributionId,
    newState
  );

  if (!success) return;

  contrib.state = newState;

  // on libère le verrou sur la langue en question
  lockType.value =
    lockType.value & ~getModificationTypeFromLanguage(props.language);

  if (lockType.value === 0) deleteLock();
  else insertLock();
}
</script>
