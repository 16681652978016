<template>
  <q-dialog ref="dialogRef" persistent @hide="onDialogHide">
    <div class="q-dialog-plugin">
      <q-card>
        <q-card-section>
          <div class="text-h6">
            {{ $t("articleContribution.popupAmount.title") }}
          </div>
        </q-card-section>
        <q-card-section>
          <q-input
            ref="amount"
            class="amountInput"
            type="number"
            filled
            placeholder="0"
            :model-value="amountRef"
            @update:model-value="handleUpdatedValue($event)"
          >
            <template #append>€</template>
          </q-input>
        </q-card-section>
        <q-card-section class="q-gutter-md on-right">
          <q-btn
            color="secondary"
            :label="$t('articleContribution.popupAmount.btnCancel')"
            @click="onDialogCancel()"
          />
          <q-btn
            color="primary"
            :label="$t('articleContribution.popupAmount.btnSave')"
            @click="onSaveAmount()"
          />
        </q-card-section>
      </q-card>
    </div>
  </q-dialog>
</template>

<script lang="ts" setup>
import { useDialogPluginComponent, useQuasar } from "quasar";
import { ref } from "vue";
import { ContributionDto } from "@/types/api";
import { articleService } from "@/services/ArticleService";
import { formatErrorMessage } from "@/utils";

const $q = useQuasar();

const { dialogRef, onDialogCancel, onDialogOK, onDialogHide } =
  useDialogPluginComponent();

const props = defineProps<{
  contribution: ContributionDto;
  articleId: number;
}>();

const amountRef = ref<number>(
  props.contribution.amount ? props.contribution.amount : 0
);

defineEmits([...useDialogPluginComponent.emits]);

/**
 * Sauvegarde le montant pour la contribution
 */
async function onSaveAmount() {
  console.log("contribution", props.contribution);
  await articleService
    .setAmountToContribution(
      props.articleId,
      props.contribution.id,
      amountRef.value
    )
    .then(() => onDialogOK())
    .catch((err) => {
      $q.notify({
        type: "negative",
        message: formatErrorMessage(
          err,
          "articleContribution.errors.onSaveAmount"
        ),
      });
    });
}

/**
 * Gére la valeur entrée dans l'input.
 */
function handleUpdatedValue(selected: string | number | null) {
  if (selected === null) amountRef.value = 0;
  if (typeof selected === "string") {
    if (selected.length === 0) amountRef.value = 0;
    else amountRef.value = Number(selected);
  } else amountRef.value = selected as number;
}
</script>
<style lang="scss">
.amountInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
