import type { SectionDto } from "@/types/api";
import type { SectionState } from "@/types/section";
import { defineStore } from "pinia";
import { sectionService } from "@/services/SectionService";
import { usePublicationStore } from "@/stores/publication";
import { i18n } from "@/i18n";
import { compareSectionsByLabel } from "@/helpers/section";

export const useSectionStore = defineStore({
  id: "section",
  state: (): SectionState => ({
    sections: [],
  }),
  getters: {
    groupByPublications(state) {
      const publicationStore = usePublicationStore();
      return publicationStore.publications.map((publication) => ({
        ...publication,
        sections: state.sections.filter((section) =>
          section.sectionPublications.some(
            (p) => p.publication === publication.id
          )
        ),
      }));
    },
  },
  actions: {
    filterSectionsByPublication(publicationId: string, onlyActive = true) {
      const sections = this.sections.filter(
        (s) =>
          (!onlyActive || s.active) &&
          s.sectionPublications.some(
            (p) => p.publication === publicationId && (!onlyActive || p.active)
          )
      );

      return sections.sort(compareSectionsByLabel);
    },
    async fetchSections() {
      this.sections = (await sectionService.getList()).sort(
        compareSectionsByLabel
      );
    },
    findSection(id: number | undefined): SectionDto {
      const section = this.sections.find((s) => s.id === id);
      if (section) return section;
      else throw new Error(i18n.t("section.errors.notFound"));
    },
    async addSection(newSection: SectionDto) {
      try {
        const section = await sectionService.insert(newSection);
        this.sections.push(section);
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("section.errors.create"));
      }
    },
    async updateSection(section: SectionDto) {
      try {
        const sectionUpdated = await sectionService.update(section);
        const sectionIndex = this.sections.findIndex(
          (s) => s.id === section.id
        );
        this.sections[sectionIndex] = sectionUpdated;
      } catch (error) {
        console.error(error);
        throw new Error(i18n.t("section.errors.update"));
      }
    },
  },
});
