<template>
  <PlanningArticle :published-from="publishedFrom" v-on="eventsParamsChanged" />
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { RoutesName } from "@/router/routesName";
import PlanningArticle from "@/components/Article/PlanningArticle.vue";

const router = useRouter();
const route = useRoute();
const publishedFrom = computed(() => route.query.publishedFrom as string);
const eventsParamsChanged = {
  "update:publishedFrom": (publishedFromPayload) =>
    handleParamsChanged("publishedFrom", publishedFromPayload),
};

/**
 *
 */
function handleParamsChanged(paramName: string, paramValue: string) {
  router.push({
    name: RoutesName.Planning,
    query: {
      ...route.query,
      [paramName]: paramValue,
    },
  });
}
</script>
