<template>
  <PageWithPanel>
    <template #default>
      <div class="q-gutter-sm q-px-xs-sm q-px-sm-none">
        <q-card class="q-mb-md">
          <q-tabs
            v-model="tab"
            class="bg-primary text-white"
            align="left"
            narrow-indicator
            @update:model-value="onTabChanged"
          >
            <q-tab
              :name="RoutesName.FreelanceManagement"
              :label="$t('contribution.freelances.titles.management')"
            />
            <q-tab
              :name="RoutesName.FreelanceBudget"
              :label="$t('contribution.freelances.titles.budget')"
            />
            <q-tab
              :name="RoutesName.FreelanceConsumption"
              :label="$t('contribution.freelances.titles.consumption')"
            />
          </q-tabs>
          <q-separator />
          <q-tab-panels v-model="tab" animated>
            <q-tab-panel :name="RoutesName.FreelanceManagement">
              <FreelanceManagement />
            </q-tab-panel>
            <q-tab-panel :name="RoutesName.FreelanceBudget">
              <FreelanceBudget />
            </q-tab-panel>
            <q-tab-panel :name="RoutesName.FreelanceConsumption">
              <FreelanceConsumption />
            </q-tab-panel>
          </q-tab-panels>
        </q-card>
      </div>
    </template>
  </PageWithPanel>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { RoutesName } from "@/router/routesName";
// Components
import PageWithPanel from "@/components/Layouts/PageWithPanel.vue";
import FreelanceManagement from "@/components/Freelance/FreelanceManagement.vue";
import FreelanceBudget from "@/components/Freelance/FreelanceBudget.vue";
import FreelanceConsumption from "@/components/Freelance/FreelanceConsumption.vue";
// Composables

const { name } = useRoute();
const router = useRouter();

const tab = ref<string>(name as string);

router.afterEach((to) => {
  tab.value = to.name as string;
});
/** Fonction appelé au moment du changement d'onglet. */
function onTabChanged(value) {
  router.push({ name: value });
}
</script>
