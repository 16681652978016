<template>
  <div class="q-gutter-sm" :class="cssItemClass">
    <div>
      <div class="row q-gutter-sm">
        <q-select
          :class="
            showLanguage || showAmount ? 'col-12 col-sm' : 'col-12 col-sm'
          "
          :disable="disable"
          :model-value="contribution.idContributor"
          :options="contributorsFiltered"
          emit-value
          map-options
          use-input
          filled
          dense
          input-debounce="50"
          autocomplete="mainLabel"
          @filter="filterInput"
          @update:model-value="
            (selected: string) => {
              contribution.idContributor = selected;
              $emit('update:modelValue', contribution);
            }
          "
        >
          <template #prepend>
            <q-icon name="person" />
          </template>
        </q-select>
        <q-select
          v-if="showLanguage"
          class="col-12 col-sm"
          :disable="disable"
          :model-value="contribution.idLanguage"
          :options="languages"
          :label="$t('articleContribution.fields.language')"
          :rules="[
            (v) => {
              if (!showLanguage) return true;
              return v !== null || `error`;
            },
          ]"
          emit-value
          map-options
          filled
          dense
          autocomplete="mainLabel"
          @update:model-value="
            (selected: string | undefined) => {
              contribution.idLanguage = selected;
              $emit('update:modelValue', contribution);
            }
          "
        />

        <q-input
          v-if="showAmount"
          class="col-12 col-sm amountInput"
          style="-webkit-appearance: none; -moz-appearance: textfield"
          type="number"
          :model-value="contribution.amount"
          :disable="disable"
          :label="$t('articleContribution.fields.amount')"
          filled
          dense
          @update:model-value="handleUpdatedValue($event)"
        >
          <template #append>€</template> </q-input
        ><q-btn
          v-if="showButtonAmount"
          :disable="disableBtnAmount"
          :label="$t('articleContribution.labels.btnAddAmount')"
          color="primary"
          @click="openDialogAmount"
        />

        <q-btn
          v-if="!disable"
          class="col-12 col-sm-1"
          :tooltip="$t('articleContribution.labels.btnDelete')"
          color="grey"
          :round="$q.screen.gt.xs"
          outline
          icon="delete"
          :style="$q.screen.gt.xs ? 'width: 40px; height: 40px' : undefined"
          :label="
            $q.screen.lt.sm
              ? $t('articleContribution.labels.btnDelete')
              : undefined
          "
          @click="onRemove"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { QSelectOption, useQuasar } from "quasar";
import { ContributionDto, ContributionType } from "@/types/api";
import { computed, ref, toRefs } from "vue";
import { i18n } from "@/i18n";
import { useLanguageStore } from "@/stores/language";
import DialogAddAmount from "@/components/Article/Contribution/DialogAddAmountContribution.vue";
import { useCurrentArticleStore } from "@/stores/currentArticle";
import { storeToRefs } from "pinia";
import { authService } from "@/services/AuthService";
import { removeDiacritics } from "@/utils";
import { getContributorOptions } from "@/helpers/contributor";

const { currentArticle: articleForm } = storeToRefs(useCurrentArticleStore());

const languageStore = useLanguageStore();

const $q = useQuasar();

const props = withDefaults(
  defineProps<{
    modelValue: ContributionDto;
    publication: string;
    disable?: boolean;
    editable?: boolean;
    articleId: number;
  }>(),
  {
    disable: false,
    editable: false,
  }
);

const disableBtnAmount =
  !articleForm.value.contributions.some(
    (p) =>
      p.type == ContributionType.Author &&
      p.idContributor == authService.currentUser?.id
  ) || authService.hasRight("PUBLICATION_ARTICLE_CONTRIBUTION_UpdateAmount");

const { modelValue: contribution } = toRefs(props);

const emits = defineEmits<{
  (e: "update:modelValue", value: ContributionDto): void;
  (e: "remove-contribution", value: ContributionDto): void;
}>();

const showAmount = computed<boolean>(() => {
  return props.modelValue.type === ContributionType.Freelancer;
});

const showButtonAmount = computed<boolean>(() => {
  return props.disable && props.modelValue.type == ContributionType.Freelancer;
});

const showLanguage = computed<boolean>(() => {
  return props.modelValue.type === ContributionType.Reviewer;
});

const cssItemClass = computed<string>(() => {
  return props.editable ? "" : "new-contribution q-my-sm q-py-sm";
});

const contributors = computed<QSelectOption<string>[]>(() => {
  return getContributorOptions({
    type: props.modelValue.type,
    publication: props.publication,
    idUserToAdd: props.modelValue.idContributor,
  });
});
const contributorsFiltered = ref<QSelectOption<string>[]>(contributors.value);

const languages = computed<QSelectOption<string | null>[]>(() => {
  return [
    {
      value: null,
      label: "",
    },
    ...languageStore.languages.map((l) => ({
      value: l,
      label: i18n.t(`languages.${l}`),
    })),
  ];
});

/**
 *
 */
function onRemove() {
  emits("remove-contribution", props.modelValue);
}

/**
 * Ouvre la modal pour ajouter un montant
 */
function openDialogAmount() {
  $q.dialog({
    component: DialogAddAmount,
    componentProps: {
      contribution: contribution.value,
      articleId: props.articleId,
    },
  }).onOk(() => {
    window.location.reload();
  });
}

/**
 * Gére la valeur entrée dans l'input.
 */
function handleUpdatedValue(selected: string | number | null) {
  if (selected === null) contribution.value.amount = 0;
  if (typeof selected === "string") {
    if (selected.length === 0) contribution.value.amount = 0;
    else contribution.value.amount = Number(selected);
  } else contribution.value.amount = selected as number;
}
/**
 * Fonction qui sert a filtrer dans les options
 */
function filterInput(val, update) {
  if (val === "") {
    update(() => {
      contributorsFiltered.value = contributors.value;
    });
    return;
  }

  update(() => {
    const needle = removeDiacritics(val).toLowerCase();
    contributorsFiltered.value = contributors.value.filter(
      (v) => removeDiacritics(v.label).toLowerCase().indexOf(needle) > -1
    );
  });
}
</script>
<style lang="scss">
.new-contribution {
  position: relative;
}
.new-contribution:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% + 8px);
  height: 100%;
  border: 2px solid #d84315;
  border-radius: 5px;
}

.amountInput {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
}
</style>
