<template>
  <div>
    <div class="q-mb-lg">
      <div class="text-subtitle1">{{ $t("import.titles.importArticle") }}</div>
      <q-form class="q-gutter-md" @submit="onSubmit" @reset="onReset">
        <q-select
          v-model="publication"
          filled
          :options="publications"
          :label="$t('import.fields.publications')"
          stack-label
        />

        <div class="row q-col-gutter-sm">
          <div class="col q-pl-none">
            <InputDate
              v-model="startDate"
              type="date"
              :label="$t('import.fields.startDate')"
              add-year
            />
          </div>
          <div v-if="!onlyUpdatedArticles" class="col">
            <InputDate
              v-model="endDate"
              type="date"
              :label="$t('import.fields.endDate')"
              add-year
            />
          </div>
        </div>

        <q-checkbox
          v-model="onlyUpdatedArticles"
          :label="$t('import.fields.onlyUpdatedArticles')"
          class="q-mt-md"
        />

        <div>
          <div class="q-gutter-sm">
            <q-btn
              :label="$t('import.labels.btnImport')"
              type="submit"
              color="primary"
            />
            <q-btn
              :label="$t('import.labels.btnReset')"
              type="reset"
              color="primary"
              flat
              class="q-ml-sm"
            />
          </div>
        </div>
      </q-form>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { useImportActions } from "@/composables/useImportActions";
import InputDate from "@/components/Custom/CustomInputDate.vue";

const { actions } = useImportActions();
const { importArticles } = actions;

const publication = ref("LMR");
const startDate = ref("2022/01/01");
const endDate = ref("2023/01/01");
const publications = ["LLA", "LMR", "AIF", "GLF"];
const onlyUpdatedArticles = ref(false);

/**
 *
 */
function onSubmit() {
  importArticles(
    publication.value,
    startDate.value,
    endDate.value,
    onlyUpdatedArticles.value
  );
}

/**
 *
 */
function onReset() {
  publication.value = "LMR";
  startDate.value = "2022/01/01";
  endDate.value = "2023/01/01";
}
</script>
