<template>
  <div v-if="language === 'all'">
    <div v-if="wordSignCountFr">
      <div>
        <div>
          {{
            $t("article.fields.countWords", [
              wordSignCountFr.wordCount.toLocaleString(),
            ])
          }}
        </div>
        <div>
          {{
            $t("article.fields.countChars", [
              wordSignCountFr.signCount.toLocaleString(),
            ])
          }}
        </div>
      </div>
    </div>
    <div v-if="wordSignCountEn">
      <div>
        <div>
          {{
            $t("article.fields.countWords", [
              wordSignCountEn.wordCount.toLocaleString(),
            ])
          }}
        </div>
        <div>
          {{
            $t("article.fields.countChars", [
              wordSignCountEn.signCount.toLocaleString(),
            ])
          }}
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-if="wordSignCount[language]">
      <div>
        <div>
          {{
            $t("article.fields.countWords", [
              wordSignCount[language].wordCount.toLocaleString(),
            ])
          }}
        </div>
        <div>
          {{
            $t("article.fields.countChars", [
              wordSignCount[language].signCount.toLocaleString(),
            ])
          }}
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { ArticleODataDto } from "@/types/api";
import { toRefs, computed } from "vue";
import { FrenchLanguage, EnglishLanguage } from "@/constants";

const props = defineProps<{
  data: ArticleODataDto;
  language: string;
  otherLanguage: string;
}>();
const { data } = toRefs(props);

const contentFr = computed(() => {
  return data.value.contents.find((c) => c.language === FrenchLanguage);
});
const contentEn = computed(() => {
  return data.value.contents.find((c) => c.language === EnglishLanguage);
});

const wordSignCountFr = computed(() => {
  return {
    wordCount: contentFr.value?.wordCount ?? 0,
    signCount: contentFr.value?.charCount ?? 0,
  };
});
const wordSignCountEn = computed(() => {
  return {
    wordCount: contentEn.value?.wordCount ?? 0,
    signCount: contentEn.value?.charCount ?? 0,
  };
});
const wordSignCount = computed(() => {
  return {
    [FrenchLanguage]: wordSignCountFr.value,
    [EnglishLanguage]: wordSignCountEn.value,
  };
});
</script>
