import type { CrudServiceInstance } from "@/types/crud";
import type { LanguageDto } from "@/types/api";
import type { LanguageServiceInstance } from "@/types/language";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { CrudService } from "@/services/CrudService";

/**
 * Service pour récupérer la liste des langues
 */
class LanguageService extends AxiosRequest implements LanguageServiceInstance {
  crudService: CrudServiceInstance<LanguageDto>;
  /**
   * Constructeur de la class Language service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<LanguageDto>(rootPath);
  }
  /**
   * Récupère la liste des langues
   */
  async getList() {
    const languages = await this.crudService.getList(
      undefined,
      undefined,
      true
    );
    return languages.map((l) => l.id);
  }
}

export const languageService = new LanguageService("languages");
