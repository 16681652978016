import { ContributionType, WorkflowState } from "@/types/api";
import { QSelectOption } from "quasar";

/**
 * Interface pour le model de recherche.
 */
export interface SearchPart {
  type: string;
  value: unknown;
  searchValue: string;
  label: string;
  icon: string;
  publications: string[];
  color: string;
  order: number;
}

/**
 * Extrait des valeurs string pour executer la recherche.
 */
export function extractStringValue(
  search: QSelectOption<SearchPart>[],
  type: string
) {
  return search
    .filter((s) => s.value.type === type)
    .map((s) => `${s.value.value}`);
}

/**
 * Extrait un paramètre de recherche de la selection des options.
 */
export function extractSearchParamFomSelectOption(
  search: QSelectOption<SearchPart>[],
  page: number,
  filterOnlyOnTitleAndStandFirst: boolean,
  addDefaultParam = true
) {
  return {
    page: page,
    nbByPage: 10,
    fridge: false,
    deleted: addDefaultParam ? false : undefined,
    publishedAt: extractDateValue(search, "PublishedAt"),
    from: extractDateValue(search, "From"),
    to: extractDateValue(search, "To", 1),
    cms: [...extractIntValue(search, "CMS")],
    interests: [...extractIntValue(search, "Interest")],
    countries: [...extractIntValue(search, "Country")],
    contributions: [...extractContributions(search)],
    publications: [...extractStringValue(search, "Publication")],
    formats: [...extractStringValue(search, "Format")],
    formatsExcluded: [...extractStringValue(search, "FormatExcluded")],
    sections: [...extractIntValue(search, "Section")],
    states: extractValue<WorkflowState>(search, "State"),
    statesExcluded: [],
    statesAdvanced: [],
    texts: [...extractStringValue(search, "Text")],
    idWorkflow: [...extractIntValue(search, "ID_Workflow")],
    idContentWorkflow: [...extractIntValue(search, "ID_ContentWorkflow")],
    idBackOffice: [...extractIntValue(search, "ID_BackOffice")],
    language:
      [...extractStringValue(search, "Language")].length === 1
        ? [...extractStringValue(search, "Language")][0]
        : undefined,
    onlyOnTitleAndStandfirst: filterOnlyOnTitleAndStandFirst,
  };
}

/**
 * Extrait des valeurs date pour executer la recherche.
 */
export function extractDateValue(
  search: QSelectOption<SearchPart>[],
  type: string,
  offset = 0
) {
  const find = search
    .filter((s) => s.value.type === type)
    .map((s) => `${s.value.value}`);

  if (find.length > 0) {
    const selectedDate = find[0].split(":")[1];
    const date = convertToDate(selectedDate);
    if (offset !== 0) {
      date?.setDate(date.getDate() + offset);
      date?.setMilliseconds(-1);
    }
    return date?.toISOString();
  } else {
    return undefined;
  }
}

/**
 * Extrait des valeurs int pour executer la recherche.
 */
export function extractIntValue(
  search: QSelectOption<SearchPart>[],
  type: string
) {
  return search
    .filter((s) => s.value.type === type)
    .map((s) => parseInt(`${s.value.value}`));
}

/**
 * Extrait des valeurs T typé pour executer la recherche.
 */
export function extractValue<T>(
  search: QSelectOption<SearchPart>[],
  type: string
) {
  return search
    .filter((s) => s.value.type === type)
    .map((s) => s.value.value as T);
}

/**
 * Extrait les contributions pour executer la recherche.
 */
export function extractContributions(search: QSelectOption<SearchPart>[]) {
  return search
    .filter((s) => s.value.type === "User")
    .map((s) => {
      const values = `${s.value.value}`.split(":");
      return {
        user: values[1],
        type: values[0] as ContributionType,
      };
    });
}

/**
 * Convertit une chaine en date si le format est respecté DD/MM.
 */
export function convertToDate(str: string): Date | null {
  let reg = /^(\d{2})\/(\d{2})$/;

  let matches = str.match(reg);
  if (!matches) {
    reg = /^(\d{2})\/(\d{2})\/(\d{4})$/;
    matches = str.match(reg);
  }

  if (!matches) return null;

  const day = parseInt(matches[1]);
  const month = parseInt(matches[2]);
  const year = matches[3] ? parseInt(matches[3]) : new Date().getFullYear();

  if (day < 1 || day > 31) return null;

  if (month < 1 || month > 12) return null;

  if (year < 1900 || year > new Date().getFullYear() + 1) return null;

  return new Date(Date.UTC(year, month - 1, day));
}
