import type { Editor } from "@ckeditor/ckeditor5-core";
import { SuggestionData } from "@ckeditor/ckeditor5-track-changes";
import { suggestionService } from "@/services/SuggestionService";
import { SuggestionDto } from "@/types/api";
import { FormatData } from "@ckeditor/ckeditor5-track-changes/src/trackchangesediting";
import {
  AddSuggestionInput,
  TrackChangesAdapter as CkTrackChangesAdapter,
  UpdateSuggestionInput,
} from "@ckeditor/ckeditor5-track-changes/src/trackchanges";

/**
 *
 */
class SuggestionDataImpl implements SuggestionData {
  attributes: Record<string, any>;
  authorId: string;
  createdAt: Date;
  data?: FormatData | null;
  hasComments: boolean;
  id: string;
  type: string;

  /**
   *
   */
  constructor(dto: SuggestionDto) {
    this.authorId = dto.authorId;
    this.createdAt =
      dto.createdAt === undefined ? new Date() : new Date(dto.createdAt);
    this.data = dto.data ? (dto.data as FormatData) : undefined;
    this.attributes = dto.attributes ?? {};
    this.hasComments = dto.hasComments;
    this.id = dto.id;
    this.type = dto.type;
  }
}

/**
 *
 */
class TrackChangesAdapter implements CkTrackChangesAdapter {
  editor: Editor;

  /**
   *
   */
  constructor(editor: Editor) {
    this.editor = editor;
  }

  /**
   *
   */
  init() {
    const trackChangesPlugin = this.editor.plugins.get("TrackChanges");

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    trackChangesPlugin.adapter = this;
  }

  /** @type {TrackChangesAdapter.getSuggestion} */
  getSuggestion(id: string) {
    return suggestionService.getSuggestion(id).then((suggestion) => {
      return new SuggestionDataImpl(suggestion);
    });
  }

  /** @type {TrackChangesAdapter.addSuggestion} */
  addSuggestion(suggestionData: AddSuggestionInput) {
    return suggestionService
      .addSuggestion({
        ...suggestionData,
        hasComments: suggestionData.hasComments ?? false,
        authorId: "",
        data: suggestionData.data ? suggestionData.data : undefined,
        originalSuggestionId: suggestionData.originalSuggestionId || "",
      })
      .then((suggestion) => {
        return new SuggestionDataImpl(suggestion);
      });
  }

  /** @type {TrackChangesAdapter.updateSuggestion} */
  updateSuggestion(id: string, suggestionData: UpdateSuggestionInput) {
    if (suggestionData === undefined) return Promise.resolve();
    return suggestionService
      .updateSuggestion({
        id: id,
        ...suggestionData,
      })
      .then(() => {
        return;
      });
  }
}

export default TrackChangesAdapter;
