<template>
  <div>
    <q-btn
      v-if="medias.length > 0"
      :label="$t('media.actions.btnSave')"
      color="primary"
      class="q-ml-xs-md"
      @click="updateMedias"
    />
    <q-chip v-else>{{ $t("media.labels.NoMedias") }}</q-chip>
    <q-list>
      <q-item v-for="med in medias" :key="med.id">
        <q-item-section>
          <div class="row">
            <div class="col-12 col-md-2">
              <q-img
                :src="image(med)"
                :alt="med.filePath"
                style="height: 140px"
              >
                <div class="absolute-bottom text-subtitle3 text-center">
                  {{ med.filePath }}
                </div>
              </q-img>
            </div>
            <div class="col-12 col-md-10 q-px-md-sm q-gutter-sm">
              <q-input
                v-model="med.legendFr"
                filled
                dense
                autogrow
                :label="$t('media.labels.LegendeFr')"
              />
              <q-input
                v-model="med.copyrightFr"
                filled
                dense
                :label="$t('media.labels.CopyrightsFr')"
              />
              <q-input
                v-model="med.legendEn"
                filled
                dense
                autogrow
                :label="$t('media.labels.LegendeEn')"
              />
              <q-input
                v-model="med.copyrightEn"
                filled
                dense
                :label="$t('media.labels.CopyrightsEn')"
              />
            </div>
          </div>
        </q-item-section>
      </q-item>
    </q-list>
  </div>
</template>

<script lang="ts" setup>
import { MediaDto } from "@/types/api";
import { conf_elcanoapi_current } from "@/constants";
import { mediaService } from "@/services/MediaService";
import { watch, ref } from "vue";
import { useQuasar } from "quasar";
import { i18n } from "@/i18n";
import { handlePromiseResults } from "@/utils";

// eslint-disable-next-line vue/no-setup-props-destructure
const props = defineProps<{
  mediasProps: MediaDto[];
}>();

const medias = ref<MediaDto[]>(props.mediasProps);

const $q = useQuasar();

watch(
  () => props.mediasProps,
  (newMedias: MediaDto[]) => {
    medias.value = newMedias;
  }
);

/**
 * Renvoie le lien de l'image.
 */
function image(media: MediaDto) {
  return conf_elcanoapi_current + "/medias/files/Images" + media.filePath;
}

/**
 * Sauvegarde les modifications des medias.
 */
async function updateMedias() {
  const promises: Promise<MediaDto>[] = [];

  medias.value.forEach((media) => {
    promises.push(mediaService.update(media));
  });

  const results = await Promise.allSettled(promises);
  try {
    handlePromiseResults(results);
    $q.notify({
      type: "positive",
      message: i18n.t("media.notifications.onSave"),
    });
  } catch (err) {
    for (const error of (err as AggregateError).errors) {
      $q.notify({
        type: "negative",
        message: i18n.t("exceptions.MediaSaveException.default", {
          filePath: error.message,
        }),
      });
    }
  }
}
</script>
