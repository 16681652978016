import type { CrudServiceInstance } from "@/types/crud";
import type { EditionServiceInstance } from "@/types/user";
import { CrudService } from "@/services/CrudService";
import { AxiosRequest } from "@/services/ElcanoAPI";
import { AxiosResponse } from "axios";
import { fileDownload } from "@/fileDownload";

/**
 * Service pour récupérer la liste des utilisateurs
 */
class EditionService extends AxiosRequest implements EditionServiceInstance {
  crudService: CrudServiceInstance<boolean>;
  /**
   * Constructeur de la class User service
   */
  constructor(public rootPath: string) {
    super();
    this.crudService = new CrudService<boolean>(rootPath);
  }

  /**
   * Récupère si l'édition est verrouillée
   * @param publicationId - id de la publication
   * @param date - date de l'édition
   * @returns true si l'édition est verrouillée, false sinon
   */
  isLocked(publicationId: string, date: Date) {
    return this.axiosInstance
      .get(this.constructPath(publicationId, date))
      .then((response: AxiosResponse<boolean>) => {
        if (response.status !== 200) return false;
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   * Verrouille une édition
   * @param publicationId - id de la publication
   * @param date - date de l'édition
   * @param lock - lock
   * @returns true si tout s'est bien passé, false sinon
   */
  setLock(publicationId: string, date: Date, lock: boolean) {
    const method = lock ? "put" : "delete";
    return this.axiosInstance[method](this.constructPath(publicationId, date))
      .then((response: AxiosResponse<boolean>) => {
        if (response.status !== 200) return false;
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  getHtmlEdition(publicationId: string, language: string, date: Date) {
    return this.axiosInstance
      .get(
        `${this.rootPath}/${publicationId}/${date.getFullYear()}/${
          date.getMonth() + 1
        }/${date.getDate()}/${language}/htmlpdf`
      )
      .then((response: AxiosResponse<string>) => {
        if (response.status !== 200) return "";
        return response.data;
      })
      .catch(this.handleError);
  }

  /**
   *
   */
  getPdfEdition(publicationId: string, language: string, date: Date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate();
    const path = `${this.rootPath}/${publicationId}/${year}/${month}/${day}/${language}`;

    return this.axiosInstance
      .get(`${path}/pdf`, { responseType: "blob" })
      .then((response) => {
        fileDownload(response.data, `${path.replaceAll("/", "_")}.pdf`);
      });
  }

  /**
   * Construit l'url.
   * @param publicationId - id de la publication
   * @param date - date de l'édition
   * @returns url de l'api
   */
  constructPath(publicationId: string, date: Date) {
    return `${this.rootPath}/${publicationId}/${date.getFullYear()}/${
      date.getMonth() + 1
    }/${date.getDate()}/locked`;
  }
}

export const editionService = new EditionService("editions");
